/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
    UserModel,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

interface TrainingsessionSets {
    enddate: DateTime;
    startdate: DateTime;
    value: string;
    wdh: string;
}

interface Exercise {
    name: string;
}

interface TrainingsessionExercise {
    id: number;
    end: DateTime;
    start: DateTime;
    trainingsessions_sets: TrainingsessionSets[];
    exercise: Exercise;
}

interface Uploads {
    id: number;
}

interface Feedback {
    id: number;
    foreign_key: number;
    model: string;
    type: number;
    text: string;
    feedback: number;
}

interface ExtendedUserModel extends UserModel {
    uploads: Uploads[];
}

export interface TrainingsessionModel extends Model {
    workoutschedule: {
        name: string;
    };
    workoutschedule_id?: number;
    user: ExtendedUserModel;
    user_id?: number;
    start?: DateTime | string;
    end?: DateTime | string;
    trainingsessions_exercises: TrainingsessionExercise[];
    trainingsessionfeedbacks: Feedback[];
}

interface TrainingsessionUserStatistic {
    dates: string[];
    values: number[];
}

export interface TrainingsessionUserSpecial {
    total: number;
    last: number;
    days: number;
    lovedHour: {
        values: number[];
        dates: string[];
    };
    lovedDay: {
        values: string[];
        dates: string[];
    };
}

export interface TrainingsessionsModelAddModel extends Partial<TrainingsessionModel> {
    id?: number;
    end?: DateTime | string;
}

export interface TrainingsessionModelPaginator extends Paginator {
    trainingsession: TrainingsessionModel[];
}

export default class TrainingsessionsAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    TrainingsessionModel,
    TrainingsessionModelPaginator,
    TrainingsessionsModelAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Trainingsessions", "Trainingsession", "Trainingsessions", adapter);
    }

    public async getHistory(
        id: number | null,
        page: number
    ): Promise<TrainingsessionModelPaginator> {
        const url = id ? `/${id}?page=${page}` : `?page=${page}`;

        const { data } = await this._get<TrainingsessionModelPaginator>(
            `viewPastTrainingsessions${url}`
        );

        return data;
    }

    public async getWeekCoachCountStatistic(id: number): Promise<TrainingsessionUserStatistic> {
        const { data } = await this._get<TrainingsessionUserStatistic>(
            `getWeekCoachCountStatistic/${id}`
        );

        return data;
    }

    public async getUserViewSpecialStats(id: number): Promise<TrainingsessionUserSpecial> {
        const { data } = await this._get<TrainingsessionUserSpecial>(
            `getUserViewSpecialStats/${id}`
        );

        return data;
    }
}
