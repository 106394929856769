/**
 * /* eslint react/destructuring-assignment: 0
 *
 * @format
 */

import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface OptionProps {
    sendData: Dispatch<SetStateAction<string>>;
    option: string;
}

const Option: React.FC<OptionProps> = ({ sendData, option }) => {
    const [t] = useTranslation();

    const dataOption = option.split(",");

    return (
        <div className="col-6">
            <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                    {t("global.option", "Option")}
                </span>

                <select
                    name="option"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    className="text-right"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => sendData(e.target.value)}
                >
                    <option value="/">-</option>
                    {dataOption.map((x) => {
                        return (
                            <option value={x} key={x}>
                                {x}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
};

export default Option;
