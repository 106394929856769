/** @format */

export const makeCurrencyFormatter = (currency: string): Intl.NumberFormat => {
    return new Intl.NumberFormat(undefined, {
        style: "currency",
        currency,
    });
};

export const makeNumberFormatter = (): Intl.NumberFormat => {
    return new Intl.NumberFormat();
};
