/** @format */

import { ExercisesWorkoutschedules } from "@api/ext/WorkoutschedulesAdapter";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface AssignedExercisesProps {
    exercisesWorkoutschedules: {
        [key: number]: ExercisesWorkoutschedules[];
    };
}

interface ExercisesImageProps {
    image: ExercisesWorkoutschedules;
}

const typesExercises: { [key: string]: string } = {
    key1: "Warm Up",
    key2: "Hauptteil",
    key3: "Finischer/Challange",
    key4: "Cooldown",
};

const ExercisesImage: React.FC<ExercisesImageProps> = ({ image }) => {
    if (!image || !image.exercise) {
        return null;
    }

    if (!image.exercise.uploads) {
        return null;
    }

    if (image.exercise.uploads?.length > 0) {
        return (
            <Link to={`/exercises/view/${image.exercise.id}`}>
                <div className="d-flex justify-content-center text-center">
                    <div
                        className="exercise-table-image"
                        style={{
                            background: `url(${image.exercise.uploads[0].url})`,
                        }}
                    />
                </div>
            </Link>
        );
    }

    return (
        <Link to={`/exercises/view/${image.exercise.id}`}>
            <div className="d-flex justify-content-center text-center">
                <div className="exercise-table-image-placeholder">
                    {image.exercise.name.charAt(0) + image.exercise.name.charAt(1)}
                </div>
            </div>
        </Link>
    );
};

const AssignedExercises: React.FC<AssignedExercisesProps> = ({ exercisesWorkoutschedules }) => {
    const [t] = useTranslation();

    return (
        <div className="row">
            {Object.keys(exercisesWorkoutschedules).map((value) => (
                <React.Fragment key={`group-${value}`}>
                    <h5 className="info-view-headline">{typesExercises["key" + String(value)]}</h5>
                    {exercisesWorkoutschedules[Number(value)].map((data) => {
                        if (!data.exercise) {
                            return;
                        }

                        return (
                            <div key={`exercise-${data.id}`} className="col-md-4 mt-2">
                                <div className="inner-view-container">
                                    <div className="d-flex align-items-center">
                                        <ExercisesImage image={data} />
                                        <h6 className="ms-3">{data.exercise.name}</h6>
                                    </div>
                                    <div className="show-set-count">
                                        <span>
                                            {String(data.exercises_workoutschedules_sets?.length) +
                                                ". "}
                                            {data.exercises_workoutschedules_sets &&
                                            data.exercises_workoutschedules_sets?.length <= 1
                                                ? t("assignedExercises.set", "Satz")
                                                : t("assignedExercises.set_plural", "Sätze")}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column mt-1">
                                        {data.exercises_workoutschedules_sets?.map((set, index) => (
                                            <span
                                                key={`set-${index}-${set.id}`}
                                                className="set-name-view"
                                            >
                                                {String(index + 1) + ". "} {set.value}{" "}
                                                {set.value && set.wdh ? "-" : ""} {set.wdh}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </React.Fragment>
            ))}
        </div>
    );
};

export default AssignedExercises;
