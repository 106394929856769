/** @format */

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `inter-coach-coach@0.0.1`,
    debug: process.env.NODE_ENV === "development",
    environment: process.env.NODE_ENV,
    beforeSend(event) {
        if (event.user) {
            delete event.user.ip_address;
            delete event.user.email;
        }

        return event;
    },
});
