/** @format */

import AuthContext from "@context/AuthProvider";
import React, { useContext } from "react";

import AdminNav from "./AdminNav";
import ClientNav from "./ClientNav";

import "./MainNav.scss";

const MainNav: React.FC = () => {
    const { user } = useContext(AuthContext);

    return (
        <header>
            <div className="top-navigation-wrapper">
                <div id="menue-root">
                    <nav className="top-navigation d-flex">
                        <div className="main-nav">
                            {!user ? null : !user.is_admin && !user.is_coach ? (
                                <ClientNav />
                            ) : (
                                <AdminNav />
                            )}
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default MainNav;
