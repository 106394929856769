/** @format */

import {
    faChevronDoubleLeft,
    faChevronLeft,
    faChevronRight,
    faChevronDoubleRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./Pagination.scss";

interface PaginationInterface {
    pageCount: number;
    pageIndex: number;
    pageOptions: {
        length: number;
    };
    pageSize: number;

    canNextPage: boolean;
    canPreviousPage: boolean;

    gotoPage: (page: number) => void;
    previousPage: () => void;
    nextPage: () => void;

    setPageSize: (size: number) => void;
}

const Pagination: React.FC<PaginationInterface> = ({
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,

    canNextPage,
    canPreviousPage,

    gotoPage,
    previousPage,
    nextPage,

    setPageSize,
}) => {
    return (
        <div className="pagination">
            <button
                type="button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="sort-icon-pagination"
            >
                <FontAwesomeIcon icon={faChevronDoubleLeft} className="pagination-icon" />
            </button>{" "}
            <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="sort-icon-pagination"
            >
                <FontAwesomeIcon icon={faChevronLeft} className="pagination-icon" />
            </button>{" "}
            <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="sort-icon-pagination"
            >
                <FontAwesomeIcon icon={faChevronRight} className="pagination-icon" />
            </button>{" "}
            <button
                type="button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="sort-icon-pagination"
            >
                <FontAwesomeIcon icon={faChevronDoubleRight} className="pagination-icon" />
            </button>{" "}
            <span>
                Seite{" "}
                <strong>
                    {pageIndex + 1} von {pageOptions.length}
                </strong>{" "}
            </span>
            <select
                className="form-control form-pagination"
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[10, 20, 30, 40, 50].map((pageSizeMap) => (
                    <option key={pageSizeMap} value={pageSizeMap}>
                        Zeige {pageSizeMap}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Pagination;
