/** @format */

import { notificationAdapter, extendedUserAdapter } from "@api/ApiRequest";
import { UserSearchModel } from "@api/ext/ExtendedUserAdapter";
import AuthContext from "@context/AuthProvider";
import { DateTime } from "luxon";
import React, { useState, useEffect, useCallback, useContext, useReducer, Reducer } from "react";
import { useLocation } from "react-router-dom";
import io, { Socket } from "socket.io-client";

const socket: ISocket = io(`${process.env.REACT_APP_CHAT_URL as string}`);

interface ISocket extends Socket {
    userID?: string;
}

export interface IMessage {
    content: string;
    fromSelf: boolean;
    to?: string;
    from?: string;
    created?: string;
}

interface UserChat {
    connected?: boolean;
    messages?: IMessage[];
    hasNewMessages?: boolean;
    userChatId?: string;
}

interface IncMessage {
    from: string;
    content: string;
    toUsername: number;
    created: string;
}

interface PaginationMessage {
    messages: IMessage[];
    last: boolean;
    currUsername: number;
}

interface UpdateUserList {
    [key: string]: {
        connected: boolean;
        userID: string;
        messages?: [
            {
                content: string;
                to: string;
                from: string;
                toUsername: string;
                fromSelf: boolean;
            }
        ];
    };
}

interface UpdateUserMessages {
    [key: string]: {
        usernameId: string;
        messages?: [
            {
                content: string;
                to: string;
                from: string;
                fromSelf: boolean;
            }
        ];
    };
}

interface UserStateConnection {
    type: "connect" | "disconnect";
    id: number;
    userID?: string;
}

export interface UserChatModel extends UserSearchModel, UserChat {
    canLoadMessages?: boolean;
    is_admin: boolean;
    is_coach: boolean;
    is_client: boolean;
    coachName: string | null;
}

interface ConnectedList {
    username: string;
    connected: boolean;
    userID: string;
}

type LastValueAction = {
    type: "inc" | "clear";
};

const defaultValue: {
    activeChatUser: UserChatModel | null;
    setActiveChatUser: React.Dispatch<React.SetStateAction<UserChatModel | null>>;
    chatUsers: UserChatModel[];
    setChatUsers: React.Dispatch<React.SetStateAction<UserChatModel[]>>;
    isConnected: boolean;
    sendChatMessage: (message: string) => void;
    dispatchNewMessageStack: (value: LastValueAction) => void;
    newMessageStack: number | null;
    handleScrollEnd: () => void;
    loadPagination: boolean;
    setLoadPagination: React.Dispatch<React.SetStateAction<boolean>>;
    setNewMessageScroll: React.Dispatch<React.SetStateAction<boolean>>;
    newMessageScroll: boolean;
} = {
    activeChatUser: null,
    chatUsers: [],
    setChatUsers: () => null,
    setActiveChatUser: () => null,
    isConnected: false,
    sendChatMessage: () => null,
    dispatchNewMessageStack: () => null,
    newMessageStack: null,
    handleScrollEnd: () => null,
    loadPagination: false,
    setLoadPagination: () => null,
    setNewMessageScroll: () => null,
    newMessageScroll: false,
};

const ChatContext = React.createContext(defaultValue);

const initialState = null;

function reducer(state: number | null, action: LastValueAction): number | null {
    switch (action.type) {
        case "inc":
            return state ? state + 1 : 1;
        case "clear":
            return null;
        default:
            throw new Error();
    }
}

let timeoutId: ReturnType<typeof setTimeout>;

const ChatProvider: React.FC = ({ children }) => {
    const { user, token } = useContext(AuthContext);

    const location = useLocation();

    const [isConnected, setIsConnected] = useState(socket.connected);

    const [users, setUsers] = useState<UserChatModel[]>([]);

    const [activeUser, setActiveUser] = useState<UserChatModel | null>(null);

    const [incMessage, setIncMessage] = useState<IncMessage | null>(null);

    const [paginationMessage, setPaginationMessage] = useState<PaginationMessage | null>(null);

    const [loadPagination, setLoadPagination] = useState<boolean>(false);

    const [updateUserList, setUpdateUserList] = useState<UpdateUserList | null>(null);

    const [updateUserMessages, setUpdateUserMessages] = useState<UpdateUserMessages | null>(null);

    const [userConnection, setUserConnection] = useState<UserStateConnection | null>(null);

    const [newMessageScroll, setNewMessageScroll] = useState<boolean>(false);

    const [newMessageStack, dispatchNewMessageStack] = useReducer<
        Reducer<number | null, LastValueAction>
    >(reducer, initialState);

    useEffect(() => {
        void extendedUserAdapter.getChatUsers("").then((value) => {
            setUsers(value);

            if (value.length) {
                setActiveUser(value[0]);
            }
        });
    }, []);

    useEffect(() => {
        if (!user || !token) {
            return;
        }

        const sessionID = localStorage.getItem("sessionID");

        if (sessionID) {
            socket.auth = { sessionID, username: user.id, token };

            socket.connect();
        } else {
            socket.auth = { username: user.id, token };

            socket.connect();
        }

        socket.on("connect_error", (err) => {
            if (err.message === "session not found") {
                console.log("Retry session");
                socket.disconnect();

                localStorage.removeItem("sessionID");

                socket.auth = { username: user.id, token };

                socket.connect();
            }
        });

        socket.on("connect", () => {
            setIsConnected(true);
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
        });

        socket.on("session", ({ sessionID, userID }: { sessionID: string; userID: string }) => {
            // attach the session ID to the next reconnection attempts
            socket.auth = { sessionID, username: user.id, token };
            // store it in the localStorage
            localStorage.setItem("sessionID", sessionID);
            // save the ID of the user
            socket.userID = userID;
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");

            socket.disconnect();
        };
    }, [user, token]);

    useEffect(() => {
        socket.on("connect_error", (error) => {
            console.log("ERROR: ", error);
        });

        return () => {
            socket.off("connect_error");
        };
    }, []);

    useEffect(() => {
        if (!incMessage || !activeUser) {
            return;
        }

        if (location.pathname !== "/chat") {
            dispatchNewMessageStack({ type: "inc" });
        }

        const { from, toUsername, content, created } = incMessage;

        const cloneUsers = [...users];

        if (users.length) {
            for (let i = 0; i < cloneUsers.length; i++) {
                const userClone = cloneUsers[i];
                const fromSelf = socket.userID === from;

                if (userClone.id === toUsername) {
                    if (userClone.messages) {
                        userClone.messages.push({
                            content,
                            fromSelf,
                            created,
                        });
                    } else {
                        userClone.messages = [
                            {
                                content,
                                fromSelf,
                                created,
                            },
                        ];
                    }

                    if (userClone.id !== activeUser?.id) {
                        userClone.hasNewMessages = true;
                    } else {
                        setActiveUser(userClone);

                        setNewMessageScroll(true);
                    }

                    cloneUsers[i] = userClone;

                    break;
                }
            }

            setIncMessage(null);

            setUsers(cloneUsers);
        }
    }, [incMessage, users, activeUser, location]);

    useEffect(() => {
        if (!activeUser || !paginationMessage || !user) {
            return;
        }

        if (paginationMessage.currUsername !== activeUser.id) {
            setPaginationMessage(null);
            setLoadPagination(false);

            return;
        }

        const cloneActiveUser = { ...activeUser };

        cloneActiveUser.canLoadMessages = !paginationMessage.last;

        const loadMessages: IMessage[] = [];

        paginationMessage.messages.forEach((message) => {
            loadMessages.push({
                content: message.content,
                fromSelf: user.id === message.from,
                to: message.to,
                from: message.from,
                created: message.created,
            });
        });

        if (cloneActiveUser.messages) {
            cloneActiveUser.messages = [...loadMessages, ...cloneActiveUser.messages];
        } else {
            cloneActiveUser.messages = [...loadMessages];
        }

        setPaginationMessage(null);
        setActiveUser(cloneActiveUser);
    }, [paginationMessage, activeUser, user]);

    useEffect(() => {
        socket.on(
            "user:message",
            ({
                content,
                from,
                toUsername,
                created,
            }: {
                from: string;
                content: string;
                toUsername: number;
                created: string;
            }) => {
                setIncMessage({ content, from, toUsername, created });
            }
        );

        return () => {
            socket.off("user:message");
        };
    }, []);

    useEffect(() => {
        if (!userConnection || !activeUser) {
            return;
        }

        if (userConnection.type === "connect") {
            const cloneUsers = [...users];

            if (users.length) {
                for (let i = 0; i < users.length; i++) {
                    const existingUser = { ...users[i] };

                    if (existingUser.id === userConnection.id) {
                        cloneUsers[i].connected = true;

                        cloneUsers[i].userChatId = userConnection.userID;

                        if (cloneUsers[i].id === activeUser.id) {
                            setActiveUser(cloneUsers[i]);
                        }

                        break;
                    }
                }

                setUsers(cloneUsers);
            }
        } else if (userConnection.type === "disconnect") {
            const cloneUsers = [...users];

            if (users.length) {
                for (let i = 0; i < cloneUsers.length; i++) {
                    if (cloneUsers[i].id === userConnection.id) {
                        cloneUsers[i].connected = false;

                        if (cloneUsers[i].id === activeUser.id) {
                            setActiveUser(cloneUsers[i]);
                        }

                        break;
                    }
                }

                setUsers(cloneUsers);
            }
        }

        setUserConnection(null);
    }, [userConnection, users, activeUser]);

    useEffect(() => {
        if (!updateUserList || !users.length) {
            return;
        }
        const cloneUsers = [...users];

        if (users.length) {
            for (let i = 0; i < users.length; i++) {
                const existingUser = users[i];

                if (updateUserList[`u-${existingUser.id}`]) {
                    if (updateUserList[`u-${existingUser.id}`].connected) {
                        cloneUsers[i].connected = true;
                    }

                    if (updateUserList[`u-${existingUser.id}`].userID) {
                        cloneUsers[i].userChatId = updateUserList[`u-${existingUser.id}`].userID;
                    }
                }
            }
        }

        setUpdateUserList(null);

        setUsers(cloneUsers);
    }, [updateUserList, users]);

    useEffect(() => {
        if (!updateUserMessages || !users.length) {
            return;
        }

        const cloneUsers = [...users];

        if (users.length) {
            for (let i = 0; i < users.length; i++) {
                const existingUser = users[i];

                if (updateUserMessages[`u-${existingUser.id}`]) {
                    if (updateUserMessages[`u-${existingUser.id}`].messages) {
                        cloneUsers[i].messages =
                            updateUserMessages[`u-${existingUser.id}`].messages;
                    }
                }
            }
        }

        setUpdateUserMessages(null);

        setUsers(cloneUsers);
    }, [updateUserMessages, users]);

    useEffect(() => {
        socket.on("user:list", (connectedUsers: ConnectedList[]) => {
            const connected: UpdateUserList = {};

            connectedUsers.forEach((value) => {
                connected[`u-${value.username}`] = {
                    connected:
                        connected[`u-${value.username}`] &&
                        connected[`u-${value.username}`].connected
                            ? connected[`u-${value.username}`].connected
                            : value.connected,
                    userID: value.userID,
                };
            });

            setUpdateUserList(connected);
        });

        socket.on("user:messages", (usersMessages: UpdateUserMessages) => {
            setUpdateUserMessages(usersMessages);
        });

        socket.on("user:disconnected", (id: number) => {
            setUserConnection({ type: "disconnect", id });
        });

        socket.on("user:connected", (user: { username: number; userID: string }) => {
            setUserConnection({ type: "connect", id: user.username, userID: user.userID });
        });

        socket.on("user:pagination", ({ messages, currUsername, last }: PaginationMessage) => {
            setPaginationMessage({ messages, currUsername, last });
        });

        return () => {
            socket.off("user:messages");
            socket.off("user:connected");
            socket.off("user:disconnected");
            socket.off("user:list");
            socket.off("user:pagination");
        };
    }, []);

    const sendChatMessage = useCallback(
        (chatText: string) => {
            if (!activeUser || !chatText) {
                return;
            }

            const index = users.findIndex((value) => {
                return activeUser.id === value.id;
            });

            chatText = chatText.trimStart();
            chatText = chatText.trimEnd();

            const copyUser: UserChatModel[] = [...users];

            const singleMessage: IMessage = {
                content: chatText,
                fromSelf: true,
                created: DateTime.local().toISO(),
            };

            if (copyUser[index].messages) {
                copyUser[index].messages?.push(singleMessage);
            } else {
                copyUser[index].messages = [singleMessage];
            }

            setUsers(copyUser);

            setActiveUser(copyUser[index]);

            if (activeUser.is_client && activeUser.coachName) {
                void notificationAdapter.sendNotification({
                    user_id: activeUser.id,
                    title: activeUser.coachName,
                    notification: chatText,
                });
            }

            socket.emit("user:private_message", {
                content: chatText,
                to: activeUser.userChatId,
                toUsername: activeUser.id,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [socket, activeUser, users]
    );

    useEffect(() => {
        if (loadPagination) {
            timeoutId = setTimeout(() => {
                setLoadPagination(false);
            }, 2000);
        } else {
            clearTimeout(timeoutId);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [loadPagination]);

    const handleScrollEnd = useCallback(() => {
        if (activeUser?.canLoadMessages === false) {
            return;
        }

        setLoadPagination(true);

        socket.emit("user:pagination_messages", {
            count: activeUser?.messages?.length,
            currUsername: activeUser?.id,
        });
    }, [activeUser]);

    const providerValue = {
        activeChatUser: activeUser,
        setActiveChatUser: setActiveUser,
        chatUsers: users,
        setChatUsers: setUsers,
        isConnected,
        setNewMessageScroll,
        newMessageScroll,
        sendChatMessage,
        dispatchNewMessageStack,
        newMessageStack,
        handleScrollEnd,
        loadPagination,
        setLoadPagination,
    };

    return <ChatContext.Provider value={providerValue}>{children}</ChatContext.Provider>;
};

export { ChatProvider };
export default ChatContext;
