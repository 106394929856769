/** @format */

import { onboardingAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import PasswordStrength from "@components/PasswordStrength/PasswordStrength";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { useLoading } from "@hook/useLoading";
import CenteredLayout from "@layouts/Unauthenticated/CenteredLayout";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface ResetPasswordParams {
    token: string;
}

interface PasswordErrors {
    password?: {
        [error_code: string]: string;
    };
    newAgainPassword?: {
        [error_code: string]: string;
    };
}

const ResetPassword: React.FC = () => {
    const [t] = useTranslation();
    const { authentication } = useContext(AuthContext);

    const { setLoading } = useLoading();
    const { setSaved } = useContext(LoadingContext);
    const { token } = useParams<ResetPasswordParams>();

    const [password, setPassword] = useState("");
    const [newAgainPassword, setNewAgainPassword] = useState("");

    const [error, setError] = useState<PasswordErrors>({});

    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [tokenValid, setTokenValid] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [succeeded, setSucceeded] = useState<boolean>(false);

    const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setPassword(value);
    };

    const handleRepeatPasswordChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setNewAgainPassword(value);
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (!canSubmit) {
            return;
        }

        if (newAgainPassword !== password) {
            setError({
                newAgainPassword: {
                    1: t("global.passwordNotMatch", "Die Kennwört stimmen nicht überein"),
                },
            });

            return;
        }

        onboardingAdapter
            .restorePassword(password, newAgainPassword, token)
            .then(({ success, errors }) => {
                if (success) {
                    setSaved({
                        type: "successful",
                        show: true,
                        text: t(
                            "reset.password.done.text",
                            "Dein neues Kennwort wurde erfolgreich gespeichert."
                        ),
                    });

                    setSucceeded(true);

                    return;
                }

                setSaved({
                    type: "failed",
                    show: true,
                    text: t(
                        "reset.password.error.text",
                        "Dein neues Kennwort konnte nicht gespeichert werden."
                    ),
                });

                setError(errors as PasswordErrors);
            })
            .catch((e) => {
                setSaved({
                    type: "failed",
                    show: true,
                    text: t(
                        "reset.password.error.text",
                        "Dein neues Kennwort konnte nicht gespeichert werden."
                    ),
                });
                console.log(e);
            });
    };

    useEffect(() => {
        if (!token) {
            return;
        }

        setLoading(true);

        onboardingAdapter
            .checkToken(token)
            .then((response) => {
                setTokenValid(!!response);
                setLoaded(true);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [token, setLoading]);

    useEffect(() => {
        setError({});
    }, [password, newAgainPassword]);

    if (!loaded) {
        return null;
    }

    if (loaded && !tokenValid) {
        return (
            <CenteredLayout>
                <div className="col-lg-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                    <div className="col-12 d-flex justify-content-center">
                        <p className="col-md-6 col-12 font-weight-light mt-3">
                            {t("reset.password.tokenInvalid", "Der verwendete Link ist ungültig!")}
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="pt-4 d-flex col justify-content-center">
                            <a href="/" className="btn btn-save">
                                {authentication
                                    ? t("global.continue", "Weiter")
                                    : t("reset.password.button.login", "Zum Login")}
                            </a>
                        </div>
                    </div>
                </div>
            </CenteredLayout>
        );
    }

    if (loaded && succeeded) {
        return (
            <CenteredLayout>
                <div className="col-lg-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                    <div className="col-12 d-flex justify-content-center">
                        <p className="col-md-6 col-12 font-weight-light mt-3">
                            {t(
                                "reset.password.succeeded",
                                "Dein neues Kennwort wurde erfolgreich gespeichert und ist ab sofort für Deinen Account gültig."
                            )}
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="pt-4 d-flex col justify-content-center">
                            <a href="/" className="btn btn-save">
                                {authentication
                                    ? t("global.continue", "Weiter")
                                    : t("reset.password.button.login", "Zum Login")}
                            </a>
                        </div>
                    </div>
                </div>
            </CenteredLayout>
        );
    }

    return (
        <CenteredLayout>
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                </div>
                <div className="col-lg-6 mx-auto col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="font-weight-light mb-3">
                                {t("reset.password.title", "Neues Kennwort")}
                            </h1>
                        </div>

                        <div className="col-12 mb-3">
                            <InputLabel required>{t("password.new", "Neues Kennwort")}</InputLabel>
                            <input
                                className={`form-control ${error?.password ? "is-invalid" : ""}`}
                                id="new-password"
                                type="password"
                                placeholder={t("password.new", "Neues Kennwort")}
                                onChange={handleNewPasswordChange}
                                value={password}
                            />
                            <div className="invalid-feedback">
                                {error?.password
                                    ? error?.password[Object.keys(error.password)[0]]
                                    : ""}
                            </div>
                        </div>
                        <div className="col-12">
                            <PasswordStrength
                                password={password}
                                title={false}
                                callback={setCanSubmit}
                            />
                        </div>

                        <div className="col-12">
                            <InputLabel required>
                                {t("password.newAgain", "Neues Kennwort erneut eingeben")}
                            </InputLabel>
                            <input
                                className={`form-control ${
                                    error?.newAgainPassword ? "is-invalid" : ""
                                }`}
                                id="repeat-password"
                                type="password"
                                placeholder={t(
                                    "password.newAgain",
                                    "Neues Kennwort erneut eingeben"
                                )}
                                onChange={handleRepeatPasswordChange}
                                value={newAgainPassword}
                            />
                            <div className="invalid-feedback">
                                {error?.newAgainPassword
                                    ? error?.newAgainPassword[
                                          Object.keys(error.newAgainPassword)[0]
                                      ]
                                    : ""}
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <button
                                disabled={!canSubmit || newAgainPassword !== password}
                                type="submit"
                                className={`btn ${
                                    !canSubmit || newAgainPassword !== password
                                        ? "btn-disabled"
                                        : "btn-save"
                                }`}
                            >
                                {t("reset.password.button.save", "Kennwort speichern")}
                            </button>
                        </div>

                        <div className="mt-4 d-flex col-12 justify-content-end">
                            <a href="/">
                                {authentication
                                    ? t("global.continue", "Weiter")
                                    : t("reset.password.button.login", "Zum Login")}
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </CenteredLayout>
    );
};

export default ResetPassword;
