/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { ExerciseModel } from "@api/ext/ExerciseAdapter";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CellProps } from "react-table";

import TableWithPagination from "../TableWithPagination";

import "./ExerciseTable.scss";

type ExerciseTableColumns =
    | "select"
    | "image"
    | "name"
    | "nameShort"
    | "sections"
    | "created"
    | "options";

interface ExerciseTableProps {
    data: ExerciseModel[];
    hideColumns?: ExerciseTableColumns[];
    selectable?: boolean;
    onSelectedChange?: (selectedIds: number[]) => void;
    multiSelectOptions?: React.ReactNode;
    rowOptions?: React.ReactNode;
}

const ExerciseTable: React.FC<ExerciseTableProps> = ({
    data,
    hideColumns = [],
    selectable = false,
    onSelectedChange = () => null,
    multiSelectOptions = null,
    rowOptions = null,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    const columns = useMemo(() => {
        const imageCell = !hideColumns.includes("image")
            ? [
                  {
                      Header: () => {
                          return <div style={{ width: 25 }} />;
                      },
                      id: "image",
                      Cell: ({ row }: CellProps<ExerciseModel>) => {
                          if (!row.original.uploads || !row.original.id) {
                              return null;
                          }

                          if (row.original.uploads?.length > 0) {
                              return (
                                  <Link
                                      to={{
                                          pathname: `/exercises/view/${row.original.id}`,
                                          state: { background: location },
                                      }}
                                  >
                                      <div className="d-flex justify-content-center w-100 text-center">
                                          <div
                                              className="exercise-table-image"
                                              style={{
                                                  background: `url(${row.original.uploads[0].url})`,
                                              }}
                                          />
                                      </div>
                                  </Link>
                              );
                          }

                          return (
                              <Link
                                  to={{
                                      pathname: `/exercises/view/${row.original.id}`,
                                      state: { background: location },
                                  }}
                              >
                                  <div className="d-flex justify-content-center w-100 text-center">
                                      <div className="exercise-table-image-placeholder">
                                          {row.original.name.charAt(0) +
                                              row.original.name.charAt(1)}
                                      </div>
                                  </div>
                              </Link>
                          );
                      },
                  },
              ]
            : [];

        const nameCell = !hideColumns.includes("name")
            ? [
                  {
                      Header: t("exercises.name", "Übungsname"),
                      accessor: "name",
                      Cell: ({ row }: CellProps<ExerciseModel>) => {
                          if (!row.original.id) {
                              return null;
                          }

                          return (
                              <div className="d-flex justify-content-start align-items-center">
                                  <Link
                                      to={{
                                          pathname: `/exercises/view/${row.original.id}`,
                                          state: { background: location },
                                      }}
                                      className="cell-link"
                                  >
                                      {row.original.name}
                                  </Link>
                              </div>
                          );
                      },
                      className: "w-50",
                  },
              ]
            : [];

        const nameShortCell = !hideColumns.includes("nameShort")
            ? [
                  {
                      Header: t("exercises.nameShort", "Spitzname"),
                      accessor: "secondname",
                      className: "w-25",
                  },
              ]
            : [];

        const sectionsCell = !hideColumns.includes("sections")
            ? [
                  {
                      Header: t("exercises.sctions", "Sektionen"),
                      accessor: "sections",
                      className: "w-25",
                      Cell: ({ row }: CellProps<ExerciseModel>) => {
                          if (!row.original.exercisesections) {
                              return null;
                          }

                          const exerciseSections = row.original.exercisesections.map(
                              (section) => section.name
                          );

                          return <div className="d-flex">{exerciseSections.join(", ")}</div>;
                      },
                  },
              ]
            : [];

        const createdCell = !hideColumns.includes("created")
            ? [
                  {
                      Header: () => {
                          return (
                              <div className="d-flex justify-content-end">
                                  {t("global.created", "Erstellt")}
                              </div>
                          );
                      },
                      accessor: "created",
                      Cell: ({ row }: CellProps<ExerciseModel>) => {
                          if (!row.original.created) {
                              return null;
                          }

                          const created: string = row.original.created.toString();

                          return (
                              <div className="d-flex">
                                  {row.original.created === null
                                      ? "-"
                                      : DateTime.fromISO(created).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const rowOptionsCell = rowOptions ? [rowOptions] : [];

        return [
            ...(imageCell || []),
            ...(nameCell || []),
            ...(nameShortCell || []),
            ...(sectionsCell || []),
            ...(createdCell || []),
            ...(rowOptionsCell || []),
        ];
    }, [hideColumns, location, rowOptions, t]);

    return <TableWithPagination columns={columns} data={data} selectable={selectable} />;
};

export default ExerciseTable;
