/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { ExercisetagModel } from "@api/ext/ExercisetagAdapter";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";

import TableWithPagination from "../TableWithPagination";

import "./ExercisetagTable.scss";

type ExercisetagTableColumns = "select" | "name" | "created" | "options";

interface ExercisetagTableProps {
    data: ExercisetagModel[];
    hideColumns?: ExercisetagTableColumns[];
    selectable?: boolean;
    onSelectedChange?: (selectedIds: number[]) => void;
    multiSelectOptions?: React.ReactNode;
    rowOptions?: React.ReactNode;
}

const ExercisetagTable: React.FC<ExercisetagTableProps> = ({
    data,
    hideColumns = [],
    selectable = false,
    onSelectedChange = () => null,
    multiSelectOptions = null,
    rowOptions = null,
}) => {
    const { t } = useTranslation();

    const columns = useMemo(() => {
        const nameCell = !hideColumns.includes("name")
            ? [
                  {
                      Header: t("exercisetag.name", "Problem-Tag"),
                      accessor: "name",
                      className: "w-100",
                  },
              ]
            : [];

        const createdCell = !hideColumns.includes("created")
            ? [
                  {
                      Header: t("exercisetag.created", "Erstellt"),
                      accessor: "lastlogin",
                      Cell: ({ row }: CellProps<ExercisetagModel>) => {
                          const created: string = row.original.created
                              ? row.original.created.toString()
                              : "";

                          return (
                              <div className="flex justify-start w-100">
                                  {created === ""
                                      ? "-"
                                      : DateTime.fromISO(created).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const rowOptionsCell = rowOptions ? [rowOptions] : [];

        return [...(nameCell || []), ...(createdCell || []), ...(rowOptionsCell || [])];
    }, [hideColumns, rowOptions, t]);

    return <TableWithPagination columns={columns} data={data} selectable={selectable} />;
};

export default ExercisetagTable;
