/** @format */

import React from "react";

const Imprint: React.FC = () => {
    return (
        <div className="container py-5">
            <div className="col-lg-12 mb-3 text-center">
                <img
                    src="/img/background_logo.png"
                    alt="InterCoach Logo"
                    className="img-fluid main-logo"
                />
            </div>
            <div className="col-lg-6 offset-lg-3 col-md-12">
                <div className="row">
                    <div className="col-12">
                        <h1>Impressum</h1>
                    </div>
                    <div className="col-12">
                        <div className="paragraph">
                            <h3>Verantwortlich für den Inhalt</h3>
                            <p>Interweber</p>
                        </div>

                        <div className="paragraph">
                            <h3>Inhaltlich Verantwortlicher</h3>
                            <p>gemäß §5 TMG sowie §55 RStV: Prof. Dr. Michael Teubert</p>
                        </div>

                        <div className="paragraph">
                            <h3>Anschrift</h3>
                            <p>
                                Interweber
                                {/* Interweber
                                <br />
                                Hanse 90° – Normannenweg 28
                                <br />
                                D-20537 Hamburg
                                <br />
                                Telefon: +49 (0) 40 46 000 870
                                <br />
                                Telefax: +49 (0) 40 46 000 885
                                <br />
                                empfang@contalis.com www.contalis.com */}
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>1. Inhalt des Onlineangebotes</h3>
                            <p>
                                Interweber übernimmt keinerlei Gewähr für die Aktualität,
                                Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
                                Informationen. Haftungsansprüche gegen den Autor, welche sich auf
                                Schäden materieller oder ideeller Art beziehen, die durch die
                                Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch
                                die Nutzung fehlerhafter und unvollständiger Informationen
                                verursacht wurden sind grundsätzlich ausgeschlossen, sofern seitens
                                Interweber kein nachweislich vorsätzliches oder grob fahrlässiges
                                Verschulden vorliegt. Alle Angebote sind freibleibend und
                                unverbindlich. Interweber behält es sich ausdrücklich vor, Teile der
                                Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
                                verändern, zu ergänzen, zu löschen oder die Veröffentlichung
                                zeitweise oder endgültig einzustellen.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>2. Verweise und Links</h3>
                            <p>
                                Bei direkten oder indirekten Verweisen auf fremde Internetseiten
                                (“Links”), die außerhalb des Verantwortungsbereiches der CONTALIS
                                Wirtschaftsinstitut GmbH liegen, würde eine Haftungsverpflichtung
                                ausschließlich in dem Fall in Kraft treten, in dem die CONTALIS
                                Wirtschaftsinstitut GmbH von den Inhalten Kenntnis hat und es ihm
                                technisch möglich und zumutbar wäre, die Nutzung im Falle
                                rechtswidriger Inhalte zu verhindern. Die CONTALIS
                                Wirtschaftsinstitut GmbH erklärt daher ausdrücklich, dass zum
                                Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei
                                von illegalen Inhalten waren. Interweber hat keinerlei Einfluss auf
                                die aktuelle und zukünftige Gestaltung und auf die Inhalte der
                                gelinkten/verknüpften Seiten. Deshalb distanziert sie sich hiermit
                                ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten,
                                die nach der Linksetzung verändert wurden. Diese Feststellung gilt
                                für alle innerhalb des eigenen Internetangebotes gesetzten Links und
                                Verweise sowie für Fremdeinträge in vom Autor eingerichteten
                                Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale,
                                fehlerhafte oder unvollständige Inhalte und insbesondere für
                                Schäden, die aus der Nutzung oder Nichtnutzung solcherart
                                dargebotener Informationen entstehen, haftet allein der Anbieter der
                                Seite, auf welche verwiesen wurde, nicht derjenige, der über Links
                                auf die jeweilige Veröffentlichung lediglich verweist.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>3. Uhrheber- und Kennzeichenrecht</h3>
                            <p>
                                Interweber ist bestrebt, in allen Publikationen die Urheberrechte
                                der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
                                beachten, von ihm selbst erstellte Grafiken, Tondokumente,
                                Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
                                Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle
                                innerhalb des Internetangebotes genannten und ggf. durch Dritte
                                geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
                                Bestimmungen des jeweils gültigen Kennzeichenrechts und den
                                Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein
                                aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass
                                Markenzeichen nicht durch Rechte Dritter geschützt sind! Das
                                Copyright für veröffentlichte, vom Autor selbst erstellte Objekte
                                bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder
                                Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte
                                in anderen elektronischen oder gedruckten Publikationen ist ohne
                                ausdrückliche Zustimmung durch Interweber nicht gestattet.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>4. Rechtswirksamkeit dieses Haftungsausschlusses</h3>
                            <p>
                                Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
                                betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
                                Teile oder einzelne Formulierungen dieses Textes der geltenden
                                Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
                                sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt
                                und ihrer Gültigkeit davon unberührt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Imprint;
