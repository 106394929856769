/** @format */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface SelectTypeProps {
    type: number;
    setType: React.Dispatch<React.SetStateAction<number | null>>;
    hiddenType?: number;
    setOption: React.Dispatch<React.SetStateAction<string>>;
}

const SelectType: React.FC<SelectTypeProps> = ({ type, setType, hiddenType, setOption }) => {
    const [t] = useTranslation();

    useEffect(() => {
        if (hiddenType === type) {
            setType(null);
        }
        if (hiddenType === 6) {
            setOption("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenType]);

    return (
        <div className="row">
            <div className="col">
                <div className="btn-group btn-group-sm w-100" role="group" aria-label="...">
                    {hiddenType !== 1 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 1 ? "section-active" : ""
                            }`}
                            onClick={() => setType(1)}
                        >
                            {t("global.repeatShort", "Wdh")}
                        </button>
                    )}
                    {hiddenType !== 2 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 2 ? "section-active" : ""
                            }`}
                            onClick={() => setType(2)}
                        >
                            {t("global.kgShort", "Kg")}
                        </button>
                    )}

                    {hiddenType !== 3 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 3 ? "section-active" : ""
                            }`}
                            onClick={() => setType(3)}
                        >
                            {t("global.kgShort", "Kg")} +
                        </button>
                    )}

                    {hiddenType !== 4 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 4 ? "section-active" : ""
                            }`}
                            onClick={() => setType(4)}
                        >
                            {t("global.kgShort", "Kg")} -
                        </button>
                    )}

                    {hiddenType !== 5 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 5 ? "section-active" : ""
                            }`}
                            onClick={() => setType(5)}
                        >
                            {t("global.time", "Zeit")}
                        </button>
                    )}

                    {hiddenType !== 6 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 6 ? "section-active" : ""
                            }`}
                            onClick={() => setType(6)}
                        >
                            {t("global.stage", "Stufe")}
                        </button>
                    )}

                    {hiddenType !== 7 && (
                        <button
                            className={`btn btn-secondary select-btn-active ${
                                type === 7 ? "section-active" : ""
                            }`}
                            onClick={() => setType(7)}
                        >
                            {t("global.track", "Strecke")}
                        </button>
                    )}
                </div>
            </div>
            {type === 6 ? (
                <div className="col-12 mt-2">
                    <input
                        onChange={(e) => setOption(e.target.value)}
                        type="text"
                        className="form-control option-input-form"
                        placeholder={t("global.stage", "Stufe")}
                    />
                    <p className="option-info">
                        {t("select.commaSeparatedDescription", "Komma separierte Eingabe")}
                    </p>
                </div>
            ) : null}
        </div>
    );
};

export default SelectType;
