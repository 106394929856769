/** @format */

import { authAdapter } from "@api/ApiRequest";
import DevBunchSignup from "@components/Development/DevBunchSignup/DevBunchSignup";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context/LoadingProvider";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Login.scss";

import CenteredLayout from "../../../layouts/Unauthenticated/CenteredLayout";

const Login: React.FC = () => {
    const [t] = useTranslation();

    const { setLoading } = useContext(LoadingContext);

    const [username, setUsername] = useState<string>("");
    const [passwort, setKennwort] = useState<string>("");
    const [error, setError] = useState<string | boolean>(false);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        authAdapter
            .login(username, passwort)
            .then((value) => {
                if (!value) {
                    setError(`${t("login.error", "Benutzername oder Kennwort nicht korrekt.")}`);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    return (
        <CenteredLayout>
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                </div>
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-10 col-sm-10 col-10 mx-auto">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <InputLabel required>{t("global.username", "Benutzername")}</InputLabel>
                            <input
                                type="username"
                                className={`form-control ${error ? "is-invalid" : ""}`}
                                id="inputUsername"
                                aria-describedby="Username"
                                autoComplete="username"
                                placeholder={t("global.placeholder.username", "Benutzername")}
                                ng-hide="true"
                                onChange={(e) => {
                                    setError(false);
                                    setUsername(e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-between">
                                <InputLabel required>{t("global.password", "Kennwort")}</InputLabel>
                                <a
                                    className="text-link login-forgot-password"
                                    href="/forgot-password"
                                    tabIndex={-1}
                                >
                                    {t(
                                        "global.btn.forgot-passwort",
                                        "Du hast Dein Kennwort vergessen?"
                                    )}
                                </a>
                            </div>
                            <input
                                type="password"
                                className={`form-control ${error ? "is-invalid" : ""}`}
                                id="inputKennwort"
                                autoComplete="password"
                                placeholder={t("global.placeholder.password", "Kennwort")}
                                onChange={(e) => {
                                    setError(false);
                                    setKennwort(e.target.value);
                                }}
                            />
                            <div className="invalid-feedback">{error ? error : " "}</div>
                        </div>

                        <div className="col-12 d-grid mt-4 mb-1">
                            <button
                                disabled={!username || !passwort}
                                type="submit"
                                className={`btn ${
                                    !username || !passwort ? "btn-disabled" : "btn-save"
                                }`}
                            >
                                {t("global.btn.login", "login")}
                            </button>
                        </div>

                        <label className="pt-4 d-flex col-12 justify-content-center">
                            Du hast noch kein Konto?&nbsp;
                            <a className="text-link" href="/signup/basic/monthly">
                                {t("global.btn.signup", "21 Tage testen")}
                            </a>
                        </label>
                    </div>

                    {process.env.NODE_ENV === "development" && <DevBunchSignup />}
                </div>
            </form>
        </CenteredLayout>
    );
};

export default Login;
