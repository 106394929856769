/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

import { CompanyListItem } from "./ExtendedMandantorAdapter";
import { MuscelesgroupModel } from "./MuscelesgroupAdapter";

export interface MuscleModel extends Model {
    id: number;
    name: string;
    editableCompany: boolean;
    editableMandantor: boolean;
    company_id: number;
    created: DateTime;
    muscelesgroup: MuscelesgroupModel;
}

export interface MuscleAddModel extends Model {
    name: string;
    muscelesgroup_id: number;
    company_id?: number;
}

interface MuscleMusclegroups {
    [key: number]: string;
}

export default class MuscleAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    MuscleModel,
    Paginator,
    MuscleAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Exercisemuscles", "Exercisemuscle", "Exercisemuscles", adapter);
    }

    public async getIndex(search = ""): Promise<MuscleModel[]> {
        const { data } = await this._get<{ exercisemuscles: MuscleModel[] }>(
            `index?search=${search}`
        );

        return data.exercisemuscles;
    }

    public async getAddMuscle(): Promise<{
        muscelesgroups: MuscleMusclegroups;
        companies: CompanyListItem;
    }> {
        const { data } = await this._get<{
            muscelesgroups: MuscleMusclegroups;
            companies: CompanyListItem;
        }>(`add`);

        return {
            muscelesgroups: data.muscelesgroups,
            companies: data.companies,
        };
    }

    public async getEditMuscle(
        id: string
    ): Promise<{
        musclegroups: MuscleMusclegroups;
        muscle: MuscleModel;
        companies: CompanyListItem;
    }> {
        const { data } = await this._get<{
            muscelesgroups: MuscleMusclegroups;
            exercisemuscle: MuscleModel;
            companies: CompanyListItem;
        }>(`edit/${id}`);

        return {
            muscle: data.exercisemuscle,
            musclegroups: data.muscelesgroups,
            companies: data.companies,
        };
    }
}
