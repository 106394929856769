/** @format */

import { SubscriptionType } from "@api/ext/SubscriptiontypeAdapter";
import { makeCurrencyFormatter } from "@helper/numberFormatting";
import React from "react";
import { useTranslation } from "react-i18next";

const currencyFormatter = makeCurrencyFormatter("EUR");

interface BundleBodyProps {
    subscriptiontype: SubscriptionType;
}

const BundleBody: React.FC<BundleBodyProps> = ({ subscriptiontype }) => {
    const [t] = useTranslation();

    if (subscriptiontype.cost && subscriptiontype.cost?.options?.[0]) {
        const option = subscriptiontype.cost.options[0];

        return (
            <div className="bundle-body">
                {option && (
                    <>
                        <div>
                            {option.curr_seats} {t("subscription.genderesUser", "Benutzer*innen")}
                        </div>
                        <div>
                            {currencyFormatter.format(option.calculated_price_gross)}{" "}
                            {t("subscription.monthly", "pro Monat")}
                        </div>
                        {option.free_seats > 0 && (
                            <div>
                                + {option.free_seats}{" "}
                                {t("subscription.freeGenderedUser", "weitere freie Benutzer*innen")}
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="bundle-body">
            {subscriptiontype.subscriptiontypeoptions?.[0] && (
                <>
                    <div>
                        {t("subscription.basePrice", "Basis-Preis")}:{" "}
                        {currencyFormatter.format(
                            subscriptiontype.subscriptiontypeoptions?.[0].base_price_gross
                        )}
                    </div>
                    <div>
                        {t("subscription.base_seats", "inkl. Sitze")}:{" "}
                        {subscriptiontype.subscriptiontypeoptions?.[0].count_base_seats}
                    </div>
                    <div>
                        {t("subscription.pricePerSeat", "Je zusätzlicher Nutzer")}:{" "}
                        {currencyFormatter.format(
                            subscriptiontype.subscriptiontypeoptions?.[0].cost_per_seat_gross
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default BundleBody;
