/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { globalUserAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import PasswordStrength from "@components/PasswordStrength/PasswordStrength";
import LoadingContext from "@context//LoadingProvider";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import "./Password.scss";

interface PasswordErrors {
    currPassword?: {
        [error_code: string]: string;
    };
    password?: {
        [error_code: string]: string;
    };
    newAgainPassword?: {
        [error_code: string]: string;
    };
}

const Password: React.FC = () => {
    const [t] = useTranslation();

    const { setSaved } = useContext(LoadingContext);
    const { user } = useContext(AuthContext);

    const { setLoading } = useLoading();

    const [currPassword, setCurrPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [newAgainPassword, setNewAgainPassword] = useState<string>("");

    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const [error, setError] = useState<PasswordErrors>({});

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        if (newAgainPassword !== password) {
            setError({
                newAgainPassword: {
                    1: t("global.passwordNotMatch", "Die Kennwört stimmen nicht überein"),
                },
            });

            return;
        }

        globalUserAdapter
            .changePassword(currPassword, password, newAgainPassword)
            .then(({ success, errors }) => {
                if (!success) {
                    setSaved({
                        type: "failed",
                        show: true,
                        text: t("password.change.error", "Kennwort konnte nicht geändert werden"),
                    });

                    setError(errors as PasswordErrors);
                } else {
                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("password.change.success", "Kennwort wurde geändert"),
                    });

                    setNewAgainPassword("");
                    setPassword("");
                    setCurrPassword("");
                }
            })
            .catch((e) => {
                setSaved({
                    type: "failed",
                    show: true,
                    text: t("password.change.error", "Kennwort konnte nicht geändert werden"),
                });

                console.log(e);
            });
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>{t("global.password", "Kennwort")}</h1>
                </div>

                <form className="col-md-6 col-12" onSubmit={(e) => handleChangePassword(e)}>
                    <input className="hide" readOnly type="username" value={user.username} />

                    <div className="mb-4">
                        <InputLabel required htmlFor="password">
                            {t("password.current", "Aktuelles Kennwort")}
                        </InputLabel>
                        <input
                            id="password"
                            className={`form-control ${error?.currPassword ? "is-invalid" : ""}`}
                            onChange={(e) => {
                                setError({});
                                setCurrPassword(e.target.value);
                            }}
                            required
                            autoComplete="password"
                            type="password"
                            placeholder={t("password.current", "Aktuelles Kennwort")}
                            value={currPassword}
                        />
                        <div className="invalid-feedback">
                            {error?.currPassword
                                ? error?.currPassword[Object.keys(error.currPassword)[0]]
                                : ""}
                        </div>
                    </div>

                    <div className="mb-3">
                        <InputLabel required htmlFor="new-password">
                            {t("password.new", "Neues Kennwort")}
                        </InputLabel>
                        <input
                            id="new-password"
                            className={`form-control ${error?.password ? "is-invalid" : ""}`}
                            onChange={(e) => {
                                setError({});
                                setPassword(e.target.value);
                            }}
                            required
                            autoComplete="new-password"
                            type="password"
                            placeholder={t("password.new", "Neues Kennwort")}
                            value={password}
                        />
                        <div className="invalid-feedback">
                            {error?.password ? error?.password[Object.keys(error.password)[0]] : ""}
                        </div>
                    </div>
                    <div>
                        <InputLabel required htmlFor="new-password-repeat">
                            {t("password.newAgain", "Neues Kennwort wiederholen")}
                        </InputLabel>
                        <input
                            id="new-password-repeat"
                            className={`form-control ${
                                error?.newAgainPassword ? "is-invalid" : ""
                            }`}
                            onChange={(e) => {
                                setError({});
                                setNewAgainPassword(e.target.value);
                            }}
                            required
                            autoComplete="new-password"
                            type="password"
                            placeholder={t("password.newAgain", "Neues Kennwort wiederholen")}
                            value={newAgainPassword}
                        />
                        <div className="invalid-feedback">
                            {error?.newAgainPassword
                                ? error?.newAgainPassword[Object.keys(error.newAgainPassword)[0]]
                                : ""}
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="col-12 mt-5 d-grid">
                            <button className="btn btn-save" type="submit" disabled={!canSubmit}>
                                {t("password.changePassword", "Kennwort ändern")}
                            </button>
                        </div>
                    </div>
                </form>

                <div className="col-md-6 col-12 fs-7">
                    <PasswordStrength password={password} callback={setCanSubmit} />
                </div>
            </div>
        </>
    );
};

export default Password;
