/** @format */

import { trainingsessionsAdapter } from "@api/ApiRequest";
import { TrainingsessionModel } from "@api/ext/TrainingsessionsAdapter";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import { Paginator } from "@interweberde/prima-core";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Trainingsession from "../Trainingsession/Trainingsession";

import "./TrainingsessionWrapper.scss";

interface TrainingsessionWrapperProps {
    id?: number;
}

const TrainingsessionWrapper: React.FC<TrainingsessionWrapperProps> = ({ id = null }) => {
    const [t] = useTranslation();
    const { setLoading } = useLoading();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [endLoad, setEndLoad] = useState<boolean>(false);
    const [endLoadCheck, setEndLoadCheck] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);

    const [paginator, setPaginator] = useState<Paginator | null>(null);

    const [trainingsession, setTrainingsession] = useState<TrainingsessionModel[] | null>(null);

    const [loadedPage, setLoadedPage] = useState<number[]>([]);

    const handleLoadTrainingssesion = useCallback(() => {
        if (!loadedPage.includes(page)) {
            const loadedPageCopy = [...loadedPage];
            loadedPageCopy.push(page);

            setLoadedPage(loadedPageCopy);
            trainingsessionsAdapter
                .getHistory(id, page)
                .then((data) => {
                    setPage(
                        data.paginator.pageCount > data.paginator.page
                            ? data.paginator.page + 1
                            : data.paginator.page
                    );
                    setPaginator({ paginator: data.paginator });
                    if (!trainingsession) {
                        setTrainingsession(data.trainingsession);
                    } else {
                        setTrainingsession([...trainingsession, ...data.trainingsession]);
                    }

                    setEndLoad(false);
                    setLoaded(true);
                    setLoading(false);
                })
                .catch(() => {
                    setEndLoad(false);
                    setLoaded(true);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedPage, page, id, trainingsession]);

    useEffect(() => {
        if (endLoadCheck || !trainingsession) {
            setEndLoadCheck(false);
            handleLoadTrainingssesion();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endLoadCheck, trainingsession]);

    const handleCheckPaginator = useCallback(() => {
        if (paginator) {
            if (paginator.paginator.page < paginator.paginator.pageCount) {
                setEndLoadCheck(true);
            }
        }
    }, [paginator]);

    useEffect(() => {
        if (endLoad) {
            handleCheckPaginator();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endLoad]);

    const handleEvent = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            setEndLoad(true);
        }
    };

    useEffect(() => {
        if (loaded || !endLoad) {
            window.addEventListener("scroll", handleEvent);
        } else {
            window.removeEventListener("scroll", handleEvent);
        }

        return () => {
            window.removeEventListener("scroll", handleEvent);
        };
    }, [loaded, endLoad]);

    if (!loaded || !paginator) {
        return null;
    }

    if (loadedPage && !trainingsession) {
        return (
            <div className="row">
                <div className="col-12 d-flex justify-content-center align-content-center">
                    <div className="noDataJourney">
                        <div className="alert">
                            {t(
                                "trainingSessions.noHistory",
                                "Es gibt leider noch keinen Verlauf, keine auswertbaren Daten verfügbar."
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="trainingsession-wrapper row">
            {trainingsession &&
                trainingsession.map((value) => (
                    <Trainingsession trainingsession={value} key={value.id} />
                ))}
            {endLoad && paginator.paginator.page !== paginator.paginator.pageCount && (
                <div className="col-12 text-center mt-3">
                    <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
                </div>
            )}
        </div>
    );
};

export default TrainingsessionWrapper;
