/** @format */

import AuthContext from "@context/AuthProvider";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import "./ClientWelcomeScreen.scss";

const ClientWelcomeScreen: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);

    return (
        <div className="d-flex flex-column align-items-center">
            <h2 className="mb-4">
                {t("client.screen.header", "Hallo {{name}}", {
                    name: user?.name,
                })}
            </h2>
            <p className="text-center">
                {t(
                    "client.screen.startAppText",
                    "Starte unsere mobile App und fange mit Deinem Training an!"
                )}
            </p>
            <p className="text-center">
                {t("client.screen.noAppText", "Du hast unsere App noch nicht?")}
            </p>
            <p className="text-center">{t("global.noProblem", "Kein Problem")}!</p>
            <div className="client-icon-wrapper">
                <img
                    src="/img/download_on_app_store.svg"
                    className="img img-responsive app-store-icon"
                    alt="Apple App Store"
                />
                <img
                    src="/img/download_on_play_store.png"
                    className="img img-responsive play-store-icon"
                    alt="Google Play Store"
                />
            </div>
        </div>
    );
};

export default ClientWelcomeScreen;
