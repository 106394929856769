/** @format */

import { Modal } from "bootstrap";
import React, { useEffect, useRef } from "react";

interface ModalSmallProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    handleSearch: (search: string) => void;
}

const ModalSmall: React.FC<ModalSmallProps> = ({
    children,
    show,
    setShow,
    title,
    handleSearch,
}) => {
    const modalView = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalView.current) {
            const myModal = modalView.current;
            let bsModal = Modal.getInstance(myModal);

            if (!bsModal) {
                bsModal = new Modal(myModal);
                bsModal.hide();
            } else if (show) {
                bsModal.toggle();
            }
        }
    }, [show, modalView]);

    useEffect(() => {
        if (modalView.current && show) {
            modalView.current.addEventListener("hidden.bs.modal", function () {
                setShow(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <div
            ref={modalView}
            className="modal fade"
            tabIndex={-1}
            aria-hidden="true"
            aria-labelledby="modal"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div id="contained-modal-title-vcenter" className="modal-title">
                            {title}
                        </div>
                        <button
                            onClick={() => setShow(false)}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col">
                                <input
                                    type="text"
                                    placeholder="Suche"
                                    className="form-search form-control"
                                    onChange={(t) => handleSearch(t.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSmall;
