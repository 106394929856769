/** @format */

import { SubscriptionInfo } from "@api/ext/SubscriptionAdapter";
import { SubscriptionType } from "@api/ext/SubscriptiontypeAdapter";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BundleBody from "./BundleBody";

interface RenderBundlesProps {
    subscription: SubscriptionInfo;
    subscriptiontypes: SubscriptionType[] | null;
    bundle: SubscriptionType | null;
    onChange: (subscriptiontype: SubscriptionType) => void;
    userCount: number | null;
    isLoading: boolean;
}

const RenderBundles: React.FC<RenderBundlesProps> = ({
    subscription,
    subscriptiontypes,
    bundle,
    onChange,
    userCount,
    isLoading = false,
}) => {
    const [t] = useTranslation();
    const [possibleSubscriptiontypes, setPossibleSubscriptiontypes] = useState<
        SubscriptionType[] | null
    >(null);

    useEffect(() => {
        if (!subscriptiontypes) {
            setPossibleSubscriptiontypes(null);

            return;
        }

        const minCount = userCount || 1;
        setPossibleSubscriptiontypes(
            subscriptiontypes.filter(
                (s) =>
                    s.subscriptiontypeoptions &&
                    s.subscriptiontypeoptions?.length > 0 &&
                    (!s.subscriptiontypeoptions[0].count_max_seats ||
                        s.subscriptiontypeoptions[0].count_max_seats >= minCount)
            )
        );
    }, [subscriptiontypes, userCount]);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center">
                <FontAwesomeIcon icon={faSpinnerThird} spin className="loading-icon-spinner ms-5" />
            </div>
        );
    }

    if (!possibleSubscriptiontypes) {
        return null;
    }

    return (
        <>
            {possibleSubscriptiontypes?.map((subscriptiontype, key) => {
                const boocked =
                    subscription.mollie_db_subscription.subscriptiontypeoption.subscriptiontype
                        ?.id === subscriptiontype.id;

                const footerStyle = boocked ? { backgroundColor: `${subscriptiontype.color}` } : {};

                return (
                    <div
                        key={key}
                        className={`bundle col-12 col-lg ${boocked ? "active" : ""} ${
                            bundle?.id === subscriptiontype.id ? "selected" : ""
                        } ${subscriptiontype.disabled ? "disabled" : ""}`}
                        onClick={() => onChange(subscriptiontype)}
                    >
                        <div className="bundle-inner">
                            <div
                                className="bundle-title text-white"
                                style={{ backgroundColor: `${subscriptiontype.color}` }}
                            >
                                {subscriptiontype.title}
                            </div>
                            <BundleBody subscriptiontype={subscriptiontype} />
                            <div className="bundle-footer" style={footerStyle}>
                                {boocked
                                    ? t("global.booked", "Gebucht")
                                    : t("global.booking", "Buchen")}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default RenderBundles;
