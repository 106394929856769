/** @format */
/* eslint-disable no-template-curly-in-string */

import * as yup from "yup";
import { setLocale, SchemaOf } from "yup";

interface SchemaShape {
    company: string;
    company2?: string;
    sex: string;
    name: string;
    surname: string;
    address: string;
    address2?: string;
    zip: string;
    city: string;
    ustid?: string;
    country_id: number;
    email: string;
    phone: string;
    invoice_email?: string;
}

setLocale({
    mixed: {
        required: "Eine Eingabe ist notwendig",
        number: "Bitte eine Auswahl treffen",
        typeError: "Bitte etwas eingeben",
    },
    number: {
        positive: "Eine Eingabe ist notwendig",
        required: "Eine Eingabe ist notwendig",
    },
    string: {
        lowercase: "Nur Kleinbuchstaben sind erlaubt.",
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
        required: "Eine Eingabe ist notwendig",
        typeError: "Bitte etwas eingeben",
    },
});

const invoiceRecipientValidateSchema: SchemaOf<SchemaShape> = yup.object().shape({
    company: yup.string().min(2).max(128).required(),
    company2: yup.string(),
    sex: yup.string().required(),
    name: yup.string().min(2).max(64).required(),
    surname: yup.string().min(2).max(64).required(),
    address: yup.string().min(2).max(64).required(),
    address2: yup.string(),
    zip: yup.string().min(5).max(7).required(),
    city: yup.string().min(2).max(128).required(),
    ustid: yup.string(),
    country_id: yup.number().positive().required(),
    email: yup.string().email("Nur gültige E-Mails erlaubt.").required(),
    phone: yup.string().required(),
    invoice_email: yup.string().email("Nur gültige E-Mails erlaubt."),
});

export default invoiceRecipientValidateSchema;
