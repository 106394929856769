/** @format */

import { muscleAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context//LoadingProvider";
import AuthContext from "@context/AuthProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

import ModalView from "../../../components/Modal/ModalView";
import { OptionType, SelectedOption } from "../MuscleAdd/MuscleAdd";
import "./MuscleEdit.scss";

interface MuscleEditParams {
    id: string;
}

const MuscleEdit: React.FC = () => {
    const [t] = useTranslation();
    const { setSaved, setShouldRefetch } = useContext(LoadingContext);
    const { user } = useContext(AuthContext);
    const { id } = useParams<MuscleEditParams>();

    const history = useHistory();

    const [muscleId, setMuscleId] = useState<number | null>(null);
    const [name, setName] = useState<string>("");

    const [post, setPost] = useState<boolean>(false);

    const [selectable, setSelectable] = useState<null | OptionType[]>(null);
    const [selected, setSelected] = useState<SelectedOption>(null);

    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    useEffect(() => {
        void muscleAdapter.getEditMuscle(id).then((response) => {
            const { muscle, musclegroups, companies } = response;

            if (
                !(user?.is_admin && muscle.editableMandantor) &&
                !(user?.is_coach && muscle.editableCompany)
            ) {
                return history.push("/collection/muscles");
            }

            setMuscleId(muscle.id);
            setName(muscle.name);
            setSelected(
                muscle.muscelesgroup
                    ? { value: muscle.muscelesgroup.id, label: muscle.muscelesgroup.name }
                    : null
            );

            const updateSelect: { value: string; label: string }[] = [];
            Object.keys(musclegroups).map((key) => {
                updateSelect.push({ value: key, label: musclegroups[Number(key)] });
            });
            setSelectable(updateSelect);

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            const defaultCompany = updateCompaniesSelect.find(
                (ele) => Number(ele.value) === muscle.company_id
            );

            setSelectedCompany(defaultCompany ? defaultCompany : updateCompaniesSelect[0]);
        });
    }, [id, user?.is_admin, user?.is_coach, history]);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!muscleId) {
            return;
        }

        setPost(true);
        void muscleAdapter
            .edit(Number(muscleId), {
                name,
                muscelesgroup_id: Number(selected?.value),
                company_id: id
                    ? undefined
                    : user?.is_admin && selectedCompany
                    ? Number(selectedCompany.value)
                    : user?.company_id,
            })
            .then((response) => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    if (!selectable || !user) {
        return null;
    }

    return (
        <ModalView
            title={t("muscles.edit", "Muskel bearbeiten")}
            pushRef={handlePushClose}
            goBack="collection/muscles"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="muscleCompany">
                                {t("muscle.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="muscleCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                                isDisabled={!!id}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-6">
                        <InputLabel required htmlFor="muscleName">
                            {t("muscle.muscleName", "Muskel")}
                        </InputLabel>
                        <input
                            id="muscleName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            value={name}
                            required
                        />
                    </div>
                    <div className="col-6">
                        <InputLabel required htmlFor="muscleMusclegroup">
                            {t("muscle.musclegroup", "Muskelgruppe")}
                        </InputLabel>
                        <Select
                            id="muscleMusclegroup"
                            value={selected}
                            required
                            placeholder={t("global.selectEmptyChose", "-- Wähle --")}
                            onChange={(e) => setSelected(e)}
                            options={selectable}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button disabled={!name} className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("muscle.save", "Muskel speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default MuscleEdit;
