/** @format */

import ManageSubscription from "@screen/Profile/Payment/Subscription/ManageSubscription";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const SubscriptionInactive: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <div>
            <h1>Sie haben kein aktives Abo</h1>
            <Switch>
                <Route path={path} component={ManageSubscription} />
            </Switch>
        </div>
    );
};

export default SubscriptionInactive;
