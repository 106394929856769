/** @format */

import { faEdit, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { ExercisesWorkoutscheduleDefault } from "./ExerciseWrapper";

interface ExerciseSelectedCardProps {
    exercisId: string;
    dragging: boolean;
    dragElement: string;
    index: number;
    handleModalShow: (key: number) => void;
    exercisData: ExercisesWorkoutscheduleDefault[];
    exercisType: number;
    exercisName: string;
}

const RenderSecondIcon: React.FC<{
    dragging: boolean;
    exercisId: string;
    dragElement: string;
    index: number;
    handleModalShow: (key: number) => void;
}> = ({ dragging, exercisId, dragElement, handleModalShow, index }) => {
    if (!dragging && `${exercisId}-selected` === dragElement) {
        return <FontAwesomeIcon icon={faTrash} className="icon remove-icon" />;
    }

    return (
        <FontAwesomeIcon
            icon={faEdit}
            className="icon icon-select"
            onClick={() => {
                handleModalShow(index);
            }}
        />
    );
};

const RenderText: React.FC<{
    length: number;
}> = ({ length }) => {
    if (length > 0) {
        return (
            <div className="row">
                <div className="col-12 selected-set-col">
                    <span className="selected-set-value">
                        <strong>{length} </strong>
                        {length > 1 ? "Sätze" : "Satz"}
                    </span>
                </div>
            </div>
        );
    }
    return null;
};

const RenderTypeName: React.FC<{ exercisType: number }> = ({ exercisType }) => {
    switch (exercisType) {
        case 1:
            return <>Warm Up</>;
        case 2:
            return <>Hauptteil</>;
        case 3:
            return <>Finischer/Challange</>;
        case 4:
            return <>Cooldown</>;
        default:
            return null;
    }
};

const ExerciseSelectedCard: React.FC<ExerciseSelectedCardProps> = ({
    exercisId,
    dragging,
    dragElement,
    index,
    handleModalShow,
    exercisData,
    exercisType,
    exercisName,
}) => {
    const selectClass = `draggable-element d-flex justify-content-between ${
        exercisData.length > 0 ? "draggable-selected-data" : "draggable-selected"
    }`;

    return (
        <Draggable
            key={`${exercisId}-selected`}
            draggableId={`${exercisId}-selected`}
            index={Number(index)}
        >
            {(provided) => (
                <div
                    className="col-12"
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className={selectClass}>
                        <div className="d-flex justify-content-between w-100">
                            {exercisName}
                            <div className="d-flex justify-content-space-between align-items-center">
                                <span className="excercise-select-type">
                                    <RenderTypeName exercisType={exercisType} />
                                </span>
                                <RenderText length={exercisData.length} />
                            </div>
                        </div>
                        <RenderSecondIcon
                            dragging={dragging}
                            exercisId={exercisId}
                            dragElement={dragElement}
                            handleModalShow={handleModalShow}
                            index={index}
                        />
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default ExerciseSelectedCard;
