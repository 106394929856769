/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

export interface MuscelesgroupModel extends Model {
    id: number;
    name: string;
    editableCompany: boolean;
    editableMandantor: boolean;
    created: DateTime;
}

export interface MuscelesgroupAddModel extends Model {
    name: string;
}

export default class MuscelesgroupAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    MuscelesgroupModel,
    Paginator,
    MuscelesgroupAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Muscelesgroups", "Muscelesgroup", "Muscelesgroups", adapter);
    }

    public async getIndex(search = ""): Promise<MuscelesgroupModel[]> {
        const { data } = await this._get<{ muscelesgroups: MuscelesgroupModel[] }>(
            `index?search=${search}`
        );

        return data.muscelesgroups;
    }
}
