/** @format */

import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

interface CounterProps {
    from: number;
    to: number;
    round?: boolean;
}

const Counter: React.FC<CounterProps> = ({ from, to, round = false }) => {
    const nodeRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const node = nodeRef.current;
        if (!node) {
            return;
        }

        const controls = animate(from, to, {
            duration: 1,
            onUpdate(value) {
                node.textContent = round ? value.toFixed(2) : value.toFixed(0);
            },
        });

        return () => controls.stop();
    }, [from, to, round]);

    return <span ref={nodeRef} className="counter" />;
};

export default Counter;
