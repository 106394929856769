/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { extendedUserAdapter, globalUserAdapter } from "@api/ApiRequest";
import { ExtendedUserModel } from "@api/ext/ExtendedUserAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import RowOptionWrapper from "@components/Table/RowOptionWrapper/RowOptionWrapper";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import UsersTable from "@components/Table/UsersTable/UsersTable";
import LoadingContext from "@context/LoadingProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import { faArchive, faInfoSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

const ArchivedUserIndex: React.FC = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const { setLoading } = useLoading();
    const { setSaved } = useContext(LoadingContext);
    const { subscription, userCount, updateUserCount, clientGroupIds } = useSubscriptionContext();

    const [users, setUsers] = useState<ExtendedUserModel[]>([]);
    const [selectedSingleUser, setSelectedSingleUser] = useState<ExtendedUserModel | null>(null);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [selectedClientsToUnarchive, setSelectedClientsToUnarchive] = useState<number>(0);

    const [showUnarchiveModal, setShowUnarchiveModal] = useState<boolean>(false);
    const [showCannotUnarchiveModal, setShowCannotUnarchiveModal] = useState<boolean>(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        extendedUserAdapter
            .getIndex(search, true)
            .then((data) => {
                setUsers(data.users);
                setLoading(false);
            })
            .catch((e) => console.log(e));
    }, [setLoading, search]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    const onSelectedChange = useCallback((selectedIds: number[]) => {
        setSelectedUserIds(selectedIds);
    }, []);

    const onUnarchiveUser = useCallback(
        (singleUser?: ExtendedUserModel) => {
            let unarchiveUsers = users.filter((elem) => {
                return elem.id ? selectedUserIds.includes(elem.id) : undefined;
            });

            if (singleUser) {
                unarchiveUsers = [singleUser];
            }

            const numUnarchiveClients = unarchiveUsers.filter((elem) =>
                elem.group_id ? clientGroupIds.includes(elem.group_id) : undefined
            ).length;

            setSelectedSingleUser(singleUser ?? null);

            setSelectedClientsToUnarchive(numUnarchiveClients);

            if (userCount && userCount >= subscription.seat_count && numUnarchiveClients > 0) {
                setShowCannotUnarchiveModal(true);

                return;
            }

            if (
                selectedUserIds &&
                userCount &&
                userCount + numUnarchiveClients > subscription.seat_count
            ) {
                setShowCannotUnarchiveModal(true);

                return;
            }

            setShowUnarchiveModal(true);
        },
        [clientGroupIds, selectedUserIds, subscription.seat_count, userCount, users]
    );

    const doUnarchiveUser = useCallback(() => {
        let idsToSend = null;

        if (selectedSingleUser) {
            idsToSend = selectedSingleUser.id;
        }

        if (selectedUserIds.length && !idsToSend) {
            idsToSend = selectedUserIds;
        }

        if (!idsToSend) {
            return;
        }

        void globalUserAdapter.unarchive(idsToSend).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("userView.archiveSuccess", "Benutzer wurden archiviert."),
                });

                fetchData();
                updateUserCount();
            }
        });
    }, [fetchData, updateUserCount, selectedSingleUser, selectedUserIds, setSaved, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData, search]);

    return (
        <>
            <div className="row">
                <div className="col-md-6 col-sm-9 col-12 py-3">
                    <TableSearch
                        handleSearch={handleSearch}
                        placeholder={t("global.search", "Suche")}
                        search={search}
                    />
                </div>
            </div>
            <UsersTable
                data={users}
                selectable
                onSelectedChange={onSelectedChange}
                hideColumns={["lastlogin"]}
                multiSelectOptions={
                    <>
                        <button
                            className="btn btn-sm btn-light"
                            onClick={() => {
                                setSelectedSingleUser(null);
                                onUnarchiveUser();
                            }}
                        >
                            {t("global.unarchive", "Dearchivieren")}
                        </button>
                    </>
                }
                rowOptions={{
                    id: "options",
                    Header: "",
                    Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                        if (!row.original.id) {
                            return null;
                        }

                        return (
                            <RowOptionWrapper id={`dMenue-${row.original.id}`}>
                                <li>
                                    <Link
                                        className="dropdown-item option-dropdown"
                                        to={`/users/view/${row.original.id}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoSquare}
                                            className="option-icon"
                                        />
                                        {t("global.view", "Ansicht")}
                                    </Link>
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item option-dropdown"
                                        onClick={() => {
                                            onUnarchiveUser(row.original);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArchive} className="option-icon" />
                                        {t("global.unarchive", "Dearchivieren")}
                                    </button>
                                </li>
                            </RowOptionWrapper>
                        );
                    },
                }}
            />
            {showUnarchiveModal && (
                <ConfirmDialogue
                    title={t("user.unarchiveUser", "Benutzer dearchivieren")}
                    show={showUnarchiveModal}
                    onHide={() => setShowUnarchiveModal(false)}
                    onSubmit={doUnarchiveUser}
                >
                    <div className="p-3">
                        {selectedSingleUser ? (
                            <p>
                                {t(
                                    "users.singleUnarchiveText",
                                    "Möchten Sie diesem Benutzer {{name}} jetzt dearchivieren?",
                                    {
                                        name:
                                            selectedSingleUser.name +
                                            " " +
                                            selectedSingleUser.surname,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {t(
                                    "users.multiUnarchiveText",
                                    "Sie haben {{num}} Benutzer ausgewählt. Möchten Sie diese Benutzer jetzt dearchivieren?",
                                    {
                                        num: selectedUserIds.length,
                                    }
                                )}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
            {showCannotUnarchiveModal && (
                <ConfirmDialogue
                    title={t("user.cannotUnarchiveModalTitle", "Benutzer erneut einladen")}
                    show={showCannotUnarchiveModal}
                    onHide={() => setShowCannotUnarchiveModal(false)}
                    onSubmit={() => {
                        setShowCannotUnarchiveModal(false);

                        history.push("/profile/subscription");
                    }}
                    confirmText={t("user.cannotUnarchiveModalSubmit", "Zusätzliche Sitze buchen")}
                >
                    <div className="p-3">
                        <p>
                            {t(
                                "users.cannotUnarchiveModalText",
                                "Aktuell sind {{userCount}} von {{seatCount}} in Benutzung. Sie haben {{num}} Clienten zum Dearchivieren ausgewählt, jedoch {{free}} Sitze frei. Buchen Sie weitere Sitze um die ausgewählten Benutzer zu Dearchivieren.",
                                {
                                    userCount: userCount ?? 0,
                                    seatCount: subscription.seat_count,
                                    free: subscription.seat_count - (userCount ?? 0),
                                    num: selectedClientsToUnarchive,
                                }
                            )}
                        </p>
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default ArchivedUserIndex;
