/** @format */

import { Modal } from "bootstrap";
import React, { useEffect, useRef } from "react";

import "./WorkoutschedulesModalView.scss";

interface WorkoutschedulesModalViewProps {
    show: boolean;
    onHide: () => void;
    size?: "lg" | "sm";
}

const WorkoutschedulesModalView: React.FC<WorkoutschedulesModalViewProps> = ({
    children,
    show,
    onHide,
    size = "sm",
}) => {
    const modalView = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let bsModal: Modal | null = null;

        if (modalView.current) {
            const myModal = modalView.current;
            bsModal = Modal.getInstance(myModal);

            if (!bsModal && !show) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.hide();
            } else if (!bsModal && show) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.show();
            } else if (show) {
                bsModal.show();
            }

            modalView.current.addEventListener("hidden.bs.modal", function () {
                onHide();
            });
        }
    }, [modalView, show, onHide]);

    return (
        <div className="modal-backdrop-custom">
            <div
                ref={modalView}
                className="modal fade show"
                tabIndex={-1}
                id="coachModal"
                aria-hidden="true"
                aria-labelledby="modal"
                style={{
                    display: "block",
                }}
            >
                <div className={`modal-dialog modal-${size} modal-dialog-centered`}>
                    <div className="modal-content">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default WorkoutschedulesModalView;
