/** @format */

import AuthContext from "@context/AuthProvider";
import { faHome, faSignOut, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ClientNav: React.FC = () => {
    const [t] = useTranslation();
    const { profileImage, user } = useContext(AuthContext);

    return (
        <>
            <Link className="top-navigation-left" to="/dashboard">
                <img src="/img/logo_h.png" className="img img-responsive" alt="" />
            </Link>
            {user?.emailconfirmed ? (
                <ul className="top-navigation-right">
                    <li className="d-inline top-nav-icon-wrapper">
                        <Link
                            to="/core/users/profile"
                            className="top-navigation-right-link profile-link"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            id="navbarDropdown"
                        >
                            <div className="profile-img">
                                {profileImage?.url && user?.id ? (
                                    <img
                                        key={`profile-image-${user?.id}`}
                                        alt={t("global.prfileImage", "Profilbild")}
                                        src={`${profileImage.url}`}
                                    />
                                ) : (
                                    <div className="empty-img-nav" />
                                )}
                            </div>
                            {!user?.id ? (
                                <span key="profile-no-user" className="empty-user" />
                            ) : (
                                <span key={`profile-${user.id}`}>
                                    {user.name + " " + user.surname}
                                </span>
                            )}
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <Link className="dropdown-item dropdown-mainnav" to="/dashboard">
                                    <FontAwesomeIcon icon={faHome} />
                                    <span className="ms-2">{t("mainNav.home", "Startseite")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item dropdown-mainnav" to="/profile">
                                    <FontAwesomeIcon icon={faUser} />
                                    <span className="ms-2">
                                        {t("mainNav.profile", "Benutzerprofil")}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item dropdown-mainnav option-danger"
                                    to="/logout"
                                >
                                    <FontAwesomeIcon icon={faSignOut} />
                                    <span className="ms-2">{t("mainNav.logout", "Ausloggen")}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            ) : (
                <ul className="top-navigation-right">
                    <li className="d-flex align-items-center">
                        <Link className="second-nav-btn me-3" to="/logout">
                            <FontAwesomeIcon icon={faSignOut} />
                            <span className="ms-2">{t("mainNav.logout", "Ausloggen")}</span>
                        </Link>
                    </li>
                </ul>
            )}
        </>
    );
};

export default ClientNav;
