/** @format */

import { equipmentAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import ModalView from "@components/Modal/ModalView";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import "./EquipmentEdit.scss";
import { Modal } from "bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

interface EquipmentEditParams {
    id: string;
}

const EquipmentEdit: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setSaved, setShouldRefetch } = useContext(LoadingContext);

    const history = useHistory();
    const { id } = useParams<EquipmentEditParams>();

    const [equipmentId, setEquipmentId] = useState<number | null>(null);
    const [name, setName] = useState<string>("");

    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [post, setPost] = useState<boolean>(false);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    useEffect(() => {
        void equipmentAdapter.getEditEquipment(id).then((response) => {
            const { equipment, companies } = response;

            if (
                !(user?.is_admin && equipment.editableMandantor) &&
                !(user?.is_coach && equipment.editableCompany)
            ) {
                return history.push("/collection/equipment");
            }

            setEquipmentId(equipment.id);
            setName(equipment.name);

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            const defaultCompany = updateCompaniesSelect.find(
                (ele) => Number(ele.value) === equipment.company_id
            );

            setSelectedCompany(defaultCompany ? defaultCompany : updateCompaniesSelect[0]);
        });
    }, [id, user?.is_admin, user?.is_coach, history]);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!equipmentId) {
            return;
        }

        setPost(true);
        void equipmentAdapter
            .edit(Number(equipmentId), {
                name,
                company_id: id
                    ? undefined
                    : user?.is_admin && selectedCompany
                    ? Number(selectedCompany.value)
                    : user?.company_id,
            })
            .then((response) => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    if (!user) {
        return null;
    }

    return (
        <ModalView
            title={t("equipment.edit", "Trainingsgerät bearbeiten")}
            pushRef={handlePushClose}
            goBack="collection/equipment"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="equipmentCompany">
                                {t("equipment.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="equipmentCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                                isDisabled={!!id}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12">
                        <InputLabel required htmlFor="equipmentName">
                            {t("equipment.equipmentName", "Trainingsgerät")}
                        </InputLabel>
                        <input
                            id="equipmentName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            required
                            value={name}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button disabled={!name} className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("equipment.save", "Equipment speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default EquipmentEdit;
