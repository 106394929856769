/** @format */

import LoadingContext from "@context/LoadingProvider";
import { faExclamationCircle, faTimes, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import "./PopUp.scss";

const PopUp: React.FC = () => {
    const [t] = useTranslation();

    const { saved, handleClose } = useContext(LoadingContext);

    const variants = {
        open: { opacity: 1, y: "-35px" },
        closed: { opacity: 0, y: "100%" },
    };

    return (
        <>
            <motion.div
                className={`popup d-flex align-items-center ${
                    saved.type === "successful" ? "popup-success" : ""
                }`}
                animate={saved.show ? "open" : "closed"}
                variants={variants}
            >
                {saved.type === "successful" ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="info-icon-popup" />
                ) : (
                    <FontAwesomeIcon icon={faExclamationCircle} className="info-icon-popup" />
                )}

                <div className="d-flex">
                    <div className="popup-text">
                        <span>
                            {saved.type === "successful"
                                ? t("popup.succes.headline", "Erfolgreich")
                                : t("popup.fail.headline", "Fehlgeschlagen")}
                        </span>
                        <span>
                            {saved.text ? (
                                saved.text
                            ) : (
                                <>
                                    {saved.type === "successful"
                                        ? t("popup.succes.text", "Es wurde gespeichert")
                                        : t(
                                              "popup.fail.text",
                                              "Es konnte nicht gespeichert werden"
                                          )}
                                </>
                            )}
                        </span>
                    </div>
                    <div onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} className="close-popup" />
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default PopUp;
