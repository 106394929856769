/** @format */

import { ApiAdapter, RequestAdapterInterface } from "@interweberde/prima-core";

export enum SubscriptionBundle {
    START = "start",
    BASIC = "basic",
    ADVANCED = "advanced",
    PRO = "pro",
    TEST = "test",
}

export enum SubscriptionInterval {
    MONTHLY = "1 month",
    QUARTERLY = "3 months",
    YEARLY = "12 month",
}

export type SubscriptionTypeOption = {
    id: number;
    description: string | null;
    benefits: string | null;
    interval: SubscriptionInterval;
    count_base_seats: number;
    count_max_seats: number | null;
    base_price: number;
    base_price_gross: number;
    cost_per_seat: number;
    cost_per_seat_gross: number;
    vat: number;
    subscriptiontype?: SubscriptionType;
};

export type SubscriptionTypeOptionCost = {
    id: number;
    interval: string;
    count_base_seats: number;
    count_max_seats: number;
    base_price: number;
    base_price_gross: number;
    cost_per_seat: number;
    cost_per_seat_gross: number;
    calculated_price: number;
    calculated_price_gross: number;
    price_diff: number;
    price_diff_gross: number;
    free_seats: number;
    curr_seats: number;
};

export type SubscriptionTypeAndCost = {
    id: number;
    title: string;
    options: SubscriptionTypeOptionCost[];
};

export type SubscriptionType = {
    id: number;
    title: SubscriptionBundle;
    color: string;
    count_base_seats: number;
    subscriptiontypeoptions?: SubscriptionTypeOption[];
    cost?: SubscriptionTypeAndCost;
    disabled?: boolean;
};

export default class SubscriptiontypeAdapter<
    RequestConfigType
> extends ApiAdapter<RequestConfigType> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter, "subscriptions/subscriptiontypes");
    }

    async getSubscriptionTypes(seat_count: number | null): Promise<SubscriptionType[]> {
        const response = seat_count
            ? await this._get<{ subscriptiontypes: SubscriptionType[] }>(`index/${seat_count}`)
            : await this._get<{ subscriptiontypes: SubscriptionType[] }>(`index`);

        const { subscriptiontypes } = response.data;

        if (!subscriptiontypes) {
            throw new Error("invalid response");
        }

        return subscriptiontypes;
    }

    async getSubscriptionBundle(
        title: SubscriptionBundle,
        interval: SubscriptionInterval
    ): Promise<SubscriptionTypeOption> {
        const response = await this._get<{ subscriptiontypeoption: SubscriptionTypeOption }>(
            `get-subscription-bundle/${title}/${interval}`
        );

        const { subscriptiontypeoption } = response.data;

        if (!subscriptiontypeoption) {
            throw new Error("invalid response");
        }

        return subscriptiontypeoption;
    }
}
