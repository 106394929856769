/** @format */

import { SubscriptionTypeOption } from "@api/ext/SubscriptiontypeAdapter";
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";

interface RenderChangeSeatsProps {
    option: SubscriptionTypeOption | null;
    userCount: number | null;
    seats: number;
    setSeats: (value: number | ((value: number) => number)) => void;
}

const RenderChangeSeats: React.FC<RenderChangeSeatsProps> = ({
    option,
    userCount,
    seats,
    setSeats,
}) => {
    const [subEnabled, setSubEnabled] = useState(true);
    const [addEnabled, setAddEnabled] = useState(true);
    const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

    const handleClickSub = useCallback(() => {
        setSeats(seats - 1);
    }, [setSeats, seats]);

    const handleClickAdd = useCallback(() => {
        setSeats(seats + 1);
    }, [setSeats, seats]);

    const startSub = () => {
        if (intervalRef.current) return;

        intervalRef.current = setInterval(() => {
            subEnabled && setSeats((prev) => prev - 1);
        }, 100);
    };

    const stopSub = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const startAdd = () => {
        if (intervalRef.current) return;

        intervalRef.current = setInterval(() => {
            addEnabled && setSeats((prev) => prev + 1);
        }, 100);
    };

    const stopAdd = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    useEffect(() => {
        setSubEnabled(!(userCount === seats || option?.count_base_seats === seats || seats === 1));
        setAddEnabled(!(option && option.count_max_seats && option.count_max_seats <= seats));
    }, [option, seats, userCount]);

    useEffect(() => {
        if (!subEnabled) stopSub();
    }, [subEnabled]);

    useEffect(() => {
        if (!addEnabled) stopAdd();
    }, [addEnabled]);

    useEffect(() => {
        return () => stopAdd();
    }, []);

    return (
        <div className="col-12 d-flex align-items-center">
            <button
                className="change-seats-button"
                disabled={!subEnabled}
                onClick={handleClickSub}
                onMouseDown={startSub}
                onMouseUp={stopSub}
                onMouseLeave={stopSub}
                onTouchStart={startSub}
                onTouchEnd={stopSub}
                onTouchCancel={stopSub}
            >
                <FontAwesomeIcon icon={faMinus} />
            </button>
            <div className="seats-display">{seats}</div>
            <button
                className="change-seats-button"
                disabled={!addEnabled}
                onClick={handleClickAdd}
                onMouseDown={startAdd}
                onMouseUp={stopAdd}
                onMouseLeave={stopAdd}
                onTouchStart={startAdd}
                onTouchEnd={stopAdd}
                onTouchCancel={stopAdd}
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>
        </div>
    );
};

export default RenderChangeSeats;
