/** @format */

import UserViewContext from "@context/UserViewProvider";
import { faMailbox, faExclamationCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import UserWorkoutschedulesAssignment from "./UserWorkoutschedulesAssignment/UserWorkoutschedulesAssignment";

const UserInfo: React.FC = () => {
    const [t] = useTranslation();

    const { user } = useContext(UserViewContext);

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="col-md-6 col-12">
                <table className="table table-striped table-small">
                    <tbody>
                        <tr>
                            <th scope="row">{t("user.coach", "Trainer")}</th>
                            <td>{user.workoutcoaches_user?.name}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.group", "Gruppe")}</th>
                            <td>{user.group.name}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.username", "Benutzername")}</th>
                            <td>{user.username}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.sex", "Geschlecht")}</th>
                            <td>
                                {user.sex === "f"
                                    ? t("global.sex.f", "weiblich")
                                    : user.sex === "m"
                                    ? t("global.sex.m", "männlich")
                                    : t("global.sex.d", "sonstiges")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.firstname", "Name")}</th>
                            <td>{`${user.name} ${user.surname}`}</td>
                        </tr>
                        <tr>
                            <th scope="row">
                                {t("user.email", "E-Mail")}
                                {!user.emailconfirmed ? (
                                    <FontAwesomeIcon
                                        className="ms-2 text-danger"
                                        icon={faExclamationCircle}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        className="ms-2 text-success"
                                        icon={faCheckCircle}
                                    />
                                )}
                            </th>
                            <td>
                                <a
                                    className="w-100 d-flex justify-content-between align-items-center mail-link"
                                    href={`mailto: ${user.email}`}
                                >
                                    {user.email + " "} <FontAwesomeIcon icon={faMailbox} />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-6 col-12">
                <table className="table table-striped table-small">
                    <tbody>
                        <tr>
                            <th scope="row">{t("user.activ", "Aktiv")}</th>
                            <td>
                                {!user.archived ? (
                                    <span className="badge bg-success">Aktiv</span>
                                ) : !user.enabled ? (
                                    <span className="badge bg-danger">Gesperrt</span>
                                ) : (
                                    <span className="badge bg-danger">Achiviert</span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.lastlogin", "Letzter Login")}</th>
                            <td>
                                {user?.lastlogin
                                    ? DateTime.fromISO(String(user.lastlogin), {
                                          locale: "de",
                                      }).toFormat("dd LLL. yyyy")
                                    : t("user.noLogin", "Noch nicht eingeloggt")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.failedLogins", "Fehlgeschlagene Logins")}</th>
                            <td>{user.failedlogins}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.created", "Erstellt")}</th>
                            <td>
                                {user?.created &&
                                    DateTime.fromISO(String(user.created), {
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("user.modified", "Bearbeitet")}</th>
                            <td>
                                {user?.modified &&
                                    DateTime.fromISO(String(user.modified), {
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {user.id !== undefined && (
                <UserWorkoutschedulesAssignment user_id={user.id} disabled={!!user.archived} />
            )}
        </>
    );
};

export default UserInfo;
