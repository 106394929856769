/** @format */

import React, { useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import Distance from "./Distance";
import Kg from "./Kg";
import Min from "./Min";
import Option from "./Option";
import Wdh from "./Wdh";

interface TypeWrapperProps {
    type: number;
    secondType: number;
    handleSubmit: (data: { wdh: string; value: string }) => void;
    option: string;
}

const TypeWrapper: React.FC<TypeWrapperProps> = ({ type, secondType, handleSubmit, option }) => {
    const [t] = useTranslation();

    const [firstValue, setFirstValue] = useState<string>("");
    const [secondValue, setSecondValue] = useState<string>("");

    const getType = (type: number, sendData: Dispatch<SetStateAction<string>>) => {
        if (type === 1) {
            return <Wdh sendData={sendData} />;
        }
        if (type === 2 || type === 3 || type === 4) {
            return <Kg sendData={sendData} />;
        }
        if (type === 5) {
            return <Min sendData={sendData} />;
        }
        if (type === 6) {
            return <Option sendData={sendData} option={option} />;
        }
        if (type === 7) {
            return <Distance sendData={sendData} />;
        }
    };

    const sendSubmit = () => {
        handleSubmit({
            wdh: firstValue,
            value: secondValue,
        });
    };

    return (
        <div className="row mt-2">
            {getType(type, setFirstValue)}
            {getType(secondType, setSecondValue)}
            <div className=" col-12 mb-2">
                <button className="btn btn-save btn-sm" onClick={() => sendSubmit()}>
                    {t("global.add", "Hinzufügen")}
                </button>
            </div>
        </div>
    );
};

export default TypeWrapper;
