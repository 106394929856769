/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import "./WorkoutscheduleTable.scss";
import { WorkoutschedulesIndex } from "@api/ext/WorkoutschedulesAdapter";
import { faClipboard } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "bootstrap";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import TableWithPagination from "../TableWithPagination";

type WorkoutscheduleTableColumns = "select" | "name" | "assignment" | "created" | "options";

interface WorkoutscheduleTableProps {
    data: WorkoutschedulesIndex[];
    hideColumns?: WorkoutscheduleTableColumns[];
    selectable?: boolean;
    onSelectedChange?: (selectedIds: number[]) => void;
    multiSelectOptions?: React.ReactNode;
    rowOptions?: React.ReactNode;
}

const WorkoutscheduleTable: React.FC<WorkoutscheduleTableProps> = ({
    data,
    hideColumns = [],
    selectable = false,
    onSelectedChange = () => null,
    multiSelectOptions = null,
    rowOptions = null,
}) => {
    const { t } = useTranslation();

    const columns = useMemo(() => {
        const nameCell = !hideColumns.includes("name")
            ? [
                  {
                      Header: t("global.workoutschedule", "Trainingsplan"),
                      accessor: "name",
                      Cell: ({ row }: CellProps<WorkoutschedulesIndex>) => {
                          const tooltipRef = useRef<HTMLDivElement>(null);

                          useEffect(() => {
                              if (tooltipRef.current) {
                                  let btTooltip = Tooltip.getInstance(tooltipRef.current);

                                  if (!btTooltip) {
                                      btTooltip = new Tooltip(tooltipRef.current, {
                                          trigger: "hover",
                                          placement: "right",
                                      });
                                  }
                              }
                          }, []);

                          if (!row.original.exercises) {
                              return null;
                          }

                          return (
                              <div className="d-flex justify-content-start align-items-center">
                                  <div
                                      ref={tooltipRef}
                                      className="d-flex justify-content-center align-items-center ein-mr2"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={`${t("global.exercise_plural", "Übungen")}: ${
                                          row.original.exercises
                                      }`}
                                  >
                                      <FontAwesomeIcon
                                          icon={faClipboard}
                                          size="2x"
                                          color="rgba(0, 0, 0, 0.8)"
                                      />
                                      <div className="exercise-info-number">
                                          {row.original.exercises}
                                      </div>
                                  </div>

                                  <Link
                                      to={`/workoutschedules/view/${row.original.id}`}
                                      className="cell-link"
                                  >
                                      {row.original.name}
                                  </Link>
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const assignmentCell = !hideColumns.includes("assignment")
            ? [
                  {
                      Header: () => {
                          return (
                              <div className="d-flex justify-content-end">
                                  {t("global.assigned", "Zugewiesen")}
                              </div>
                          );
                      },
                      className: "workoutschedules-index-span",
                      id: "active",
                      Cell: ({ row }: CellProps<WorkoutschedulesIndex>) => {
                          return (
                              <div className="d-flex justify-content-end">
                                  {row.original.self_workoutschedules}{" "}
                                  {t("global.user", "Benutzer")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const createdCell = !hideColumns.includes("created")
            ? [
                  {
                      Header: () => {
                          return (
                              <div className="d-flex justify-content-end">
                                  {t("global.created", "Erstellt")}
                              </div>
                          );
                      },
                      className: "options-span workoutschedules-index-span",
                      id: "created",
                      Cell: ({ row }: CellProps<WorkoutschedulesIndex>) => {
                          if (!row.original.created) {
                              return null;
                          }

                          const created: string = row.original.created.toString();

                          return (
                              <div className="d-flex justify-content-end">
                                  {row.original.created === null
                                      ? "-"
                                      : DateTime.fromISO(created).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const rowOptionsCell = rowOptions ? [rowOptions] : [];

        return [
            ...(nameCell || []),
            ...(assignmentCell || []),
            ...(createdCell || []),
            ...(rowOptionsCell || []),
        ];
    }, [hideColumns, rowOptions, t]);

    return <TableWithPagination columns={columns} data={data} selectable={selectable} />;
};

export default WorkoutscheduleTable;
