/** @format */

import { muscleAdapter } from "@api/ApiRequest";
import { MuscleModel } from "@api/ext/MuscleAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import MuscleTable from "@components/Table/MuscleTable/MuscleTable";
import RowOptionWrapper from "@components/Table/RowOptionWrapper/RowOptionWrapper";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CellProps } from "react-table";

const MusclesIndex: React.FC = () => {
    const [t] = useTranslation();
    const location = useLocation();
    const { setLoading } = useLoading();
    const { setSaved, shouldRefetch, setShouldRefetch } = useContext(LoadingContext);
    const { user } = useContext(AuthContext);

    const [muslesDefault, setMuslesDefault] = useState<MuscleModel[]>([]);

    const [muscles, setMuscles] = useState<MuscleModel[]>([]);

    const [selectedMuscle, setSelectedMuscle] = useState<MuscleModel | null>(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        muscleAdapter
            .getIndex()
            .then((data) => {
                setMuscles(data);
                setMuslesDefault(data);
                setLoading(false);
            })
            .catch(() => null);
    }, [setLoading]);

    const onDelete = useCallback((muscle?: MuscleModel) => {
        if (!muscle) {
            return;
        }

        setSelectedMuscle(muscle);
        setShowDeleteModal(true);
    }, []);

    const doDelete = useCallback(() => {
        if (!selectedMuscle?.id) {
            return;
        }

        void muscleAdapter.delete(selectedMuscle.id).then((response) => {
            setSaved({
                type: "successful",
                show: true,
                text: t("muscle.muscleDeleted", "Muskel {{name}} wurde gelöscht.", {
                    name: selectedMuscle.name,
                }),
            });

            fetchData();
        });
    }, [selectedMuscle, t, fetchData, setSaved]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (shouldRefetch) {
            fetchData();
            setShouldRefetch(false);
        }
    }, [fetchData, setShouldRefetch, shouldRefetch]);

    useEffect(() => {
        if (search) {
            muscleAdapter
                .getIndex(search)
                .then((response) => {
                    setMuscles(response);
                })
                .catch(() => null);
        } else {
            setMuscles(muslesDefault);
        }
    }, [search, muslesDefault]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    return (
        <>
            <div className="row my-4">
                <div className="col-6">
                    <TableSearch
                        handleSearch={handleSearch}
                        placeholder={t("global.search", "Suche")}
                        search={search}
                    />
                </div>
                <div className="col-6 pull-right">
                    <div className="d-flex col justify-content-end">
                        <Link
                            className="btn btn-default align-self-end"
                            to={{
                                pathname: `/muscles/add`,
                                state: { background: location },
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} /> {t("global.add", "Hinzufügen")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <MuscleTable
                    data={muscles}
                    rowOptions={{
                        id: "options",
                        Header: "",
                        Cell: ({ row }: CellProps<MuscleModel>) => (
                            <>
                                {((user?.is_admin && row.original.editableMandantor) ||
                                    (user?.is_coach && row.original.editableCompany)) && (
                                    <RowOptionWrapper id={`dMenue-${row.original.id}`}>
                                        <li>
                                            <Link
                                                className="dropdown-item option-dropdown"
                                                to={{
                                                    pathname: `/muscles/edit/${row.original.id}`,
                                                    state: { background: location },
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="option-icon"
                                                />
                                                {t("global.edit", "Bearbeiten")}
                                            </Link>
                                        </li>
                                        <li>
                                            <button
                                                onClick={() => onDelete(row.original)}
                                                className="dropdown-item option-dropdown option-danger"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="option-icon"
                                                />
                                                {t("global.delete", "Löschen")}
                                            </button>
                                        </li>
                                    </RowOptionWrapper>
                                )}
                            </>
                        ),
                    }}
                />
            </div>
            {showDeleteModal && (
                <ConfirmDialogue
                    title={t("muscle.deleteModalTitle", "Muskel löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDelete}
                >
                    <div className="p-3">
                        {selectedMuscle ? (
                            <p>
                                {t(
                                    "muscle.singleDeleteText",
                                    "Möchten Sie den Muskel {{name}} jetzt löschen?",
                                    {
                                        name: selectedMuscle.name,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {/* {t(
                                    "muscle.multiDeleteText",
                                    "Sie haben {{num}} Muskeln ausgewählt. Möchten Sie diese Muskeln jetzt löschen?",
                                    {
                                        num: selectedMuscleIds.length,
                                    }
                                )} */}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default MusclesIndex;
