/** @format */

import { SubscriptionInfo } from "@api/ext/SubscriptionAdapter";
import { SubscriptionInterval } from "@api/ext/SubscriptiontypeAdapter";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface PaymentIntervalProps {
    subscription: SubscriptionInfo;
}

const PaymentInterval: React.FC<PaymentIntervalProps> = ({ subscription }) => {
    const [t] = useTranslation();

    const paymentInterval = useMemo(() => {
        if (!subscription.mollie_db_subscription.subscriptiontypeoption.interval) {
            return "";
        }

        switch (subscription.mollie_db_subscription.subscriptiontypeoption.interval) {
            case SubscriptionInterval.MONTHLY:
                return t("subscriptions.general.interval.monthly", "monatlich");
            case SubscriptionInterval.QUARTERLY:
                return t("subscriptions.general.interval.querterly", "pro Quartal");
            case SubscriptionInterval.YEARLY:
                return t("subscriptions.general.interval.yearly", "jährlich");
            default:
                return subscription.mollie_db_subscription.subscriptiontypeoption.interval;
        }
    }, [subscription, t]);

    return <>{paymentInterval}</>;
};

export default PaymentInterval;
