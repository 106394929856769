/** @format */

import {
    Chart,
    BarController,
    CategoryScale,
    LinearScale,
    BarElement,
    DoughnutController,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import React, { useEffect, useRef } from "react";

Chart.register(
    Legend,
    Tooltip,
    BarController,
    CategoryScale,
    LinearScale,
    BarElement,
    DoughnutController,
    ArcElement
);

interface StatisticHourProps {
    dataValues?: number[];
    dataDates?: string[];
}

const StatisticHour: React.FC<StatisticHourProps> = ({ dataValues, dataDates }) => {
    const statistic = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        let chart: Chart<"bar", number[], string>;

        if (!dataValues || !dataDates) {
            return;
        }

        if (statistic.current) {
            if (!statistic.current) {
                return;
            }
            const ctx = statistic.current.getContext("2d");

            if (!ctx) {
                return;
            }

            chart = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: dataDates,
                    datasets: [
                        {
                            label: "Favorisierte Uhrzeit",
                            data: dataValues,
                            borderColor: "rgba(0, 122, 255, 0.9)",
                            borderWidth: 1,
                            backgroundColor: "rgba(0, 122, 255, 0.5)",
                        },
                    ],
                },
                options: {
                    responsive: true,
                    indexAxis: "y",
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                        tooltip: {
                            callbacks: {
                                labelTextColor(context) {
                                    return "#ffffff";
                                },
                            },
                        },
                    },
                },
            });
        }
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [dataValues, dataDates]);

    if (!dataValues || !dataDates) {
        return null;
    }

    return <canvas id="statisticUserChart" ref={statistic} height="200" />;
};

export default StatisticHour;
