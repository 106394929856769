/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { extendedMandantorAdapter } from "@api/ApiRequest";
import { CompaniesModel } from "@api/ext/ExtendedMandantorAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context//LoadingProvider";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import "../Company.scss";

const CompanyForm: React.FC = () => {
    const [t] = useTranslation();

    const { setSaved } = useContext(LoadingContext);

    const [imageChanged, setImageChanged] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const [imageView, setImageView] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | undefined>();

    const [name, setName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    const [companies, setCompanies] = useState<CompaniesModel[]>([]);

    const { setLoading } = useLoading();

    useEffect(() => {
        if (imageChanged) {
            setImageChanged(false);
        }
    }, [imageChanged]);

    useEffect(() => {
        if (imageView) {
            const reader = new FileReader();

            reader.onload = (result) => {
                setImageUrl(result?.target?.result as string);
            };

            reader.readAsDataURL(imageView);
        } else {
            setImageUrl(undefined);
        }
    }, [imageView]);

    useEffect(() => {
        void extendedMandantorAdapter.getMandantor().then((mandantor) => {
            if (mandantor.uploads != null) {
                setImageUrl(mandantor.uploads?.url);
            }

            setName(mandantor.name ?? "");
            setUrl(mandantor.www ?? "");
            setAddress(mandantor.address ?? "");
            setZip(mandantor.zip ?? "");
            setPhone(mandantor.phone ?? "");
            setCity(mandantor.city ?? "");
            setEmail(mandantor.email ?? "");

            setCompanies(mandantor.companies);

            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        void extendedMandantorAdapter
            .setMandantor(
                {
                    name,
                    address,
                    zip,
                    phone,
                    www: url,
                    city,
                    email,
                },
                imageView
            )
            .then((data) => {
                if (data) {
                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("company.change.success", "Daten wurden Aktualisiert"),
                    });
                } else {
                    setSaved({
                        type: "failed",
                        show: true,
                        text: t("company.change.failed", "Daten wurden nicht Aktualisiert!"),
                    });
                }
            });
    };

    useEffect(() => {
        setCanSubmit(!!(name && address && zip && city && phone));
    }, [address, city, name, zip, phone]);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
                <div className="col-12">
                    <h1>{t("copmany.title", "Unternehmen")}</h1>
                </div>
                <div className="col-12 justify-content-center d-flex mb-3 position-relative">
                    {!imageUrl ? (
                        <div className="company-placeholder">
                            <span>{name.charAt(0) + name.charAt(1)}</span>
                        </div>
                    ) : (
                        <img src={imageUrl} alt="Profilbild" className="company-img" />
                    )}

                    <InputLabel className="edit-avatar shadow-sm">
                        <input
                            accept="image/*"
                            className="select-image"
                            type="file"
                            onChange={(value) => {
                                setImageChanged(true);
                                setImageView(value.target.files ? value.target.files[0] : null);
                            }}
                        />
                        <FontAwesomeIcon icon={faEdit} />
                    </InputLabel>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="name">
                        {t("company.name", "Firmenname")}
                    </InputLabel>
                    <input
                        id="name"
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder={t("company.name", "Firmenname")}
                        value={name}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="address">
                        {t("company.address", "Adresse")}
                    </InputLabel>
                    <input
                        id="address"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        placeholder={t("company.address", "Adresse")}
                        value={address}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 col-md-3">
                    <InputLabel required htmlFor="zip">
                        {t("company.plz", "PLZ")}
                    </InputLabel>
                    <input
                        id="zip"
                        className="form-control"
                        onChange={(e) => setZip(e.target.value)}
                        type="text"
                        placeholder={t("company.plz", "PLZ")}
                        value={zip}
                    />
                </div>

                <div className="col-12 col-md-9">
                    <InputLabel required htmlFor="city">
                        {t("company.city", "Stadt")}
                    </InputLabel>
                    <input
                        id="city"
                        className="form-control"
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        placeholder={t("company.city", "Stadt")}
                        value={city}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 mb-2">
                    <InputLabel required htmlFor="email">
                        {t("company.email", "E-Mail")}
                    </InputLabel>
                    <input
                        id="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder={t("company.email", "E-Mail")}
                        value={email}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <InputLabel optional htmlFor="url">
                        {t("company.url", "Webadresse (url)")}
                    </InputLabel>
                    <input
                        id="url"
                        className="form-control"
                        onChange={(e) => setUrl(e.target.value)}
                        type="text"
                        placeholder={t("company.url", "Webadresse (url)")}
                        value={url}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <InputLabel optional htmlFor="tel">
                        {t("company.tel", "Telefon")}
                    </InputLabel>
                    <input
                        id="tel"
                        className="form-control"
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        placeholder={t("company.tel", "Telefon")}
                        value={phone}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
                    <button className="btn btn-save" type="submit" disabled={!canSubmit}>
                        {t("company.save", "Angaben speichern")}
                    </button>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <h5>{t("company.second", "Studios")}</h5>
                </div>
            </div>
            <div className="col-12">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t("company.name", "Name")}</th>
                            <th scope="col">{t("company.city", "Stadt")}</th>
                            <th scope="col">{t("company.address", "Adresse")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((value, index) => (
                            <tr key={`companies-${index}`}>
                                <td scope="row">{index + 1}</td>
                                <td>{value.name}</td>
                                <td>{value.city}</td>
                                <td>{value.address}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </form>
    );
};

export default CompanyForm;
