/** @format */

import { workoutschedulesAdapter } from "@api/ApiRequest";
import { ExercisesWorkoutschedulesSets } from "@api/ext/WorkoutschedulesAdapter";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ExerciseWrapper from "../ExerciseWrapper";

interface WorkoutschedulesEditParams {
    id: string | undefined;
}

interface WorkoutschedulesSelected {
    id: number;
    uuid: string;
    defaultId: number;
    primariepropertie: number;
    secondariepropertie: number;
    type: number;
    option: string;
    data: ExercisesWorkoutschedulesSets[];
    sort: number;
    content: string | null;
}

const WorkoutschedulesEdit: React.FC = () => {
    const { id } = useParams<WorkoutschedulesEditParams>();

    const [name, setName] = useState("");
    const [selected, setSelected] = useState<WorkoutschedulesSelected[]>([]);
    const [workoutscheduleId, setWorkoutscheduleId] = useState<number | null>(null);
    const [companyId, setCompanyId] = useState<number | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState<undefined | number>(undefined);

    useEffect(() => {
        if (!id) {
            return;
        }

        workoutschedulesAdapter
            .getWorkoutschedulesEdit(Number(id))
            .then((workoutschedule) => {
                const selectedData: WorkoutschedulesSelected[] = [];

                setName(workoutschedule.name);
                setWorkoutscheduleId(workoutschedule?.id);
                setCompanyId(workoutschedule.company_id);

                if (workoutschedule.exercises_workoutschedules.length) {
                    workoutschedule.exercises_workoutschedules.map((value) => {
                        return selectedData.push({
                            id: value.exercise_id,
                            uuid: String(value.id),
                            defaultId: value.id,
                            primariepropertie: value.primariepropertie,
                            secondariepropertie: value.secondariepropertie,
                            type: value.type,
                            option: String(value.option),
                            data: value.exercises_workoutschedules_sets
                                ? value.exercises_workoutschedules_sets
                                : [],
                            sort: value.sort,
                            content: value.exercise ? value.exercise.name : null,
                        });
                    });
                }

                setSelected(selectedData);

                if (workoutschedule.users.length && workoutschedule.users[0].id) {
                    setUser(Number(workoutschedule.users[0].id));
                }

                setLoaded(true);
            })
            .catch((e) => {
                console.log("failed", e);
            });
    }, [id]);

    if (!loaded || !workoutscheduleId) {
        return (
            <div className="col-12 text-center">
                <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
            </div>
        );
    }

    return (
        <ExerciseWrapper
            edit
            userId={user}
            selectedDefault={selected}
            workoutscheduleId={workoutscheduleId}
            nameDefault={name}
            companyDefaultId={companyId ? companyId : undefined}
        />
    );
};

export default WorkoutschedulesEdit;
