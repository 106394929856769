/** @format */

import { workoutUserAttributeAdapter } from "@api/ApiRequest";
import AuthContext from "@context/AuthProvider";
import { Modal } from "bootstrap";
import React, { useContext, useEffect, useRef } from "react";

import "./Welcome.scss";

const Welcome: React.FC<{ show: boolean; onHide: () => void }> = ({ show = false, onHide }) => {
    const modalView = useRef<HTMLDivElement>(null);
    const { attributes, refetchUser } = useContext(AuthContext);

    useEffect(() => {
        if (!attributes?.first_login) {
            void workoutUserAttributeAdapter.setFirstLogin().then(() => refetchUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes?.first_login]);

    useEffect(() => {
        let bsModal: Modal | null = null;

        if (modalView.current) {
            const myModal = modalView.current;
            bsModal = Modal.getInstance(myModal);

            if (!bsModal && !show) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.hide();
            } else if (!bsModal && show) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.show();
            } else if (show) {
                bsModal.show();
            }

            modalView.current.addEventListener("hidden.bs.modal", function () {
                onHide();
            });
        }
    }, [modalView, show, onHide]);

    return (
        <div className="modal-backdrop-custom">
            <div
                ref={modalView}
                className="modal fade show"
                tabIndex={-1}
                id="coachModal"
                aria-hidden="true"
                aria-labelledby="modal"
                style={{
                    display: "block",
                }}
            >
                <div className="welcome-modal modal-dialog modal modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <div id="contained-modal-title-vcenter" className="modal-title">
                                <h3 className="mb-0">Welcome</h3>
                            </div>
                            <button
                                onClick={() => onHide()}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <h1>Welcome Screen t.b.d.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
