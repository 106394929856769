/** @format */

import { PaymentMethod } from "@api/ext/MandateAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./Sepa.scss";

interface SepaProps {
    setCanSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sepa: React.FC<SepaProps> = ({ setCanSubmit }) => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const {
        sepaName,
        setSepaName,
        iban,
        setIban,
        bic,
        setBic,
        setMethod,
        method,
    } = useSubscriptionContext();

    useEffect(() => {
        if (user) {
            setSepaName(`${user.name} ${user.surname}`);
        }
        // Test code
        // setIban("DE02120300000000202051");
        // setBic("BYLADEM1001");
    }, [setSepaName, user]);

    useEffect(() => {
        if (method === PaymentMethod.DIRECTDEBIT) {
            setCanSubmit(!!sepaName && !!iban && !!bic);

            return;
        }

        setCanSubmit(!!method);
    }, [method, sepaName, iban, bic, setCanSubmit]);

    return (
        <>
            {method === PaymentMethod.DIRECTDEBIT ? (
                <div className="col-12 method-wrapper sepa-active">
                    <div className="wrapper-inner">
                        <div className="method-header">
                            {t("subscription.payment.method.directDebit", "Lastschrift")}
                        </div>
                        <div className="method-body">
                            <div className="row px-3 sepa-inner">
                                <div className="col-12 mb-3">
                                    <InputLabel required htmlFor="name">
                                        {t(
                                            "subscription.payment.method.directDebitOwner",
                                            "Kontoinhaber"
                                        )}
                                    </InputLabel>
                                    <input
                                        type="text"
                                        id="name"
                                        className="form-control"
                                        placeholder="Name"
                                        value={sepaName ?? ""}
                                        onChange={(e) => setSepaName(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputLabel required htmlFor="iban">
                                        iban
                                    </InputLabel>
                                    <input
                                        type="text"
                                        id="iban"
                                        className="form-control"
                                        placeholder="IBAN"
                                        value={iban ?? ""}
                                        onChange={(e) => setIban(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 mb-4">
                                    <InputLabel required htmlFor="bic">
                                        bic
                                    </InputLabel>
                                    <input
                                        type="text"
                                        id="bic"
                                        className="form-control"
                                        placeholder="BIC"
                                        value={bic ?? ""}
                                        onChange={(e) => setBic(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="align-self-center p-3 opacity-50"
                            onClick={() => setMethod(null)}
                        >
                            andere zahlungsart wählen
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="col-12 col-md-4 method-wrapper"
                    onClick={() => setMethod(PaymentMethod.DIRECTDEBIT)}
                >
                    <div className="wrapper-inner">
                        <div className="method-header">
                            {t("subscription.payment.method.directDebit", "Lastschrift")}
                        </div>
                        <div className="method-body">
                            <img
                                src="/img/sepa.svg"
                                className="img img-responsive payment-icon"
                                alt=""
                            />
                        </div>
                        <div className="method-footer">Wählen</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sepa;
