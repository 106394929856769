/** @format */

import { PaymentMethod } from "@api/ext/MandateAdapter";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React from "react";
import { useTranslation } from "react-i18next";

interface CreditCardProps {
    active: boolean;
}

const CreditCard: React.FC<CreditCardProps> = ({ active }) => {
    const [t] = useTranslation();
    const { setMethod } = useSubscriptionContext();

    return (
        <div
            className={`col-12 col-md-4 method-wrapper ${active ? "active" : ""}`}
            onClick={() => setMethod(PaymentMethod.CREDITCARD)}
        >
            <div className="wrapper-inner">
                <div className="method-header">
                    {t("subscription.payment.method.creditCard", "Kreditkarte")}
                </div>
                <div className="method-body">
                    <img
                        src="/img/creditcard.svg"
                        className="img img-responsive payment-icon"
                        alt=""
                    />
                </div>
                <div className="method-footer">Wählen</div>
            </div>
        </div>
    );
};

export default CreditCard;
