/** @format */

import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";

interface RowOptionWrapperProps {
    id: string;
}

const RowOptionWrapper: React.FC<RowOptionWrapperProps> = ({ children, id }) => {
    const dropdown = useRef<HTMLDivElement>(null);

    const [dropdownView, setDorpdownView] = useState(false);

    return (
        <div className="dropdown d-flex justify-content-end" ref={dropdown}>
            <div
                className="btn-sm"
                role="button"
                id={id}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setDorpdownView(!!dropdownView)}
            >
                <FontAwesomeIcon icon={faEllipsisH} />
            </div>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {children}
            </ul>
        </div>
    );
};

export default RowOptionWrapper;
