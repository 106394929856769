/**
 * @format
 */

import { exerciseAdapter } from "@api/ApiRequest";
import { MuscleData, GroupDataType } from "@api/ext/ExerciseAdapter";
import { Muscle } from "@context/ExerciseProvider";
import { faCaretDown, faSpinnerThird, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import ModalView from "../../Exercises/ExerciseForm/ModalView/ExerciseModalView";
import ModalViewMuscle from "../../Exercises/ExerciseForm/ModalView/ModalViewMuscle/ModalViewMuscle";

interface ExerciseWrapperProps {
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

const ExerciseWrapper: React.FC<ExerciseWrapperProps> = ({ setSearchString }) => {
    const [t] = useTranslation();
    const [muscle, setMuscle] = useState<Muscle[]>([]);
    const [muscleGroup, setMuscleGroup] = useState<MuscleData[]>([]);

    const [tag, setTag] = useState<Muscle[]>([]);

    const [pattern, setPattern] = useState<Muscle[]>([]);

    const [trainingsapparatus, setTrainingsapparatus] = useState<Muscle[]>([]);

    const [loaded, setLoaded] = useState<boolean>(false);

    const [modalTags, setModalTags] = useState<boolean>(false);
    const [modalMuscle, setModalMuscle] = useState<boolean>(false);
    const [modalPattern, setModalPattern] = useState<boolean>(false);
    const [modalTrainingsapparatus, setModalTrainingsapparatus] = useState<boolean>(false);

    useEffect(() => {
        if (muscle && tag && trainingsapparatus && pattern) {
            let musclePattern = "";

            muscle.map((value) => {
                if (value.active) {
                    musclePattern += String(value.id) + ",";
                }
            });

            let tagPattern = "";

            tag.map((value) => {
                if (value.active) {
                    tagPattern += String(value.id) + ",";
                }
            });

            let filterPattern = "";

            pattern.map((value) => {
                if (value.active) {
                    filterPattern += String(value.id) + ",";
                }
            });

            let trainingsapparatusPattern = "";

            trainingsapparatus.map((value) => {
                if (value.active) {
                    trainingsapparatusPattern += String(value.id) + ",";
                }
            });

            const removeLastChar = (remove: string): string => {
                let newRemove: string = remove;

                if (remove.slice(-1) === ",") {
                    newRemove = newRemove.substring(0, newRemove.length - 1);
                }

                return newRemove;
            };

            let searchString = "";

            if (musclePattern) {
                searchString = "?exercisemuscles=" + removeLastChar(musclePattern);
            }

            if (tagPattern) {
                searchString +=
                    (searchString ? "&exercisetags=" : "?exercisetags=") +
                    removeLastChar(tagPattern);
            }

            if (filterPattern) {
                searchString +=
                    (searchString ? "&exercisefilters=" : "?exercisefilters=") +
                    removeLastChar(filterPattern);
            }

            if (trainingsapparatusPattern) {
                searchString +=
                    (searchString ? "&trainingsapparatuses=" : "?trainingsapparatuses=") +
                    removeLastChar(trainingsapparatusPattern);
            }

            setSearchString(searchString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [muscle, tag, trainingsapparatus, pattern]);

    const mapValues = (data: GroupDataType): Muscle[] => {
        const defaultValue: Muscle[] = [];

        Object.keys(data).map((value) => {
            defaultValue.push({
                id: Number(value),
                name: data[Number(value)],
                active: false,
            });
        });

        return defaultValue;
    };

    const mapMuscleValues = (data: GroupDataType): Muscle[] => {
        const defaultValue: Muscle[] = [];

        Object.keys(data).map((value) => {
            const checkInner = false;
            if (!checkInner) {
                defaultValue.push({
                    id: Number(value),
                    type: 0,
                    name: data[Number(value)].toString(),
                    active: false,
                });
            }
        });

        return defaultValue;
    };

    useEffect(() => {
        setLoaded(false);

        exerciseAdapter
            .getWorkoutschedulesInfo()
            .then((data) => {
                setMuscleGroup(data.exercisemuscleList);

                setMuscle(mapMuscleValues(data.exercisemuscle));

                setTag(mapValues(data.exercisetag));

                setPattern(mapValues(data.exercisefilter));

                setTrainingsapparatus(mapValues(data.trainingsapparatus));

                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleRemoveActive = (
        id: number,
        setData: React.Dispatch<React.SetStateAction<Muscle[]>>,
        data: Muscle[]
    ) => {
        const resetData: Muscle[] = [];

        data.map((dataValue: Muscle) => {
            resetData.push(
                dataValue.id === id
                    ? {
                          ...dataValue,
                          active: false,
                      }
                    : dataValue
            );
        });

        setData(resetData);
    };

    if (!loaded) {
        return (
            <div className="col-12 text-center mt-5">
                <FontAwesomeIcon icon={faSpinnerThird} spin size="2x" />
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-12 mt-2 mb-2 text-filter-header">
                    {t("workoutschedules.choseFilter", "Auswahl Filter")}:
                </div>
            </div>
            <div className="info-filler d-flex ">
                <div
                    className="select-info d-flex align-items-center"
                    onClick={() => setModalPattern(true)}
                >
                    <div className="pe-1">{t("global.pattern", "Pattern")}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
                <div
                    className="select-info d-flex align-items-center"
                    onClick={() => setModalMuscle(true)}
                >
                    <div className="pe-1">{t("global.muscles", "Muskeln")}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
                <div
                    className="select-info d-flex align-items-center"
                    onClick={() => setModalTags(true)}
                >
                    <div className="pe-1">{t("global.tags", "Tags")}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>

                <div
                    className="select-info d-flex align-items-center"
                    onClick={() => setModalTrainingsapparatus(true)}
                >
                    <div className="pe-1">{t("global.equipment", "Equipment")}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
            </div>
            <div className="select-group-exercise">
                <div className="pe-1 d-flex">
                    <div className="info-type-select d-flex">
                        {pattern.filter((value) => {
                            return !!value.active;
                        }).length > 0 ? (
                            <div className="info-header-type">
                                {" "}
                                {t("global.pattern", "Pattern")}:
                            </div>
                        ) : null}
                        {pattern.map((value, index) => (
                            <React.Fragment key={`pattern-${index}`}>
                                {value.active ? (
                                    <div
                                        className="search-container d-flex align-items-center"
                                        onClick={() =>
                                            handleRemoveActive(value.id, setPattern, pattern)
                                        }
                                    >
                                        {value.name}{" "}
                                        <FontAwesomeIcon icon={faTimes} className="ms-1" />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                        {muscle.filter((value) => {
                            return !!value.active;
                        }).length > 0 ? (
                            <div className="info-header-type">
                                {" "}
                                {t("global.muscles", "Muskeln")}:
                            </div>
                        ) : null}

                        {muscle.map((value, index) => (
                            <React.Fragment key={`muscle-${index}`}>
                                {value.active ? (
                                    <div
                                        className="search-container d-flex align-items-center"
                                        onClick={() =>
                                            handleRemoveActive(value.id, setMuscle, muscle)
                                        }
                                    >
                                        {value.name}{" "}
                                        <FontAwesomeIcon icon={faTimes} className="ms-1" />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                        {tag.filter((value) => {
                            return !!value.active;
                        }).length > 0 ? (
                            <div className="info-header-type"> {t("global.tags", "Tags")}:</div>
                        ) : null}
                        {tag.map((value, index) => (
                            <React.Fragment key={`tag-${index}`}>
                                {value.active ? (
                                    <div
                                        className="search-container d-flex align-items-center"
                                        onClick={() => handleRemoveActive(value.id, setTag, tag)}
                                    >
                                        {value.name}{" "}
                                        <FontAwesomeIcon icon={faTimes} className="ms-1" />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                        {trainingsapparatus.filter((value) => {
                            return !!value.active;
                        }).length > 0 ? (
                            <div className="info-header-type">
                                {" "}
                                {t("global.equipment", "Equipment")}:
                            </div>
                        ) : null}
                        {trainingsapparatus.map((value, index) => (
                            <React.Fragment key={`trainingsapparatus-${index}`}>
                                {value.active ? (
                                    <div
                                        className="search-container d-flex align-items-center"
                                        onClick={() =>
                                            handleRemoveActive(
                                                value.id,
                                                setTrainingsapparatus,
                                                trainingsapparatus
                                            )
                                        }
                                    >
                                        {value.name}{" "}
                                        <FontAwesomeIcon icon={faTimes} className="ms-1" />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <ModalView
                show={modalPattern}
                setShow={setModalPattern}
                defaultData={pattern}
                title={t("global.pattern", "Pattern")}
                setData={setPattern as React.Dispatch<SetStateAction<Muscle[] | null>>}
            />
            <ModalView
                show={modalTags}
                setShow={setModalTags}
                defaultData={tag}
                title={t("global.tags", "Tags")}
                setData={setTag as React.Dispatch<SetStateAction<Muscle[] | null>>}
            />
            <ModalViewMuscle
                show={modalMuscle}
                setShow={setModalMuscle}
                defaultData={muscle}
                title={t("global.muscles", "Muskeln")}
                setData={setMuscle as React.Dispatch<SetStateAction<Muscle[] | null>>}
                extra={muscleGroup}
            />
            <ModalView
                show={modalTrainingsapparatus}
                setShow={setModalTrainingsapparatus}
                defaultData={trainingsapparatus}
                title={t("global.equipment", "Equipment")}
                setData={setTrainingsapparatus as React.Dispatch<SetStateAction<Muscle[] | null>>}
            />
        </>
    );
};

export default ExerciseWrapper;
