/** @format */

import React from "react";

import "./MulitSelectOptions.scss";

interface MultiSelectOptionsProps {
    selectedRowIds: number[];
    multiOptions: React.ReactNode;
}

const MultiSelectOptions: React.FC<MultiSelectOptionsProps> = ({
    selectedRowIds = {},
    multiOptions = null,
}) => {
    if (!Object.keys(selectedRowIds).length) {
        return null;
    }

    return (
        <div className="d-flex justify-content-between align-items-center table-multi-select-options">
            <span className="num-selected">Ausgewählt: {Object.keys(selectedRowIds).length}</span>
            {multiOptions && <div className="multi-options">{multiOptions}</div>}
        </div>
    );
};

export default MultiSelectOptions;
