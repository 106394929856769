/** @format */
import { extendedUserAdapter } from "@api/ApiRequest";
import { UserView } from "@api/ext/ExtendedUserAdapter";
import React, { useState, useCallback } from "react";

const defaultValue: {
    user: UserView | null;
    getUser: (id: number) => void;
} = {
    user: null,
    getUser: () => null,
};

const UserViewContext = React.createContext(defaultValue);

const UserViewProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<UserView | null>(null);

    const getUser = useCallback((id: number) => {
        void extendedUserAdapter.getUser(Number(id)).then((value) => {
            setUser(value);
        });
    }, []);

    const providerValue = {
        user,
        getUser,
    };

    return <UserViewContext.Provider value={providerValue}>{children}</UserViewContext.Provider>;
};

export { UserViewProvider };
export default UserViewContext;
