/** @format */

import ChatContext from "@context/ChatProvider";
import React, { useContext } from "react";

interface ChatUsersListProps {
    searchUsers: number[] | null;
    handleSetActiveUser: (id: number) => void;
}

const ChatUsersList: React.FC<ChatUsersListProps> = ({ searchUsers, handleSetActiveUser }) => {
    const { activeChatUser, chatUsers } = useContext(ChatContext);

    return (
        <div className="col-12 users-chat-box">
            {chatUsers.map((value, index) => {
                if (searchUsers !== null && !searchUsers.includes(value.id)) {
                    return null;
                }

                return (
                    <div
                        key={value.id}
                        className={`w-100 user-search-item-base ${
                            activeChatUser && activeChatUser.id === value.id
                                ? "user-current-base"
                                : ""
                        }`}
                        onClick={() => handleSetActiveUser(value.id)}
                    >
                        <div
                            className={`user-search-item ${
                                chatUsers.length === index + 1 ? "last-user" : ""
                            }`}
                        >
                            <div className="d-flex align-items-center w-100">
                                <div className="d-flex justify-content-center text-center position-relative">
                                    <div
                                        className={`chat-user-state ${
                                            value.connected ? "online" : "offline"
                                        }`}
                                    />
                                    {value.uploads[0] ? (
                                        <img
                                            src={`${value.uploads[0].url}`}
                                            alt="avatar"
                                            className="profileImage"
                                        />
                                    ) : (
                                        <div className="userCircle">
                                            {value.name.charAt(0) + value.surname.charAt(0)}
                                        </div>
                                    )}
                                </div>
                                <div className="d-none d-lg-flex flex-column w-100 ms-3">
                                    {value.full}
                                    <div className="chat-user-role">
                                        {value.is_admin && <small className="me-1">admin</small>}
                                        {value.is_coach && <small className="me-1">coach</small>}
                                    </div>
                                </div>

                                {value.hasNewMessages ? (
                                    <div className="ms-auto">
                                        <div className="chat-new-user-message" />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                );
            })}

            {searchUsers !== null && searchUsers.length === 0 ? (
                <div className="d-flex justify-content-center user-chat-search-no-user">
                    <p>Keine Benutzer gefunden</p>
                </div>
            ) : null}
        </div>
    );
};

export default ChatUsersList;
