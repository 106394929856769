/** @format */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import deTranslation from "../locales/de/translation.json";
import enTranslation from "../locales/en/translation.json";

void i18n
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: "de",
        // lng: Localization.locale,
        fallbackLng: "de",
        debug: false,
        saveMissing: false, // send not translated keys to endpoint
        simplifyPluralSuffix: false,
        keySeparator: ".", // we do not use keys in form messages.welcome
        initImmediate: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        preload: ["de", "en"],
        whitelist: ["de", "en"],
        resources: {
            en: {
                translation: {
                    ...enTranslation,
                },
            },
            de: {
                translation: {
                    ...deTranslation,
                },
            },
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
