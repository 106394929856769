/** @format */

import { invoiceRecipientAdapter } from "@api/ApiRequest";
import { InvoiceCountry, InvoiceRecipientAdd } from "@api/ext/InvoiceRecipientAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import invoiceRecipientValidateSchema from "./InvoiceDataFormValidator";

interface ValidationError {
    errors: string | string[];
    path: string;
    message: string;
    inner?: ValidationError[];
}

interface InvoiceRecipientFormProps {
    initialData: InvoiceRecipientAdd | null;
    countriesList: InvoiceCountry[];
    onBack: () => void;
    onAfterSave: () => void;
}

const InvoiceRecipientForm: React.FC<InvoiceRecipientFormProps> = ({
    initialData,
    countriesList,
    onBack,
    onAfterSave,
}) => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setSaved } = useContext(LoadingContext);

    const [errors, setErrors] = useState<{
        company?: string;
        company2?: string;
        sex?: string;
        name?: string;
        surname?: string;
        address?: string;
        address2?: string;
        zip?: string;
        city?: string;
        country_id?: string;
        ustid?: string;
        invoiceEmail?: string;
        phone?: string;
        email?: string;
    }>({});

    const [company, setCompany] = useState<string>("");
    const [company2, setCompany2] = useState<string>("");
    const [sex, setSex] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [address2, setAddress2] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [countryId, setCountryId] = useState<number>(0);
    const [ustid, setUstid] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [invoiceEmail, setInvoiceEmail] = useState<string>("");

    const [didChange, setDidChange] = useState(false);

    const onClickBack = useCallback(() => {
        onBack();
    }, [onBack]);

    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();

        setErrors({});

        const recipient = {
            company,
            company2,
            sex,
            name,
            surname,
            address,
            address2,
            zip,
            city,
            ustid,
            country_id: countryId,
            email,
            phone,
            invoice_email: invoiceEmail,
        };

        invoiceRecipientValidateSchema
            .validate(recipient, {
                abortEarly: false,
                strict: true,
            })
            .then(() => {
                void invoiceRecipientAdapter.change(recipient).then((response) => {
                    const { success, errors } = response;

                    if (success) {
                        setSaved({
                            type: "successful",
                            show: true,
                            text: t(
                                "invoice.recipientAdded",
                                "Rechnungsadresse wurde gespeichert."
                            ),
                        });

                        onAfterSave();
                    } else {
                        let validation = {};

                        if (errors) {
                            Object.keys(errors).map((i) => {
                                validation = {
                                    ...validation,
                                    [i]: errors[i][Object.keys(errors[i])[0]],
                                };
                            });

                            setErrors(validation);
                        }
                    }
                });
            })
            .catch((err: ValidationError) => {
                let errors = {};
                if (err.inner) {
                    // eslint-disable-next-line array-callback-return
                    err.inner.map((value) => {
                        errors = {
                            ...errors,
                            [value.path]: value.message,
                        };
                    });
                }

                setErrors(errors);
            });
    };

    useEffect(() => {
        if (!initialData) {
            return;
        }

        setDidChange(
            sex !== initialData.sex ||
                name !== initialData.name ||
                surname !== initialData.surname ||
                company !== initialData.company ||
                company2 !== initialData.company2 ||
                address !== initialData.address ||
                address2 !== initialData.address2 ||
                zip !== initialData.zip ||
                city !== initialData.city ||
                email !== initialData.email ||
                phone !== initialData.phone ||
                ustid !== initialData.ustid ||
                countryId !== initialData.country_id ||
                invoiceEmail !== initialData.invoice_email
        );
    }, [
        sex,
        name,
        address,
        zip,
        city,
        initialData,
        surname,
        company,
        company2,
        address2,
        email,
        invoiceEmail,
        phone,
        ustid,
        countryId,
    ]);

    useEffect(() => {
        if (name === "") {
            setName(user?.name || "");
        }
        if (surname === "") {
            setSurname(user?.surname || "");
        }
    }, [name, surname, user]);

    useEffect(() => {
        if (!initialData) {
            return;
        }

        setCompany(initialData.company ?? "");
        setCompany2(initialData.company2 ?? "");
        setSex(initialData.sex ?? "");
        setName(initialData.name ?? "");
        setSurname(initialData.surname ?? "");
        setAddress(initialData.address ?? "");
        setAddress2(initialData.address2 ?? "");
        setZip(initialData.zip ?? "");
        setCity(initialData.city ?? "");
        setCountryId(initialData.country_id ?? "");
        setUstid(initialData.ustid ?? "");
        setPhone(initialData.phone ?? "");
        setEmail(initialData.email ?? "");
        setInvoiceEmail(initialData.invoice_email ?? "");
    }, [initialData]);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="company">
                        {t("invoice.company", "Firma")}
                    </InputLabel>
                    <input
                        id="company"
                        className={`form-control ${errors.company ? "is-invalid" : ""}`}
                        onChange={(e) => setCompany(e.target.value)}
                        type="text"
                        placeholder={t("invoice.company", "Firma")}
                        value={company}
                    />
                    <div className="invalid-feedback">{errors.company}</div>
                </div>
                <div className="col-12 col-md-6">
                    <InputLabel optional htmlFor="company2">
                        {t("invoice.company2", "Firma2")}
                    </InputLabel>
                    <input
                        id="company2"
                        className={`form-control ${errors.company2 ? "is-invalid" : ""}`}
                        onChange={(e) => setCompany2(e.target.value)}
                        type="text"
                        placeholder={t("invoice.company2", "Firma2")}
                        value={company2}
                    />
                    <div className="invalid-feedback">{errors.company2}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-5">
                    <InputLabel required htmlFor="sex">
                        {t("invoice.sex", "Geschlecht")}
                    </InputLabel>
                    <select
                        name="sex"
                        className={`form-select ${errors.sex ? "is-invalid" : ""}`}
                        onChange={(e) => setSex(e.target.value)}
                        value={sex}
                    >
                        <option>{t("global.selectEmptySex", "- Geschlecht -")}</option>
                        <option value="f">{t("global.female", "Weiblich")}</option>
                        <option value="m">{t("global.male", "Männlich")}</option>
                        <option value="d">{t("global.diverse", "Divers")}</option>
                    </select>
                    <div className="invalid-feedback">{errors.sex}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="surname">
                        {t("invoice.surname", "Nachname")}
                    </InputLabel>
                    <input
                        id="surname"
                        className={`form-control ${errors.surname ? "is-invalid" : ""}`}
                        onChange={(e) => setSurname(e.target.value)}
                        type="text"
                        placeholder={t("invoice.surname", "Nachname")}
                        value={surname}
                    />
                    <div className="invalid-feedback">{errors.surname}</div>
                </div>
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="name">
                        {t("invoice.name", "Vorname(n)")}
                    </InputLabel>
                    <input
                        id="name"
                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder={t("invoice.name", "Vorname(n)")}
                        value={name}
                    />
                    <div className="invalid-feedback">{errors.name}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="address">
                        {t("invoice.address", "Rechnungsanschrift")}
                    </InputLabel>
                    <input
                        id="address"
                        className={`form-control ${errors.address ? "is-invalid" : ""}`}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        placeholder={t("invoice.address", "Rechnungsanschrift")}
                        value={address}
                    />
                    <div className="invalid-feedback">{errors.address}</div>
                </div>
                <div className="col-12 col-md-6">
                    <InputLabel htmlFor="address2">
                        {t("invoice.address2", "Rechnungsanschrift2")}
                    </InputLabel>
                    <input
                        id="address2"
                        className={`form-control ${errors.address2 ? "is-invalid" : ""}`}
                        onChange={(e) => setAddress2(e.target.value)}
                        type="text"
                        placeholder={t("invoice.22", "Rechnungsanschrift2")}
                        value={address2}
                    />
                    <div className="invalid-feedback">{errors.address2}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="zip">
                        {t("invoice.plz", "PLZ")}
                    </InputLabel>
                    <input
                        id="zip"
                        className={`form-control ${errors.zip ? "is-invalid" : ""}`}
                        onChange={(e) => setZip(e.target.value)}
                        type="text"
                        placeholder={t("invoice.plz", "PLZ")}
                        value={zip}
                    />
                    <div className="invalid-feedback">{errors.zip}</div>
                </div>

                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="city">
                        {t("invoice.city", "Stadt")}
                    </InputLabel>
                    <input
                        id="city"
                        className={`form-control ${errors.city ? "is-invalid" : ""}`}
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        placeholder={t("invoice.city", "Stadt")}
                        value={city}
                    />
                    <div className="invalid-feedback">{errors.city}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="country_id">
                        {t("invoice.country", "Land")}
                    </InputLabel>
                    <select
                        name="country_id"
                        className={`form-select ${errors.country_id ? "is-invalid" : ""}`}
                        onChange={(e) => setCountryId(Number(e.target.value))}
                        value={countryId}
                    >
                        <option value="0">{t("global.selectCountry", "- Bitte wählen -")}</option>
                        {countriesList &&
                            countriesList.map((x) => {
                                return (
                                    <option value={x.id} key={x.id}>
                                        {x.name}
                                    </option>
                                );
                            })}
                    </select>
                    <div className="invalid-feedback">{errors.country_id}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <InputLabel optional htmlFor="ustid">
                        {t("invoice.ustidShort", "Ust.-ID")}
                    </InputLabel>
                    <input
                        id="ustid"
                        className={`form-control ${errors.ustid ? "is-invalid" : ""}`}
                        onChange={(e) => setUstid(e.target.value)}
                        type="text"
                        placeholder={t("invoice.ustid", "Umsatzsteuer-ID")}
                        value={ustid}
                    />
                    <div className="invalid-feedback">{errors.ustid}</div>
                </div>
                <div className="col-12 col-md-6">
                    <InputLabel required htmlFor="email">
                        {t("invoice.email", "E-Mail")}
                    </InputLabel>
                    <input
                        id="email"
                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder={t("invoice.email", "E-Mail")}
                        value={email}
                    />
                    <div className="invalid-feedback">{errors.email}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6 ms-auto">
                    <InputLabel required htmlFor="phone">
                        {t("invoice.phone", "Telefon")}
                    </InputLabel>
                    <input
                        id="phone"
                        className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        placeholder={t("invoice.phoneNumber", "Telefonnummer")}
                        value={phone}
                    />
                    <div className="invalid-feedback">{errors.phone}</div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6 ms-auto">
                    <InputLabel optional htmlFor="invoiceMail">
                        {t("invoice.invoiceMail", "E-Mail (Rechnungsversand)")}
                    </InputLabel>
                    <input
                        id="invoiceMail"
                        className={`form-control ${errors.invoiceEmail ? "is-invalid" : ""}`}
                        onChange={(e) => setInvoiceEmail(e.target.value)}
                        type="text"
                        placeholder={t("invoice.invoiceMail", "E-Mail (Rechnungsversand)")}
                        value={invoiceEmail}
                    />
                    <div className="invalid-feedback">{errors.invoiceEmail}</div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <button className="btn btn-danger" type="button" onClick={onClickBack}>
                        {t("global.cancel", "Abbrechen")}
                    </button>
                    <button
                        className={`btn btn-save ${!didChange ? "disabled" : ""}`}
                        type="button"
                        onClick={() => handleSubmit()}
                    >
                        {t("invocie.save", "Rechnungsadresse speichern")}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default InvoiceRecipientForm;
