/** @format */

import {
    ControllerAdapter,
    RequestAdapterInterface,
    Paginator,
    Model,
} from "@interweberde/prima-core";

import { ExtendedUserModel } from "./ExtendedUserAdapter";

export interface UploadImage {
    id: number | string;
    ext?: string;
    file: string;
    type: string;
    url?: string;
}

export interface CompaniesModel extends Model {
    name: string;
    address: string;
    zip: string;
    city: string;
    phone: string;
    email: string;
}

export interface ExtendedMandantorModel extends Model {
    name: string;
    company?: string;
    company2?: string;
    address: string;
    zip: string;
    city: string;
    phone?: string;
    email?: string;
    www?: string;
    companies: CompaniesModel[];
    uploads?: UploadImage | null;
    owner?: ExtendedUserModel | null;
}

export interface ExtendedMandantorAddModel extends Model {
    name: string;
    company?: string;
    company2?: string;
    address: string;
    zip: string;
    city: string;
    phone?: string;
    email?: string;
    www?: string;
    uploads?: UploadImage | null;
}

export interface CompanyListItem {
    [key: number]: string;
}

export default class ExtendedMandantorAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("ExtendedMandantors", "ExtendedMandantor", "ExtendedMandantors", adapter);
    }

    public async getMandantor(): Promise<ExtendedMandantorModel> {
        const { data } = await this._get<{ mandantor: ExtendedMandantorModel }>("editMandantor");

        return data.mandantor;
    }

    public async setMandantor(
        mandantor: ExtendedMandantorAddModel,
        image: File | null
    ): Promise<boolean> {
        const formData = new FormData();
        if (image) {
            formData.append("uploads[file]", image);
        }

        formData.append("name", mandantor.name);
        if (mandantor.email) {
            formData.append("email", mandantor.email);
        }
        formData.append("address", mandantor.address);
        formData.append("zip", mandantor.zip);
        formData.append("city", mandantor.city);
        if (mandantor.www) {
            formData.append("www", mandantor.www);
        }
        if (mandantor.phone) {
            formData.append("phone", mandantor.phone);
        }
        if (mandantor.company) {
            formData.append("phone", mandantor.company);
        }
        if (mandantor.company2) {
            formData.append("phone", mandantor.company2);
        }

        const { data } = await this._post<{ success: boolean }>("editMandantor", formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data.success;
    }
}
