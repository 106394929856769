/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";

export interface Invoice extends InvoiceAdd {
    id: number;
}

export interface InvoiceAdd extends Model {
    invoice_number: string;
    url: string;
}

export interface InvoiceChangeRespond {
    success: boolean;
    errors?: {
        [model: string]: {
            [field: string]: {
                [error_code: string]: string;
            };
        };
    };
}

export default class InvoiceAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Invoice,
    Paginator,
    InvoiceAdd
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("invoice/Invoices", "Invoice", "Invoices", adapter);
    }

    public async index(): Promise<Invoice[]> {
        const { data } = await this._get<{ invoices: Invoice[] }>(`index`);

        return data.invoices;
    }
}
