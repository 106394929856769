/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { workoutschedulesAdapter } from "@api/ApiRequest";
import { WorkoutschedulesIndex } from "@api/ext/WorkoutschedulesAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import WorkoutscheduleTable from "@components/Table/WorkoutscheduleTable/WorkoutscheduleTable";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import {
    faPlus,
    faEdit,
    faInfoSquare,
    faEllipsisH,
    faPlusCircle,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CellProps } from "react-table";

import "./WorkoutschedulesIndex.scss";

const WorkoutschedulesIndexView: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const { setSaved } = useContext(LoadingContext);

    const location = useLocation();

    const [workoutschedulesDefault, setWorkoutschedulesDefault] = useState<WorkoutschedulesIndex[]>(
        []
    );
    const [workoutschedules, setWorkoutschedules] = useState<WorkoutschedulesIndex[]>([]);

    const [
        selectedWorkoutschedule,
        setSelectedWorkoutschedule,
    ] = useState<WorkoutschedulesIndex | null>(null);
    const [selectedWorkoutscheduleIds] = useState<number[]>([]);

    const [, setIsLoaded] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        workoutschedulesAdapter
            .getWorkoutschedules()
            .then((response) => {
                setIsLoaded(true);
                setWorkoutschedules(response);
                setWorkoutschedulesDefault(response);

                setLoading(false);
            })
            .catch(() => null);
    }, [setLoading]);

    const onDelete = useCallback((workoutschedule?: WorkoutschedulesIndex) => {
        if (!workoutschedule) {
            return;
        }

        setSelectedWorkoutschedule(workoutschedule);
        setShowDeleteModal(true);
    }, []);

    const doDelete = useCallback(() => {
        if (!selectedWorkoutschedule?.id) {
            return;
        }

        void workoutschedulesAdapter.delete(selectedWorkoutschedule.id).then((response) => {
            setSaved({
                type: "successful",
                show: true,
                text: t(
                    "workoutschedule.workoutscheduleDeleted",
                    "Trainingsplan {{name}} wurde gelöscht.",
                    {
                        name: selectedWorkoutschedule.name,
                    }
                ),
            });

            fetchData();
        });
    }, [selectedWorkoutschedule, t, setSaved, fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (search) {
            workoutschedulesAdapter
                .getWorkoutschedules(search)
                .then((response) => {
                    setIsLoaded(true);
                    setWorkoutschedules(response);
                })
                .catch(() => null);
        } else {
            setWorkoutschedules(workoutschedulesDefault);
        }
    }, [search, workoutschedulesDefault]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <h1>Trainingspläne</h1>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <div className="d-flex col justify-content-end">
                        <Link className="btn btn-default align-self-end" to="/workoutschedules/add">
                            <FontAwesomeIcon icon={faPlus} /> {t("global.add", "Hinzufügen")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-6 ps-0 pb-2 pt-2">
                <TableSearch
                    handleSearch={handleSearch}
                    placeholder={t("global.search", "Suche")}
                    search={search}
                />
            </div>
            <WorkoutscheduleTable
                data={workoutschedules}
                rowOptions={{
                    id: "options",
                    className: "options-span",
                    Header: "",
                    Cell: ({ row }: CellProps<WorkoutschedulesIndex>) => {
                        const dropdown = useRef<HTMLDivElement>(null);

                        const [dropdownView, setDorpdownView] = useState(false);

                        return (
                            <div className="d-flex align-items-center justify-content-end">
                                <Link
                                    className="btn-add-workoutschedule"
                                    to={{
                                        pathname: `/workoutschedules/chose/${row.original.id}`,
                                        state: { background: location },
                                    }}
                                >
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                        <span>{t("global.assign", "Zuweisen")}</span>
                                    </div>
                                </Link>
                                <div className="dropdown d-flex justify-content-end" ref={dropdown}>
                                    <div
                                        className="btn-sm"
                                        role="button"
                                        id={`dMenue-${row.original.id}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => setDorpdownView(!!dropdownView)}
                                    >
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </div>

                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <li>
                                            <Link
                                                className="dropdown-item option-dropdown"
                                                to={`/workoutschedules/view/${row.original.id}`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoSquare}
                                                    className="option-icon"
                                                />
                                                {t("global.view", "Ansicht")}
                                            </Link>
                                        </li>

                                        {((user?.is_admin && row.original.editableMandantor) ||
                                            (user?.is_coach && row.original.editableCompany)) && (
                                            <>
                                                <li>
                                                    <Link
                                                        className="dropdown-item option-dropdown"
                                                        to={`/workoutschedules/edit/${row.original.id}`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            className="option-icon"
                                                        />
                                                        {t("global.edit", "Bearbeiten")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item option-dropdown option-danger"
                                                        onClick={() => onDelete(row.original)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                            className="option-icon"
                                                        />
                                                        {t("global.delete", "Löschen")}
                                                    </button>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        );
                    },
                }}
            />
            {showDeleteModal && (
                <ConfirmDialogue
                    title={t("workoutschedule.deleteModalTitle", "Trainingsplan löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDelete}
                >
                    <div className="p-3">
                        {selectedWorkoutschedule ? (
                            <p>
                                {t(
                                    "workoutschedule.singleDeleteText",
                                    "Möchten Sie den Trainingsplan {{name}} jetzt löschen?",
                                    {
                                        name: selectedWorkoutschedule.name,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {t(
                                    "workoutschedule.multiDeleteText",
                                    "Sie haben {{num}} Trainingspläne ausgewählt. Möchten Sie diese Trainingspläne jetzt löschen?",
                                    {
                                        num: selectedWorkoutscheduleIds.length,
                                    }
                                )}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default WorkoutschedulesIndexView;
