/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Route, Switch } from "react-router-dom";

import ArchivedUserIndex from "./ArchivedUserIndex/ArchivedUserIndex";
import UnarchivedUserIndex from "./UnarchivedUserIndex/UnarchivedUserIndex";

const UserIndex: React.FC = () => {
    const [t] = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col-12 mb-4">
                    <h1>{t("global.users", "Benutzer")}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/users"
                                activeClassName="active"
                                className="nav-link"
                            >
                                <span>{t("global.activeUsers", "Aktive Benutzer")}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to="/users/archived"
                                className="nav-link"
                            >
                                <span>{t("global.archivedUsers", "Archivierte Benutzer")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <Switch>
                        <Route path="/users" exact component={UnarchivedUserIndex} />
                        <Route path="/users/archived" exact component={ArchivedUserIndex} />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default UserIndex;
