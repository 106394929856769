/** @format */

import { IMessage } from "@context/ChatProvider";
import { faTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React from "react";

interface MessageProps {
    index: number;
    userName: string;
    message: IMessage;
}

const Message: React.FC<MessageProps> = ({ index, message, userName }) => {
    return (
        <div
            className={`d-flex mt-3 w-100 position-relative ${
                message.fromSelf ? "self-message" : ""
            }`}
        >
            {message.fromSelf ? (
                <div className="date-chat">
                    <span>
                        {DateTime.fromISO(String(message.created), {
                            locale: "de",
                        }).toFormat("dd LLL. yyyy HH:mm")}
                    </span>
                </div>
            ) : null}

            <div className="message">
                <span className="user-chat-name mb-1">
                    {message.fromSelf ? "Du:" : userName + ":"}
                </span>
                <div className="d-flex flex-row">
                    {!message.fromSelf ? (
                        <div className="arrow-bubble-left">
                            <FontAwesomeIcon
                                icon={faTriangle}
                                color="rgb(242, 242, 247)"
                                transform={{ rotate: -180 }}
                            />
                        </div>
                    ) : null}
                    <div className={`message-text ${message.fromSelf ? "self-message-text" : ""}`}>
                        <p>{message.content}</p>
                    </div>
                    {message.fromSelf ? (
                        <div className="arrow-bubble">
                            <FontAwesomeIcon
                                icon={faTriangle}
                                color="#cee4ff"
                                transform={{ rotate: 180 }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {!message.fromSelf ? (
                <div className="date-chat">
                    <span>
                        {DateTime.fromISO(String(message.created), {
                            locale: "de",
                        }).toFormat("dd LLL. yyyy HH:mm")}
                    </span>
                </div>
            ) : null}
        </div>
    );
};

export default Message;
