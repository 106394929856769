/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { exerciseAdapter } from "@api/ApiRequest";
import { ExerciseModel } from "@api/ext/ExerciseAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import ExerciseTable from "@components/Table/ExerciseTable/ExerciseTable";
import RowOptionWrapper from "@components/Table/RowOptionWrapper/RowOptionWrapper";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

const ExerciseIndex: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const { setSaved } = useContext(LoadingContext);

    const [exerciseDefault, setExerciseDefault] = useState<ExerciseModel[]>([]);
    const [exercise, setExercise] = useState<ExerciseModel[]>([]);

    const [selectedExercise, setSelectedExercise] = useState<ExerciseModel | null>(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        exerciseAdapter
            .getIndexExercise()
            .then((response) => {
                setExercise(response);
                setExerciseDefault(response);

                setLoading(false);
            })
            .catch(() => null);
    }, [setLoading]);

    const onDelete = useCallback((exercise?: ExerciseModel) => {
        if (!exercise) {
            return;
        }

        setSelectedExercise(exercise);
        setShowDeleteModal(true);
    }, []);

    const doDelete = useCallback(() => {
        if (!selectedExercise?.id) {
            return;
        }

        void exerciseAdapter.delete(selectedExercise.id).then((response) => {
            setSaved({
                type: "successful",
                show: true,
                text: t("exercises.execiseDeleted", "Übung {{name}} wurde gelöscht.", {
                    name: selectedExercise.name,
                }),
            });

            fetchData();
        });
    }, [selectedExercise, setSaved, t, fetchData]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (search) {
            exerciseAdapter
                .indexSearch(search)
                .then((response) => {
                    setExercise(response);
                })
                .catch((e) => console.log(e));
        } else {
            setExercise(exerciseDefault);
        }
    }, [search, exerciseDefault]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    return (
        <>
            <div className="row my-4">
                <div className="col-6">
                    <TableSearch
                        handleSearch={handleSearch}
                        placeholder={t("global.search", "Suche")}
                        search={search}
                    />
                </div>
                <div className="col-6 pull-right">
                    <div className="d-flex col justify-content-end">
                        <Link to="/exercises/add" className="btn btn-default align-self-end">
                            <FontAwesomeIcon icon={faPlus} /> {t("global.add", "Hinzufügen")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <ExerciseTable
                    data={exercise}
                    rowOptions={{
                        id: "options",
                        Header: "",
                        Cell: ({ row }: CellProps<ExerciseModel>) => {
                            if (!row.original.id) {
                                return null;
                            }

                            return (
                                <>
                                    {((user?.is_admin && row.original.editableMandantor) ||
                                        (user?.is_coach && row.original.editableCompany)) && (
                                        <RowOptionWrapper id={`dMenue-${row.original.id}`}>
                                            <li>
                                                <Link
                                                    to={`/exercises/edit/${row.original.id}`}
                                                    className="dropdown-item option-dropdown"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        className="option-icon"
                                                    />
                                                    {t("global.edit", "Bearbeiten")}
                                                </Link>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => onDelete(row.original)}
                                                    className="dropdown-item option-dropdown option-danger"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        className="option-icon"
                                                    />
                                                    {t("global.delete", "Löschen")}
                                                </button>
                                            </li>
                                        </RowOptionWrapper>
                                    )}
                                </>
                            );
                        },
                    }}
                />
            </div>
            {showDeleteModal && (
                <ConfirmDialogue
                    title={t("exercises.deleteModalTitle", "Übung löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDelete}
                >
                    <div className="p-3">
                        {selectedExercise ? (
                            <p>
                                {t(
                                    "exercises.singleDeleteText",
                                    "Möchten Sie die Übung {{name}} jetzt löschen?",
                                    {
                                        name: selectedExercise.name,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {/* {t(
                                    "exercises.multiDeleteText",
                                    "Sie haben {{num}} Übungen ausgewählt. Möchten Sie diese Übungen jetzt löschen?",
                                    {
                                        num: selectedExerciseIds.length,
                                    }
                                )} */}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default ExerciseIndex;
