/** @format */

import { globalUserAdapter } from "@api/ApiRequest";
import LoadingContext from "@context/LoadingProvider";
import CenteredLayout from "@layouts/Unauthenticated/CenteredLayout";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

let intervalId: ReturnType<typeof setInterval>;

const Connecting: React.FC = () => {
    const [t] = useTranslation();
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        setLoading(false);

        intervalId = setInterval(() => {
            void globalUserAdapter.loggedin().then((check) => {
                if (check) {
                    window.location.reload();
                }
            });
        }, 15 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [setLoading]);

    return (
        <CenteredLayout>
            <div>
                <div className="col-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                </div>
                <div className="col-12 text-center">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="font-weight-light mb-3">
                                {t("global.hoppla", "Hoppla!")}
                            </h1>
                            <p>Der Service ist nicht erreichbar.</p>
                            <p>Bitte versuchen Sie es später noch einmal.</p>
                        </div>

                        <div className="col-12 mt-4 mb-1">
                            <button
                                type="button"
                                className="btn btn-save"
                                onClick={() => window.location.reload()}
                            >
                                {t("global.btn.tryAgain", "Erneut versuchen")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </CenteredLayout>
    );
};

export default Connecting;
