/** @format */

import ClientWelcomeScreen from "@screen/ClientWelcomeScreen/ClientWelcomeScreen";
import Logout from "@screen/Logout/Logout";
import Profile from "@screen/Profile/Profile";
import ConfirmEmailChange from "@screen/UnauthenticatedScreens/ConfirmEmailChange/ConfirmEmailChange";
import ResetPassword from "@screen/UnauthenticatedScreens/ResetPassword/ResetPassword";
import AcceptInvite from "@screen/UnauthenticatedScreens/ValidateEmail/AcceptInvite";
import ValidateEmail from "@screen/UnauthenticatedScreens/ValidateEmail/ValidateEmail";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const ClientRouter: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={ClientWelcomeScreen} />
            <Route path="/logout" exact component={Logout} />

            <Route path="/confirm-email-change/:token" component={ConfirmEmailChange} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/validate-mail/:token" component={ValidateEmail} />
            <Route path="/accept-invite/:token" component={AcceptInvite} />

            <Route path="/profile" component={Profile} />

            <Route path="*">
                <Redirect to={{ pathname: "/" }} />
            </Route>
        </Switch>
    );
};

export default ClientRouter;
