/** @format */

import { SubscriptionStatus } from "@api/ext/SubscriptionAdapter";
import PaymentInterval from "@components/PaymentInterval/PaymentInterval";
import RenderPaymentMethod from "@components/RenderPaymentMethod/RenderPaymentMethod";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import "./SubscriptionOverview.scss";

const SubscriptionOverview: React.FC = () => {
    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const { subscription } = useContext(AuthContext);

    const subscriptionStatus = useMemo(() => {
        if (!subscription) {
            return "";
        }

        switch (subscription.status) {
            case SubscriptionStatus.TRIAL:
                return t("subscriptions.general.status.trial", "Testphase");
            case SubscriptionStatus.CANCELED:
                return t("subscriptions.general.status.canceled", "Gekündigt");
            case SubscriptionStatus.MOLLIE_PENDING:
                return t(
                    "subscriptions.general.status.mollie_pending",
                    "Warten auf Zahlungsdienstleister"
                );
            case SubscriptionStatus.ACTIVE:
                return t("subscriptions.general.status.active", "Aktiv");
            case SubscriptionStatus.PENDING:
                return t(
                    "subscriptions.general.status.pending",
                    "Warten auf Zahlungsdienstleister"
                );
            case SubscriptionStatus.SUSPENDED:
                return t("subscriptions.general.status.suspended", "Gekündigt");
        }
    }, [subscription, t]);

    useEffect(() => {
        if (subscription) {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    if (!subscription) {
        return null;
    }

    return (
        <>
            <div className="d-flex flex-column shadow rounded">
                <div
                    className={`d-flex p-4 align-items-center rounded-top subscription-${subscription.status}`}
                    style={{
                        backgroundColor: `${
                            subscription.mollie_db_subscription.subscriptiontypeoption
                                ?.subscriptiontype?.color
                                ? subscription.mollie_db_subscription.subscriptiontypeoption
                                      ?.subscriptiontype?.color
                                : ""
                        }`,
                    }}
                >
                    <h3 className="mb-0 text-uppercase text-white">
                        {
                            subscription.mollie_db_subscription.subscriptiontypeoption
                                .subscriptiontype?.title
                        }
                    </h3>
                    <b className="text-uppercase ms-auto text-white">{subscriptionStatus}</b>
                </div>
                <div className="p-4">
                    {subscription.status !== SubscriptionStatus.TRIAL && (
                        <p>
                            <b>
                                {subscription.seat_count}{" "}
                                {t("subscription.seats", "Kunden-Accounts")}
                            </b>
                        </p>
                    )}
                    {subscription.status === SubscriptionStatus.TRIAL &&
                        subscription?.trial_end_date && (
                            <div className="d-flex flex-column">
                                <p>
                                    {t("subscription.trial.to", "Testzeitraum bis")}:{" "}
                                    <b>
                                        {subscription?.trial_end_date.toLocaleString(
                                            DateTime.DATE_FULL
                                        )}
                                    </b>
                                </p>
                            </div>
                        )}
                    {subscription.status === SubscriptionStatus.CANCELED &&
                        subscription?.subscription_end_date &&
                        subscription.is_active && (
                            <div className="d-flex flex-column">
                                <p>
                                    {t("subscription.canceled.from", "gekündigt zum")}:{" "}
                                    <b>
                                        {subscription?.subscription_end_date.toLocaleString(
                                            DateTime.DATE_FULL
                                        )}
                                    </b>
                                </p>
                            </div>
                        )}
                    {subscription.status === SubscriptionStatus.CANCELED &&
                        !subscription.is_active && (
                            <div className="d-flex flex-column">
                                <p>{t("subscription.status.canceled", "gekündigt")}</p>
                            </div>
                        )}
                    {subscription.client_subscription && (
                        <div className="d-flex flex-column">
                            <div className="d-flex">
                                <h3 className="mb-0">
                                    {subscription.client_subscription?.amount.value}{" "}
                                    {subscription.client_subscription?.amount.currency}
                                </h3>
                            </div>
                            <div className="d-flex mb-3">
                                <small>
                                    <PaymentInterval subscription={subscription} />
                                </small>
                            </div>
                            <div className="d-flex flex-column mb-3">
                                <p className="mt-auto mb-0">
                                    <RenderPaymentMethod
                                        mandate={subscription.client_subscription.mandate}
                                    />
                                </p>
                                {subscription.status === SubscriptionStatus.ACTIVE && (
                                    <NavLink
                                        className=""
                                        to="/profile/subscription/manage-mandates"
                                    >
                                        {t(
                                            "subscription.payment.method.changeMethod",
                                            "Zahlungsmethode ändern"
                                        )}
                                    </NavLink>
                                )}
                            </div>
                            <p>
                                {t("subscription.nextPaymentDate", "Nächste Zahlung am")}:{" "}
                                <b>
                                    {subscription?.client_subscription?.nextPaymentDate?.toLocaleString(
                                        DateTime.DATE_FULL
                                    )}
                                </b>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SubscriptionOverview;
