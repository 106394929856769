/**
 *
 * @format
 */

import LoadingContext from "@context/LoadingProvider";
import CenteredLayout from "@layouts/Unauthenticated/CenteredLayout";
import React, { useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router";

interface LocationType {
    email: string;
}

const SignupDone: React.FC = () => {
    const [t] = useTranslation();
    const { setLoading } = useContext(LoadingContext);

    const location = useLocation<LocationType>();

    const { email } = location.state;

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    return (
        <CenteredLayout>
            <div className="col-lg-12 text-center mb-3">
                <img
                    src="/img/background_logo.png"
                    alt="InterCoach Logo"
                    className="img-fluid main-logo"
                />
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="mt-3">Registrierung erfolgreich!</h1>
                    <p className="col-md-6 col-12 font-weight-light mt-3">
                        <Trans i18nKey="signup.success.text">
                            Wir haben eine E-Mail an <strong>{{ email }}</strong> gesendet. Bitte
                            überprüfe Dein E-Mail-Postfach und folge dem Link in der E-Mail.
                        </Trans>
                    </p>
                </div>
                <div className="mt-3">
                    <a href="/" className="btn btn-save">
                        {t("signup.button.login", "Zum Login")}
                    </a>
                </div>
            </div>
        </CenteredLayout>
    );
};

export default SignupDone;
