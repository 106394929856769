/** @format */

import { ExtendedUserModel } from "@api/ext/ExtendedUserAdapter";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import TableWithPagination from "../TableWithPagination";

import "./UsersTable.scss";

type UsersTableColumns =
    | "select"
    | "image"
    | "name"
    | "surname"
    | "coach"
    | "email"
    | "company"
    | "lastlogin"
    | "archived"
    | "options";

interface UsersTableProps {
    data: ExtendedUserModel[];
    onSelectedChange?: (selectedIds: number[]) => void;
    selectable?: boolean;
    hideColumns?: UsersTableColumns[];
    multiSelectOptions?: React.ReactNode;
    rowOptions?: React.ReactNode;
}

const UsersTable: React.FC<UsersTableProps> = ({
    data,
    selectable = false,
    onSelectedChange = () => null,
    hideColumns = [],
    multiSelectOptions = null,
    rowOptions = null,
}) => {
    const { t } = useTranslation();

    const columns = useMemo(() => {
        const profileImageCell = !hideColumns.includes("image")
            ? [
                  {
                      Header: () => {
                          return <div style={{ width: 25 }} />;
                      },
                      id: "image",
                      Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                          if (!row.original.uploads || !row.original.id) {
                              return null;
                          }

                          if (
                              row.original.uploads?.length > 0 &&
                              process.env.REACT_APP_BACKEND_URL
                          ) {
                              return (
                                  <Link to={`/users/view/${row.original.id}`}>
                                      <div className="d-flex justify-content-center w-100 text-center">
                                          <img
                                              src={`${process.env.REACT_APP_BACKEND_URL}/core/uploads/getfile/${row.original.uploads[0].id}`}
                                              alt={t("global.prfileImage", "Profilbild")}
                                              className="profileImage"
                                          />
                                      </div>
                                  </Link>
                              );
                          }

                          return (
                              <Link to={`/users/view/${row.original.id}`}>
                                  <div className="d-flex justify-content-center w-100 text-center">
                                      <div className="userCircle">
                                          {row.original.name.charAt(0) +
                                              row.original.surname.charAt(0)}
                                      </div>
                                  </div>
                              </Link>
                          );
                      },
                      className: "users-table-image",
                  },
              ]
            : [];

        const workoutcoachCell = !hideColumns.includes("coach")
            ? [
                  {
                      Header: t("user.coach", "Trainer"),
                      accessor: "workoutcoach",
                      Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                          return row.original.workoutcoaches_user
                              ? row.original.workoutcoaches_user.name
                              : "";
                      },
                      className: "w-25",
                  },
              ]
            : [];

        const companyCell = !hideColumns.includes("company")
            ? [
                  {
                      Header: t("user.company", "Studio"),
                      accessor: "company.name",
                      className: "w-25",
                  },
              ]
            : [];

        const archivedCell = !hideColumns.includes("archived")
            ? [
                  {
                      Header: t("user.archived", "Archiviert"),
                      accessor: "archived",
                      className: "justify-content-center align-items-center",
                      Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                          const archived: string = row.original.archived
                              ? row.original.archived.toString()
                              : "";

                          return (
                              <div className="flex justify-around w-100 text-center">
                                  {archived === ""
                                      ? "-"
                                      : DateTime.fromISO(archived).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const lastLoginCell = !hideColumns.includes("lastlogin")
            ? [
                  {
                      Header: t("user.lastlogin", "Aktivität"),
                      accessor: "lastlogin",
                      className: "justify-content-center align-items-center",
                      Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                          const lastlogin: string = row.original.lastlogin
                              ? row.original.lastlogin.toString()
                              : "";

                          return (
                              <div className="flex justify-around w-100 text-center">
                                  {lastlogin === ""
                                      ? "-"
                                      : DateTime.fromISO(lastlogin).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const rowOptionsCell = rowOptions ? [rowOptions] : [];

        return [
            ...(profileImageCell || []),
            {
                Header: t("user.name", "Name"),
                accessor: "fullname",
                className: "w-25",
            },
            ...(workoutcoachCell || []),
            {
                Header: t("user.email", "E-Mail"),
                accessor: "email",
                className: "w-25",
                Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                    return (
                        <a className="cell-link" href={`mailto:${row.original.email}`}>
                            {row.original.email}
                        </a>
                    );
                },
            },
            ...(companyCell || []),
            ...(archivedCell || []),
            ...(lastLoginCell || []),
            ...(rowOptionsCell || []),
        ];
    }, [hideColumns, t, rowOptions]);

    return (
        <TableWithPagination
            columns={columns}
            data={data}
            selectable={selectable}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSelect={onSelectedChange as unknown}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            multiOptions={multiSelectOptions as unknown}
        />
    );
};

export default UsersTable;
