/** @format */

import EquipmentIndex from "@screen/Equipment/EquipmentIndex/EquipmentIndex";
import ExerciseIndex from "@screen/Exercises/ExercisesIndex/ExerciseIndex";
import ExercisetagsIndex from "@screen/Exercisetags/ExercisetagsIndex/ExercisetagsIndex";
import MusclesIndex from "@screen/Muscles/MusclesIndex/MusclesIndex";
import React from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, NavLink, useRouteMatch } from "react-router-dom";

import "./CollectionScreen.scss";

const CollectionScreen: React.FC = () => {
    const [t] = useTranslation();
    const { path, url } = useRouteMatch();

    return (
        <>
            <div className="row">
                <div className="col-12 mb-4">
                    <h1>{t("global.collection", "Kollektion")}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to={`${url}`}
                                activeClassName="active"
                                className="nav-link"
                            >
                                <span>{t("global.exercises", "Übungen")}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/muscles`}
                                className="nav-link"
                            >
                                <span>{t("global.muscles", "Muskeln")}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/equipment`}
                                className="nav-link"
                            >
                                <span>{t("global.equipment", "Equipment")}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/exercisetags`}
                                className="nav-link"
                            >
                                <span>{t("global.Exercisetag", "Problem-Tag")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <Switch>
                        <Route path={`${path}/`} exact component={ExerciseIndex} />
                        <Route path={`${path}/muscles`} exact component={MusclesIndex} />
                        <Route path={`${path}/equipment`} component={EquipmentIndex} />
                        <Route path={`${path}/exercisetags`} component={ExercisetagsIndex} />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default CollectionScreen;
