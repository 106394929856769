/** @format */

import TrainingsessionWrapper from "@components/TrainingsessionWrapper/TrainingsessionWrapper";
import AuthContext from "@context/AuthProvider";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import "./Dashboard.scss";

const Dashboard: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);

    return (
        <div className="h-100">
            <div className="row">
                <div className="col-12">
                    <div className="dashboard-header d-flex justify-content-between align-items-center">
                        <div>
                            <h5>{DateTime.local().toFormat("EEEE", { locale: "de" })}</h5>
                            <h3>{DateTime.local().toFormat("dd. LLLL", { locale: "de" })}</h3>
                        </div>
                        <div className="text-dashboard">
                            <h5>
                                {t("dashboard.header", "Willkommen {{name}} {{surname}}", {
                                    name: user?.name,
                                    surname: user?.surname,
                                })}
                                ,
                            </h5>
                            <p>
                                {t(
                                    "dashboard.headerDescription",
                                    "Lege Trainingspläne mit ausgewählten Übungen an und ordne Deinen Benutzern Trainingspläne zu. Beobachte Deine Benutzer beim erfüllen ihrer Ziele und gib ihnen hilfreiches Feedback."
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <TrainingsessionWrapper />
        </div>
    );
};

export default Dashboard;
