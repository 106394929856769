/** @format */

import { globalUserAdapter, onboardingAdapter } from "@api/ApiRequest";
import { AuthUserModel, ExtendUserAddModel } from "@api/ext/GlobalUserAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

let intervalId: ReturnType<typeof setInterval>;

const Unconfirmed: React.FC = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const { setLoading } = useLoading();

    const { setSaved } = useContext(LoadingContext);

    const { user, refetchUser } = useContext(AuthContext);

    const [profileUser, setProfileUser] = useState<AuthUserModel | null>(() => {
        return user ? user : null;
    });
    const [postAvailable, setPostAvailable] = useState<boolean>(false);

    const cancelPendingEmailChange = useCallback(() => {
        void globalUserAdapter.cancelPendingEmailChange().then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("user.cancelChangeEmail.success", "E-Mail Änderung wurde abgebrochen."),
                });

                void refetchUser();
            }
        });
    }, [refetchUser, setSaved, t]);

    const resendPendingEmailChange = useCallback(() => {
        void globalUserAdapter.resendPendingEmailChange().then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t(
                        "user.resendChangeEmail.success",
                        "E-Mail Änderung wurde erneut versendet."
                    ),
                });

                return;
            }

            setSaved({
                type: "failed",
                show: true,
                text: t(
                    "user.resendChangeEmail.failed",
                    "E-Mail Änderung konnte nicht erneut versendet werden."
                ),
            });

            void refetchUser();
        });
    }, [refetchUser, t, setSaved]);

    const resendValidateEmail = useCallback(() => {
        void onboardingAdapter.sendValidateMail().then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t(
                        "user.resendValidateMail.success",
                        "E-Mail-Bestätigung wurde erneut versendet."
                    ),
                });

                return;
            }

            setSaved({
                type: "failed",
                show: true,
                text: t(
                    "user.resendValidateMail.failed",
                    "E-Mail-Bestätigung konnte nicht erneut versendet werden."
                ),
            });

            void refetchUser();
        });
    }, [refetchUser, t, setSaved]);

    const handleSubmitChangeEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!profileUser) {
            return;
        }

        if (!profileUser?.email) {
            return;
        }

        if (user && user.email === profileUser.email) {
            return;
        }

        globalUserAdapter
            .submitProfile(
                {
                    name: profileUser.name,
                    surname: profileUser.surname,
                    email: profileUser.email,
                    sex: profileUser.sex,
                } as ExtendUserAddModel,
                null
            )
            .then(() => {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("user.changeMail.success", "Die E-Mail Änderung wurde gestartet."),
                });

                void refetchUser();
            })
            .catch(() => {
                setSaved({
                    type: "failed",
                    show: true,
                    text: t(
                        "user.changeMail.failed",
                        "Die E-Mail Änderungkonnte nicht gestartet werden!"
                    ),
                });
            });
    };

    useEffect(() => {
        if (profileUser && user && user.email === profileUser?.email) {
            setPostAvailable(false);
        } else {
            setPostAvailable(true);
        }
    }, [user, profileUser]);

    useEffect(() => {
        if (user?.emailconfirmed) {
            history.push("/dashboard");
        }

        if (user) {
            setProfileUser(user);
        }
    }, [user, history]);

    useEffect(() => {
        if (profileUser) {
            setLoading(false);
        }
    }, [profileUser, setLoading]);

    useEffect(() => {
        intervalId = setInterval(() => {
            void refetchUser();
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!profileUser) {
        return null;
    }

    return (
        <form onSubmit={(e) => handleSubmitChangeEmail(e)}>
            <div className="row mt-3">
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-10 col-sm-10 col-10 mx-auto">
                    <h1>{t("unconfirmed.title", "Bestätige Deine E-Mail")}</h1>
                    <p>
                        {t(
                            "unconfirmed.description",
                            "Bestätige zuerst Deine E-Mail um Intercoach uneingeschränkt nutzen zu können."
                        )}
                    </p>
                    <InputLabel htmlFor="email">{t("user.email", "E-Mail")}</InputLabel>
                    <div className="d-flex mb-3">
                        <input
                            id="email"
                            disabled={!!profileUser.pending_email_change}
                            className="form-control"
                            onChange={(e) =>
                                setProfileUser((prev) => {
                                    if (!prev) {
                                        return prev;
                                    }

                                    return {
                                        ...prev,
                                        email: e.target.value,
                                    };
                                })
                            }
                            type="text"
                            placeholder={t("user.email", "E-Mail")}
                            value={profileUser.email}
                        />
                        <button className="btn btn-save" type="submit" disabled={!postAvailable}>
                            {t("user.saveEmail", "Speichern")}
                        </button>
                    </div>
                    {!!profileUser.pending_email_change && (
                        <div className="d-flex flex-column">
                            <span className="my-2 mb-3">
                                {t(
                                    "user.emailHasPendingChange",
                                    "Bitte bestätige Deine E-Mail Änderung über den Link der an die neue E-Mail-Adresse {{newEmail}} versendet wurde.",
                                    {
                                        newEmail: profileUser.pending_email_change,
                                    }
                                )}
                            </span>
                            <div className="d-flex justify-content-between">
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={cancelPendingEmailChange}
                                >
                                    {t("user.cancelEmailChange", "Änderung abbrechen")}
                                </button>
                                <button
                                    className="btn btn-sm btn-save"
                                    onClick={resendPendingEmailChange}
                                >
                                    {t("user.sendAgain", "E-Mail erneut senden")}
                                </button>
                            </div>
                        </div>
                    )}
                    {!profileUser.pending_email_change && !profileUser.emailconfirmed && (
                        <>
                            <span className="fs-7">
                                {t(
                                    "user.emailNotValidated",
                                    "Deine E-Mail ist noch nicht bestätigt. Bitte bestätige sie über den Link der an Deine E-Mail-Adresse {{email}} versendet wurde.",
                                    {
                                        email: profileUser.email,
                                    }
                                )}
                            </span>
                            <div className="d-grid flex-column mt-4">
                                <button className="btn btn-save" onClick={resendValidateEmail}>
                                    {t("user.sendAgain", "E-Mail erneut senden")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </form>
    );
};

export default Unconfirmed;
