/** @format */

import { SubscriptionStatus } from "@api/ext/SubscriptionAdapter";
import { SubscriptionType } from "@api/ext/SubscriptiontypeAdapter";
import PaymentInterval from "@components/PaymentInterval/PaymentInterval";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeCurrencyFormatter } from "@helper/numberFormatting";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import RenderBundles from "./RenderBundles";
import RenderChangeSeats from "./RenderChangeSeats";

import "./SubscriptionChange.scss";

const currencyFormatter = makeCurrencyFormatter("EUR");

const SubscriptionChange: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setLoading } = useContext(LoadingContext);
    const {
        subscription,
        seats,
        setSeats,
        userCount,
        subscriptiontypes,
        setBundle,
        bundle,
        option,
        setOption,
        changed,
        changeSeatCostLoading,
        changeSeatCost,
        isLoadingData,
    } = useSubscriptionContext();

    const [bundleSelected, setBundleSelected] = useState(!!bundle);

    const setSelectedBundle = useCallback(
        (value: SubscriptionType) => {
            setBundle(value);
            setBundleSelected(true);
        },
        [setBundle]
    );

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        if (bundle?.subscriptiontypeoptions && bundle?.subscriptiontypeoptions?.length > 0) {
            setOption(bundle.subscriptiontypeoptions[0]);
        }
    }, [bundle, setOption]);

    useEffect(() => {
        if (
            !isLoadingData &&
            !option &&
            subscriptiontypes &&
            subscriptiontypes?.length > 0 &&
            subscriptiontypes[0].subscriptiontypeoptions &&
            subscriptiontypes[0].subscriptiontypeoptions?.length > 0
        ) {
            const minCount = userCount ? userCount : 1;
            const possibleBundles = subscriptiontypes.filter(
                (s) =>
                    s.subscriptiontypeoptions &&
                    s.subscriptiontypeoptions?.length > 0 &&
                    (!s.subscriptiontypeoptions[0].count_max_seats ||
                        s.subscriptiontypeoptions[0].count_max_seats >= minCount)
            );

            if (possibleBundles && possibleBundles.length) {
                setSelectedBundle(possibleBundles[0]);
            }
        }
    }, [isLoadingData, option, setSelectedBundle, subscriptiontypes, userCount]);

    useEffect(() => {
        if (option && userCount !== null) {
            if (subscription.status === SubscriptionStatus.TRIAL) {
                setSeats(Math.min(userCount, option.count_base_seats));

                return;
            }

            if (userCount > option.count_base_seats) {
                setSeats(
                    Math.max(
                        Math.min(userCount, option.count_max_seats ?? userCount),
                        subscription.seat_count
                    )
                );

                return;
            }

            setSeats(
                Math.max(Math.min(userCount, option.count_base_seats), subscription.seat_count)
            );
        }
    }, [option, setSeats, userCount, subscription]);

    return (
        <>
            <div className="row mb-3">
                <div className="col-12">
                    <h1>
                        {t("subscription.changeSubscription", "{{name}}, bitte wähle Dein Abo", {
                            name: user?.name,
                        })}
                    </h1>
                    {/* <h3>
                        {t("subscription.seats", "Kunden-Accounts")}: {userCount} /{" "}
                        {subscription.seat_count}
                    </h3> */}
                </div>
            </div>
            <div className="row mb-3 bundles-wrapper">
                <RenderBundles
                    subscription={subscription}
                    subscriptiontypes={subscriptiontypes}
                    bundle={bundle}
                    onChange={setSelectedBundle}
                    userCount={userCount}
                    isLoading={isLoadingData}
                />
            </div>
            {/* <div className="row mb-3 options-wrapper">
                <RenderOptions
                    subscription={subscription}
                    option={option}
                    bundle={bundle}
                    onChange={setOption}
                />
            </div> */}
            <div className="row mb-3">
                <div className="col-12 d-flex">
                    <h3>{t("subscription.seats", "Kunden-Accounts")}</h3>
                </div>
                <div className="col-12 d-flex align-items-center">
                    <RenderChangeSeats
                        option={option}
                        userCount={userCount}
                        seats={seats}
                        setSeats={setSeats}
                    />
                </div>
                <div className="col-12 d-flex align-items-center my-4 change-cost-wrapper">
                    {!changeSeatCostLoading && changeSeatCost ? (
                        <>
                            {subscription.status === SubscriptionStatus.TRIAL ||
                            subscription.status === SubscriptionStatus.CANCELED ? (
                                <div className="d-flex flex-column">
                                    <p>
                                        {currencyFormatter.format(changeSeatCost.monthGross)} (
                                        <PaymentInterval subscription={subscription} />)
                                    </p>
                                </div>
                            ) : (
                                <div className="d-flex flex-column">
                                    <p>
                                        Einmalig:{" "}
                                        {currencyFormatter.format(changeSeatCost.onceGross)}
                                    </p>
                                    <p>
                                        Ab nächstem Abrechnungszeitraum:{" "}
                                        {currencyFormatter.format(changeSeatCost.monthGross)} (
                                        <PaymentInterval subscription={subscription} />)
                                    </p>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faSpinnerThird}
                                spin
                                className="loading-icon-spinner ms-5"
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-between mt-4">
                    <NavLink
                        activeClassName="active"
                        className="btn btn-default"
                        to="/profile/payment"
                    >
                        {t("global.back", "Zurück")}
                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        className={`btn btn-save ${!changed || !bundleSelected ? "disabled" : ""}`}
                        to="subscription/data"
                    >
                        {t("global.continue", "Weiter")}
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default SubscriptionChange;
