/** @format */

import React from "react";

const Privacy: React.FC = () => {
    return (
        <div className="container py-5">
            <div className="col-lg-12 mb-3 text-center">
                <img
                    src="/img/background_logo.png"
                    alt="InterCoach Logo"
                    className="img-fluid main-logo"
                />
            </div>
            <div className="col-lg-6 offset-lg-3 col-md-12">
                <div className="row">
                    <div className="col-12">
                        <h1>Datenschutz</h1>
                    </div>
                    <div className="col-12">
                        <div className="paragraph">
                            <h3>I. Name und Anschrift des Verantwortlichen</h3>
                            <p>
                                Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
                                anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
                                sonstiger datenschutzrechtlicher Bestimmungen ist die:
                            </p>
                            <p>
                                Interweber
                                {/* <br />
                                Normannenweg 28
                                <br />
                                20537 Hamburg
                                <br />
                                Deutschland
                                <br />
                                <br />
                                Tel.:
                                <br />
                                +49 (0) 40 46 000 870
                                <br />
                                <br /> */}
                                E-Mail:
                                <br />
                                info@interweber.de
                                <br />
                                <br />
                                Website:
                                <br />
                                www.interweber.com
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>II. Allgemeines zur Datenverarbeitung</h3>
                            <h4>1. Umfang der Verarbeitung personenbezogener Daten</h4>
                            <p>
                                Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich
                                nur, soweit dies zur Bereitstellung einer funktionsfähigen App, der
                                zugehörigen Website sowie unserer Inhalte und Leistungen
                                erforderlich ist. Die Verarbeitung personenbezogener Daten unserer
                                Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine
                                Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung
                                einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und
                                die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet
                                ist.
                            </p>
                            <h4>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
                            <p>
                                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
                                Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
                                lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
                            </p>
                            <p>
                                Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
                                eines Vertrages, dessen Vertragspartei die betroffene Person ist,
                                erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
                                Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
                                Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                            </p>
                            <p>
                                Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
                                rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
                                unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
                            </p>
                            <p>
                                Für den Fall, dass lebenswichtige Interessen der betroffenen Person
                                oder einer anderen natürlichen Person eine Verarbeitung
                                personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
                                lit. d DSGVO als Rechtsgrundlage.
                            </p>
                            <p>
                                Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
                                unseres Unternehmens oder eines Dritten erforderlich und überwiegen
                                die, Grundrechte und Grundfreiheiten des Betroffenen das
                                erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO
                                als Rechtsgrundlage für die Verarbeitung.
                            </p>
                            <h4>3. Datenlöschung und Speicherdauer</h4>
                            <p>
                                Die personenbezogenen Daten der betroffenen Person werden gelöscht
                                oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
                                Speicherung kann darüber hinaus erfolgen, wenn dies durch den
                                europäischen oder nationalen Gesetzgeber in unionsrechtlichen
                                Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
                                Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
                                Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
                                Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
                                Erforderlichkeit zur weiteren Speicherung der Daten für einen
                                Vertragsabschluss oder eine Vertragserfüllung besteht.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>
                                III. Bereitstellung der App, der Website und Erstellung von Logfiles
                            </h3>
                            <h4>1. Beschreibung und Umfang der Datenverarbeitung</h4>
                            <p>
                                Bei jedem Aufruf der App oder den zugehörigen Internetseiten erfasst
                                unser System automatisiert Daten und Informationen vom
                                Computersystem des aufrufenden Rechners.
                            </p>
                            <p>Folgende Daten werden hierbei erhoben:</p>
                            <ul>
                                <li>
                                    Informationen über den Browsertyp und die verwendete Version
                                </li>
                                <li>Das Betriebssystem des Nutzers</li>
                                <li>Den Internet-Service-Provider des Nutzers</li>
                                <li>Die IP-Adresse des Nutzers</li>
                                <li>Datum und Uhrzeit des Zugriffs</li>
                                <li>
                                    Websites, von denen das System des Nutzers auf unsere
                                    Internetseite gelangt
                                </li>
                                <li>
                                    Websites, die vom System des Nutzers über unsere Website
                                    aufgerufen werden
                                </li>
                                <li>
                                    Die Daten werden ebenfalls in den Logfiles unseres Systems
                                    gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
                                    personenbezogenen Daten des Nutzers findet nicht statt.
                                </li>
                            </ul>
                            <p>
                                Daten, die vom Nutzer über die App eingegeben werden, werden für die
                                Dauer der Nutzung und ggf. darüber hinaus gespeichert. Folgende
                                Daten werden dabei Erfasst:
                            </p>
                            <ul>
                                <li>Körpergewicht</li>
                                <li>BMI</li>
                                <li>Vorerkrankungen</li>
                                <li>Tägliche Mahlzeiten in Form von Bilder</li>
                                <li>Tägliche Bewegungsdaten</li>
                            </ul>
                            <p>
                                Die Mitarbeiter des Westdeutschen Diabetes- und Gesundheitszentrums
                                Hohensandweg 37 40591 Düsseldorf können über eine Benutzeroberfläche
                                auf die Daten zugreifen und diese für Ihre Beratung des Nutzers
                                verwenden. Ferner wird eine anonyme Verwendung der Daten für
                                statistische Zwecke gestattet.
                            </p>
                            <h4>2. Rechtsgrundlage für die Datenverarbeitung</h4>
                            <p>
                                Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
                                Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
                            </p>
                            <h4>3. Zweck der Datenverarbeitung</h4>
                            <p>
                                Die vorübergehende Speicherung der IP-Adresse durch das System ist
                                notwendig, um eine Auslieferung der Website an den Rechner des
                                Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für
                                die Dauer der Sitzung gespeichert bleiben.
                            </p>
                            <p>
                                Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der
                                Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung
                                der Website und der App und zur Sicherstellung der Sicherheit
                                unserer informationstechnischen Systeme. Eine Auswertung der Daten
                                zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
                            </p>
                            <p>
                                In diesen Zwecken liegt auch unser berechtigtes Interesse an der
                                Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                            </p>
                            <h4>4. Dauer der Speicherung</h4>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
                                ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
                                der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
                                jeweilige Sitzung beendet ist.
                            </p>
                            <p>
                                Im Falle der Speicherung der Daten in Logfiles ist dies nach
                                spätestens sechs Monaten der Fall. Eine darüberhinausgehende
                                Speicherung ist möglich. In diesem Fall werden die IP-Adressen der
                                Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des
                                aufrufenden Clients nicht mehr möglich ist.
                            </p>
                            <p>
                                Im Falle der App werden die Daten auf dem Endgerät des Nutzers so
                                lange gespeichert, bis die App deinstalliert oder gelöscht wird.
                            </p>
                            <h4>5. Widerspruchs- und Beseitigungsmöglichkeit</h4>
                            <p>
                                Die Erfassung der Daten zur Nutzung der App sowie zur Bereitstellung
                                der Website und die Speicherung der Daten in Logfiles ist für den
                                Betrieb der App und der Internetseite zwingend erforderlich. Es
                                besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h3>IV. Rechte der betroffenen Person</h3>
                            <h4>1. Auskunftsrecht</h4>
                            <p>
                                Sie können von dem Verantwortlichen eine Bestätigung darüber
                                verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
                                verarbeitet werden.
                            </p>
                            <p>
                                Liegt eine solche Verarbeitung vor, können Sie von dem
                                Verantwortlichen über folgende Informationen Auskunft verlangen:
                            </p>
                            <ul>
                                <li>
                                    die Zwecke, zu denen die personenbezogenen Daten verarbeitet
                                    werden;
                                </li>

                                <li>
                                    die Kategorien von personenbezogenen Daten, welche verarbeitet
                                    werden;
                                </li>

                                <li>
                                    die Empfänger bzw. die Kategorien von Empfängern, gegenüber
                                    denen die Sie betreffenden personenbezogenen Daten offengelegt
                                    wurden oder noch offengelegt werden;
                                </li>

                                <li>
                                    die geplante Dauer der Speicherung der Sie betreffenden
                                    personenbezogenen Daten oder, falls konkrete Angaben hierzu
                                    nicht möglich sind, Kriterien für die Festlegung der
                                    Speicherdauer;
                                </li>

                                <li>
                                    das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                                    betreffenden personenbezogenen Daten, eines Rechts auf
                                    Einschränkung der Verarbeitung durch den Verantwortlichen oder
                                    eines Widerspruchsrechts gegen diese Verarbeitung;
                                </li>

                                <li>
                                    das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
                                </li>

                                <li>
                                    alle verfügbaren Informationen über die Herkunft der Daten, wenn
                                    die personenbezogenen Daten nicht bei der betroffenen Person
                                    erhoben werden;
                                </li>

                                <li>
                                    das Bestehen einer automatisierten Entscheidungsfindung
                                    einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
                                    zumindest in diesen Fällen – aussagekräftige Informationen über
                                    die involvierte Logik sowie die Tragweite und die angestrebten
                                    Auswirkungen einer derartigen Verarbeitung für die betroffene
                                    Person. Ihnen steht das Recht zu, Auskunft darüber zu verlangen,
                                    ob die Sie betreffenden personenbezogenen Daten in ein Drittland
                                    oder an eine internationale Organisation übermittelt werden. In
                                    diesem Zusammenhang können Sie verlangen, über die geeigneten
                                    Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
                                    Übermittlung unterrichtet zu werden.
                                </li>
                            </ul>
                            <h4>2. Recht auf Berichtigung</h4>
                            <p>
                                Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
                                gegenüber dem Verantwortlichen, sofern die verarbeiteten
                                personenbezogenen Daten, die Sie betreffen, unrichtig oder
                                unvollständig sind. Der Verantwortliche hat die Berichtigung
                                unverzüglich vorzunehmen.
                            </p>
                            <h4>3. Recht auf Einschränkung der Verarbeitung</h4>
                            <p>
                                Unter den folgenden Voraussetzungen können Sie die Einschränkung der
                                Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
                            </p>
                            <ul>
                                <li>
                                    wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
                                    für eine Dauer bestreiten, die es dem Verantwortlichen
                                    ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                                    überprüfen;
                                </li>
                                <li>
                                    die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                                    personenbezogenen Daten ablehnen und stattdessen die
                                    Einschränkung der Nutzung der personenbezogenen Daten verlangen;
                                </li>
                                <li>
                                    der Verantwortliche die personenbezogenen Daten für die Zwecke
                                    der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
                                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                                    benötigen, oder wenn Sie Widerspruch gegen die Verarbeitung
                                    gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht
                                    feststeht, ob die berechtigten Gründe des Verantwortlichen
                                    gegenüber Ihren Gründen überwiegen.
                                </li>
                            </ul>
                            <p>
                                Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
                                eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen
                                – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                                Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
                                anderen natürlichen oder juristischen Person oder aus Gründen eines
                                wichtigen öffentlichen Interesses der Union oder eines
                                Mitgliedstaats verarbeitet werden.
                            </p>
                            <p>
                                Wurde die Einschränkung der Verarbeitung nach den o.g.
                                Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen
                                unterrichtet bevor die Einschränkung aufgehoben wird.
                            </p>
                            <h4>4. Recht auf Löschung</h4>
                            <p>a) Löschungspflicht</p>
                            <p>
                                Sie können von dem Verantwortlichen verlangen, dass die Sie
                                betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
                                und der Verantwortliche ist verpflichtet, diese Daten unverzüglich
                                zu löschen, sofern einer der folgenden Gründe zutrifft:
                            </p>
                            <p>
                                Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
                                für die sie erhoben oder auf sonstige Weise verarbeitet wurden,
                                nicht mehr notwendig.
                                <br />
                                Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
                                Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
                                fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                                <br />
                                Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
                                Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
                                für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
                                Widerspruch gegen die Verarbeitung ein.
                                <br /> Die Sie betreffenden personenbezogenen Daten wurden
                                unrechtmäßig verarbeitet. Die Löschung der Sie betreffenden
                                personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                                Verpflichtung nach dem Unionsrecht oder dem Recht der
                                Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
                                <br /> Die Sie betreffenden personenbezogenen Daten wurden in Bezug
                                auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8
                                Abs. 1 DSGVO erhoben.
                            </p>
                            <p>b) Information an Dritte</p>
                            <p>
                                Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
                                öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
                                Löschung verpflichtet, so trifft er unter Berücksichtigung der
                                verfügbaren Technologie und der Implementierungskosten angemessene
                                Maßnahmen, auch technischer Art, um für die Datenverarbeitung
                                Verantwortliche, die die personenbezogenen Daten verarbeiten,
                                darüber zu informieren, dass Sie als betroffene Person von ihnen die
                                Löschung aller Links zu diesen personenbezogenen Daten oder von
                                Kopien oder Replikationen dieser personenbezogenen Daten verlangt
                                haben.
                            </p>
                            <p>c) Ausnahmen</p>
                            <p>
                                Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                                erforderlich ist
                            </p>
                            <ul>
                                <li>
                                    zur Ausübung des Rechts auf freie Meinungsäußerung und
                                    Information;
                                </li>
                                <li>
                                    zur Erfüllung einer rechtlichen Verpflichtung, die die
                                    Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
                                    dem der Verantwortliche unterliegt, erfordert, oder zur
                                    Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
                                    oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                                    Verantwortlichen übertragen wurde; aus Gründen des öffentlichen
                                    Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9
                                    Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                                </li>
                                <li>
                                    für im öffentlichen Interesse liegende Archivzwecke,
                                    wissenschaftliche oder historische Forschungszwecke oder für
                                    statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
                                    Abschnitt a) genannte Recht voraussichtlich die Verwirklichung
                                    der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
                                    beeinträchtigt, oder zur Geltendmachung, Ausübung oder
                                    Verteidigung von Rechtsansprüchen.
                                </li>
                            </ul>
                            <p>5. Recht auf Unterrichtung</p>
                            <p>
                                Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
                                der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
                                dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
                                personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
                                Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
                                es sei denn, dies erweist sich als unmöglich oder ist mit einem
                                unverhältnismäßigen Aufwand verbunden.
                            </p>
                            <p>
                                Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
                                Empfänger unterrichtet zu werden.
                            </p>
                            <p>6. Recht auf Datenübertragbarkeit</p>
                            <p>
                                Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
                                die Sie dem Verantwortlichen bereitgestellt haben, in einem
                                strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
                                Außerdem haben Sie das Recht diese Daten einem anderen
                                Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
                                die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
                                sofern
                            </p>
                            <p>
                                die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
                                DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem.
                                Art. 6 Abs. 1 lit. b DSGVO beruht und die Verarbeitung mithilfe
                                automatisierter Verfahren erfolgt.
                            </p>
                            <p>
                                In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken,
                                dass die Sie betreffenden personenbezogenen Daten direkt von einem
                                Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
                                soweit dies technisch machbar ist. Freiheiten und Rechte anderer
                                Personen dürfen hierdurch nicht beeinträchtigt werden.
                            </p>
                            <p>
                                Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
                                personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
                                erforderlich ist, die im öffentlichen Interesse liegt oder in
                                Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
                                übertragen wurde.
                            </p>
                            <p>7. Widerspruchsrecht</p>
                            <p>
                                Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
                                Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                                betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
                                lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
                                für ein auf diese Bestimmungen gestütztes Profiling.
                            </p>
                            <p>
                                Der Verantwortliche verarbeitet die Sie betreffenden
                                personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
                                schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                                Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
                                dient der Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen.
                            </p>
                            <p>
                                Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
                                Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
                                Widerspruch gegen die Verarbeitung der Sie betreffenden
                                personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
                                dies gilt auch für das Profiling, soweit es mit solcher
                                Direktwerbung in Verbindung steht.
                            </p>
                            <p>
                                Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
                                werden die Sie betreffenden personenbezogenen Daten nicht mehr für
                                diese Zwecke verarbeitet.
                            </p>
                            <p>
                                Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
                                Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
                                2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
                                auszuüben, bei denen technische Spezifikationen verwendet werden.
                            </p>
                            <p>
                                8. Recht auf Widerruf der datenschutzrechtlichen
                                Einwilligungserklärung
                            </p>
                            <p>
                                Sie haben das Recht, Ihre datenschutzrechtliche
                                Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
                                der Einwilligung wird die Rechtmäßigkeit der aufgrund der
                                Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
                            </p>
                            <p>
                                9. Automatisierte Entscheidung im Einzelfall einschließlich
                                Profiling
                            </p>
                            <p>
                                Sie haben das Recht, nicht einer ausschließlich auf einer
                                automatisierten Verarbeitung – einschließlich Profiling – beruhenden
                                Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
                                Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
                                beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
                            </p>
                            <p>
                                für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen
                                und dem Verantwortlichen erforderlich ist, aufgrund von
                                Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
                                Verantwortliche unterliegt, zulässig ist und diese
                                Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte
                                und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
                                mit Ihrer ausdrücklichen Einwilligung erfolgt.
                            </p>
                            <p>
                                Allerdings dürfen diese Entscheidungen nicht auf besonderen
                                Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen,
                                sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene
                                Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer
                                berechtigten Interessen getroffen wurden.
                            </p>
                            <p>
                                Hinsichtlich der in (1) und (3) genannten Fälle trifft der
                                Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
                                sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das
                                Recht auf Erwirkung des Eingreifens einer Person seitens des
                                Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
                                Anfechtung der Entscheidung gehört.
                            </p>
                            <p>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</p>
                            <p>
                                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                                gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
                                einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                                Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
                                Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
                                Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                            </p>
                            <p>
                                Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
                                unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse
                                der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
                                Rechtsbehelfs nach Art. 78 DSGVO.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
