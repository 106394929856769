/** @format */

import { extendedUserAdapter } from "@api/ApiRequest";
import ModalView from "@components/Modal/ModalView";
import ChatContext, { UserChatModel } from "@context/ChatProvider";
import { faSpinnerThird, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import ChatMessagesList from "./ChatMessagesList";
import ChatUsersList from "./ChatUsersList";

import "./ChatView.scss";

let timeoutId: ReturnType<typeof setTimeout>;

const ChatView: React.FC = () => {
    const [t] = useTranslation();

    const {
        activeChatUser,
        chatUsers,
        setChatUsers,
        setActiveChatUser,
        dispatchNewMessageStack,
    } = useContext(ChatContext);

    const input = useRef<HTMLInputElement>(null);

    const [, setModaInstance] = useState<null | Modal>(null);
    const [, setModalRef] = useState<null | React.MutableRefObject<HTMLDivElement | null> | null>(
        null
    );

    const [search, setSearch] = useState<string>("");
    const [searchUsers, setSearchUsers] = useState<number[] | null>(null);

    const [searchLoad, setSearchLoad] = useState<boolean>(false);

    const handlePushClose = (
        modal: Modal,
        ref: React.MutableRefObject<HTMLDivElement | null> | null
    ) => {
        setModaInstance(modal);
        setModalRef(ref);
    };

    useEffect(() => {
        dispatchNewMessageStack({ type: "clear" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        clearTimeout(timeoutId);

        if (search) {
            timeoutId = setTimeout(() => {
                void extendedUserAdapter.getChatUsers(search).then((value) => {
                    setSearchLoad(false);

                    const userSearchIds: number[] = [];

                    value.forEach((element) => {
                        userSearchIds.push(element.id);
                    });

                    setSearchUsers(userSearchIds);

                    const newActiveUser = chatUsers.find((elem) => elem.id === value[0]?.id);

                    if (newActiveUser) {
                        setActiveChatUser(newActiveUser);
                    }
                });
            }, 250);
        } else {
            setSearchUsers(null);
            setSearchLoad(false);
        }

        return () => clearTimeout(timeoutId);
    }, [search, chatUsers, setActiveChatUser]);

    const handleSetActiveUser = useCallback(
        (id: number) => {
            if (activeChatUser && id === activeChatUser.id) {
                return;
            }

            const index = chatUsers.findIndex((value) => {
                return id === value.id;
            });

            if (index >= 0) {
                const copyUser: UserChatModel[] = [...chatUsers];

                if (chatUsers[index].hasNewMessages) {
                    copyUser[index].hasNewMessages = false;

                    setChatUsers(copyUser);
                }

                setActiveChatUser(copyUser[index]);
            }
        },
        [activeChatUser, chatUsers, setActiveChatUser, setChatUsers]
    );

    return (
        <ModalView
            title={t("user.chat", "Kommunikation mit Benutzern")}
            size="xl"
            pushRef={handlePushClose}
            stopHide
        >
            <div className="d-lg-none row mb-4">
                <div className="col-12 position-relative">
                    <input
                        ref={input}
                        id="searchUserSm"
                        className="form-control form-control-sm"
                        onChange={(e) => {
                            setSearch(e.target.value);
                            setSearchLoad(true);
                        }}
                        type="text"
                        placeholder={t("user.search", "Benutzer suchen")}
                        required
                        value={search}
                    />
                    <div className="user-serach-icon">
                        {searchLoad ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : null}
                    </div>
                </div>
                <div className="col-12 question-user mt-2">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    <span className="ms-2">
                        {t(
                            "user.question",
                            "Gib einen Benutzer mit seinem Namen oder Nachnamen ein"
                        )}
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-2 col-lg-4">
                    <div className="d-none d-lg-flex row">
                        <div className="col-12 position-relative">
                            <input
                                ref={input}
                                id="searchUser"
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    setSearchLoad(true);
                                }}
                                type="text"
                                placeholder={t("user.search", "Benutzer suchen")}
                                required
                                value={search}
                            />
                            <div className="user-serach-icon">
                                {searchLoad ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : null}
                            </div>
                        </div>
                        <div className="col-12 question-user mt-2">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                            <span className="ms-2">
                                {t(
                                    "user.question",
                                    "Gib einen Benutzer mit seinem Namen oder Nachnamen ein"
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <ChatUsersList
                            searchUsers={searchUsers}
                            handleSetActiveUser={handleSetActiveUser}
                        />
                    </div>
                </div>
                <div className="col-10 col-lg-8 position-relative d-flex align-content-between flex-column">
                    <ChatMessagesList />
                </div>
            </div>
        </ModalView>
    );
};

export default ChatView;
