/** @format */

import React, { useEffect } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";

import MultiSelectOptions from "./MultiSelectOptions/MultiSelectOptions";
import Pagination from "./Pagination/Pagination";
import Table from "./Table";

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
        </>
    );
});

const TableWithPagination = ({
    columns,
    data,
    selectable = false,
    onSelect = null,
    multiOptions = null,
}) => {
    const {
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        page,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                if (!selectable) {
                    return columns;
                }

                return [
                    {
                        id: "selection",
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                        className: "select-column",
                    },
                    ...columns,
                ];
            });
        }
    );

    useEffect(() => {
        if (selectable && onSelect) {
            const selectedData = data.filter((ele, key) => {
                if (Object.keys(selectedRowIds).includes(String(key))) {
                    return ele.id;
                }
            });

            const ids = selectedData.map((ele) => ele.id);

            onSelect(ids);
        }
    }, [selectable, selectedRowIds, onSelect, data]);

    return (
        <div className="table-wrapper">
            <Table
                columns={columns}
                data={data}
                page={page}
                useSortBy={useSortBy}
                usePagination={usePagination}
                useRowSelect={useRowSelect}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                prepareRow={prepareRow}
                pageSize={pageSize}
            />
            <Pagination
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                setPageSize={setPageSize}
            />
            {selectable && (
                <MultiSelectOptions selectedRowIds={selectedRowIds} multiOptions={multiOptions} />
            )}
        </div>
    );
};

export default TableWithPagination;
