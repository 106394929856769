/** @format */

import ChatContext from "@context/ChatProvider";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

let timeoutId: ReturnType<typeof setTimeout>;

const Connected: React.FC = () => {
    const [t] = useTranslation();

    const { isConnected } = useContext(ChatContext);

    const [willShowConnection, setWillShowConnection] = useState<boolean>(false);

    useEffect(() => {
        if (isConnected) {
            clearTimeout(timeoutId);
            setWillShowConnection(false);
        } else {
            timeoutId = setTimeout(() => {
                setWillShowConnection(true);
            }, 3000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isConnected]);

    if (willShowConnection) {
        return (
            <div className="d-flex">
                <p className="absolute noConnection-chat">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="icon-noConnection" />
                    {t(
                        "chat.noConnection",
                        "Keine Verbindung zum Nachrichten-Server möglich, bitte melden Sie sich beim Support sollte diser Fehler häufiger auftreten."
                    )}
                </p>
            </div>
        );
    } else {
        return null;
    }
};

export default Connected;
