/** @format */

import { muscleAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context//LoadingProvider";
import AuthContext from "@context/AuthProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Select, { OptionTypeBase, ValueType } from "react-select";

import ModalView from "../../../components/Modal/ModalView";

import "./MuscleAdd.scss";

export interface OptionType extends OptionTypeBase {
    value: string | number;
    label: string;
}

export type SelectedOption = ValueType<OptionType, false>;

const MuscleAdd: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);

    const { setSaved, setShouldRefetch } = useContext(LoadingContext);

    const [name, setName] = useState<string>("");
    const [post, setPost] = useState<boolean>(false);

    const [selectable, setSelectable] = useState<null | OptionType[]>(null);
    const [selected, setSelected] = useState<SelectedOption>(null);

    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    useEffect(() => {
        void muscleAdapter.getAddMuscle().then((value) => {
            const { muscelesgroups, companies } = value;

            const updateSelect: { value: string; label: string }[] = [];
            Object.keys(muscelesgroups).map((key) => {
                updateSelect.push({ value: key, label: muscelesgroups[Number(key)] });
            });
            setSelectable(updateSelect);

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            if (user?.is_admin && updateCompaniesSelect[0]) {
                setSelectedCompany(updateCompaniesSelect[0]);
            }
        });
    }, [user?.is_admin]);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setPost(true);
        void muscleAdapter
            .add({
                name,
                muscelesgroup_id: Number(selected?.value),
                company_id:
                    user?.is_admin && selectedCompany
                        ? Number(selectedCompany.value)
                        : user?.company_id,
            })
            .then((response) => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    if (!selectable || !user) {
        return null;
    }

    return (
        <ModalView
            title={t("muscles.add", "Muskel hinzufügen")}
            pushRef={handlePushClose}
            goBack="collection/muscles"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="muscleCompany">
                                {t("muscle.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="muscleCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-6">
                        <InputLabel required htmlFor="muscleName">
                            {t("muscle.muscleName", "Muskel")}
                        </InputLabel>
                        <input
                            id="muscleName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            required
                            value={name}
                        />
                    </div>
                    <div className="col-6">
                        <InputLabel required htmlFor="muscleMusclegroup">
                            {t("muscle.musclegroup", "Muskelgruppe")}
                        </InputLabel>
                        <Select
                            id="muscleMusclegroup"
                            value={selected}
                            required
                            placeholder={t("global.selectEmptyChose", "-- Wähle --")}
                            onChange={(e) => setSelected(e)}
                            options={selectable}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button disabled={!name} className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("muscle.save", "Muskel speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default MuscleAdd;
