/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";

export interface Notfification {
    title: string;
    notification: string;
    user_id: number;
}

export default class NotificationAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(
            "app-notification/AppNotificationNotifications",
            "AppNotificationNotification",
            "AppNotificationNotifications",
            adapter
        );
    }

    public async sendNotification(notification: Notfification): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(
            `sendChatNotification`,
            notification
        );

        return data.success;
    }
}
