/** @format */

import { onboardingAdapter } from "@api/ApiRequest";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import CenteredLayout from "@layouts/Unauthenticated/CenteredLayout";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface AcceptInviteParams {
    token: string;
}

const AcceptInvite: React.FC = () => {
    const [t] = useTranslation();
    const { authentication } = useContext(AuthContext);

    const { setLoading } = useLoading();
    const { token } = useParams<AcceptInviteParams>();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [succeeded, setSucceeded] = useState<boolean | null>(null);

    useEffect(() => {
        if (!token) {
            setSucceeded(false);

            return;
        }

        setLoading(true);

        onboardingAdapter
            .acceptInvite(token)
            .then((response) => {
                setSucceeded(!!response);
                setLoaded(true);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [token, setLoading]);

    if (!loaded) {
        return null;
    }

    if (loaded && succeeded === false) {
        return (
            <CenteredLayout>
                <div className="col-lg-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                    <div className="col-12 d-flex justify-content-center">
                        <p className="col-md-6 col-12 font-weight-light mt-3">
                            {t("acceptInvite.tokenInvalid", "Der verwendete Link ist ungültig!")}
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="pt-4 d-flex col justify-content-center">
                            <a href="/" className="btn btn-save">
                                {authentication
                                    ? t("global.continue", "Weiter")
                                    : t("acceptInvite.button.login", "Zum Login")}
                            </a>
                        </div>
                    </div>
                </div>
            </CenteredLayout>
        );
    }

    return (
        <CenteredLayout>
            <div className="col-lg-12 text-center mb-3">
                <img
                    src="/img/background_logo.png"
                    alt="InterCoach Logo"
                    className="img-fluid main-logo"
                />
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                    <p className="col-md-6 col-12 font-weight-light mt-3">
                        {t("acceptInvite.succeeded", "Sie haben die Einladung angenommen.")}
                    </p>
                    <p className="col-md-6 col-12 font-weight-light mt-3">
                        {t(
                            "acceptInvite.succeededDescription",
                            "Bevor Du startest, senden wir Dir eine E-Mail mit dem letzten Schritt zur Einrichtung Deines Accounts."
                        )}
                    </p>
                </div>
                <div className="col-12">
                    <div className="pt-4 d-flex col justify-content-center">
                        <a href="/" className="btn btn-save">
                            {authentication
                                ? t("global.continue", "Weiter")
                                : t("acceptInvite.button.login", "Zum Login")}
                        </a>
                    </div>
                </div>
            </div>
        </CenteredLayout>
    );
};

export default AcceptInvite;
