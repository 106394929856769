/** @format */
/* eslint-disable no-template-curly-in-string */

import * as yup from "yup";

interface SchemaShape {
    name: string;
    surname: string;
    email: string;
    username: string;
    group_id: number;
    sex: string;
    coach_id?: number;
}

yup.setLocale({
    mixed: {
        required: "Eine Eingabe ist notwendig",
        number: "Bitte eine Auswahl treffen",
        typeError: "Bitte etwas eingeben",
    },
    number: {
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
        positive: "Eine Eingabe ist notwendig",
        required: "Eine Eingabe ist notwendig",
    },
    string: {
        lowercase: "Nur Kleinbuchstaben sind erlaubt.",
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
        required: "Eine Eingabe ist notwendig",
        typeError: "Bitte etwas eingeben",
    },
});

const validateSchema: yup.SchemaOf<SchemaShape> = yup.object().shape({
    name: yup
        .string()
        .typeError("Es dürfen nur Buchstaben eingegeben werden.")
        .min(2)
        .max(30)
        .required(),
    surname: yup
        .string()
        .typeError("Es dürfen nur Buchstaben eingegeben werden.")
        .min(2)
        .max(30)
        .required(),
    email: yup.string().email().required(),
    username: yup
        .string()
        .typeError("Es dürfen nur Buchstaben eingegeben werden.")
        .required()
        .min(5)
        .max(30)
        .lowercase(),
    group_id: yup.number().typeError("Bitte eine Auswahl treffen.").required(),
    sex: yup.string().required(),
    isCoach: yup.boolean(),
    coach_id: yup.number().when("isCoach", {
        is: false,
        then: yup.number().positive().required("Bitte eine Auswahl treffen."),
    }),
});

export default validateSchema;
