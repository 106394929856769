/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { globalUserAdapter, onboardingAdapter } from "@api/ApiRequest";
import { AuthUserModel, ExtendUserAddModel } from "@api/ext/GlobalUserAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context//LoadingProvider";
import AuthContext from "@context/AuthProvider";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import "./Settings.scss";

const Settings: React.FC = () => {
    const [t] = useTranslation();

    const { setSaved } = useContext(LoadingContext);

    const { profileImage, user, refetchUser } = useContext(AuthContext);

    const [profileUser, setProfileUser] = useState<AuthUserModel | null>(() => {
        return user ? user : null;
    });

    const [imageChanged, setImageChanged] = useState<boolean>(false);
    const [postAvailable, setPostAvailable] = useState<boolean>(false);

    const [imageView, setImageView] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const { setLoading } = useLoading();

    const cancelPendingEmailChange = useCallback(() => {
        void globalUserAdapter.cancelPendingEmailChange().then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("user.cancelChangeEmail.success", "E-Mail Änderung wurde abgebrochen."),
                });

                void refetchUser();
            }
        });
    }, [refetchUser, setSaved, t]);

    const resendPendingEmailChange = useCallback(() => {
        void globalUserAdapter.resendPendingEmailChange().then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t(
                        "user.resendChangeEmail.success",
                        "E-Mail Änderung wurde erneut versendet."
                    ),
                });

                return;
            }

            setSaved({
                type: "failed",
                show: true,
                text: t(
                    "user.resendChangeEmail.failed",
                    "E-Mail Änderung konnte nicht erneut versendet werden."
                ),
            });

            void refetchUser();
        });
    }, [refetchUser, t, setSaved]);

    const resendValidateEmail = useCallback(() => {
        void onboardingAdapter.sendValidateMail().then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t(
                        "user.resendValidateMail.success",
                        "E-Mail-Bestätigung wurde erneut versendet."
                    ),
                });

                return;
            }

            setSaved({
                type: "failed",
                show: true,
                text: t(
                    "user.resendValidateMail.failed",
                    "E-Mail-Bestätigung konnte nicht erneut versendet werden."
                ),
            });

            void refetchUser();
        });
    }, [refetchUser, t, setSaved]);

    useEffect(() => {
        if (user) {
            setProfileUser(user);
        }
    }, [user]);

    useEffect(() => {
        if (profileUser) {
            setLoading(false);
        }
    }, [profileUser, setLoading]);

    useEffect(() => {
        if (
            profileUser &&
            user &&
            user.name === profileUser?.name &&
            user.surname === profileUser?.surname &&
            user.email === profileUser?.email &&
            user.sex === profileUser?.sex &&
            !imageChanged
        ) {
            setPostAvailable(false);
        } else {
            setPostAvailable(true);
        }
    }, [user, profileUser, imageChanged]);

    const handleSubmitProfile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!profileUser) {
            return;
        }

        if (!profileUser?.name || !profileUser?.surname || !profileUser?.email) {
            return;
        }

        if (
            user &&
            user.name === profileUser.name &&
            user.surname === profileUser.surname &&
            user.email === profileUser.email &&
            user.sex === profileUser.sex &&
            !imageChanged
        ) {
            return;
        }

        globalUserAdapter
            .submitProfile(
                {
                    name: profileUser.name,
                    surname: profileUser.surname,
                    email: profileUser.email,
                    sex: profileUser.sex,
                } as ExtendUserAddModel,
                imageView
            )
            .then(() => {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("user.change.success", "Daten wurden Aktualisiert"),
                });

                void refetchUser();
            })
            .catch(() => {
                setSaved({
                    type: "failed",
                    show: true,
                    text: t("user.change.failed", "Daten wurden nicht Aktualisiert!"),
                });
            });
    };

    useEffect(() => {
        if (imageView) {
            const reader = new FileReader();

            reader.onload = (result) => {
                setImageUrl(result?.target?.result as string);
            };

            reader.readAsDataURL(imageView);
        } else {
            setImageUrl(null);
        }
    }, [imageView]);

    if (!profileUser) {
        return null;
    }

    return (
        <form onSubmit={(e) => handleSubmitProfile(e)}>
            <div className="row">
                <div className="col-12">
                    <h1>{t("settings.title", "Einstellungen")}</h1>
                </div>
                <div className="col-12 justify-content-center d-flex mb-3 position-relative">
                    {!imageUrl && !profileImage?.url ? (
                        <div className="avatar-placeholder">
                            <span>
                                {profileUser.name.charAt(0) + profileUser.surname.charAt(0)}
                            </span>
                        </div>
                    ) : (
                        <img
                            src={imageUrl ? imageUrl : profileImage?.url}
                            alt="Profilbild"
                            className="avatar-img"
                        />
                    )}

                    <InputLabel className="edit-avatar shadow-sm">
                        <input
                            accept="image/*"
                            className="select-image"
                            type="file"
                            onChange={(value) => {
                                setImageChanged(true);
                                setImageView(value.target.files ? value.target.files[0] : null);
                            }}
                        />
                        <FontAwesomeIcon icon={faEdit} />
                    </InputLabel>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 mt-3">
                    <InputLabel required>{t("user.sex", "Geschlecht")}</InputLabel>
                    <select
                        className="form-select"
                        name="sex"
                        onChange={(e) =>
                            setProfileUser((prev) => {
                                if (!prev) {
                                    return prev;
                                }

                                return {
                                    ...prev,
                                    sex: e.target.value as "d" | "f" | "m",
                                };
                            })
                        }
                        value={profileUser.sex}
                    >
                        <option value="f">{t("global.sexType.f", "Weiblich")}</option>
                        <option value="m">{t("global.sexType.m", "Männlich")}</option>
                        <option value="d">{t("global.sexType.d", "Divers")}</option>
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 col-12">
                    <InputLabel htmlFor="Name" required>
                        {t("user.name", "Vorname")}
                    </InputLabel>
                    <input
                        id="name"
                        className="form-control"
                        onChange={(e) =>
                            setProfileUser((prev) => {
                                if (!prev) {
                                    return prev;
                                }

                                return {
                                    ...prev,
                                    name: e.target.value,
                                };
                            })
                        }
                        type="text"
                        placeholder={t("user.name", "Vorname")}
                        value={profileUser.name}
                    />
                </div>
                <div className="col-md-6 col-12">
                    <InputLabel htmlFor="surname" required>
                        {t("user.surname", "Nachname")}
                    </InputLabel>
                    <input
                        id="surname"
                        className="form-control"
                        onChange={(e) =>
                            setProfileUser((prev) => {
                                if (!prev) {
                                    return prev;
                                }

                                return {
                                    ...prev,
                                    surname: e.target.value,
                                };
                            })
                        }
                        type="text"
                        placeholder={t("user.surname", "Nachname")}
                        value={profileUser.surname}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <InputLabel htmlFor="email" required>
                        {t("user.email", "E-Mail")}
                    </InputLabel>
                    <input
                        id="email"
                        disabled={!!profileUser.pending_email_change}
                        className="form-control"
                        onChange={(e) =>
                            setProfileUser((prev) => {
                                if (!prev) {
                                    return prev;
                                }

                                return {
                                    ...prev,
                                    email: e.target.value,
                                };
                            })
                        }
                        type="text"
                        placeholder={t("user.email", "E-Mail")}
                        value={profileUser.email}
                    />
                    {!!profileUser.pending_email_change && (
                        <div className="d-flex flex-column">
                            <span className="my-2">
                                {t(
                                    "user.emailHasPendingChange",
                                    "Bitte bestätigen Sie die E-Mail Änderung über den Link der an die neue E-Mail-Adresse {{newEmail}} versendet wurde.",
                                    {
                                        newEmail: profileUser.pending_email_change,
                                    }
                                )}
                            </span>
                            <div className="d-flex justify-content-between">
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={cancelPendingEmailChange}
                                >
                                    {t("user.cancelEmailChange", "Änderung abbrechen")}
                                </button>
                                <button
                                    className="btn btn-sm btn-save"
                                    onClick={resendPendingEmailChange}
                                >
                                    {t("user.sendAgain", "E-Mail erneut senden")}
                                </button>
                            </div>
                        </div>
                    )}
                    {!profileUser.pending_email_change && !profileUser.emailconfirmed && (
                        <div className="d-flex flex-column">
                            <span className="my-2">
                                {t(
                                    "user.emailNotValidated",
                                    "Deine E-Mail ist noch nicht bestätigt. Bitte bestätige sie über den Link der an Deine E-Mail-Adresse {{email}} versendet wurde.",
                                    {
                                        email: profileUser.email,
                                    }
                                )}
                            </span>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-sm btn-save"
                                    onClick={resendValidateEmail}
                                >
                                    {t("user.sendAgain", "E-MAil erneut senden")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 col-12">
                    <InputLabel htmlFor="username" required>
                        {t("user.username", "Benutzername")}
                    </InputLabel>
                    <input
                        id="username"
                        className="form-control"
                        readOnly
                        type="text"
                        disabled
                        defaultValue={profileUser.username}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
                    <button className="btn btn-save" type="submit" disabled={!postAvailable}>
                        {t("profile.save", "Profil speichern")}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default Settings;
