/** @format */

import { MuscleData } from "@api/ext/ExerciseAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import { faSpinnerThird, faImage } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { setLocale, SchemaOf } from "yup";

import ExerciseContext from "../../../context/ExerciseProvider";
import ExerciseModalView from "./ModalView/ExerciseModalView";
import ModalDescription from "./ModalView/ModalDescription";
import ModalViewMuscle from "./ModalView/ModalViewMuscle/ModalViewMuscle";
import SelectDescription from "./SelectDescription/SelectDescription";
import SelectPhase from "./SelectPhase/SelectPhase";
import SelectedList from "./SelectedList/SelectedList";

import "./ExerciseForm.scss";

setLocale({
    mixed: {
        required: "Eine Eingabe ist notwendig",
    },
});

interface SchemaShape {
    name: string;
}

interface ExerciseFormParams {
    id: string | undefined;
}

const validateSchema: SchemaOf<SchemaShape> = yup.object().shape({
    name: yup.string().min(3).max(50).required(),
});

const ExerciseForm: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const {
        loaded,
        section,
        pattern,
        setPattern,
        setSection,
        setTag,
        tag,
        muscle,
        setMuscle,
        save,
        post,
        name,
        secondname,
        setName,
        setSecondname,
        setImage,
        isEdit,
        defaultImage,
        video,
        setVideo,
        trainingsapparatus,
        setTrainingsapparatus,
        muscleGroup,
        setExerciseId,
        selectableCompanies,
        selectedCompany,
        setSelectedCompany,
    } = useContext(ExerciseContext);

    const { id } = useParams<ExerciseFormParams>();

    const [image, setImageView] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [error, setError] = useState<boolean>(false);
    const [modalTags, setModalTags] = useState<boolean>(false);
    const [modalPattern, setModalPattern] = useState<boolean>(false);
    const [modalMuscle, setModalMuscle] = useState<boolean>(false);
    const [modalDescription, setModalDescription] = useState<boolean>(false);
    const [modalTrainingsapparatus, setModalTrainingsapparatus] = useState<boolean>(false);

    const handleSubmit = useCallback(() => {
        setError(false);

        validateSchema
            .validate(
                { name },
                {
                    strict: true,
                }
            )
            .then(() => save())
            .catch((err: { message: boolean }) => {
                setError(err.message);
            });
    }, [name, save]);

    useEffect(() => {
        if (id) {
            setExerciseId(Number(id));
        } else {
            setExerciseId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (defaultImage !== null) {
            setImageUrl(defaultImage);
        }
    }, [defaultImage]);

    useEffect(() => {
        if (image) {
            setImage(image);

            const reader = new FileReader();

            reader.onload = (result) => {
                if (result?.target?.result !== undefined) {
                    const img = result?.target?.result;

                    setImageUrl(img as string | null);
                }
            };

            reader.readAsDataURL(image);
        } else {
            setImageUrl(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    useEffect(() => {
        if (!id && user?.is_admin && selectableCompanies[0]) {
            setSelectedCompany(selectableCompanies[0]);
        }
    }, [selectableCompanies, setSelectedCompany, user?.is_admin, id]);

    if (!loaded) {
        return (
            <div className="row">
                <div className="col-12 text-center align-center mt-5">
                    <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
                </div>
            </div>
        );
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleChangeSecondName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecondname(event.target.value);
    };

    const handleChangeVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVideo(event.target.value);
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col d-flex justify-content-between">
                    <h1>
                        {isEdit
                            ? t("exercise.view.addExercise", "Übung bearbeiten")
                            : t("exercise.view.editExercise", "Übung hinzufügen")}
                    </h1>
                    <div className="col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                        <div className="row">
                            <div className="col-12">
                                <button
                                    disabled={!name || post}
                                    className="btn btn-save"
                                    onClick={handleSubmit}
                                >
                                    {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                                    {t("exercise.save", "Übung speichern")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form>
                <div className="row pt-3">
                    <div className="col">
                        {selectableCompanies && user.is_admin && (
                            <div className="mb-2">
                                <InputLabel required htmlFor="exerciseCompany">
                                    {t("exercise.company", "Studio")}
                                </InputLabel>
                                <Select
                                    id="exerciseCompany"
                                    value={selectedCompany}
                                    required
                                    onChange={(e) => setSelectedCompany(e)}
                                    options={selectableCompanies}
                                    isDisabled={!!id}
                                />
                            </div>
                        )}
                        <InputLabel required>{t("exercises.name", "Übungsname")}</InputLabel>
                        <input
                            type="text"
                            className={`form-control ${error ? "is-invalid" : ""}`}
                            placeholder={t("exercises.name", "Übungsname")}
                            value={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChangeName(e)
                            }
                        />
                        <div className="invalid-feedback">{error}</div>
                        <div className="mt-2">
                            <InputLabel optional>
                                {t("exercises.nameShort", "Spitzname")}
                            </InputLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("exercises.nameShort", "Spitzname")}
                                value={secondname}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChangeSecondName(e)
                                }
                            />
                        </div>

                        <div className="mt-2">
                            <InputLabel optional>
                                {t("exercises.videoLink", "Video Link")}
                            </InputLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("exercises.videoLink", "Video Link")}
                                value={video}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChangeVideo(e)
                                }
                            />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        {!image && !defaultImage && (
                            <>
                                <InputLabel required className="select-label">
                                    <input
                                        accept="image/*"
                                        className="select-image"
                                        type="file"
                                        onChange={(value) =>
                                            setImageView(
                                                value.target.files ? value.target.files[0] : null
                                            )
                                        }
                                    />
                                    <FontAwesomeIcon icon={faImage} size="5x" />
                                </InputLabel>
                            </>
                        )}
                        {(image || imageUrl) && (
                            <InputLabel required className="select-label">
                                <input
                                    accept="image/*"
                                    className="select-image"
                                    type="file"
                                    onChange={(value) =>
                                        setImageView(
                                            value.target.files ? value.target.files[0] : null
                                        )
                                    }
                                />
                                <img
                                    src={imageUrl ? imageUrl : undefined}
                                    alt={t("exercises.imageAlt", "Bild zur Übung")}
                                    className="image-chose rounded"
                                />
                            </InputLabel>
                        )}
                    </div>
                </div>
            </form>

            <div className="row mt-5">
                <div className="col-12">
                    <h5>{t("exercises.description", "Beschreibung der Übung")}</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <button className="select-btn" onClick={() => setModalDescription(true)}>
                        {t("global.description", "Beschreibung")}
                    </button>
                    <ModalDescription show={modalDescription} setShow={setModalDescription} />
                    <SelectDescription />
                </div>
                <div className="col-6">
                    <SelectPhase defaultData={section} setData={setSection} />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12">
                    <h5>{t("global.categories", "Kategorien")}</h5>
                </div>
            </div>

            <div className="row">
                <div className="col box-info-border">
                    <button className="select-btn" onClick={() => setModalPattern(true)}>
                        {t("global.pattern", "Pattern")}
                        <span className="badgeModal badge bg-primary">
                            {pattern && pattern.filter((value) => value.active).length}
                        </span>
                    </button>

                    <ExerciseModalView
                        show={modalPattern}
                        setShow={setModalPattern}
                        defaultData={pattern}
                        title={t("global.pattern", "Pattern")}
                        setData={setPattern}
                    />

                    <SelectedList data={pattern} setData={setPattern} />
                </div>
                <div className="col box-info-border">
                    <button className="select-btn" onClick={() => setModalMuscle(true)}>
                        {t("global.muscles", "Muskeln")}
                        <span className="badgeModal badge bg-primary">
                            {muscle && muscle.filter((value) => value.active).length}
                        </span>
                    </button>

                    <ModalViewMuscle
                        show={modalMuscle}
                        setShow={setModalMuscle}
                        defaultData={muscle}
                        title={t("global.muscles", "Muskeln")}
                        setData={setMuscle}
                        extra={(muscleGroup as unknown) as MuscleData[]}
                    />

                    <SelectedList data={muscle} setData={setMuscle} />
                </div>
                <div className="col box-info-border">
                    <button className="select-btn" onClick={() => setModalTags(true)}>
                        {t("global.exercisetags", "Problem-Tags")}
                        <span className="badgeModal badge bg-primary">
                            {tag && tag.filter((value) => value.active).length}
                        </span>
                    </button>

                    <ExerciseModalView
                        show={modalTags}
                        setShow={setModalTags}
                        defaultData={tag}
                        title={t("global.exercisetags", "Problem-Tags")}
                        setData={setTag}
                    />

                    <SelectedList data={tag} setData={setTag} />
                </div>
                <div className="col">
                    <button className="select-btn" onClick={() => setModalTrainingsapparatus(true)}>
                        {t("global.equipment", "Equipment")}
                        <span className="badgeModal badge bg-primary">
                            {trainingsapparatus &&
                                trainingsapparatus.filter((value) => value.active).length}
                        </span>
                    </button>

                    <ExerciseModalView
                        show={modalTrainingsapparatus}
                        setShow={setModalTrainingsapparatus}
                        defaultData={trainingsapparatus}
                        title={t("global.equipment", "Equipment")}
                        setData={setTrainingsapparatus}
                    />

                    <SelectedList data={trainingsapparatus} setData={setTrainingsapparatus} />
                </div>
            </div>
        </>
    );
};

export default ExerciseForm;
