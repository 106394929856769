/** @format */

import { extendedUserAdapter } from "@api/ApiRequest";
import { UserSearchModel } from "@api/ext/ExtendedUserAdapter";
import {
    faSpinnerThird,
    faQuestionCircle,
    faAnalytics,
    faTasks,
    faUser,
    faClipboardList,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ModalView from "../../../components/Modal/ModalView";

import "./UserSearch.scss";

let timeoutId: ReturnType<typeof setTimeout>;

const UserSearch: React.FC = () => {
    const [t] = useTranslation();

    const input = useRef<HTMLInputElement>(null);
    const history = useHistory();

    const [search, setSearch] = useState<string>("");

    const [users, setUsers] = useState<UserSearchModel[]>([]);

    const [searchLoad, setSearchLoad] = useState<boolean>(false);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);
    const [modalRef, setModalRef] = useState<null | React.MutableRefObject<HTMLDivElement | null>>(
        null
    );

    useEffect(() => {
        setTimeout(() => {
            if (input.current) {
                input.current.focus();
            }
        }, 500);
    }, []);

    useEffect(() => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            void extendedUserAdapter.searchUsers(search).then((value) => {
                setSearchLoad(false);
                setUsers(value);
            });
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [search]);

    const handlePushClose = (
        modal: Modal,
        ref: React.MutableRefObject<HTMLDivElement | null> | null
    ) => {
        setModaInstance(modal);
        setModalRef(ref);
    };

    const handleClose = (url: string) => {
        if (modalInstance && modalRef && modalRef.current) {
            modalRef.current.addEventListener("hidden.bs.modal", function () {
                history.push(url);
            });

            modalInstance.hide();
        }
    };

    return (
        <ModalView title={t("user.search", "Benutzer suchen")} pushRef={handlePushClose} stopHide>
            <div className="row">
                <div className="col-12 position-relative">
                    <input
                        ref={input}
                        id="searchUser"
                        className="form-control form-control-lg"
                        onChange={(e) => {
                            setSearch(e.target.value);
                            setSearchLoad(true);
                        }}
                        type="text"
                        placeholder={t("user.search", "Benutzer suchen")}
                        required
                        value={search}
                    />
                    <div className="user-serach-icon">
                        {searchLoad ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : null}
                    </div>
                </div>
                <div className="col-12 question-user mt-2">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    <span className="ms-2">
                        {t(
                            "user.question",
                            "Gib einen Benutzer mit seinem Namen oder Nachnamen ein"
                        )}
                    </span>
                </div>
            </div>
            <div className="user-box">
                {users.map((value) => (
                    <div
                        key={value.id}
                        className={`w-100 user-search-item-base ${
                            value.archived ? "archived" : ""
                        }`}
                    >
                        <div className="user-search-item">
                            <div className="d-flex align-items-center">
                                {value.uploads[0] ? (
                                    <div className="d-flex justify-content-center text-center me-3">
                                        <img
                                            src={`${value.uploads[0].url}`}
                                            alt="avatar"
                                            className="profileImage"
                                        />
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center text-center me-3">
                                        <div className="userCircle">
                                            {value.name.charAt(0) + value.surname.charAt(0)}
                                        </div>
                                    </div>
                                )}

                                {value.full}
                            </div>
                            <div className="d-flex">
                                <div
                                    onClick={() => handleClose(`/users/view/${value.id}`)}
                                    className="user-search-link"
                                >
                                    <FontAwesomeIcon icon={faUser} color="rgba(0, 0, 0, 0.8)" />
                                </div>

                                <div
                                    onClick={() =>
                                        handleClose(`/users/view/${value.id}/statistics`)
                                    }
                                    className="user-search-link"
                                >
                                    <FontAwesomeIcon icon={faTasks} color="rgba(0, 0, 0, 0.8)" />
                                </div>
                                <div
                                    onClick={() => handleClose(`/users/view/${value.id}/progress`)}
                                    className="user-search-link"
                                >
                                    <FontAwesomeIcon
                                        icon={faAnalytics}
                                        color="rgba(0, 0, 0, 0.8)"
                                    />
                                </div>
                                <div
                                    onClick={() => handleClose(`/users/view/${value.id}/notes`)}
                                    className="user-search-link"
                                >
                                    <FontAwesomeIcon
                                        icon={faClipboardList}
                                        color="rgba(0, 0, 0, 0.8)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {users.length === 0 ? (
                    <div className="d-flex justify-content-lg-center align-items-center mt-3">
                        <p className="text-black-50">
                            {t("user.noUsersFound", "Keine Benutzer gefunden")}
                        </p>
                    </div>
                ) : null}
            </div>
        </ModalView>
    );
};

export default UserSearch;
