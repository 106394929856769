/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./Table.scss";

const Table = ({
    columns,
    data,
    useSortBy,
    usePagination,
    useRowSelect,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageSize = 10,
    canNextPage = false,
}) => {
    return (
        <div className="table-responsive table-component">
            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={`${column.className ? column.className : ""}`}
                                >
                                    <div className="d-flex align-items-center">
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span className="sort-icon">
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faChevronUp} />
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        return (
                            prepareRow(row) || (
                                <tr id={row.original.id} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={`table-cell ${
                                                    cell.column.className
                                                        ? cell.column.className
                                                        : ""
                                                }`}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )
                        );
                    })}
                    {!canNextPage && page.length < pageSize
                        ? [...Array(pageSize - page.length).keys()].map((index) => (
                              <tr key={`header-${index}`}>
                                  {headerGroups.map((headerGroup, keyIndex) => (
                                      <React.Fragment key={keyIndex}>
                                          {headerGroup.headers.map((key) => (
                                              <td key={`${index}-${key.id}`} className="table-cell">
                                                  <span>&nbsp;</span>
                                              </td>
                                          ))}
                                      </React.Fragment>
                                  ))}
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
