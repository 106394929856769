/** @format */

import { ApiAdapter, RequestAdapterInterface } from "@interweberde/prima-core";

export enum PaymentMethod {
    CREDITCARD = "creditcard",
    DIRECTDEBIT = "directdebit",
    PAYPAL = "paypal",
}

export type SepaInfo = {
    name: string;
    iban: string;
    bic?: string;
};

export type Mandate = {
    id: string;
    status: string;
} & (
    | {
          method: PaymentMethod.CREDITCARD;
          details: {
              cardHolder: string | null;
              cardNumber: string | null;
              cardLabel: string | null;
              cardFingerprint: string | null;
              cardExpiryDate: string | null;
          } | null;
      }
    | {
          method: PaymentMethod.DIRECTDEBIT;
          details: {
              consumerName: string | null;
              consumerAccount: string | null;
              consumerBic: string | null;
          } | null;
      }
    | {
          method: PaymentMethod.PAYPAL;
          details: {
              consumerName: string | null;
              consumerAccount: string | null;
          } | null;
      }
);

export default class MandateAdapter<RequestConfigType> extends ApiAdapter<RequestConfigType> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter, "subscriptions/mandates");
    }

    async getMandates(): Promise<Mandate[]> {
        const response = await this._get<Mandate[]>("index");

        const mandates = response.data;
        if (!mandates) {
            throw new Error("invalid response");
        }

        return mandates;
    }

    async addSepaMandate(
        info: SepaInfo,
        seatCount: number,
        subscriptiontypeoptionId: number,
        reactivate: boolean,
        mandateId?: string
    ): Promise<void> {
        await this._post("add", {
            type: PaymentMethod.DIRECTDEBIT,
            name: info.name,
            iban: info.iban,
            bic: info.bic,
            seatCount,
            subscriptiontypeoptionId,
            reactivate,
            mandateId,
        });
    }

    async addMandate(
        type: Exclude<PaymentMethod, PaymentMethod.DIRECTDEBIT>,
        redirect: string,
        seatCount: number,
        subscriptiontypeoptionId: number,
        reactivate: boolean,
        newDefault?: boolean,
        mandateId?: string
    ): Promise<string> {
        const response = await this._post<{ checkout: string }>("add", {
            type,
            redirect,
            newDefault: !!newDefault,
            seatCount,
            subscriptiontypeoptionId,
            reactivate,
            mandateId,
        });

        const checkout = response.data.checkout;

        if (!checkout) {
            throw new Error("invalid response");
        }

        return checkout;
    }

    async removeMandate(id: string): Promise<Mandate[]> {
        const response = await this._get<Mandate[]>(`remove/${id}`);

        const mandates = response.data;
        if (!mandates) {
            throw new Error("invalid response");
        }

        return mandates;
    }
}
