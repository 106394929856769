/** @format */

import { exerciseAdapter } from "@api/ApiRequest";
import { ExerciseModel } from "@api/ext/ExerciseAdapter";
import ModalView from "@components/Modal/ModalView";
import { faImage } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import { Modal } from "bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import "./ExerciseView.scss";

interface ExerciseViewParams {
    id: string | undefined;
}

const ExerciseView: React.FC = () => {
    const [t] = useTranslation();
    const { id } = useParams<ExerciseViewParams>();
    const { setLoading } = useLoading();

    const [, setModaInstance] = useState<null | Modal>(null);
    const [, setModalRef] = useState<null | React.MutableRefObject<HTMLDivElement | null>>(null);
    const [exercise, setExercise] = useState<ExerciseModel | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);

    const handlePushClose = (
        modal: Modal,
        ref: React.MutableRefObject<HTMLDivElement | null> | null
    ) => {
        setModaInstance(modal);
        setModalRef(ref);
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        void exerciseAdapter.viewExercise(Number(id)).then((data) => {
            setLoaded(true);
            setExercise(data);
            setLoading(false);
        });
        // only id trigger this useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id || !loaded || !exercise) {
        return null;
    }

    return (
        <ModalView title={t("exercise.view", "Übung Details")} pushRef={handlePushClose} stopHide>
            <div className="row mb-5">
                <div className="col-12">
                    <h4>{exercise.name}</h4>
                    <div className="d-flex exerciseSectionTitle">
                        {exercise.exercisesections?.length ? (
                            <>
                                {exercise.exercisesections.map((value, index) => {
                                    const length = exercise?.exercisesections?.length;

                                    return (
                                        <span key={`sections-${index}`}>
                                            {value.name}
                                            {length && length - 1 > index ? ", " : ""}
                                        </span>
                                    );
                                })}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-6 flex-column d-flex exerciseSectionList">
                    <h6 className="font-weight-lighter">
                        {t("global.description", "Beschreibung")}:
                    </h6>
                    {exercise.exercisedescriptions?.length ? (
                        <>
                            {exercise.exercisedescriptions.map((value, index) => (
                                <span key={`descriptions-${index}`}>
                                    {index + 1}.) {value.text}
                                </span>
                            ))}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
                <div className="col-6 d-flex justify-content-end">
                    {exercise?.uploads && exercise?.uploads[0] ? (
                        <img
                            src={exercise?.uploads[0].url}
                            alt="Übungsbild"
                            className="exerciseSetImage"
                        />
                    ) : (
                        <div className="exerciseSetImage">
                            <FontAwesomeIcon icon={faImage} size="2x" />
                        </div>
                    )}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-3 exerciseSectionList">
                    <h6 className="font-weight-lighter">{t("global.pattern", "Pattern")}:</h6>
                    {exercise.exercisefilters?.length ? (
                        <>
                            {exercise.exercisefilters.map((value, index) => (
                                <span key={`filter-${index}`}>{value.name}</span>
                            ))}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
                <div className="col-3 exerciseSectionList">
                    <h6 className="font-weight-lighter">{t("global.muscles", "Muskeln")}:</h6>
                    {exercise.exercisemuscles?.length ? (
                        <>
                            {exercise.exercisemuscles.map((value, index) => (
                                <span key={`muscles-${index}`}>{value.name}</span>
                            ))}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
                <div className="col-3 exerciseSectionList">
                    <h6 className="font-weight-lighter">{t("global.tags", "Tags")}:</h6>
                    {exercise.exercisetags?.length ? (
                        <>
                            {exercise.exercisetags.map((value, index) => (
                                <span key={`tags-${index}`}>{value.name}</span>
                            ))}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
                <div className="col-3 exerciseSectionList">
                    <h6 className="font-weight-lighter">{t("global.equipment", "Equipment")}:</h6>
                    {exercise.trainingsapparatuses?.length ? (
                        <>
                            {exercise.trainingsapparatuses.map((value, index) => (
                                <span key={`apparatuses-${index}`}>{value.name}</span>
                            ))}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
            </div>
        </ModalView>
    );
};

export default ExerciseView;
