/** @format */

import React, { HTMLProps } from "react";

import "./InputLabel.scss";

interface InputLabelProps extends HTMLProps<HTMLLabelElement> {
    required?: boolean;
    optional?: boolean;
}
const InputLabel: React.FC<InputLabelProps> = ({
    required = false,
    optional = false,
    children,
    ...props
}) => {
    return (
        <label className="form-label" {...props}>
            {children} {required ? <span className="form-label-required">*</span> : ""}{" "}
            {!required && optional ? <span className="form-label-optional">(optional)</span> : ""}
        </label>
    );
};

export default InputLabel;
