/** @format */

import { PaymentMethod } from "@api/ext/MandateAdapter";
import RenderPaymentMethod from "@components/RenderPaymentMethod/RenderPaymentMethod";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import "./ManageMandate.scss";

interface ManageMandateProps {
    isModal?: boolean;
}

const ManageMandate: React.FC<ManageMandateProps> = ({ isModal = false }) => {
    const [t] = useTranslation();
    const history = useHistory();
    const {
        subscription,
        setMethod,
        selectedMandate,
        mandates,
        onRemoveMandate,
        onChangeMandate,
        setSelectedMandate,
    } = useSubscriptionContext();
    const [canSubmit, setCanSubmit] = useState(false);

    const onSubmitMandate = useCallback(async () => {
        if (!selectedMandate) {
            return;
        }

        await onChangeMandate(selectedMandate);

        history.push("/profile/subscription/done");
    }, [history, selectedMandate, onChangeMandate]);

    useEffect(() => {
        if (subscription.client_subscription?.mandate?.method) {
            setMethod(subscription?.client_subscription?.mandate?.method as PaymentMethod);
        }
    }, [setMethod, subscription]);

    useEffect(() => {
        setCanSubmit(selectedMandate?.id !== subscription.client_subscription?.mandate_id);
    }, [selectedMandate, subscription]);

    return (
        <div className={`${isModal ? "px-3" : ""}`}>
            {!isModal && <h1>{t("mandates.manageMandates", "Zahlungsmethoden verwalten")}</h1>}
            <div className="col-12 d-flex flex-column my-4 mandates-wrapper">
                {mandates &&
                    mandates.map((mandate, key) => (
                        <div key={key} className="py-2 d-flex align-items-center">
                            <label className="d-flex align-items-center mandate-wrapper">
                                <input
                                    className="me-4"
                                    type="radio"
                                    value={mandate.id}
                                    disabled={mandate.status === "invalid"}
                                    checked={selectedMandate?.id === mandate.id}
                                    onChange={() => setSelectedMandate(mandate)}
                                />
                                <div className="d-flex flex-column">
                                    <RenderPaymentMethod mandate={mandate} />
                                    {mandate.status === "invalid" && (
                                        <small className="text-danger">(Ungültig!)</small>
                                    )}
                                </div>
                            </label>
                            {mandate.id !== subscription.client_subscription?.mandate_id && (
                                <button
                                    className="btn btn-danger btn-sm ms-auto"
                                    onClick={() => onRemoveMandate(mandate.id)}
                                >
                                    x
                                </button>
                            )}
                        </div>
                    ))}
            </div>
            {!isModal && (
                <div className="row">
                    <div className="col-12 d-flex justify-content-between px-0">
                        <NavLink className="btn btn-default" to="/profile/payment">
                            {t("global.back", "Zurück")}
                        </NavLink>
                        <button
                            className={`btn btn-save ${!canSubmit ? "disabled" : ""}`}
                            onClick={onSubmitMandate}
                        >
                            {t("global.saveChange", "Änderung speichern")}
                        </button>
                        <NavLink className="btn btn-save " to="/profile/subscription/add-mandates">
                            {t(
                                "subscription.payment.method.addMethod",
                                "Neue Zahlungsmethode hinzufügen"
                            )}
                        </NavLink>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageMandate;
