/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

export interface WorkoutUserAttributeModel extends Model {
    first_login: DateTime | null;
}

export interface WorkoutUserAttributeModelPaginator extends Paginator {
    attributes: WorkoutUserAttributeModel[];
}

export default class WorkoutUserAttributeAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    WorkoutUserAttributeModel,
    WorkoutUserAttributeModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(
            "workout/Workoutuserattributes",
            "Workoutuserattribute",
            "Workoutuserattributes",
            adapter
        );
    }

    public async getAttribute(): Promise<WorkoutUserAttributeModel> {
        const { data } = await this._get<{ workoutuserattribute: WorkoutUserAttributeModel }>(
            "get-attribute"
        );

        return data.workoutuserattribute;
    }

    public async setFirstLogin(): Promise<WorkoutUserAttributeModel> {
        const { data } = await this._get<{ workoutuserattribute: WorkoutUserAttributeModel }>(
            "set-first-login"
        );

        return data.workoutuserattribute;
    }
}
