/** @format */

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

interface ExerciseCardProps {
    exercisId: string;
    exercisName: string;
    index: number;
    handleViewClick: (key: string) => void;
}

const ExerciseCard: React.FC<ExerciseCardProps> = ({
    exercisId,
    exercisName,
    index,
    handleViewClick,
}) => {
    return (
        <Draggable key={exercisId} draggableId={exercisId} index={index}>
            {(provided) => (
                <div
                    className="col-12"
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className="draggable-element d-flex justify-content-between">
                        {exercisName}
                        <FontAwesomeIcon
                            onClick={() => handleViewClick(exercisId)}
                            icon={faExclamationCircle}
                            className="icon move-select"
                        />
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default ExerciseCard;
