/** @format */

import {
    Model,
    ControllerAdapter,
    RequestAdapterInterface,
    Paginator,
} from "@interweberde/prima-core";

export interface AddNoteData {
    note: string;
    user_id: number;
}

export default class NotesAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Workoutnotes", "Workoutnote", "Workoutnotes", adapter);
    }

    public async setNote(note: AddNoteData): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>("add", note);

        return data.success;
    }
}
