/** @format */

import { DataSection } from "@context/ExerciseProvider";
import { faMinusSquare, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

interface SelectedListProps {
    data: DataSection[] | null;
    setData: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
}

const SelectedList: React.FC<SelectedListProps> = ({ data, setData }) => {
    const [active, setActive] = useState<boolean>(false);

    const handleRemove = (id: number) => {
        const newDefault: DataSection[] = [];

        if (!data) {
            return;
        }

        data.map((value) => {
            newDefault.push(id === value.id ? { ...value, active: false } : value);
        });

        setData(newDefault);
    };

    useEffect(() => {
        let defaultActive = false;

        if (!data) {
            return;
        }

        Object.keys(data).map((i) => {
            if (data[Number(i)].active) {
                defaultActive = true;
            }
        });
        setActive(defaultActive);
    }, [data]);

    if (!data) {
        return null;
    }

    return (
        <div className="row">
            <div className="col modalShowSearch mt-2">
                <div className="list-group-select">
                    {!active && (
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="align-self-center select-empty-icon"
                        />
                    )}

                    <ul className="list-group">
                        {Object.keys(data).map((i) => {
                            if (data[Number(i)].active) {
                                return (
                                    <li
                                        key={uuidv4()}
                                        className="list-group-item justify-content-between d-flex select-list-item"
                                        onClick={() => handleRemove(data[Number(i)].id)}
                                    >
                                        {data[Number(i)].name}

                                        <FontAwesomeIcon
                                            icon={faMinusSquare}
                                            className="align-self-center"
                                        />
                                    </li>
                                );
                            }

                            return null;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SelectedList;
