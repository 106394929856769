/** @format */

import { UserAdapter, UserAddModel, UserModel } from "@interweberde/prima-core";
import { DateTime } from "luxon";

export interface UploadImage {
    id: number | string;
    ext?: string;
    file: string;
    type: string;
}

export interface AuthUserModel extends UserModel {
    is_coach: boolean;
    last_invited: DateTime | null;
    pending_email_change: string | null;
}

interface GlobalUserAdapterExtendUserModel extends UserModel {
    uploads?: UploadImage[];
}
export interface ExtendUserAddModel extends UserAddModel {
    uploads?: File;
}

export interface ExtendedUserModel {
    user: GlobalUserAdapterExtendUserModel;
}

export interface ChangePasswordResponse {
    success?: boolean;
    errors?: {
        [model: string]: {
            [field: string]: {
                [error_code: string]: string;
            };
        };
    };
}

export default class GlobalUserAdapter<RequestConfigType> extends UserAdapter<RequestConfigType> {
    public async submitProfile(user: ExtendUserAddModel, image: File | null): Promise<boolean> {
        const formData = new FormData();
        if (image) {
            formData.append("uploads[file]", image);
        }

        formData.append("name", user.name);
        formData.append("surname", user.surname ? user.surname : "");
        formData.append("email", user.email);
        formData.append("sex", user.sex);

        const { data } = await this._post<boolean>("profile", formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data;
    }

    public async getProfile(): Promise<UploadImage[]> {
        const { data } = await this._get<{ upload: UploadImage[] }>("profile");

        return data.upload;
    }

    public async submitProfileImage(image: File): Promise<boolean> {
        const formData = new FormData();

        formData.append("uploads[file]", image);

        const { data } = await this._post<boolean>("profile", formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data;
    }

    public async changePassword(
        oldPassword: string,
        password: string,
        passwordRepeat: string
    ): Promise<ChangePasswordResponse> {
        const { data } = await this._post<ChangePasswordResponse>("change-password", {
            currPassword: oldPassword,
            newPassword: password,
            newPasswordAgain: passwordRepeat,
        });

        return data;
    }

    public async cancelPendingEmailChange(): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>("cancel-pending-email-change");

        return data.success;
    }

    public async resendPendingEmailChange(): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>("resend-pending-email-change");

        return data.success;
    }

    public async archive(users: number | number[]): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`archive-users`, {
            params: {
                users,
            },
        });

        return data.success;
    }

    public async unarchive(users: number | number[]): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`unarchive-users`, {
            params: {
                users,
            },
        });

        return data.success;
    }

    public async loggedin(): Promise<boolean> {
        const { data } = await this._get<boolean>(`loggedin`);

        return data;
    }
}
