/** @format */

import { workoutcoachesAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context//LoadingProvider";
import "./CoachesAdd.scss";
import AuthContext from "@context/AuthProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import { Modal } from "bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import ModalView from "../../../components/Modal/ModalView";

const CoachesAdd: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);

    const { setSaved, setShouldRefetch } = useContext(LoadingContext);

    const [name, setName] = useState<string>("");
    const [tel, setTel] = useState<string>("");
    const [mail, setMail] = useState<string>("");
    const [text, setText] = useState<string>("");

    const [post, setPost] = useState<boolean>(false);

    const [selectable, setSelectable] = useState<null | OptionType[]>(null);
    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);

    const [selected, setSelected] = useState<SelectedOption>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    useEffect(() => {
        void workoutcoachesAdapter.getAddCoach().then((value) => {
            const { users, companies } = value;

            const updateSelect: { value: string; label: string }[] = [];
            Object.keys(users).map((key) => {
                updateSelect.push({ value: key, label: users[Number(key)] });
            });
            setSelectable(updateSelect);

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            if (user?.is_admin && updateCompaniesSelect[0]) {
                setSelectedCompany(updateCompaniesSelect[0]);
            }
        });
    }, [user]);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPost(true);

        void workoutcoachesAdapter
            .add({
                name,
                tel,
                email: mail,
                text,
                user_id: Number(selected?.value),
                company_id:
                    user?.is_admin && selectedCompany
                        ? Number(selectedCompany.value)
                        : user?.company_id,
            })
            .then(() => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    if (!selectable || !user) {
        return null;
    }

    return (
        <ModalView
            title={t("coach.add", "Trainer hinzufügen")}
            pushRef={handlePushClose}
            goBack="coaches"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="coachCompany">
                                {t("coach.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="coachCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-6">
                        <InputLabel required htmlFor="coachName">
                            {t("coach.coachName", "Trainer Name")}
                        </InputLabel>
                        <input
                            id="coachName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            required
                            value={name}
                        />
                    </div>
                    <div className="col-6">
                        <InputLabel required htmlFor="coachUser">
                            {t("coach.systemUser", "System Benutzer")}
                        </InputLabel>
                        <Select
                            id="coachUser"
                            value={selected}
                            required
                            placeholder={t("global.selectEmptyChose", "-- Wähle --")}
                            onChange={(e) => setSelected(e)}
                            options={selectable}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <InputLabel htmlFor="coachTel">
                            {t("coach.phoneNumber", "Telefonnummer")}
                        </InputLabel>
                        <input
                            id="coachTel"
                            className="form-control"
                            onChange={(e) => setTel(e.target.value)}
                            type="text"
                            placeholder="+49 222 222 etc."
                            value={tel}
                        />
                    </div>
                    <div className="col-6">
                        <InputLabel required htmlFor="coachMail">
                            {t("coach.email", "E-Mail")}
                        </InputLabel>
                        <input
                            id="coachMail"
                            className="form-control"
                            onChange={(e) => setMail(e.target.value)}
                            type="email"
                            required
                            placeholder="musterman@interweber.de"
                            value={mail}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <InputLabel htmlFor="coachText">
                            {t("coach.presentationText", "Vorstellungstext")}
                        </InputLabel>
                        <textarea
                            id="coachText"
                            className="form-control"
                            onChange={(e) => setText(e.target.value)}
                            rows={3}
                            placeholder="Text"
                            value={text}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("coach.save", "Trainer speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default CoachesAdd;
