/** @format */

import { invoiceAdapter } from "@api/ApiRequest";
import { Invoice } from "@api/ext/InvoiceAdapter";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InvoicesTable from "./InvoicesTable";

const Invoices: React.FC = () => {
    const [t] = useTranslation();
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const fetchData = useCallback(() => {
        invoiceAdapter
            .index()
            .then((response) => {
                setInvoices(response);
            })
            .catch(() => null);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <h1>{t("global.myInvoices", "Meine Rechnungen")}</h1>
            <InvoicesTable data={invoices} />
        </div>
    );
};

export default Invoices;
