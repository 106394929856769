/** @format */

import { subscriptionAdapter } from "@api/ApiRequest";
import AuthContext from "@context/AuthProvider";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./SubscriptionCancel.scss";

const SubscriptionCancel: React.FC = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const { refetchSubscription } = useContext(AuthContext);

    const onSubscriptionCancel = () => {
        void subscriptionAdapter.cancelSubscription().then((res) => {
            refetchSubscription();

            history.push("/profile/payment");
        });
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-12">
                    <h1>{t("subscription.canceledSubscription", "Abo kündigen")}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-between mt-4">
                    <button className="btn btn-default" onClick={onSubscriptionCancel}>
                        {t("subscription.canceledSubscriptionNow", "Jetzt Abo kündigen")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default SubscriptionCancel;
