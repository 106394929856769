/** @format */

import { SubscriptionStatus } from "@api/ext/SubscriptionAdapter";
import AuthContext from "@context/AuthProvider";
import { DateTime, Interval } from "luxon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./SubscriptionTrialProgress.scss";

const SubscriptionTrialProgress: React.FC = () => {
    const [t] = useTranslation();
    const { subscription } = useContext(AuthContext);

    if (
        !subscription ||
        subscription.status !== SubscriptionStatus.TRIAL ||
        !subscription.trial_end_date
    ) {
        return null;
    }

    const totalTrialTime = Interval.fromDateTimes(
        DateTime.fromISO(subscription.start_date.toISODate()),
        DateTime.fromISO(subscription.trial_end_date.toISODate())
    ).length("days");

    const remainingDays = Interval.fromDateTimes(
        DateTime.fromISO(DateTime.local().toISODate()),
        DateTime.fromISO(subscription.trial_end_date.toISODate())
    ).length("days");

    const renderDiff =
        remainingDays > 1
            ? t("trial.remainingDays", "{{count}} Tage", { count: remainingDays })
            : remainingDays > 0
            ? t("trial.tomorrow", "bis Morgen")
            : t("global.hours", "wenige Stunden");

    if (!remainingDays) {
        return null;
    }

    return (
        <div className="container p-3">
            <div className="d-flex justify-content-between align-items-center trial-progress-wrapper mt-4">
                <div className="w-100">
                    <div className="trial-progress progress w-100">
                        <div
                            className="progress-bar bg-trial"
                            role="progressbar"
                            style={{
                                width:
                                    String(
                                        ((totalTrialTime - remainingDays) / totalTrialTime) * 100
                                    ) + "%",
                            }}
                            aria-valuenow={totalTrialTime - remainingDays}
                            aria-valuemin={0}
                            aria-valuemax={totalTrialTime}
                        />
                        <div
                            className="trial-progress-inverse"
                            style={{
                                width:
                                    String(
                                        100 -
                                            ((totalTrialTime - remainingDays) / totalTrialTime) *
                                                100
                                    ) + "%",
                            }}
                        />
                        <div className="inside-text">
                            {t("trial.endingText", "Du kannst Intercoach noch {{value}} testen.", {
                                value: renderDiff,
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <Link className="btn btn-save nowrap" to="/profile/subscription">
                        {t("payment.activatePayment", "Abo aktivieren")}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionTrialProgress;
