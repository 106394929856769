/** @format */

import { AuthUserModel } from "@api/ext/GlobalUserAdapter";
import debugLib from "debug";
import React, { useCallback, useEffect, useState } from "react";

import { authAdapter, userAdapter } from "../api/ApiRequest";

type UseAuthRet = [
    boolean | null,
    AuthUserModel | null,
    React.Dispatch<React.SetStateAction<AuthUserModel | null>>,
    () => Promise<void>,
    () => Promise<void>,
    () => void
];

export const useAuth = (callee: string): UseAuthRet => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    const [user, setUser] = useState<AuthUserModel | null>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debug = useCallback(debugLib("quadio:hooks:auth:" + callee), [callee]);

    useEffect(() => {
        if (authenticated === null) {
            debug("will not register login subscriber - authentication state not determined!");
            return;
        }

        if (authenticated) {
            debug("will not register login subscriber - user is logged in!");
            return;
        }

        debug("registering login subscriber");

        debug("subscripe");

        authAdapter.subscribe("login", (success) => {
            if (!success) {
                return;
            }

            debug("user logged in");

            setAuthenticated(true);
        });

        return () => {
            authAdapter.unsubscribe("login", "1");
            debug("unsubscribed from login event");
        };
    }, [authenticated, debug]);

    useEffect(() => {
        if (authenticated === null) {
            debug("will not register logout subscriber - authentication state not determined!");
            return;
        }

        if (!authenticated) {
            debug("will not register logout subscriber - user is not logged in!");
            return;
        }

        debug("registering logout subscriber");

        const unsubscribeId = "1";

        authAdapter.subscribe("logout", (success) => {
            if (!success) {
                return;
            }

            debug("user logged out");

            setAuthenticated(false);
        });

        return () => {
            authAdapter.unsubscribe("logout", unsubscribeId);
            debug("unsubscribed from logout event");
        };
    }, [authenticated, debug]);

    const validateAuth = () => {
        void authAdapter.validateAuthentication().then(({ authenticated, error }) => {
            // check if request was canceled
            if (authenticated === null) {
                return;
            }

            debug(
                `check authenticated: user is ${
                    authenticated ? "authenticated" : "unauthenticated"
                }`
            );

            setAuthenticated(authenticated);
        });
    };

    useEffect(() => {
        validateAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debug]);

    useEffect(() => {
        const unsubscribeId = "1";

        authAdapter.subscribe("userDidUpdate", () => {
            //   const user = await authAdapter.getUser(false);
            debug("user did update", user);

            userAdapter
                .whoami()
                .then((u) => {
                    const currUser = u as AuthUserModel;

                    setUser(currUser);
                })
                .catch(() => setUser(null));
        });

        debug("subscribed to user update events");

        return () => {
            authAdapter.unsubscribe("userDidUpdate", unsubscribeId);
            debug("unsubscribed from user update event");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debug]);

    const refetchUser = useCallback(async () => {
        await userAdapter
            .whoami()
            .then((u) => {
                const currUser = u as AuthUserModel;

                setUser(currUser);
            })
            .catch(() => setUser(null));
    }, []);

    const logout = useCallback(() => {
        setUser(null);
        setAuthenticated(false);

        return authAdapter.logout();
    }, []);

    return [authenticated, user, setUser, refetchUser, logout, validateAuth];
};
