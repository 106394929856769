/** @format */

import { DataSection } from "@context/ExerciseProvider";
import { faCheckSquare, faSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import ModalSmall from "../Modal/ModalSmall";

interface ExerciseModalViewProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    defaultData: DataSection[] | null;
    title: string;
    setData: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
}

const ExerciseModalView: React.FC<ExerciseModalViewProps> = ({
    show,
    setShow,
    defaultData,
    title,
    setData,
}) => {
    const [search, setSearch] = useState<string>("");

    const [serachData, setSearchData] = useState<DataSection[] | null>(null);

    const [currentData, setCurrentData] = useState<DataSection[]>([]);

    useEffect(() => {
        if (search) {
            const textSearch = search.toUpperCase();

            if (!defaultData) {
                return;
            }

            const filtered = defaultData.filter((value) => {
                const itemData = `${value.name.toUpperCase()}`;

                return itemData.indexOf(textSearch) > -1;
            });

            setSearchData(filtered);
        } else {
            setSearchData(null);
        }
    }, [search, defaultData]);

    const handleSelect = (id: number) => {
        const newDefault: DataSection[] = [];

        if (!defaultData) {
            return null;
        }

        defaultData.map((value) => {
            newDefault.push(id === value.id ? { ...value, active: !value.active } : value);
        });

        setData(newDefault);
    };

    useEffect(() => {
        if (defaultData) {
            setCurrentData(serachData ? serachData : defaultData);
        }
    }, [serachData, defaultData]);

    if (!defaultData) {
        return null;
    }

    return (
        <ModalSmall title={title} handleSearch={setSearch} show={show} setShow={setShow}>
            <div className="col modalShowSearch">
                <ul className="list-group">
                    {currentData &&
                        Object.keys(currentData).map((i) => (
                            <li
                                key={uuidv4()}
                                className={`list-group-item justify-content-between d-flex ${
                                    currentData[Number(i)].active ? " itemGroup-active" : ""
                                }`}
                                onClick={() => handleSelect(currentData[Number(i)].id)}
                            >
                                {currentData[Number(i)].name}

                                {currentData[Number(i)].active ? (
                                    <FontAwesomeIcon
                                        icon={faCheckSquare}
                                        className="align-self-center"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faSquare}
                                        className="align-self-center"
                                    />
                                )}
                            </li>
                        ))}
                </ul>
            </div>
        </ModalSmall>
    );
};

export default ExerciseModalView;
