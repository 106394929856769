/** @format */

import Logout from "@screen/Logout/Logout";
import SubscriptionInactive from "@screen/SubscriptionInactive/SubscriptionInactive";
import ConfirmEmailChange from "@screen/UnauthenticatedScreens/ConfirmEmailChange/ConfirmEmailChange";
import ResetPassword from "@screen/UnauthenticatedScreens/ResetPassword/ResetPassword";
import AcceptInvite from "@screen/UnauthenticatedScreens/ValidateEmail/AcceptInvite";
import ValidateEmail from "@screen/UnauthenticatedScreens/ValidateEmail/ValidateEmail";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const NoSubscriptionRouter: React.FC = () => {
    return (
        <Switch>
            <Route path="/profile/subscription" component={SubscriptionInactive} />
            <Route path="/logout" exact component={Logout} />

            <Route path="/confirm-email-change/:token" component={ConfirmEmailChange} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/validate-mail/:token" component={ValidateEmail} />
            <Route path="/accept-invite/:token" component={AcceptInvite} />

            <Route path="*">
                <Redirect to={{ pathname: "/profile/subscription" }} />
            </Route>
        </Switch>
    );
};

export default NoSubscriptionRouter;
