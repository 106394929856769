/** @format */

import { DataSection } from "@context/ExerciseProvider";
import { faCheckSquare, faSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { v4 as uuidv4 } from "uuid";

interface SelectPhaseProps {
    defaultData: DataSection[] | null;
    setData: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
}

const SelectPhase: React.FC<SelectPhaseProps> = ({ defaultData, setData }) => {
    const handleSetData = (id: number) => {
        const returnData: DataSection[] = [];

        if (!defaultData) {
            return null;
        }

        defaultData.map((value) => {
            if (value.id === id) {
                value.active = !value.active;
            }

            returnData.push(value);
        });

        setData(returnData);
    };

    if (!defaultData) {
        return null;
    }

    return (
        <>
            {defaultData.map((value) => (
                <div key={uuidv4()} className="col mt-1">
                    <button
                        className={`select-btn btn-select-phase d-flex justify-content-between ${
                            value.active ? " itemGroup-active" : ""
                        }`}
                        onClick={() => handleSetData(Number(value.id))}
                    >
                        <span>{value.name}</span>
                        {value.active ? (
                            <FontAwesomeIcon icon={faCheckSquare} className="align-self-center" />
                        ) : (
                            <FontAwesomeIcon icon={faSquare} className="align-self-center" />
                        )}
                    </button>
                </div>
            ))}
        </>
    );
};

export default SelectPhase;
