/** @format */

import { Modal } from "bootstrap";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import "./Modal.scss";

interface ModalViewProps {
    title: string;
    goBack?: string;
    pushRef?: (modal: Modal, ref: React.MutableRefObject<HTMLDivElement | null> | null) => void;
    stopHide?: boolean;
    size?: "xl" | "lg" | "md";
}

const ModalView: React.FC<ModalViewProps> = ({
    children,
    title,
    goBack = null,
    pushRef,
    stopHide = false,
    size = "lg",
}) => {
    const modalView = useRef<HTMLDivElement | null>(null);
    const modalInner = useRef<HTMLDivElement | null>(null);

    const history = useHistory();

    const catchEscape = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            handleClose();
        }
    };

    const handleClose = () => {
        if (!goBack) {
            history.goBack();
        } else {
            history.push(`/${goBack}`);
        }
    };

    useEffect(() => {
        let bsModal: Modal | null = null;

        if (modalView.current) {
            const myModal = modalView.current;
            bsModal = Modal.getInstance(myModal);

            if (!bsModal) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.show();
            }

            if (pushRef !== undefined) {
                pushRef(bsModal, modalView);
            }

            if (!stopHide) {
                modalView.current.addEventListener("hidden.bs.modal", function () {
                    handleClose();
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalView, goBack]);

    useEffect(() => {
        let bsModal: Modal | null = null;

        const checkIfClickedOutside = (e: MouseEvent) => {
            const myModal = modalView.current;

            if (myModal) {
                bsModal = Modal.getInstance(myModal);

                if (
                    bsModal &&
                    modalInner.current &&
                    !modalInner.current.contains(e.target as Node)
                ) {
                    bsModal.hide();
                    handleClose();
                }
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", catchEscape, false);

        return () => {
            document.removeEventListener("keydown", catchEscape, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="modal-backdrop-custom">
            <div
                ref={modalView}
                className="modal fade show"
                tabIndex={-1}
                id="coachModal"
                aria-hidden="true"
                aria-labelledby="modal"
                style={{
                    display: "block",
                }}
            >
                <div
                    ref={modalInner}
                    className={`modal-dialog modal-${size} modal-dialog-centered`}
                >
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <div id="contained-modal-title-vcenter" className="modal-title">
                                <h3 className="mb-0">{title}</h3>
                            </div>
                            <button
                                onClick={() => handleClose()}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body p-4">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalView;
