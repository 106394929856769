/** @format */

import ChatContext from "@context/ChatProvider";
import { faSpinnerThird, faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animate } from "framer-motion";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Connected from "./Connected/Connected";
import Message from "./Message/Message";

const ChatMessagesList: React.FC = () => {
    const [t] = useTranslation();
    const {
        activeChatUser,
        sendChatMessage,
        handleScrollEnd,
        loadPagination,
        setLoadPagination,
        newMessageScroll,
        setNewMessageScroll,
    } = useContext(ChatContext);

    const nodeRefEnd = useRef<HTMLDivElement>(null);

    const [chatText, setChatText] = useState<string>("");

    const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);

    const scrollToMessage = useCallback(
        (t = 1) => {
            const node = nodeRefEnd.current;
            if (!node) {
                return;
            }

            if ((node.scrollHeight !== node.scrollTop, node.scrollHeight)) {
                const controls = animate(node.scrollTop, node.scrollHeight, {
                    duration: t as number,
                    onUpdate(value) {
                        node.scrollTop = value;
                    },
                });

                return () => controls.stop();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeChatUser, nodeRefEnd]
    );

    const scrollMessagePagination = useCallback(() => {
        if (nodeRefEnd.current) {
            const node = nodeRefEnd.current;

            const currPosition = node.scrollHeight - lastScrollPosition;

            const controls = animate(node.scrollTop, currPosition, {
                duration: 0,
                onUpdate(value) {
                    node.scrollTop = value;
                },
            });

            return () => controls.stop();
        }

        setLastScrollPosition(0);
    }, [nodeRefEnd, lastScrollPosition]);

    const checkScrollable = useCallback(() => {
        if (loadPagination) {
            if (nodeRefEnd.current) {
                scrollMessagePagination();
            }

            setLoadPagination(false);
            return;
        }

        if (activeChatUser) {
            scrollToMessage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeChatUser, loadPagination]);

    const sendMessage = useCallback(() => {
        if (!activeChatUser || !chatText) {
            return;
        }

        sendChatMessage(chatText);

        setChatText("");

        scrollToMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatText]);

    const handleCheckSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.shiftKey && e.key === "Enter") {
            return;
        }

        if (e.key === "Enter") {
            e.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        if (newMessageScroll) {
            setNewMessageScroll(false);

            scrollToMessage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessageScroll]);

    useEffect(() => {
        if (loadPagination) {
            if (nodeRefEnd.current) {
                setLastScrollPosition(nodeRefEnd.current.scrollHeight);
            }
        }
    }, [loadPagination]);

    useEffect(() => {
        checkScrollable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeChatUser?.messages]);

    useEffect(() => {
        if (!nodeRefEnd.current) {
            return;
        }

        const currentRef = nodeRefEnd.current;

        const scrollEvent = () => {
            if (!currentRef) {
                return;
            }

            if (currentRef.scrollHeight !== currentRef.offsetHeight && currentRef.scrollTop === 0) {
                handleScrollEnd();
            }
        };

        currentRef?.addEventListener("scroll", scrollEvent);

        return () => {
            if (!currentRef) {
                return;
            }

            currentRef.removeEventListener("scroll", scrollEvent);
        };
    }, [handleScrollEnd]);

    return (
        <>
            <div className="w-100 d-flex align-content-stretch flex-column justify-content-between flex-fill">
                {activeChatUser ? (
                    <div
                        key={activeChatUser.id}
                        className="w-100 user-search-item-base user-chat-base-top"
                    >
                        <div className="user-search-item w-100">
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center text-center me-3 position-relative">
                                    <div
                                        className={`big-chat-status chat-user-state ${
                                            activeChatUser.connected ? "online" : "offline"
                                        }`}
                                    />
                                    {activeChatUser.uploads[0] ? (
                                        <img
                                            src={`${activeChatUser.uploads[0].url}`}
                                            alt="avatar"
                                            className="profileImage"
                                        />
                                    ) : (
                                        <div className="userCircle">
                                            {activeChatUser.name.charAt(0) +
                                                activeChatUser.surname.charAt(0)}
                                        </div>
                                    )}
                                </div>
                                <div className="d-none d-lg-flex flex-column w-100">
                                    {activeChatUser.full}
                                    <div className="chat-user-role">
                                        {activeChatUser.is_admin && (
                                            <small className="me-1">admin</small>
                                        )}
                                        {activeChatUser.is_coach && (
                                            <small className="me-1">coach</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div ref={nodeRefEnd} className="p-2 messages-chat-content">
                    <div className="messages-chat">
                        {loadPagination ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <FontAwesomeIcon icon={faSpinnerThird} spin />
                            </div>
                        ) : null}
                        {!activeChatUser?.messages ? (
                            <div className="noMessage-info d-flex justify-content-center">
                                {t(
                                    "chat.noMessage",
                                    "Noch keine Nachrichten mit {{name}} {{surname}} vorhanden.",
                                    {
                                        name: activeChatUser?.name,
                                        surname: activeChatUser?.surname,
                                    }
                                )}
                            </div>
                        ) : null}
                        {activeChatUser?.messages?.map((value, index) => (
                            <React.Fragment key={`m-${index}`}>
                                <Message
                                    index={index}
                                    userName={activeChatUser.full}
                                    message={value}
                                />
                            </React.Fragment>
                        ))}
                        <span className="chat-end-content">&nbsp;</span>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row w-100 align-content-center">
                <textarea
                    id="chatText"
                    className="form-control form-control-sm w-100"
                    onChange={(e) => {
                        setChatText(e.target.value);
                    }}
                    rows={1}
                    placeholder={t("chat.text", "Nachricht")}
                    required
                    value={chatText}
                    onKeyDown={(e) => handleCheckSubmit(e)}
                />
                <div className="d-flex justify-content-end">
                    <button
                        onClick={() => sendMessage()}
                        className={`btn btn-send ${!chatText ? "btn-noText" : ""}`}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        {t("chat.btn", "Senden")}
                    </button>
                </div>
            </div>
            <Connected />
        </>
    );
};

export default ChatMessagesList;
