/** @format */

import { exerciseAdapter } from "@api/ApiRequest";
import { ExerciseMuscle } from "@api/ext/ExerciseAdapter";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import AuthContext from "./AuthProvider";
import LoadingContext from "./LoadingProvider";

export interface Muscle {
    id: number;
    name: string;
    type?: number;
    active?: boolean;
}

export interface DataSection {
    id: number;
    name: string;
    active?: boolean;
}

export interface Description {
    id?: number | string;
    text: string;
}

const defaultValue: {
    pattern: DataSection[] | null;
    section: DataSection[] | null;
    muscle: Muscle[] | null;
    trainingsapparatus: DataSection[] | null;
    tag: DataSection[] | null;
    description: Description[] | null;
    setPattern: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
    setSection: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
    setMuscle: React.Dispatch<React.SetStateAction<Muscle[] | null>>;
    setTag: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
    setDescription: React.Dispatch<React.SetStateAction<Description[] | null>>;
    setTrainingsapparatus: React.Dispatch<React.SetStateAction<DataSection[] | null>>;
    loaded: boolean;
    save: () => void;
    post: boolean;
    name: string;
    secondname: string;
    setExerciseId: React.Dispatch<React.SetStateAction<number | null>>;
    setName: React.Dispatch<React.SetStateAction<string>>;
    setSecondname: React.Dispatch<React.SetStateAction<string>>;
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
    setVideo: React.Dispatch<React.SetStateAction<string>>;
    video: string;
    muscleGroup: MuscleGroupInterface;
    defaultImage: string | null;
    isEdit: boolean;
    selectableCompanies: OptionType[];
    selectedCompany: SelectedOption;
    setSelectedCompany: React.Dispatch<React.SetStateAction<OptionType | null>>;
} = {
    pattern: null,
    muscleGroup: null,
    description: null,
    section: null,
    muscle: null,
    setVideo: () => null,
    video: "",
    setExerciseId: () => null,
    setDescription: () => null,
    setPattern: () => null,
    setSection: () => null,
    setMuscle: () => null,
    loaded: false,
    tag: null,
    setTag: () => null,
    save: () => null,
    post: false,
    name: "",
    secondname: "",
    setName: () => null,
    setSecondname: () => null,
    setImage: () => null,
    defaultImage: null,
    isEdit: false,
    trainingsapparatus: null,
    setTrainingsapparatus: () => null,
    selectableCompanies: [],
    selectedCompany: null,
    setSelectedCompany: () => null,
};

const ExerciseContext = React.createContext(defaultValue);

interface ExerciseProviderProps {
    id?: number;
}

type MuscleGroupInterface =
    | (ExerciseMuscle &
          {
              exercisemuscles: number[];
          }[])
    | null;

const ExerciseProvider: React.FC<ExerciseProviderProps> = ({ children }) => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const { setSaved } = useContext(LoadingContext);
    const history = useHistory();

    const [pattern, setPattern] = useState<DataSection[] | null>(null);
    const [section, setSection] = useState<DataSection[] | null>(null);

    const [muscle, setMuscle] = useState<Muscle[] | null>(null);
    const [muscleGroup, setMuscleGroup] = useState<MuscleGroupInterface>(null);

    const [tag, setTag] = useState<DataSection[] | null>(null);
    const [trainingsapparatus, setTrainingsapparatus] = useState<DataSection[] | null>(null);

    const [description, setDescription] = useState<Description[] | null>(null);

    const [video, setVideo] = useState<string>("");

    const [name, setName] = useState<string>("");

    const [secondname, setSecondname] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);
    const [defaultImage, setDefaultImage] = useState<string | null>(null);

    const [loaded, setLoaded] = useState<boolean>(false);

    const [post, setPost] = useState<boolean>(false);

    const [id, setExerciseId] = useState<number | null>(null);

    const [selectableCompanies, setSelectableCompanies] = useState<OptionType[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const mapValues = (data: string[], filter: number[]) => {
        const defaultValue: DataSection[] = [];

        Object.keys(data).map((value) => {
            return defaultValue.push({
                id: Number(value),
                name: data[Number(value)],
                active: filter ? !!filter.includes(Number(value)) : false,
            });
        });

        return defaultValue;
    };

    const mapMuscleValues = (
        data: { [key: number]: string },
        filter: { id: number; _joinData: { type: number } }[]
    ): Muscle[] => {
        const defaultValue: Muscle[] = [];

        Object.keys(data).map((value) => {
            let checkInner = false;
            if (filter) {
                filter.map((innerValue) => {
                    if (innerValue.id === Number(value)) {
                        checkInner = true;
                        return defaultValue.push({
                            id: Number(value),
                            type: innerValue._joinData.type,
                            name: data[Number(value)],
                            active: true,
                        });
                    }
                });
            }
            if (!checkInner) {
                return defaultValue.push({
                    id: Number(value),
                    type: 0,
                    name: data[Number(value)],
                    active: false,
                });
            }
        });

        return defaultValue;
    };

    const loadInitialData = useCallback(() => {
        setLoaded(false);

        exerciseAdapter
            .getExercise(id)
            .then((data) => {
                setMuscleGroup(data.exercisemuscleList);

                setSection(
                    mapValues(data.exercisesection, id ? data.exercise.exercisesectionsFilter : [])
                );
                setPattern(
                    mapValues(data.exercisefilter, id ? data.exercise.exercisefiltersFilter : [])
                );

                setMuscle(
                    mapMuscleValues(
                        data.exercisemuscle,
                        data.exercise.exercisemuscles ? data.exercise.exercisemuscles : []
                    )
                );

                setTag(mapValues(data.exercisetag, id ? data.exercise.exercisetagsFilter : []));

                setTrainingsapparatus(
                    mapValues(
                        data.trainingsapparatus,
                        id ? data.exercise.trainingsapparatusFilter : []
                    )
                );

                setDescription(id ? data.exercise.exercisedescriptions : data.exercisedescription);

                if (data.companies) {
                    const updateCompaniesSelect: { value: string; label: string }[] = [];
                    Object.keys(data.companies).map((key) => {
                        updateCompaniesSelect.push({
                            value: key,
                            label: data.companies[Number(key)],
                        });
                    });
                    setSelectableCompanies(updateCompaniesSelect);

                    const defaultCompany = updateCompaniesSelect.find(
                        (ele) => Number(ele.value) === data.exercise.company_id
                    );

                    setSelectedCompany(defaultCompany ? defaultCompany : updateCompaniesSelect[0]);
                }

                if (id) {
                    setName(data.exercise.name);
                    setVideo(data.exercise.video);
                    setSecondname(data.exercise.secondname);

                    if (data.exercise?.uploads?.[0] && process.env.REACT_APP_BACKEND_URL) {
                        const upload = data.exercise?.uploads?.[0];

                        setDefaultImage(
                            `${process.env.REACT_APP_BACKEND_URL}/core/uploads/getfile/${upload.id}`
                        );
                    }
                } else {
                    setName("");
                    setVideo("");
                    setSecondname("");

                    setDefaultImage(null);
                }

                setTimeout(() => setLoaded(true), 1000);
            })
            .catch((error) => {
                console.log("its a error");
                console.log(error);
            });
    }, [id]);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    const save = useCallback(() => {
        if (!name || !user) {
            return;
        }

        setPost(true);

        const getActive = (value: DataSection[]): number[] => {
            const getIds: number[] = [];

            value.map((value) => {
                if (value.active) {
                    getIds.push(value.id);
                }
            });

            return getIds;
        };

        const formAppend = (name: string, data: number[], formData: FormData): void => {
            if (data.length > 0) {
                data.map((value) => {
                    formData.append(name, String(value));
                });
            } else {
                formData.append(name, "");
            }
        };

        const formData = new FormData();

        formData.append("name", name);
        formData.append("secondname", secondname);

        const usersCompany = id
            ? undefined
            : user.is_admin && selectedCompany
            ? String(selectedCompany.value)
            : "";

        if (usersCompany) {
            formData.append("company_id", usersCompany);
        }

        formData.append("video", video);
        formData.append("type", String(1));

        if (pattern) {
            formAppend("exercisefilters[_ids][]", getActive(pattern), formData);
        }

        if (section) {
            formAppend("exercisesections[_ids][]", getActive(section), formData);
        }

        let activeMuscle = false;
        if (muscle) {
            muscle.map((value, index) => {
                if (value.active) {
                    activeMuscle = true;
                    formData.append(`exercisemuscles[${index}][id]`, String(value.id));
                    formData.append(
                        `exercisemuscles[${index}][_joinData][type]`,
                        String(value.type)
                    );
                }
            });
        }

        if (!activeMuscle) {
            formData.append("exercisemuscles[_ids][]", "");
        }

        if (tag) {
            formAppend("exercisetags[_ids][]", getActive(tag), formData);
        }

        if (trainingsapparatus) {
            formAppend("trainingsapparatuses[_ids][]", getActive(trainingsapparatus), formData);
        }

        if (description) {
            description.map((value, index) => {
                formData.append(`exercisedescriptions[${index}][text]`, value.text);
                if (value.id && !isNaN(Number(value.id))) {
                    formData.append(`exercisedescriptions[${index}][id]`, String(value.id));
                }
            });
        }

        if (image) {
            formData.append("uploads[file]", image);
        }

        const url = id ? `edit/${id}` : "add";

        exerciseAdapter
            .addExercise(formData, url)
            .then((response) => {
                setPost(false);

                if (response) {
                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("exercise.saveSuccess", "Übung gespeichert."),
                    });

                    return history.push("/collection");
                }
            })
            .catch((error) => {
                setSaved({
                    type: "failed",
                    show: true,
                    text: t("exercise.saveFail", "Übung konnte nicht gespeichert werden."),
                });
                console.log(error);
            });
    }, [
        history,
        t,
        setSaved,
        image,
        id,
        tag,
        description,
        muscle,
        section,
        pattern,
        name,
        secondname,
        video,
        trainingsapparatus,
        selectedCompany,
        user,
    ]);

    const providerValue = {
        pattern,
        section,
        setPattern,
        setSection,
        loaded,
        muscle,
        setMuscle,
        description,
        setDescription,
        save,
        setExerciseId,
        post,
        secondname,
        name,
        setName,
        setSecondname,
        setImage,
        defaultImage,
        muscleGroup,
        isEdit: !!id,
        tag,
        setTag,
        video,
        setVideo,
        trainingsapparatus,
        setTrainingsapparatus,
        selectableCompanies,
        selectedCompany,
        setSelectedCompany,
    };

    return <ExerciseContext.Provider value={providerValue}>{children}</ExerciseContext.Provider>;
};

export { ExerciseProvider };
export default ExerciseContext;
