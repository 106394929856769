/** @format */

import { TrainingsessionModel } from "@api/ext/TrainingsessionsAdapter";
import { faDumbbell, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Footer from "./Footer/Footer";

interface TrainingsessionProps {
    trainingsession: TrainingsessionModel;
}

const Trainingsession: React.FC<TrainingsessionProps> = ({ trainingsession }) => {
    const [t] = useTranslation();

    const [notComplete] = useState<boolean>(() => {
        let complete = false;

        trainingsession.trainingsessions_exercises.map((value) => {
            if (!value.trainingsessions_sets.length) {
                complete = true;
            }
        });

        return complete;
    });

    if (!trainingsession.user || !process.env.REACT_APP_BACKEND_URL) {
        return null;
    }

    return (
        <div className="col-12 trainingsession my-3">
            <div className="row">
                <div className="col-12">
                    <div className="trainingsession-header">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                {trainingsession.user.uploads && trainingsession.user.uploads[0] ? (
                                    <div className="d-flex justify-content-center text-center">
                                        <img
                                            src={`${process.env.REACT_APP_BACKEND_URL}core/uploads/getfile/${trainingsession.user.uploads[0].id}`}
                                            alt=""
                                            className="trainingsession-user-image"
                                        />
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center text-center">
                                        <div className="userCircle trainingsession-user-image">
                                            {trainingsession.user.name.charAt(0) +
                                                trainingsession.user.surname.charAt(0)}
                                        </div>
                                    </div>
                                )}
                                <div className="trainingsession-header-name">
                                    <h4>
                                        {trainingsession.workoutschedule
                                            ? trainingsession.workoutschedule.name
                                            : t(
                                                  "trainingSessions.deletedSession",
                                                  "-- Trainingsplan wurde gelöscht --"
                                              )}
                                    </h4>
                                    <Link to={"/users/view/" + String(trainingsession.user.id)}>
                                        <h6>
                                            {trainingsession.user.name}{" "}
                                            {trainingsession.user.surname}
                                        </h6>
                                    </Link>
                                </div>
                            </div>

                            <div className="trainingsession-date">
                                <div>
                                    {DateTime.fromISO(String(trainingsession.end), {
                                        setZone: true,
                                    }).toFormat("dd LLL. yyyy HH:mm")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="trainingsession-body">
                        <div className="d-flex justify-content-between">
                            <div className="training-date d-flex">
                                <div>{t("trainingSessions.started", "Gestartet")}:</div>
                                {DateTime.fromISO(String(trainingsession.start), {
                                    setZone: true,
                                }).toFormat("dd LLL. yyyy HH:mm")}
                            </div>
                            <div className="training-date d-flex">
                                <div>{t("trainingSessions.ended", "Beendet")}: </div>
                                {DateTime.fromISO(String(trainingsession.end), {
                                    setZone: true,
                                }).toFormat("dd LLL. yyyy HH:mm")}
                            </div>
                            <div className="training-date d-flex">
                                <div>{t("trainingSessions.duration", "Dauer")}: </div>
                                {DateTime.fromISO(String(trainingsession.end))
                                    .diff(DateTime.fromISO(String(trainingsession.start)))
                                    .toFormat("mm:ss")}{" "}
                                {t("global.minShort", "min.")}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-12 d-flex align-items-center justify-content-between ">
                                <div className="d-flex align-items-center ">
                                    <div className="trainingsesion-icon">
                                        <FontAwesomeIcon icon={faDumbbell} />
                                    </div>

                                    <h4 className="tbody-head">
                                        {t(
                                            "trainingSessions.finishedExercises",
                                            "Abgeschlossene Übungen"
                                        )}
                                    </h4>
                                </div>
                                <div className="complete-icon">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </div>
                            <div className="exercise-set-group">
                                {trainingsession.trainingsessions_exercises.map((value, index) => (
                                    <React.Fragment key={`${value.id}-${index}`}>
                                        {value.trainingsessions_sets.length ? (
                                            <div className="col-12 d-flex" key={value.id}>
                                                <div className="set-name me-2">
                                                    {value.trainingsessions_sets.length}x
                                                </div>
                                                <div className="set-name-second">
                                                    {value.exercise && value.exercise.name
                                                        ? value.exercise.name
                                                        : t(
                                                              "trainingSessions.deletedExercise",
                                                              "- Übung gelöscht -"
                                                          )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        {notComplete ? (
                            <div className="row">
                                <div className="col-12 mt-4 mb-3">
                                    <div className="notcomplete-exercise" />
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-between ">
                                    <div className="d-flex align-items-center ">
                                        <div className="trainingsesion-icon">
                                            <FontAwesomeIcon icon={faDumbbell} />
                                        </div>

                                        <h4 className="tbody-head">
                                            {t(
                                                "trainingSessions.incompletedExercises",
                                                "Unvollständige Übungen"
                                            )}
                                        </h4>
                                    </div>
                                    <div className="notcomplete-icon">
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                                <div className="exercise-set-group">
                                    {trainingsession.trainingsessions_exercises.map(
                                        (value, index) => (
                                            <React.Fragment key={`${value.id}-${index}`}>
                                                {!value.trainingsessions_sets.length ? (
                                                    <div className="col-12 d-flex" key={value.id}>
                                                        <div className="set-name-second">
                                                            {value.exercise && value.exercise.name
                                                                ? value.exercise.name
                                                                : t(
                                                                      "trainingSessions.deletedExercise",
                                                                      "- Übung gelöscht -"
                                                                  )}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {trainingsession.trainingsessionfeedbacks.length ? (
                    <Footer trainingsession={trainingsession} />
                ) : null}
            </div>
        </div>
    );
};

export default Trainingsession;
