/** @format */

import React from "react";

interface SelectStateMuscleProps {
    handleClick: (id: number, type: number) => void;
    id: number;
    stateMuscle: number;
}

const SelectStateMuscle: React.FC<SelectStateMuscleProps> = ({ handleClick, id, stateMuscle }) => {
    return (
        <div className="muscle-select-item">
            <div role="group" className="btn-group btn-group-sm w-100">
                <button
                    onClick={() => handleClick(id, 1)}
                    className={`select-btn-active btn btn-save ${
                        stateMuscle === 1 ? "section-active" : ""
                    }`}
                >
                    Aktiv
                </button>
                <button
                    onClick={() => handleClick(id, 2)}
                    className={`select-btn-active btn btn-save ${
                        stateMuscle === 2 ? "section-active" : ""
                    }`}
                >
                    Passiv
                </button>
            </div>
        </div>
    );
};

export default SelectStateMuscle;
