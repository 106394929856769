/**
 * /* eslint react/no-array-index-key: 0
 *
 * @format
 */

import { exerciseAdapter, workoutschedulesSetsAdapter } from "@api/ApiRequest";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debugLib from "debug";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ExerciseListDefaultSet } from "../../ExerciseWrapper";
import SelectType from "../../SelectType/SelectType";
import TypeWrapper from "../../Type/TypeWrapper";
import Modal from "../WorkoutschedulesModalView";

const debug = debugLib("machbar:workout:chose:workoutschedule:modal");

interface ExerciseModalProps {
    exercisesData: ExerciseListDefaultSet;
    index: number;
    show: boolean;
    selected: ExerciseListDefaultSet[];
    setSelected: React.Dispatch<React.SetStateAction<ExerciseListDefaultSet[]>>;
    handleModalClose: () => void;
}

const ExerciseModal: React.FC<ExerciseModalProps> = ({
    exercisesData,
    index,
    show,
    handleModalClose,
    selected,
    setSelected,
}) => {
    const [t] = useTranslation();
    const [type, setType] = useState<number | null>(() => {
        if (!exercisesData.id && exercisesData.new) {
            return null;
        }

        return exercisesData.primariepropertie ? exercisesData.primariepropertie : null;
    });
    const [secondType, setSecondType] = useState<number | null>(() => {
        if (!exercisesData.id && exercisesData.new) {
            return null;
        }

        return exercisesData.secondariepropertie ? exercisesData.secondariepropertie : null;
    });

    const [name, setName] = useState<string>("");
    const [secondname, setSecondname] = useState<string>("");

    const [option, setOption] = useState<string>(() => {
        if (!exercisesData.id && exercisesData.new) {
            return "";
        }

        return exercisesData.option && exercisesData.option !== "" ? exercisesData.option : "";
    });

    const [finishType, setFinishType] = useState<boolean>(() => {
        if (exercisesData.id && !exercisesData.new) {
            return true;
        }

        return false;
    });

    useEffect(() => {
        if (type || secondType) {
            handleChangeType(index, type ? type : 0, secondType ? secondType : 0, option);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, secondType, option]);

    useEffect(() => {
        const { id } = exercisesData;

        exerciseAdapter
            .getNameView(id)
            .then((exercise) => {
                setName(exercise.name);
                setSecondname(exercise.secondname);
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSelectedValue = (id: number, data: { wdh: string; value: string }) => {
        const selectedValue = [...selected];
        const single = selectedValue[id];

        single.data.push(data);

        selectedValue[id] = single;

        setSelected(selectedValue);
    };

    const handleChangeType = (
        index: number,
        primary: number,
        secondary: number,
        option: string
    ) => {
        const changeType = [...selected];

        changeType[index] = {
            ...changeType[index],
            primariepropertie: primary,
            secondariepropertie: secondary,
            new: false,
            option,
        };

        setSelected(changeType);
    };

    const handleRemoveSet = (index: number, id: number) => {
        const removed: ExerciseListDefaultSet[] = [...selected];

        const single = removed[id];

        const { 0: removedSet } = single.data.splice(index, 1);

        if (removedSet.id) {
            workoutschedulesSetsAdapter
                .delete(removedSet.id)
                .then((response) => {
                    debug("handleRemoveSet: delete ExercisesWorkoutschedulesSet", response);
                })
                .catch((error) => {
                    debug("handleRemoveSet: delete failed ExercisesWorkoutschedulesSet", error);
                });
        }

        removed[id] = single;

        debug("handleRemoveSet: remove setitem from list", index, id, single, removedSet);

        return setSelected(removed);
    };

    const getFormatData = (indexValue: number, wdh: string, value: string) => {
        return (
            <>
                Satz {indexValue + 1} - {value} | {wdh}
            </>
        );
    };

    const getModalData = () => {
        return exercisesData.data.map((data, i) => (
            <div key={i} className="col-12 d-flex align-items-start mb-2">
                <small>
                    <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="icon icon-trash-modal"
                        onClick={() => handleRemoveSet(i, index)}
                    />
                    {getFormatData(i, String(data.wdh), String(data.value))}
                </small>
            </div>
        ));
    };

    const handleSubmit = (data: { wdh: string; value: string }) => {
        setSelectedValue(index, data);
    };

    return (
        <Modal show={show} onHide={handleModalClose} size="lg">
            <div className="modal-header">
                <div className="row w-100">
                    <div className="col-12 d-flex justify-conten-space-betwenn align-items-center pe-0">
                        <h5 className="font-weight-light mb-0">
                            {name}
                            <small>{secondname}</small>
                        </h5>

                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                </div>
            </div>
            <div className="modal-body">
                {finishType ? (
                    <>
                        <TypeWrapper
                            handleSubmit={handleSubmit}
                            type={type ? type : 0}
                            secondType={secondType ? secondType : 0}
                            option={option}
                        />
                        <div className="set-wrapper-min">
                            <div className="row">{getModalData()}</div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row mt-2">
                            <div className="col">
                                <h6 className="font-weight-lighter">
                                    {t("workoutschedules.selectType", "Typ bestimmen")}
                                </h6>
                            </div>
                        </div>
                        <SelectType
                            type={type ? type : 0}
                            setType={setType}
                            setOption={setOption}
                        />

                        {type ? (
                            <>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h6 className="font-weight-lighter">x</h6>
                                    </div>
                                </div>
                                <SelectType
                                    type={secondType ? secondType : 0}
                                    setType={setSecondType}
                                    hiddenType={type}
                                    setOption={setOption}
                                />
                            </>
                        ) : null}

                        <div className="row mt-3">
                            <div className="col w-100 justify-content-end d-flex">
                                {type ? (
                                    <button
                                        className="btn btn-sm btn-save"
                                        onClick={() => setFinishType(true)}
                                    >
                                        {t("global.continue", "Weiter")}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ExerciseModal;
