/** @format */

import { onboardingAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context/LoadingProvider";
import { useLoading } from "@hook/useLoading";
import CenteredLayout from "@layouts/Unauthenticated/CenteredLayout";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const ForgotPassword: React.FC = () => {
    const [t] = useTranslation();
    const { setSaved } = useContext(LoadingContext);
    const { setLoading } = useLoading();

    const [email, setEmail] = useState("");
    const [send, setSend] = useState(false);

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setEmail(value);
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (!email) {
            return;
        }

        setLoading(true);

        onboardingAdapter
            .resetPassword(email)
            .then((response) => {
                if (response) {
                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("forgot.password.done.text", "Eine E-Mail ist zu Ihnen unterwegs."),
                    });

                    setLoading(false);
                    setSend(true);

                    return;
                }

                setSaved({
                    type: "failed",
                    show: true,
                    text: t(
                        "forgot.password.error.text",
                        "Für dieses E-Mail wurde kein Konto gefunden."
                    ),
                });

                setLoading(false);
            })
            .catch((e) => {
                setSaved({
                    type: "failed",
                    show: true,
                    text: t(
                        "forgot.password.error.text",
                        "Für dieses E-Mail wurde kein Konto gefunden."
                    ),
                });

                setLoading(false);
                console.log(e);
            });
    };

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    if (send) {
        return (
            <CenteredLayout>
                <div className="col-lg-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                    <div className="col-12 d-flex justify-content-center">
                        <p className="col-md-6 col-12 font-weight-light mt-3">
                            <Trans i18nKey="forgot.password.send.text">
                                Wir haben eine E-Mail an <strong>{{ email }}</strong> gesendet.
                                Bitte überprüfe Dein E-Mail-Postfach und folge dem Link in der
                                E-Mail.
                            </Trans>
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="pt-4 d-flex col justify-content-center">
                            <a href="/" className="btn btn-save">
                                {t("reset.password.button.login", "Zum Login")}
                            </a>
                        </div>
                    </div>
                </div>
            </CenteredLayout>
        );
    }

    return (
        <CenteredLayout>
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-10 col-sm-10 col-10 mx-auto">
                    <div className="row">
                        <div className="col-12 ">
                            <h2 className="font-weight-light text-center mb-3">
                                {t("forgot.password.title", "Kennwort vergessen")}?
                            </h2>
                            <p>
                                {t(
                                    "forgot.password.description",
                                    "Gib Deine E-Mail-Adresse ein und wir senden Dir einen Link zum zurücksetzen Deines Kennwortes"
                                )}
                                :
                            </p>
                        </div>

                        <div className="col-12">
                            <InputLabel required>{t("global.email", "Email Adresse")}</InputLabel>
                            <input
                                className="form-control"
                                id="email"
                                type="email"
                                placeholder={t(
                                    "forgot.password.form.placeholder.email",
                                    "E-Mail Adresse"
                                )}
                                onChange={handleEmailChange}
                                value={email}
                                autoComplete="email"
                            />
                        </div>
                        <div className="col-12 d-grid mt-4">
                            <button
                                disabled={!email}
                                type="submit"
                                className={`btn ${!email ? "btn-disabled" : "btn-save"}`}
                            >
                                {t("forgot.password.button.submit", "E-Mail senden")}
                            </button>
                        </div>
                        <div className="col-12">
                            <div className="pt-4 d-flex col justify-content-end">
                                <a href="/">
                                    {t("forgot.password.button.login", "Zurück zum Login")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </CenteredLayout>
    );
};

export default ForgotPassword;
