/** @format */

import {
    extendedUserAdapter,
    mandateAdapter,
    subscriptionAdapter,
    subscriptiontypeAdapter,
} from "@api/ApiRequest";
import { Mandate, PaymentMethod } from "@api/ext/MandateAdapter";
import { SeatChangeCost, SubscriptionInfo, SubscriptionStatus } from "@api/ext/SubscriptionAdapter";
import { SubscriptionType, SubscriptionTypeOption } from "@api/ext/SubscriptiontypeAdapter";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import AuthContext from "./AuthProvider";
import LoadingContext from "./LoadingProvider";

type SubscriptionContextType = ReturnType<typeof useMakeSubscriptionContext>;

const singlePrice = 9;

const SubscriptionContext = React.createContext<SubscriptionContextType | null>(null);

export const useSubscriptionContext = (): SubscriptionContextType => {
    const ctx = useContext(SubscriptionContext);

    if (ctx === null) {
        throw new Error("SubscriptionContextProvider missing in parent tree");
    }

    return ctx;
};

let timer: ReturnType<typeof setTimeout>;

export const SubscriptionContextProvider: React.FC = ({ children }) => {
    const { subscription } = useContext(AuthContext);
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        if (subscription) {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    if (!subscription) {
        return null;
    }

    return (
        <RenderSubscriptionContextProvider subscription={subscription}>
            {children}
        </RenderSubscriptionContextProvider>
    );
};

const getVatPercent = (vat: number) => vat / 100;

const useMakeSubscriptionContext = (subscription: SubscriptionInfo) => {
    const history = useHistory();
    const { refetchSubscription, setSubscription } = useContext(AuthContext);
    const [bundle, _setBundle] = useState<SubscriptionType | null>(null);
    const [option, _setOption] = useState<SubscriptionTypeOption | null>(() => {
        if (
            !subscription.mollie_db_subscription.subscriptiontypeoption ||
            subscription.mollie_db_subscription.subscriptiontypeoption.base_price <= 0
        ) {
            return null;
        }

        return subscription.mollie_db_subscription.subscriptiontypeoption;
    });
    const [seats, _setSeats] = useState<number>(1);

    const [tax, setTax] = useState<number | string>(0);
    const [price, setPrice] = useState<number | string>(0);
    const [method, setMethod] = useState<PaymentMethod | null>(null);
    const [sepaName, setSepaName] = useState<string>();
    const [iban, setIban] = useState<string>();
    const [bic, setBic] = useState<string>();
    const [redirecting, setRedirecting] = useState(false);
    const [changeSeatCost, setChangeSeatCost] = useState<SeatChangeCost | null>(null);
    const [changeSeatCostLoading, setChangeSeatCostLoading] = useState(true);
    const [userCount, setUserCount] = useState<number | null>(null);
    const [subscriptiontypes, setSubscriptiontypes] = useState<SubscriptionType[] | null>(null);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [changed, setChanged] = useState(subscription.status === SubscriptionStatus.TRIAL);

    const [selectedMandate, setSelectedMandate] = useState<Mandate | null>(null);
    const [mandates, setMandates] = useState<Mandate[] | null>(null);

    /* To Do: get client groups from server */
    const [clientGroupIds] = useState<number[]>([1, 14]);

    const setOption = useCallback((subTypeOption: SubscriptionTypeOption) => {
        _setOption(subTypeOption);
    }, []);

    const setBundle = useCallback((subType: SubscriptionType) => {
        _setBundle(subType);
    }, []);

    const setSeats = useCallback(
        (value: number | ((value: number) => number)) => {
            if (!option) {
                _setSeats(value);
                return;
            }

            if (option.count_base_seats && value < option.count_base_seats) {
                _setSeats(option.count_base_seats);
                return;
            }

            if (option.count_max_seats && value > option.count_max_seats) {
                _setSeats(option.count_max_seats);
                return;
            }

            _setSeats(value);
        },
        [option]
    );

    const updateUserCount = useCallback(() => {
        void extendedUserAdapter.getClientCount().then((response) => setUserCount(response));
    }, []);

    useEffect(() => {
        if (!option?.id) {
            return;
        }

        clearTimeout(timer);

        setChangeSeatCostLoading(true);

        timer = setTimeout(() => {
            void subscriptionAdapter
                .subscriptionChangeCost(Math.max(seats, 1), option.id)
                .then((response) => {
                    setChangeSeatCost(response);
                    setChangeSeatCostLoading(false);

                    setPrice(response.month.toFixed(2));
                    setTax((response.month * getVatPercent(option.vat)).toFixed(2));
                });
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [seats, option]);

    useEffect(() => {
        setChanged(
            subscription.subscriptiontypeoption_id !== option?.id ||
                subscription.seat_count !== seats ||
                selectedMandate?.id !== subscription.client_subscription?.mandate?.id ||
                subscription.status === SubscriptionStatus.TRIAL
        );
    }, [setChanged, seats, subscription, option, selectedMandate?.id]);

    useEffect(() => {
        updateUserCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        void subscriptiontypeAdapter.getSubscriptionTypes(userCount).then((response) => {
            setSubscriptiontypes(response);
            setIsLoadingData(false);
        });
    }, [userCount]);

    useEffect(() => {
        _setSeats(() => {
            const baseSeats = option?.count_base_seats ?? 1;
            const optionIsTrial = option?.base_price && option?.base_price <= 0;

            if (subscription.status !== SubscriptionStatus.TRIAL) {
                return Math.max(Math.max(baseSeats, 1), subscription.seat_count);
            }

            return Math.max(Math.max(userCount ?? 0, 1), optionIsTrial ? 1 : baseSeats);
        });
    }, [userCount, option, subscription]);

    useEffect(() => {
        if (
            !subscriptiontypes ||
            !subscription.mollie_db_subscription?.subscriptiontypeoption?.subscriptiontype
        ) {
            return;
        }
        const currType =
            subscription.mollie_db_subscription.subscriptiontypeoption.subscriptiontype;
        const currOptions = subscriptiontypes.find((elem) => elem.id === currType.id) ?? null;

        if (!currOptions) {
            return;
        }

        _setBundle(currOptions);
    }, [subscriptiontypes, subscription]);

    useEffect(() => {
        setSelectedMandate(subscription?.client_subscription?.mandate ?? null);
    }, [subscription]);

    useEffect(() => {
        fetchMandates();

        return () => {
            setSubscription(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMandates = useCallback(() => {
        void mandateAdapter.getMandates().then((res) => {
            setMandates(res);
        });
    }, []);

    const onRemoveMandate = useCallback((id: string) => {
        void mandateAdapter.removeMandate(id).then((res) => setMandates(res));
    }, []);

    const onChangeMandate = useCallback(
        async (mandate: Mandate) => {
            if (!subscription) {
                return;
            }

            await subscriptionAdapter.changeSubscriptionMandate(mandate.id).then((status) => {
                if (status) {
                    refetchSubscription();
                }
            });
            // @to-do: handle error and status
        },
        [subscription, refetchSubscription]
    );

    const onSubscriptionChange = useCallback(async () => {
        if (!option) {
            return;
        }

        await subscriptionAdapter.changeSubscription(
            Math.max(seats, Math.max(option?.count_base_seats ?? 1)),
            option.id,
            selectedMandate?.id ?? undefined
        );

        refetchSubscription();

        history.push("/profile/payment");
    }, [seats, refetchSubscription, selectedMandate, option, history]);

    const onSubmitMethod = useCallback(
        async (reactivate = false, redirect?: string) => {
            if (!method || !option?.id) {
                return;
            }

            if (method === PaymentMethod.DIRECTDEBIT) {
                if (!sepaName || !iban) {
                    return;
                }

                await mandateAdapter.addSepaMandate(
                    {
                        name: sepaName,
                        iban,
                        bic,
                    },
                    seats,
                    option.id,
                    reactivate,
                    selectedMandate?.id ?? undefined
                );

                history.push(redirect ? `/${redirect}` : "done");
            } else {
                const url = new URL(window.location.href);
                url.pathname = redirect ?? "profile/subscription/done";

                const checkout = await mandateAdapter.addMandate(
                    method,
                    url.href,
                    seats,
                    option.id,
                    reactivate,
                    true,
                    selectedMandate?.id ?? undefined
                );

                setRedirecting(true);

                window.location.replace(checkout);
            }

            fetchMandates();
        },
        [method, sepaName, iban, bic, history, option, seats, selectedMandate, fetchMandates]
    );

    return {
        singlePrice,

        bundle,
        setBundle,

        option,
        setOption,

        seats,
        setSeats,

        price,
        setPrice,

        tax,

        method,
        setMethod,

        sepaName,
        setSepaName,

        iban,
        setIban,

        bic,
        setBic,

        redirecting,

        onSubmitMethod,
        onSubscriptionChange,

        changeSeatCost,
        changeSeatCostLoading,

        subscription,
        updateUserCount,
        userCount,
        subscriptiontypes,

        changed,

        selectedMandate,
        setSelectedMandate,

        mandates,
        onRemoveMandate,
        onChangeMandate,
        fetchMandates,

        clientGroupIds,
        isLoadingData,
    };
};

const RenderSubscriptionContextProvider: React.FC<{
    subscription: SubscriptionInfo;
}> = ({ subscription, children }) => {
    const providerValue = useMakeSubscriptionContext(subscription);

    return (
        <SubscriptionContext.Provider value={providerValue}>
            {children}
        </SubscriptionContext.Provider>
    );
};
