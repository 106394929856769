/** @format */

import { PaymentMethod } from "@api/ext/MandateAdapter";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React from "react";
import { useTranslation } from "react-i18next";

interface PaypalProps {
    active: boolean;
}

const Paypal: React.FC<PaypalProps> = ({ active }) => {
    const [t] = useTranslation();
    const { setMethod } = useSubscriptionContext();

    return (
        <div
            className={`col-12 col-md-4 method-wrapper ${active ? "active" : ""}`}
            onClick={() => setMethod(PaymentMethod.PAYPAL)}
        >
            <div className="wrapper-inner">
                <div className="method-header">
                    {t("subscription.payment.method.paypal", "Paypal")}
                </div>
                <div className="method-body">
                    <img src="/img/paypal.svg" className="img img-responsive payment-icon" alt="" />
                </div>
                <div className="method-footer">Wählen</div>
            </div>
        </div>
    );
};

export default Paypal;
