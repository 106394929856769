/** @format */

import { muscelesgroupAdapter } from "@api/ApiRequest";
import { MuscelesgroupModel } from "@api/ext/MuscelesgroupAdapter";
import TableWithPagination from "@components/Table/TableWithPagination";
import { faEdit, faEllipsisH, faInfoSquare, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

const MuscelesgroupsIndex: React.FC = () => {
    const [t] = useTranslation();
    const { setLoading } = useLoading();

    const columns = useMemo(
        () => [
            {
                Header: t("muscelesgroup.name", "Muskelgruppe"),
                accessor: "name",
                className: "w-75",
            },
            {
                Header: t("muscelesgroup.created", "Erstellt"),
                accessor: "lastlogin",
                Cell: ({ row }: CellProps<MuscelesgroupModel>) => {
                    const created: string = row.original.created
                        ? row.original.created.toString()
                        : "";

                    return (
                        <div className="flex justify-start w-100">
                            {created === ""
                                ? "-"
                                : DateTime.fromISO(created).toFormat("dd.MM.yyyy")}
                        </div>
                    );
                },
            },
            {
                id: "options",
                Header: "",
                Cell: ({ row }: CellProps<MuscelesgroupModel>) => {
                    const dropdown = useRef<HTMLDivElement>(null);

                    const [dropdownView, setDorpdownView] = useState(false);

                    return (
                        <div className="dropdown d-flex justify-content-end" ref={dropdown}>
                            <div
                                className="btn-sm option-button"
                                role="button"
                                id={`dMenue-${row.original.id}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => setDorpdownView(!!dropdownView)}
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </div>

                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li>
                                    <Link
                                        className="dropdown-item option-dropdown"
                                        to={`/muscelesgroups/view/${row.original.id}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoSquare}
                                            className="option-icon"
                                        />
                                        {t("global.view", "Ansicht")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="dropdown-item option-dropdown"
                                        to={`/muscelesgroups/edit/${row.original.id}`}
                                    >
                                        <FontAwesomeIcon icon={faEdit} className="option-icon" />
                                        {t("global.edit", "Bearbeiten")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [t]
    );

    const [muscelesgroups, setMuscelesgroups] = useState<MuscelesgroupModel[]>([]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        muscelesgroupAdapter
            .getIndex()
            .then((data) => {
                setMuscelesgroups(data);
                setIsLoaded(true);
                setLoading(false);
            })
            .catch((e) => console.log(e));
    }, [setLoading]);

    return (
        <>
            <div className="row">
                <div className="col-6 ">
                    <h1>{t("global.muscelesgroups", "Muskelgruppen")}</h1>
                </div>
                <div className="col-6 pull-right">
                    <div className="d-flex col justify-content-end">
                        <Link to="/muscelesgroups/add" className="btn btn-default align-self-end">
                            <FontAwesomeIcon icon={faPlus} /> {t("global.add", "Hinzufügen")}
                        </Link>
                    </div>
                </div>
            </div>
            {!isLoaded ? (
                <TableWithPagination columns={columns} data={[]} />
            ) : (
                <TableWithPagination columns={columns} data={muscelesgroups} />
            )}
        </>
    );
};

export default MuscelesgroupsIndex;
