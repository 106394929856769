/** @format */

import { ExtendedUserModel } from "@api/ext/ExtendedUserAdapter";
import { WorkoutschedulesModel } from "@api/ext/WorkoutschedulesAdapter";
import UsersTable from "@components/Table/UsersTable/UsersTable";
import React, { useEffect, useState } from "react";

interface AssigneUsersProps {
    workoutworkflows: WorkoutschedulesModel;
}

const AssignedUsers: React.FC<AssigneUsersProps> = ({ workoutworkflows }) => {
    const [users, setUsers] = useState<ExtendedUserModel[]>([]);

    useEffect(() => {
        const schedules = workoutworkflows.self_workoutschedules;
        const tempUsers: ExtendedUserModel[] = [];

        schedules?.forEach((ele) => {
            if (ele.users && ele.users[0]) {
                tempUsers.push(ele.users[0] as ExtendedUserModel);
            }
        });

        setUsers(tempUsers);
    }, [workoutworkflows.self_workoutschedules]);

    return (
        <div className="col-12">
            <UsersTable data={users} hideColumns={["lastlogin", "archived", "company", "coach"]} />
        </div>
    );
};

export default AssignedUsers;
