/** @format */

import { exerciseAdapter } from "@api/ApiRequest";
import { ExerciseModel } from "@api/ext/ExerciseAdapter";
import { faSpinnerThird, faImage } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../WorkoutschedulesModalView";

import "./ExerciseSingleView.scss";

interface ExerciseViewModalProps {
    id: number | string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
}

const ExerciseViewModal: React.FC<ExerciseViewModalProps> = ({ id, setShow, show }) => {
    const [t] = useTranslation();
    const [loaded, setLoaded] = useState<boolean>(false);

    const [exercise, setExercise] = useState<ExerciseModel | null>(null);

    useEffect(() => {
        if (id) {
            void exerciseAdapter.viewExercise(Number(id)).then((data) => {
                setLoaded(true);
                setExercise(data);
            });
        }
    }, [id]);

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            {loaded && exercise ? (
                <>
                    <div className="modal-header">
                        <div className="modal-title contained-modal-title-vcenter flex-column d-flex">
                            {exercise.name}
                            <div className="d-flex exerciseSectionTitle">
                                {exercise.exercisesections?.length ? (
                                    <>
                                        {exercise.exercisesections.map((value, index) => (
                                            <span>{value.name}</span>
                                        ))}
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">
                        <div className="row mb-5">
                            <div className="col-6 flex-column d-flex exerciseSectionList">
                                <h6 className="font-weight-lighter">
                                    {t("global.description", "Beschreibung")}:
                                </h6>
                                {exercise.exercisedescriptions?.length ? (
                                    <>
                                        {exercise.exercisedescriptions.map((value, index) => (
                                            <span>
                                                {index + 1}.) {value.text}
                                            </span>
                                        ))}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                {exercise?.uploads && exercise?.uploads[0] ? (
                                    <img
                                        src={exercise?.uploads[0].url}
                                        className="exerciseSetImage"
                                    />
                                ) : (
                                    <div className="exerciseSetImage">
                                        <FontAwesomeIcon icon={faImage} size="2x" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-3 exerciseSectionList">
                                <h6 className="font-weight-lighter">
                                    {t("global.pattern", "Pattern")}:
                                </h6>
                                {exercise.exercisefilters?.length ? (
                                    <>
                                        {exercise.exercisefilters.map((value, index) => (
                                            <span>{value.name}</span>
                                        ))}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                            <div className="col-3 exerciseSectionList">
                                <h6 className="font-weight-lighter">
                                    {t("global.muscles", "Muskeln")}:
                                </h6>
                                {exercise.exercisemuscles?.length ? (
                                    <>
                                        {exercise.exercisemuscles.map((value, index) => (
                                            <span>{value.name}</span>
                                        ))}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                            <div className="col-3 exerciseSectionList">
                                <h6 className="font-weight-lighter">{t("global.tags", "Tags")}:</h6>
                                {exercise.exercisetags?.length ? (
                                    <>
                                        {exercise.exercisetags.map((value, index) => (
                                            <span>{value.name}</span>
                                        ))}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                            <div className="col-3 exerciseSectionList">
                                <h6 className="font-weight-lighter">
                                    {t("global.equipment", "Equipment")}:
                                </h6>
                                {exercise.trainingsapparatuses?.length ? (
                                    <>
                                        {exercise.trainingsapparatuses.map((value, index) => (
                                            <span>{value.name}</span>
                                        ))}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="row d-flex justify-content-center p-5">
                    <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
                </div>
            )}
        </Modal>
    );
};

export default ExerciseViewModal;
