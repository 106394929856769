/** @format */

import { trainingsessionsAdapter } from "@api/ApiRequest";
import { TrainingsessionUserSpecial } from "@api/ext/TrainingsessionsAdapter";
import UserViewContext from "@context/UserViewProvider";
import { faTrophyAlt, faHistory, faCalendarEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Chart,
    BarController,
    CategoryScale,
    LinearScale,
    BarElement,
    DoughnutController,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useContext, useState } from "react";
import "./Statistic.scss";

import Counter from "../../../../components/Counter/Counter";
import StatisticHour from "./StatisticHour/StatisticHour";

Chart.register(
    Legend,
    Tooltip,
    BarController,
    CategoryScale,
    LinearScale,
    BarElement,
    DoughnutController,
    ArcElement
);

const Statistic: React.FC = () => {
    const { user } = useContext(UserViewContext);

    const statistic = useRef<HTMLCanvasElement>(null);
    const statisticPie = useRef<HTMLCanvasElement>(null);

    const [statsData, setStatsData] = useState<TrainingsessionUserSpecial | null>(null);

    useEffect(() => {
        if (user && user.id) {
            void trainingsessionsAdapter.getUserViewSpecialStats(user.id).then((value) => {
                setStatsData(value);
            });
        }
    }, [user]);

    useEffect(() => {
        let chart: Chart<"doughnut", string[], string>;
        if (statsData && statisticPie.current) {
            const ctx = statisticPie.current.getContext("2d");
            const labels: string[] = [];
            statsData.lovedDay.dates?.map((value) => {
                labels.push(
                    DateTime.local()
                        .set({ weekday: Number(value) })
                        .toFormat("EEEE", { locale: "de" })
                );
            });

            chart = new Chart(ctx as CanvasRenderingContext2D, {
                type: "doughnut",
                data: {
                    labels,
                    datasets: [
                        {
                            label: "Week Favorite",
                            data: statsData.lovedDay.values,
                            backgroundColor: [
                                "rgb(0, 122, 255)",
                                "rgba(0, 122, 255, 0.8)",
                                "rgba(0, 122, 255, 0.6)",
                                "rgba(0, 122, 255, 0.4)",
                                "rgba(0, 122, 255, 0.2)",
                                "rgba(0, 122, 255, 0.1)",
                                "rgba(0, 122, 255, 0.05)",
                            ],
                            offset: 4,
                            hoverOffset: 2,
                            hoverBorderColor: "rgb(0, 162, 255)",
                        },
                    ],
                },
                options: {
                    cutout: "80%",
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            top: 10,
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                        tooltip: {
                            callbacks: {
                                labelTextColor(context) {
                                    return "#ffffff";
                                },
                            },
                        },
                    },
                },
            });
        }
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [statsData]);

    useEffect(() => {
        let chart: Chart<"bar", number[], string>;
        if (user && user.id && statistic.current) {
            void trainingsessionsAdapter.getWeekCoachCountStatistic(user.id).then((value) => {
                if (!statistic.current) {
                    return null;
                }
                const ctx = statistic.current.getContext("2d");

                if (!ctx) {
                    return null;
                }

                chart = new Chart(ctx, {
                    type: "bar",
                    data: {
                        labels: value.dates,
                        datasets: [
                            {
                                label: "Abgeschlossene Trainings",
                                data: value.values,
                                borderColor: "rgba(0, 122, 255, 0.9)",
                                borderWidth: 1,
                                backgroundColor: "rgba(0, 122, 255, 0.5)",
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    labelTextColor(context) {
                                        return "#ffffff";
                                    },
                                },
                            },
                        },
                    },
                });
            });
        }
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [user]);

    if (!user && !statsData) {
        return null;
    }

    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="col-lg-4 col-xs-12 mt-1">
                        <div className="info-block align-items-center d-flex">
                            <FontAwesomeIcon icon={faTrophyAlt} size="3x" />
                            <div>
                                <h5>Insgesammt</h5>
                                <span>
                                    {statsData?.total ? (
                                        <Counter from={0} to={statsData?.total} />
                                    ) : (
                                        0
                                    )}
                                    <span>Trainings</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xs-12 mt-1">
                        <div className="info-block align-items-center d-flex">
                            <FontAwesomeIcon icon={faHistory} size="3x" />
                            <div>
                                <h5>Letzten 7. Tage</h5>
                                <span>
                                    {statsData?.last ? (
                                        <Counter from={0} to={statsData?.last} />
                                    ) : (
                                        0
                                    )}{" "}
                                    <span>Trainings</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-xs-12 mt-1">
                        <div className="info-block align-items-center d-flex">
                            <FontAwesomeIcon icon={faCalendarEdit} size="3x" />
                            <div>
                                <h5>Dabei seit</h5>
                                <span>
                                    {statsData?.days ? (
                                        <Counter from={0} to={statsData?.days} />
                                    ) : (
                                        0
                                    )}{" "}
                                    <span>Tagen</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <h5>Ausgewertete Trainings der letzten 15. Wochen</h5>
            </div>
            <div className="col-12 mt-3">
                <canvas id="statisticUser" ref={statistic} width="1500" height="400" />
            </div>
            <div className="col-lg-6 col-xs-12 mt-4">
                <div style={{ height: "400px" }}>
                    <canvas ref={statisticPie} />
                </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-4">
                <StatisticHour
                    dataDates={statsData?.lovedHour.dates}
                    dataValues={statsData?.lovedHour.values}
                />
            </div>
        </>
    );
};

export default Statistic;
