/** @format */

import { extendedUserAdapter } from "@api/ApiRequest";
import { ExtendedUserAdd, UserAdd } from "@api/ext/ExtendedUserAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Link } from "react-router-dom";
import * as yup from "yup";

import validateSchema from "./UserAddFormValidator";

yup.setLocale({
    mixed: {
        required: "Eine Eingabe ist notwendig",
        number: "Bitte eine Auswahl treffen",
        typeError: "Bitte etwas eingeben",
    },
    number: {
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
        positive: "Eine Eingabe ist notwendig",
        required: "Eine Eingabe ist notwendig",
    },
    string: {
        lowercase: "Nur Kleinbuchstaben sind erlaubt.",
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
        required: "Eine Eingabe ist notwendig",
        typeError: "Bitte etwas eingeben",
    },
});

interface UserProps {
    url?: string;
    id?: number;
}

interface ValidationError {
    errors: string | string[];
    path: string;
    message: string;
    inner?: ValidationError[];
}

interface UserAddFormParams {
    id: string | undefined;
}

const UserAddForm: React.FC<UserProps> = () => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const { setSaved } = useContext(LoadingContext);
    const { subscription, userCount, updateUserCount, clientGroupIds } = useSubscriptionContext();
    const { id } = useParams<UserAddFormParams>();

    const [t] = useTranslation();
    const [save, setSave] = useState<number>(0);
    const [errors, setErrors] = useState<{
        name?: string;
        surname?: string;
        username?: string;
        email?: string;

        company_id?: string;
        group_id?: string;
        sex?: string;
        coach_id?: string;
        coach?: {
            tel?: string;
            email?: string;
        };
    }>({});
    const [isLoaded, seIsLoaded] = useState<boolean>(false);
    const [isPost, setIsPost] = useState<boolean>(false);

    const [isCoach, setIsCoach] = useState<boolean>(false);
    const [sameEmail, setSameEmail] = useState<boolean>(true);
    const [canAdd, setCanAdd] = useState<boolean>(true);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const [originalData, setOriginalData] = useState<UserAdd | null>(null);
    const [userData, setUserData] = useState<UserAdd | null>(null);
    const [groups, setGroups] = useState<{ [id: number]: string } | null>(null);
    const [companies, setCompanies] = useState<{ [id: number]: string } | null>(null);
    const [workoutcoaches, setWorkoutcoaches] = useState<{
        [id: number]: string;
    } | null>(null);
    const [doInvite, setDoInvite] = useState<boolean>(!id);

    useEffect(() => {
        extendedUserAdapter
            .getAddUser(id)
            .then((response) => {
                const { user, groups, companies, workoutcoaches } = response;

                if (user.id) {
                    if (user.workoutcoaches_user) {
                        user.coach_id = user.workoutcoaches_user.coach_id;
                    }

                    setIsCoach(user.group_id === 2 || user.group_id === 14);
                    setOriginalData(user);
                    setUserData(user);
                } else {
                    const initialData = {
                        group_id: Number(Object.keys(groups)[0]),
                        company_id: Number(Object.keys(companies)[0]),
                    };

                    setUserData(initialData as UserAdd);
                }

                setGroups(groups);
                setCompanies(companies);
                setWorkoutcoaches(workoutcoaches);

                seIsLoaded(true);
            })
            .catch((e) => console.log(e));
    }, [id]);

    const handleSubmit = () => {
        if (!canSubmit) {
            return;
        }

        setErrors({});

        const userSend: ExtendedUserAdd = {
            id: (userData && userData.id) || undefined,
            name: userData && userData.name ? userData.name : "",
            surname: userData && userData.surname ? userData.surname : "",
            email: userData && userData.email ? userData.email : "",
            username: userData && userData.username ? userData.username : "",
            group_id: userData && userData.group_id ? userData.group_id : 1,
            sex: userData && userData.sex ? userData.sex : "",
            lang: "deu",
            enabled: true,
            coach_id: userData && userData.coach_id ? userData.coach_id : 0,
            company_id: id
                ? undefined
                : userData && userData.company_id
                ? userData.company_id
                : undefined,
            isCoach,
        };

        validateSchema
            .validate(userSend, {
                abortEarly: false,
                strict: true,
            })
            .then(() => {
                setIsPost(false);

                extendedUserAdapter
                    .setAddUser(userSend, doInvite, id)
                    .then((response) => {
                        console.log(response);
                        const { success, errors, user } = response;

                        if (success) {
                            setIsPost(true);
                            setSave(user.id);
                            updateUserCount();

                            setSaved({
                                type: "successful",
                                show: true,
                                text: t("users.addSuccess", "Benutzer wurde gespeichert."),
                            });
                        } else {
                            let validation = {};

                            if (errors) {
                                Object.keys(errors).map((i) => {
                                    validation = {
                                        ...validation,
                                        [i]: errors[i][Object.keys(errors[i])[0]],
                                    };
                                });
                            }

                            setErrors(validation);
                            setIsPost(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsPost(false);
                    });
            })
            .catch((err: ValidationError) => {
                let errors = {};
                if (err.inner) {
                    err.inner.map((value) => {
                        errors = {
                            ...errors,
                            [value.path]: value.message,
                        };
                    });
                }

                setErrors(errors);
            });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setErrors({});

        const copyUsrData = userData ? { ...userData } : {};

        const newUserData = {
            ...copyUsrData,
            [e.target.name]:
                e.target.name === "group_id" || e.target.name === "coach_id"
                    ? Number(e.target.value)
                    : e.target.value,
        };

        if (e.target.name === "group_id") {
            setIsCoach(Number(e.target.value) === 2 || Number(e.target.value) === 14);
        }

        setUserData(newUserData as UserAdd);
    };

    useEffect(() => {
        if (!sameEmail || !userData?.email) {
            return;
        }

        setUserData((prev) => {
            return prev
                ? {
                      ...prev,
                      workoutcoach: {
                          ...prev?.workoutcoach,
                          email: userData.email,
                      },
                  }
                : null;
        });
    }, [sameEmail, userData?.email]);

    useEffect(() => {
        if (save > 0) {
            setTimeout(() => history.push(`/users/view/${save}`), 200);
        }
    }, [save, history]);

    useEffect(() => {
        if (userCount === null || !subscription || !userData?.group_id || !groups) {
            return;
        }

        if (!id) {
            setCanAdd(
                !(userCount >= subscription.seat_count) ||
                    !clientGroupIds.includes(userData.group_id)
            );

            return;
        }

        if (originalData?.group_id && id) {
            setCanAdd(
                !(
                    userCount >= subscription.seat_count &&
                    (clientGroupIds.includes(userData.group_id) ||
                        groups[userData.group_id] !== groups[originalData.group_id])
                )
            );
        }
    }, [
        id,
        subscription,
        userCount,
        groups,
        userData?.group_id,
        originalData?.group_id,
        clientGroupIds,
    ]);

    useEffect(() => {
        if (!id && !canAdd) {
            setCanSubmit(false);

            return;
        }

        setCanSubmit(
            !!(
                userData?.email &&
                userData?.name &&
                userData?.surname &&
                userData?.username &&
                userData?.group_id &&
                userData?.sex &&
                userData?.company_id
            )
        );
    }, [id, canAdd, userData]);

    if (!isLoaded) {
        return (
            <div className="col-12 text-center">
                <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <h1>
                        {id
                            ? t("users.edit", "Benutzer Bearbeiten")
                            : t("users.add", "Benutzer Hinzufügen")}
                    </h1>
                </div>

                <div className="col-12 mt-2">
                    <p>
                        {id
                            ? t("users.editDescription", "Bearbeite einen Benutzer aus dem System")
                            : t(
                                  "users.addDescription",
                                  "Füge einen neuen Benutzer zum System hinzu"
                              )}
                    </p>
                </div>
            </div>
            <form>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                        <InputLabel required>{t("user.sex", "Geschlecht")}</InputLabel>
                        <select
                            className={`form-select ${errors.sex ? "is-invalid" : ""}`}
                            name="sex"
                            defaultValue={userData && userData.sex ? userData.sex : 0}
                            onChange={handleChange}
                        >
                            <option>{t("global.selectEmptySex", "- Geschlecht -")}</option>
                            <option value="f">{t("global.female", "Weiblich")}</option>
                            <option value="m">{t("global.male", "Männlich")}</option>
                            <option value="d">{t("global.diverse", "Divers")}</option>
                        </select>
                        <div className="invalid-feedback">{errors.sex}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                        <InputLabel required>{t("user.name", "Vorname")}</InputLabel>
                        <input
                            className={`form-control ${errors.name ? "is-invalid" : ""}`}
                            type="text"
                            name="name"
                            defaultValue={userData && userData.name ? userData.name : ""}
                            placeholder={t("user.name", "Vorname")}
                            onChange={handleChange}
                        />

                        <div className="invalid-feedback">{errors.name}</div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                        <InputLabel required>{t("user.surname", "Nachname")}</InputLabel>
                        <input
                            type="text"
                            className={`form-control ${errors.surname ? "is-invalid" : ""}`}
                            placeholder={t("user.surname", "Nachname")}
                            name="surname"
                            defaultValue={userData && userData.surname ? userData.surname : ""}
                            onChange={handleChange}
                        />
                        <div className="invalid-feedback">{errors.surname}</div>
                    </div>
                </div>

                {user?.is_admin && (
                    <div className="row">
                        <div className="col-12 col-lg-8 mt-3">
                            <InputLabel required>{t("user.company", "Studio")}</InputLabel>
                            <select
                                name="company_id"
                                className={`form-select ${errors.company_id ? "is-invalid" : ""}`}
                                onChange={handleChange}
                                defaultValue={
                                    userData && userData.company_id ? userData.company_id : 0
                                }
                                disabled={!!id}
                            >
                                {companies &&
                                    Object.keys(companies).map((x) => {
                                        return (
                                            <option value={x} key={x}>
                                                {companies[Number(x)]}
                                            </option>
                                        );
                                    })}
                            </select>
                            <div className="invalid-feedback">{errors.company_id}</div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                        <InputLabel required>{t("user.email", "E-Mail")}</InputLabel>
                        <input
                            type="email"
                            required
                            className={`form-control ${errors.email ? "is-invalid" : ""}`}
                            placeholder={t("user.email", "E-Mail")}
                            name="email"
                            defaultValue={userData && userData.email ? userData.email : ""}
                            onChange={handleChange}
                        />

                        <div className="invalid-feedback">{errors.email}</div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                        <InputLabel required>{t("user.group", "Gruppe")}</InputLabel>
                        <select
                            name="group_id"
                            className={`form-select ${errors.group_id ? "is-invalid" : ""}`}
                            onChange={handleChange}
                            defaultValue={userData && userData.group_id ? userData.group_id : 0}
                            disabled={userData?.lastlogin}
                        >
                            {groups &&
                                Object.keys(groups).map((x) => {
                                    return (
                                        <option value={x} key={x}>
                                            {groups[Number(x)]}
                                        </option>
                                    );
                                })}
                        </select>
                        <div className="invalid-feedback">{errors.group_id}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mt-3">
                        <InputLabel required>{t("user.username", "Benutzername")}</InputLabel>
                        <input
                            type="text"
                            disabled={!!id}
                            className={`form-control ${errors.username ? "is-invalid" : ""}`}
                            placeholder={t("user.username", "Benutzername")}
                            name="username"
                            defaultValue={userData && userData.username ? userData.username : ""}
                            onChange={handleChange}
                        />
                        <div className="invalid-feedback">{errors.username}</div>
                    </div>
                    {!id && !canAdd && (
                        <div className="col-12 col-md-6 col-lg-4 mt-3">
                            <InputLabel>&nbsp;</InputLabel>
                            <div className="rounded-3 p-3 bg-light">
                                <div className="">
                                    {t(
                                        "users.seatsExhausted.description",
                                        "Du hast bereits alle in Deinem gebuchten Paket enthaltenen Sitze in Benutzung. Um fortzufahren muss ein weiterer kostenpflichtiger Sitz hinzugefügt werden."
                                    )}
                                </div>
                                <div className="d-flex mt-3 justify-content-end">
                                    <Link className="btn btn-save" to="/profile/subscription">
                                        Paket verwalten
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="row">
                    {!isCoach && (
                        <div className="col-12 col-md-6 col-lg-4 mt-3">
                            <InputLabel required>{t("user.coach", "Trainer")}</InputLabel>
                            <select
                                className={`form-select ${errors.coach_id ? "is-invalid" : ""}`}
                                defaultValue={
                                    userData && userData.workoutcoaches_user
                                        ? userData.workoutcoaches_user.coach_id
                                        : 0
                                }
                                name="coach_id"
                                onChange={handleChange}
                            >
                                <option>{t("global.selectEmptyCoach", "- Coach -")}</option>
                                {workoutcoaches &&
                                    Object.keys(workoutcoaches).map((x) => {
                                        return (
                                            <option value={x} key={x}>
                                                {workoutcoaches[Number(x)]}
                                            </option>
                                        );
                                    })}
                            </select>

                            <div className="invalid-feedback">{errors.coach_id}</div>
                        </div>
                    )}
                </div>
                {isCoach && !id && (
                    <motion.div
                        key="coach"
                        initial="collapsed"
                        // animate="open"
                        exit="collapsed"
                        className="row"
                        animate={isCoach ? "open" : "closed"}
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                        }}
                    >
                        <div className="col-12 my-3">
                            <h4>{t("coach.additionalInfo", "Zusätzliche Angaben zum Trainer")}</h4>
                        </div>
                        <div className="col-6">
                            <InputLabel>{t("coach.phoneNumber", "Telefonnummer")}</InputLabel>
                            <input
                                className={`form-control ${errors.coach?.tel ? "is-invalid" : ""}`}
                                type="text"
                                name="coachTel"
                                defaultValue={
                                    userData && userData.workoutcoach?.tel
                                        ? userData.workoutcoach?.tel
                                        : ""
                                }
                                placeholder="+49 222 222 etc."
                                onChange={handleChange}
                            />

                            <div className="invalid-feedback">{errors.coach?.tel}</div>
                        </div>
                        <div className="col-6">
                            <InputLabel required>{t("coach.email", "E-Mail")}</InputLabel>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors.coach?.email ? "is-invalid" : ""
                                }`}
                                placeholder={t("coach.email", "Email")}
                                name="coachEmail"
                                value={
                                    userData && userData?.workoutcoach?.email
                                        ? userData?.workoutcoach?.email
                                        : ""
                                }
                                onChange={handleChange}
                                disabled={sameEmail}
                            />
                            <div className="invalid-feedback">{errors.coach?.email}</div>
                            <div className="form-check mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="same-email"
                                    checked={sameEmail}
                                    onChange={() => setSameEmail((prev) => !prev)}
                                />
                                <InputLabel className="form-check-label" htmlFor="same-email">
                                    {t("signup.sameEmail", "Die selbe E-Mail nutzen.")}
                                </InputLabel>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 mt-3">
                            <InputLabel>
                                {t("coach.presentationText", "Vorstellungstext")}
                            </InputLabel>

                            <textarea
                                id="coachText"
                                name="coachText"
                                className="form-control"
                                rows={3}
                                placeholder={t(
                                    "coach.presentationText",
                                    "Vorstellungstext des Coaches eingeben"
                                )}
                                defaultValue={
                                    userData && userData.workoutcoach?.text
                                        ? userData.workoutcoach?.text
                                        : ""
                                }
                                onChange={handleChange}
                            />
                        </div>
                    </motion.div>
                )}
                <div className="row">
                    {!id && (
                        <div className="col-sm-12 d-flex align-items-center mt-3">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="send-invite-checkbox"
                                    name="send-invite-checkbox"
                                    checked={doInvite}
                                    onChange={() => setDoInvite((prev) => !prev)}
                                />
                                <InputLabel
                                    className="form-check-label"
                                    htmlFor="send-invite-checkbox"
                                >
                                    {t("user.sendInvite", "Kennwort E-Mail senden")}
                                </InputLabel>
                            </div>
                        </div>
                    )}
                </div>
            </form>
            <div className="col-12 col-lg-8 d-flex justify-content-end align-items-center mt-3">
                <button
                    className="btn btn-save"
                    onClick={() => (!isPost ? handleSubmit() : null)}
                    disabled={!canSubmit}
                >
                    {isPost && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                    {!id && t("global.create", "Benutzer Erstellen")}
                    {id && t("global.saveChanges", "Änderungen speichern")}
                </button>
            </div>
        </>
    );
};

export default UserAddForm;
