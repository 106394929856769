/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

import { CompanyListItem } from "./ExtendedMandantorAdapter";

export interface ExercisetagModel extends Model {
    id: number;
    name: string;
    editableCompany: boolean;
    editableMandantor: boolean;
    company_id: number;
    created: DateTime;
}

export interface ExercisetagAddModel extends Model {
    name: string;
    company_id?: number;
}

export default class ExercisetagAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    ExercisetagModel,
    Paginator,
    ExercisetagAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Exercisetags", "Exercisetag", "Exercisetags", adapter);
    }

    public async getIndex(search = ""): Promise<ExercisetagModel[]> {
        const { data } = await this._get<{ exercisetags: ExercisetagModel[] }>(
            `index?search=${search}`
        );

        return data.exercisetags;
    }

    public async getAddExercisetag(): Promise<{ companies: CompanyListItem }> {
        const { data } = await this._get<{ companies: CompanyListItem }>(`add`);

        return {
            companies: data.companies,
        };
    }

    public async getEditExercisetag(
        id: string
    ): Promise<{ exercisetag: ExercisetagModel; companies: CompanyListItem }> {
        const { data } = await this._get<{
            exercisetag: ExercisetagModel;
            companies: CompanyListItem;
        }>(`edit/${id}`);

        return {
            exercisetag: data.exercisetag,
            companies: data.companies,
        };
    }
}
