/** @format */

import { PaymentMethod } from "@api/ext/MandateAdapter";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import CreditCard from "../../Subscription/SubscriptionPayment/CreditCard/CreditCard";
import Paypal from "../../Subscription/SubscriptionPayment/Paypal/Paypal";
import Sepa from "../../Subscription/SubscriptionPayment/Sepa/Sepa";

const AddMandate: React.FC = () => {
    const [t] = useTranslation();
    const { method, setMethod, onSubmitMethod } = useSubscriptionContext();
    const [canSubmit, setCanSubmit] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);

    useEffect(() => {
        if (selectedMethod) {
            setMethod(selectedMethod);
        }
    }, [selectedMethod, setMethod]);

    return (
        <div>
            <h1>{t("mandates.addMandate", "Zahlungsmethode hinzufügen")}</h1>
            <div className="row d-flex my-4">
                {method !== PaymentMethod.DIRECTDEBIT && (
                    <>
                        <Paypal active={method === PaymentMethod.PAYPAL} />
                        <CreditCard active={method === PaymentMethod.CREDITCARD} />
                    </>
                )}
                <Sepa setCanSubmit={setCanSubmit} />
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-between px-0">
                    <NavLink
                        activeClassName="active"
                        className="btn btn-default"
                        to="/profile/subscription/manage-mandates"
                    >
                        {t("global.back", "Zurück")}
                    </NavLink>
                    <button
                        className={`btn btn-save ${!canSubmit ? "disabled" : ""}`}
                        onClick={() =>
                            onSubmitMethod(false, "profile/subscription/manage-mandates")
                        }
                    >
                        {t(
                            "subscription.payment.method.addMethod",
                            "Neue Zahlungsmethode hinzufügen"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddMandate;
