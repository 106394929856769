/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

import { CompanyListItem } from "./ExtendedMandantorAdapter";

export interface EquipmentModel extends Model {
    id: number;
    name: string;
    editableCompany: boolean;
    editableMandantor: boolean;
    company_id: number;
    created: DateTime;
}

export interface EquipmentAddModel extends Model {
    name: string;
    company_id?: number;
}

export default class EquipmentAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    EquipmentModel,
    Paginator,
    EquipmentAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(
            "workout/Trainingsapparatuses",
            "Trainingsapparatuse",
            "Trainingsapparatuses",
            adapter
        );
    }

    public async getIndex(search = ""): Promise<EquipmentModel[]> {
        const { data } = await this._get<{ trainingsapparatuses: EquipmentModel[] }>(
            `index?search=${search}`
        );

        return data.trainingsapparatuses;
    }

    public async getAddEquipment(): Promise<{ companies: CompanyListItem }> {
        const { data } = await this._get<{ companies: CompanyListItem }>(`add`);

        return data;
    }

    public async getEditEquipment(
        id: string
    ): Promise<{ equipment: EquipmentModel; companies: CompanyListItem }> {
        const { data } = await this._get<{
            trainingsapparatus: EquipmentModel;
            companies: CompanyListItem;
        }>(`edit/${id}`);

        return {
            equipment: data.trainingsapparatus,
            companies: data.companies,
        };
    }
}
