/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";

export default class WorkoutschedulesSetsAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(
            "workout/ExercisesWorkoutschedulesSets",
            "ExercisesWorkoutschedulesSet",
            "ExercisesWorkoutschedulesSets",
            adapter
        );
    }
}
