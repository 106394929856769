/** @format */

import { extendedUserAdapter } from "@api/ApiRequest";
import { ExtendedOwnerSignup } from "@api/ext/ExtendedUserAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context/LoadingProvider";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as yup from "yup";

import "./Signup.scss";

/* eslint-disable no-template-curly-in-string */
yup.setLocale({
    mixed: {
        required: "Eine Eingabe ist notwendig",
    },
    number: {
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
    },
    string: {
        lowercase: "Nur Kleinbuchstaben sind erlaubt.",
        min: "Es müssen Mindestens ${min}. Zeichen sein.",
        max: "Nur ${max}. Zeichen sind erlaubt.",
    },
});

interface ValidationError {
    errors: string | string[];
    path: string;
    message: string;
    inner?: ValidationError[];
}

interface SchemaShape {
    user: {
        name: string;
        surname: string;
        email: string;
        username: string;
        sex: string;
    };
    mandantor: {
        name: string;
    };
    coach: {
        email: string;
    };
}

const validateSchema: yup.SchemaOf<SchemaShape> = yup.object().shape({
    user: yup.object({
        name: yup
            .string()
            .typeError("Es dürfen nur Buchstaben eingegeben werden.")
            .min(2)
            .max(30)
            .required(),
        surname: yup
            .string()
            .typeError("Es dürfen nur Buchstaben eingegeben werden.")
            .min(2)
            .max(30)
            .required(),
        email: yup.string().email("Bitte Geben Sie eine gültige Email ein.").required(),
        username: yup
            .string()
            .typeError("Es dürfen nur Buchstaben eingegeben werden.")
            .required()
            .min(5)
            .max(30)
            .lowercase(),
        sex: yup.string().required(),
        password: yup
            .string()
            .typeError("Es dürfen nur Buchstaben eingegeben werden.")
            .required("Bitte ein Passwort eingeben.")
            .min(8, "Das Passwort ist zu kurz - es sollte mindestens 8 Zeichen lang sein."),
        newPasswordAgain: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwörter müssen identisch sein"),
    }),
    mandantor: yup.object({
        name: yup
            .string()
            .typeError("Es dürfen nur Buchstaben eingegeben werden.")
            .min(2)
            .max(68)
            .required(),
    }),
    coach: yup.object({
        email: yup.string().email().required(),
    }),
});

interface UserSignupModel {
    mandantorName: string;
    userSex: string;
    userName: string;
    userSurname: string;
    userEmail: string;
    userUsername: string;
    coachTel: string;
    coachText: string;
    coachEmail: string;
    userPassword: string;
    userPasswordAgain: string;
}

interface SignupErrors {
    "user.sex"?: string;
    "user.name"?: string;
    "user.surname"?: string;
    "user.username"?: string;
    "user.email"?: string;
    "user.tel"?: string;
    "user.password"?: string;
    "user.newPasswordAgain"?: string;
    "mandantor.name"?: string;
    "coach.tel"?: string;
    "coach.email"?: string;
}

const Signup: React.FC = () => {
    const [t] = useTranslation();
    const history = useHistory();

    const { setSaved } = useContext(LoadingContext);

    const [errors, setErrors] = useState<SignupErrors>({});
    const [isPost, setIsPost] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const [isCoach, setIsCoach] = useState<boolean>(false);
    const [sameEmail, setSameEmail] = useState<boolean>(true);

    const [userData, setUserData] = useState<UserSignupModel>({
        mandantorName: "",
        userSex: "",
        userName: "",
        userSurname: "",
        userEmail: "",
        userUsername: "",
        coachTel: "",
        coachText: "",
        coachEmail: "",
        userPassword: "",
        userPasswordAgain: "",
    });

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        setErrors({});

        const userSend: ExtendedOwnerSignup = {
            mandantor: {
                name: userData?.mandantorName,
            },
            user: {
                sex: userData?.userSex,
                name: userData?.userName,
                surname: userData?.userSurname,
                email: userData?.userEmail,
                username: userData?.userUsername,
                password: userData?.userPassword,
                newPasswordAgain: userData?.userPasswordAgain,
                privdisaccept: true,
            },
        };

        if (isCoach) {
            userSend.coach = {
                tel: userData?.coachTel,
                text: userData?.coachText,
                email: userData?.coachEmail,
            };
        }

        validateSchema
            .validate(userSend, {
                abortEarly: false,
                strict: true,
            })
            .then(() => {
                extendedUserAdapter
                    .signupOwner(userSend)
                    .then((response) => {
                        const { success, errors } = response;

                        if (success) {
                            setIsPost(true);

                            setSaved({
                                type: "successful",
                                show: true,
                                text: t("signup.success", "Bestätigungs E-Mail wurde versendet."),
                            });

                            history.push({
                                pathname: "/signup-done",
                                state: { email: userData.userEmail },
                            });
                        } else {
                            let validation = {};

                            if (errors) {
                                Object.keys(errors).map((i) => {
                                    validation = {
                                        ...validation,
                                        ["user." + i]: errors[i][Object.keys(errors[i])[0]],
                                    };
                                });
                            }

                            setErrors(validation);
                            setIsPost(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);

                        setIsPost(false);
                    });
            })
            .catch((err: ValidationError) => {
                let errors: SignupErrors = {};
                if (err.inner) {
                    err.inner.map((value) => {
                        errors = {
                            ...errors,
                            [value.path]: value.message,
                        };
                    });
                }

                setErrors(errors);
            });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setErrors({});

        const copyUsrData = { ...userData };

        const newUserData = {
            ...copyUsrData,
            [e.target.name]:
                e.target.name === "group_id" || e.target.name === "coach_id"
                    ? Number(e.target.value)
                    : e.target.value,
        };

        setUserData(newUserData);
    };

    useEffect(() => {
        if (!sameEmail || !userData?.userEmail) {
            return;
        }

        setUserData((prev: UserSignupModel) => {
            return {
                ...prev,
                coachEmail: userData?.userEmail,
            };
        });
    }, [sameEmail, userData?.userEmail]);

    useEffect(() => {
        setCanSubmit(
            !!(
                userData.mandantorName &&
                userData.userEmail &&
                userData.userPassword &&
                userData.userPasswordAgain &&
                userData.userUsername &&
                userData.userName &&
                userData.userSurname &&
                userData.userSex
            )
        );
    }, [userData]);

    return (
        <div className="container min-vh-100 login p-3 p-md-5">
            <form className="row py-5" onSubmit={handleSubmit}>
                <div className="col-lg-12 text-center mb-3">
                    <img
                        src="/img/background_logo.png"
                        alt="InterCoach Logo"
                        className="img-fluid main-logo"
                    />
                </div>
                <div className="col-lg-6 offset-lg-3 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="d-flex justify-content-between align-items-center font-weight-light mb-3">
                                {t("global.signup", "Registrieren")}
                            </h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 position-relative">
                            <InputLabel required htmlFor="mandantorName">
                                {t("company.name", "Firmenname")}
                            </InputLabel>
                            <input
                                id="mandantorName"
                                name="mandantorName"
                                className={`form-control ${
                                    errors["mandantor.name"] ? "is-invalid" : ""
                                }`}
                                onChange={handleChange}
                                type="text"
                                placeholder={t("company.name", "Firmenname")}
                                defaultValue={
                                    userData && userData.mandantorName ? userData.mandantorName : ""
                                }
                            />
                            <div className="invalid-feedback">{errors["mandantor.name"]}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                            <InputLabel required>{t("user.sex", "Geschlecht")}</InputLabel>
                            <select
                                className={`form-select ${errors["user.sex"] ? "is-invalid" : ""}`}
                                name="userSex"
                                defaultValue={userData && userData.userSex ? userData.userSex : 0}
                                onChange={handleChange}
                            >
                                <option>{t("global.selectEmptyChose", "-- Wähle --")}</option>
                                <option value="f">{t("global.female", "Weiblich")}</option>
                                <option value="m">{t("global.male", "Männlich")}</option>
                                <option value="d">{t("global.diverse", "Divers")}</option>
                            </select>
                            <div className="invalid-feedback">{errors["user.sex"]}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <InputLabel required>{t("user.name", "Vorname")}</InputLabel>
                            <input
                                className={`form-control ${
                                    errors["user.name"] ? "is-invalid" : ""
                                }`}
                                type="text"
                                name="userName"
                                defaultValue={
                                    userData && userData.userName ? userData.userName : ""
                                }
                                placeholder={t("user.name", "Vorname")}
                                onChange={handleChange}
                            />
                            <div className="invalid-feedback">{errors["user.name"]}</div>
                        </div>
                        <div className="col-6">
                            <InputLabel required>{t("user.surname", "Nachname")}</InputLabel>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors["user.surname"] ? "is-invalid" : ""
                                }`}
                                placeholder={t("user.surname", "Nachname")}
                                name="userSurname"
                                defaultValue={
                                    userData && userData.userSurname ? userData.userSurname : ""
                                }
                                onChange={handleChange}
                            />
                            <div className="invalid-feedback">{errors["user.surname"]}</div>
                        </div>
                        <div className="col-12 mt-3">
                            <InputLabel required>{t("user.email", "E-Mail")}</InputLabel>
                            <input
                                type="email"
                                className={`form-control ${
                                    errors["user.email"] ? "is-invalid" : ""
                                }`}
                                placeholder={t("user.email", "E-Mail")}
                                name="userEmail"
                                defaultValue={
                                    userData && userData.userEmail ? userData.userEmail : ""
                                }
                                onChange={handleChange}
                            />

                            <div className="invalid-feedback">{errors["user.email"]}</div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 mb-3">
                            <InputLabel required>{t("user.username", "Benutzername")}</InputLabel>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors["user.username"] ? "is-invalid" : ""
                                }`}
                                placeholder={t("user.username", "Benutzername")}
                                name="userUsername"
                                defaultValue={
                                    userData && userData.userUsername ? userData.userUsername : ""
                                }
                                onChange={handleChange}
                            />
                            <div className="invalid-feedback">{errors["user.username"]}</div>
                        </div>
                        <div className="col-12 mb-3">
                            <InputLabel required>{t("user.password", "Passwort")}</InputLabel>
                            <input
                                type="password"
                                className={`form-control ${
                                    errors["user.password"] ? "is-invalid" : ""
                                }`}
                                placeholder={t("user.password", "Passwort")}
                                name="userPassword"
                                autoComplete="new-password"
                                defaultValue={
                                    userData && userData.userPassword ? userData.userPassword : ""
                                }
                                onChange={handleChange}
                            />
                            {errors["user.password"] ? (
                                <div className="invalid-feedback">{errors["user.password"]}</div>
                            ) : (
                                <div className="input-description">
                                    {t(
                                        "user.passwordFormat",
                                        "Das Kennwort muss mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl enthalten und mindestens 8 Zeichen lang sein"
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="col-12 mb-3">
                            <InputLabel required>
                                {t("user.passwordAgain", "Passwort wiederholen")}
                            </InputLabel>
                            <input
                                type="password"
                                className={`form-control ${
                                    errors["user.newPasswordAgain"] ? "is-invalid" : ""
                                }`}
                                placeholder={t("user.passwordAgain", "Passwort wiederholen")}
                                name="userPasswordAgain"
                                autoComplete="repeat-password"
                                defaultValue={
                                    userData && userData.userPasswordAgain
                                        ? userData.userPasswordAgain
                                        : ""
                                }
                                onChange={handleChange}
                            />
                            <div className="invalid-feedback">
                                {errors["user.newPasswordAgain"]}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 d-flex align-items-center mt-3">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="is-coach"
                                    checked={isCoach}
                                    onChange={() => setIsCoach((prev) => !prev)}
                                />
                                <InputLabel
                                    required
                                    className="form-check-label"
                                    htmlFor="is-coach"
                                >
                                    {t("signup.isCoach", "Ich bin ein Trainer")}
                                </InputLabel>
                            </div>
                        </div>
                    </div>

                    {isCoach && (
                        <motion.div
                            key="coach"
                            initial="collapsed"
                            // animate="open"
                            exit="collapsed"
                            className="row"
                            animate={isCoach ? "open" : "closed"}
                            variants={{
                                open: { opacity: 1, height: "auto" },
                                collapsed: { opacity: 0, height: 0 },
                            }}
                        >
                            <div className="col-12 my-3">
                                <h3>
                                    {t("coach.additionalInfo", "Zusätzliche Angaben zum Trainer")}
                                </h3>
                            </div>
                            <div className="col-6">
                                <InputLabel optional>
                                    {t("coach.phoneNumber", "Telefonnummer")}
                                </InputLabel>
                                <input
                                    className={`form-control ${
                                        errors["coach.tel"] ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    name="coachTel"
                                    defaultValue={
                                        userData && userData.coachTel ? userData.coachTel : ""
                                    }
                                    placeholder="+49 222 222 etc."
                                    onChange={handleChange}
                                />

                                <div className="invalid-feedback">{errors["coach.tel"]}</div>
                            </div>
                            <div className="col-6">
                                <InputLabel required>{t("coach.email", "E-Mail")}</InputLabel>
                                <input
                                    type="text"
                                    className={`form-control ${
                                        errors["coach.email"] ? "is-invalid" : ""
                                    }`}
                                    placeholder={t("coach.email", "Email")}
                                    name="coachEmail"
                                    value={
                                        userData && userData.coachEmail ? userData.coachEmail : ""
                                    }
                                    onChange={handleChange}
                                    disabled={sameEmail}
                                />
                                <div className="invalid-feedback">{errors["coach.email"]}</div>
                                <div className="form-check mt-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="same-email"
                                        checked={sameEmail}
                                        onChange={() => setSameEmail((prev) => !prev)}
                                    />
                                    <InputLabel
                                        required
                                        className="form-check-label"
                                        htmlFor="same-email"
                                    >
                                        {t("signup.sameEmail", "Die selbe E-Mail nutzen.")}
                                    </InputLabel>
                                </div>
                            </div>
                            <div className="col-12">
                                <InputLabel optional>
                                    {t("coach.presentationText", "Vorstellungstext")}
                                </InputLabel>

                                <textarea
                                    id="coachText"
                                    name="coachText"
                                    className="form-control"
                                    rows={3}
                                    placeholder={t("coach.presentationText", "Vorstellungstext")}
                                    defaultValue={
                                        userData && userData.coachText ? userData.coachText : ""
                                    }
                                    onChange={handleChange}
                                />
                            </div>
                        </motion.div>
                    )}

                    <div className="row mt-3">
                        <div className="col-sm-12 d-flex align-items-center mt-3">
                            <p>
                                <Trans i18nKey="signup.privdis.description">
                                    Es gelten unsere <a href="/agb">AGB</a>. Ich habe die{" "}
                                    <a href="/privacy">Datenschutzerklärung</a> zur Kenntnis
                                    genommen.
                                </Trans>
                            </p>
                        </div>
                        <div className="col-12 d-grid mt-3">
                            <button
                                type="submit"
                                className="btn btn-save"
                                disabled={isPost || !canSubmit}
                            >
                                {t("global.btn.signup", "Registrieren")}
                            </button>
                        </div>
                        <div className="col-12">
                            <div className="pt-4 d-flex col justify-content-end">
                                <a href="/">
                                    {t("forgot.password.button.login", "Zurück zum Login")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Signup;
