/** @format */

import React from "react";

const CenteredLayout: React.FC = ({ children }) => {
    return (
        <div className="d-flex justify-content-center align-items-center container min-vh-100 login p-3 p-md-5">
            {children}
        </div>
    );
};

export default CenteredLayout;
