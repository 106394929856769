/** @format */

import PopUpLoading from "@components/PopUpAlert/Loading/PopUpLoading";
import PopUp from "@components/PopUpAlert/Save/PopUp";
import { LoadingProvider } from "@context/LoadingProvider";
import Connecting from "@screen/Connecting/Connecting";
import Agb from "@screen/UnauthenticatedScreens/Agb/Agb";
import ConfirmEmailChange from "@screen/UnauthenticatedScreens/ConfirmEmailChange/ConfirmEmailChange";
import ForgotPassword from "@screen/UnauthenticatedScreens/ForgotPassword/ForgotPassword";
import Imprint from "@screen/UnauthenticatedScreens/Imprint/Imprint";
import Login from "@screen/UnauthenticatedScreens/Login/Login";
import Privacy from "@screen/UnauthenticatedScreens/Privacy/Privacy";
import ResetPassword from "@screen/UnauthenticatedScreens/ResetPassword/ResetPassword";
import Signup from "@screen/UnauthenticatedScreens/Signup/Signup";
import SignupDone from "@screen/UnauthenticatedScreens/Signup/SignupDone/SignupDone";
import AcceptInvite from "@screen/UnauthenticatedScreens/ValidateEmail/AcceptInvite";
import ValidateEmail from "@screen/UnauthenticatedScreens/ValidateEmail/ValidateEmail";
import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, RouterProps } from "react-router-dom";

import AuthContext, { AuthProvider } from "./context/AuthProvider";
import { MainScreen } from "./router/MainRouter";

import "./services/i18n";
import "./services/sentry";

import "./App.scss";

interface PrivateRouteProps extends RouterProps {
    children: React.FC;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
    const { authentication } = useContext(AuthContext);

    if (authentication === null) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                authentication ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

const UnauthenticatedRouter: React.FC = () => {
    return (
        <div className="min-vh-100">
            <Switch>
                <Route path="/" component={Login} exact />
                <Route path="/signup/:bundle/:interval" component={Signup} />
                <Route path="/signup-done" component={SignupDone} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password/:token" component={ResetPassword} />
                <Route path="/validate-mail/:token" component={ValidateEmail} />
                <Route path="/accept-invite/:token" component={AcceptInvite} />
                <Route path="/confirm-email-change/:token" component={ConfirmEmailChange} />

                <Route path="/agb" component={Agb} />
                <Route path="/imprint" component={Imprint} />
                <Route path="/privacy" component={Privacy} />

                <Route path="*">
                    <Redirect to={{ pathname: "/" }} />
                </Route>
            </Switch>
        </div>
    );
};

const MainRouter: React.FC = () => {
    const { authentication, connected } = useContext(AuthContext);

    if (!connected) {
        return <Connecting />;
    }

    if (authentication === null) {
        return null;
    }

    return (
        <Switch>
            {authentication ? (
                <Route path="/" component={MainScreen} />
            ) : (
                <Route path="/" component={UnauthenticatedRouter} />
            )}
        </Switch>
    );
};

const App: React.FC = () => {
    return (
        <Router>
            <AuthProvider>
                <LoadingProvider>
                    <MainRouter />

                    <PopUpLoading />
                    <PopUp />
                </LoadingProvider>
            </AuthProvider>
        </Router>
    );
};
export default App;
