/** @format */

import { workoutcoachesAdapter } from "@api/ApiRequest";
import { WorkoutcoachesModel } from "@api/ext/WorkoutcoachesAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import UsersTable from "@components/Table/UsersTable/UsersTable";
import LoadingContext from "@context/LoadingProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";

interface CoachesViewParams {
    id: string | undefined;
}

const CoachesView: React.FC = () => {
    const [t] = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { setSaved } = useContext(LoadingContext);

    const { id } = useParams<CoachesViewParams>();

    const [coach, setCoach] = useState<WorkoutcoachesModel | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const doDeleteWorkoutcoach = useCallback(() => {
        if (!id || !coach) {
            return;
        }

        void workoutcoachesAdapter.delete(Number(id)).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("workoutcoaches.deleteSuccess", "Coach {{name}} wurde gelöscht.", {
                        name: coach.name,
                    }),
                });

                history.goBack();
            }
        });
    }, [id, setSaved, history, coach, t]);

    useEffect(() => {
        if (!id) {
            return;
        }

        workoutcoachesAdapter
            .view(id)
            .then((response) => {
                setCoach(response);

                setLoaded(true);
            })
            .catch((e) => console.log(e));
    }, [id]);

    if (!loaded) {
        return (
            <div className="col-12 text-center">
                <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
            </div>
        );
    }

    if (!coach || !coach?.id || !coach?.user) {
        return null;
    }

    return (
        <div className="workoutschedules-view">
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center header-text">
                        <h4>
                            <small>{t("global.coach", "Trainer")}</small>
                        </h4>
                        <h3>{coach.name}</h3>
                    </div>
                    <div className="d-flex btn-group align-items-center">
                        <Link
                            className="btn btn-sm btn-light"
                            to={{
                                pathname: `/coaches/edit/${coach.id}`,
                                state: { background: location },
                            }}
                        >
                            {t("global.edit", "Bearbeiten")}
                        </Link>
                        <button
                            className="btn btn-sm btn-danger-light"
                            onClick={() => {
                                setShowDeleteModal(true);
                            }}
                        >
                            {t("global.delete", "Löschen")}
                        </button>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <table className="table table-striped table-small">
                        <tbody>
                            <tr>
                                <th scope="row">{t("coach.coachName", "Trainer Name")}</th>
                                <td>{coach.name}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("coach.systemUser", "System Benutzer")}</th>
                                <td>
                                    <Link to={`/users/view/${coach.user?.id}`}>
                                        {coach.user?.name} {coach.user?.surname}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{t("coach.phoneNumber", "Telefonnummer")}</th>
                                <td>{coach.tel && <a href={`tel:${coach.tel}`}>{coach.tel}</a>}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("coach.email", "E-Mail")}</th>
                                <td>
                                    {coach.email && (
                                        <a href={`mailto:${coach.email}`}>{coach.email}</a>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    {t("coach.presentationText", "Vorstellungstext")}
                                </th>
                                <td>{coach.text}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("global.created", "Erstellt")}</th>
                                <td>
                                    {DateTime.fromISO(String(coach.created), {
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy")}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{t("global.modified", "Geändert")}</th>
                                <td>
                                    {DateTime.fromISO(String(coach.modified), {
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy")}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-12 mt-3">
                    <h4>{t("workoutschedules.assignedUsers", "Zugewiesene Benutzer")}</h4>
                    <UsersTable
                        data={coach.workout_users ? coach.workout_users : []}
                        hideColumns={["coach", "company", "lastlogin", "archived"]}
                    />
                </div>
            </div>
            {showDeleteModal && (
                <ConfirmDialogue
                    title={t("workoutcoaches.deleteModalTitle", "Coach löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDeleteWorkoutcoach}
                >
                    <div className="p-3">
                        <p>
                            {t(
                                "workoutcoaches.singleDeleteText",
                                "Möchten Sie den Coach {{name}} jetzt löschen?",
                                {
                                    name: coach.name,
                                }
                            )}
                        </p>
                    </div>
                </ConfirmDialogue>
            )}
        </div>
    );
};

export default CoachesView;
