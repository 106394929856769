/** @format */

import {
    extendedUserAdapter,
    subscriptionAdapter,
    workoutUserAttributeAdapter,
    storage,
} from "@api/ApiRequest";
import { UploadImage } from "@api/ext/ExtendedUserAdapter";
import { AuthUserModel } from "@api/ext/GlobalUserAdapter";
import { SubscriptionInfo } from "@api/ext/SubscriptionAdapter";
import { WorkoutUserAttributeModel } from "@api/ext/WorkoutUserAttributeAdapter";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../hook/useAuth";
import { Connection } from "../services/Connection";

const defaultValue: {
    authentication: boolean | null;
    user: AuthUserModel | null;
    refetchUser: () => Promise<void>;
    logout: () => void;
    validateAuth: () => void;
    profileImage: UploadImage | null;
    attributes: WorkoutUserAttributeModel | null;
    refetchSubscription: () => void;
    subscription: SubscriptionInfo | null;
    setSubscription: React.Dispatch<React.SetStateAction<SubscriptionInfo | null>>;
    token: string | null;
    connected: boolean;
} = {
    authentication: null,
    user: null,
    refetchUser: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    validateAuth: () => null,
    profileImage: null,
    attributes: null,
    refetchSubscription: () => null,
    subscription: null,
    setSubscription: () => null,
    token: null,
    connected: true,
};

const AuthContext = React.createContext(defaultValue);

const AuthProvider: React.FC = ({ children }) => {
    const [authentication, user, , refetchUser, doLogout, validateAuth] = useAuth("AuthProvider");

    const history = useHistory();

    const [profileImage, setProfileImage] = useState<UploadImage | null>(null);
    const [attributes, setAttributes] = useState<WorkoutUserAttributeModel | null>(null);
    const [subscription, setSubscription] = useState<SubscriptionInfo | null>(null);

    const refetchSubscription = useCallback(() => {
        if (!user?.is_admin && !user?.is_coach) {
            return;
        }

        void subscriptionAdapter.info().then((value) => setSubscription(value));
    }, [user]);

    const [connected, setConnected] = useState<boolean>(true);

    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        if (authentication && localStorage !== null) {
            void storage.getItem("auth_token").then((value) => {
                if (value) {
                    setToken(value);
                }
            });
        } else {
            setToken(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication, localStorage]);

    useEffect(() => {
        if (authentication && user) {
            void extendedUserAdapter.profileImage().then((value) => {
                if (value) {
                    setProfileImage(value);
                }
            });

            void workoutUserAttributeAdapter.getAttribute().then((value) => {
                setAttributes(value);
            });

            if (!user?.is_admin && !user?.is_coach) {
                return;
            }

            void subscriptionAdapter.info().then((value) => setSubscription(value));
        }
        // only update profileImage, attributes and subscription when user changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (!user && profileImage) {
            setProfileImage(null);
        }
    }, [user, profileImage]);

    useEffect(() => {
        if (!user && attributes) {
            setAttributes(null);
        }
    }, [user, attributes]);

    const handleLogout = () => {
        void doLogout();
        history.push("/");
    };

    useEffect(() => {
        Connection.subscribe({
            connected: true,
            event(data: boolean) {
                setConnected(data);
            },
        });
    }, []);

    const providerValue = {
        authentication,
        user,
        logout: handleLogout,
        refetchUser,
        validateAuth,
        profileImage,
        attributes,
        refetchSubscription,
        subscription,
        setSubscription,
        token,
        connected,
    };

    return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
