/** @format */

import React from "react";

interface TableSearchProps {
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    search: string;
    placeholder?: string;
}

const TableSearch: React.FC<TableSearchProps> = ({ handleSearch, search, placeholder = "" }) => {
    return (
        <input
            className="form-control"
            onChange={(e) => handleSearch(e)}
            type="text"
            placeholder={placeholder}
            value={search}
        />
    );
};

export default TableSearch;
