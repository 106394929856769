/*
 * @format
 */

import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useContext } from "react";

const Logout: React.FC = () => {
    useLoading();

    const { logout } = useContext(AuthContext);

    useEffect(() => {
        setTimeout(() => {
            logout();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default Logout;
