/** @format */

import AuthContext from "@context/AuthProvider";
import { ChatProvider } from "@context/ChatProvider";
import { ExerciseProvider } from "@context/ExerciseProvider";
import { UserViewProvider } from "@context/UserViewProvider";
import ChatView from "@screen/Chat/ChatView";
import CoachesAdd from "@screen/Coaches/CoachesAdd/CoachesAdd";
import CoachesEdit from "@screen/Coaches/CoachesEdit/CoachesEdit";
import CoachesTable from "@screen/Coaches/CoachesIndex";
import CoachesView from "@screen/Coaches/CoachesView/CoachesView";
import CollectionScreen from "@screen/CollectionScreen/CollectionScreen";
import Dashboard from "@screen/Dashboard/Dashboard";
import EquipmentAdd from "@screen/Equipment/EquipmentAdd/EquipmentAdd";
import EquipmentEdit from "@screen/Equipment/EquipmentEdit/EquipmentEdit";
import ExerciseForm from "@screen/Exercises/ExerciseForm/ExerciseForm";
import ExerciseView from "@screen/Exercises/ExerciseView/ExerciseView";
import ExercisetagAdd from "@screen/Exercisetags/ExercisetagAdd/ExercisetagAdd";
import ExercisetagEdit from "@screen/Exercisetags/ExercisetagEdit/ExercisetagEdit";
import Logout from "@screen/Logout/Logout";
import MuscelesgroupsIndex from "@screen/Muscelesgroups/MuscelesgroupsIndex/MuscelesgroupsIndex";
import MuscleAdd from "@screen/Muscles/MuscleAdd/MuscleAdd";
import MuscleEdit from "@screen/Muscles/MuscleEdit/MuscleEdit";
import Profile from "@screen/Profile/Profile";
import ConfirmEmailChange from "@screen/UnauthenticatedScreens/ConfirmEmailChange/ConfirmEmailChange";
import ResetPassword from "@screen/UnauthenticatedScreens/ResetPassword/ResetPassword";
import AcceptInvite from "@screen/UnauthenticatedScreens/ValidateEmail/AcceptInvite";
import ValidateEmail from "@screen/UnauthenticatedScreens/ValidateEmail/ValidateEmail";
import UserAddForm from "@screen/Users/UserAddForm/UserAddForm";
import UserIndex from "@screen/Users/UserIndex/UserIndex";
import UserSearch from "@screen/Users/UserSearch/UserSearch";
import UserView from "@screen/Users/UserView/UserView";
import Welcome from "@screen/Welcome/Welcome";
import ExerciseWrapper from "@screen/Workoutschedules/ExerciseWrapper";
import WorkoutschedulesEdit from "@screen/Workoutschedules/WorkoutschedulesEdit/WorkoutschedulesEdit";
import WorkoutschedulesIndex from "@screen/Workoutschedules/WorkoutschedulesIndex/WorkoutschedulesIndexView";
import WorkoutschedulesUserAdd from "@screen/Workoutschedules/WorkoutschedulesUserAdd/WorkoutschedulesUserAdd";
import WorkoutschedulesView from "@screen/Workoutschedules/WorkoutschedulesView/WorkoutschedulesView";
import { Location, LocationState } from "history";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

interface ScreenProps extends Location<LocationState> {
    background: Location<LocationState>;
}

const AuthenticatedRouter: React.FC = () => {
    const { user, attributes } = useContext(AuthContext);
    const [showWelcome, setShowWelcome] = useState<boolean>(false);

    const location = useLocation<ScreenProps>();

    const background = location.state && location.state.background;

    useEffect(() => {
        if (!attributes || !user?.emailconfirmed) {
            return;
        }

        setShowWelcome((prev) => {
            if (prev) {
                return prev;
            }

            return !attributes.first_login;
        });
    }, [attributes, user]);

    return (
        <ChatProvider>
            {showWelcome && <Welcome show={showWelcome} onHide={() => setShowWelcome(false)} />}
            <Switch location={background || location}>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>

                <Route
                    path="/users"
                    render={({ match: { url } }) => (
                        <>
                            <Route path={`${url}/`} component={UserIndex} exact />
                            <Route path={`${url}/archived`} component={UserIndex} exact />
                            <Route path={`${url}/add`} component={UserAddForm} />
                            <Route path={`${url}/edit/:id`} component={UserAddForm} />
                            <UserViewProvider>
                                <Route path={`${url}/view/:id`} component={UserView} />
                            </UserViewProvider>
                        </>
                    )}
                />

                <Route
                    path="/exercises"
                    render={({ match: { url } }) => (
                        <>
                            <ExerciseProvider>
                                <Route path={`${url}/add`} component={ExerciseForm} />
                                <Route path={`${url}/edit/:id`} component={ExerciseForm} />
                            </ExerciseProvider>
                        </>
                    )}
                />

                <Route
                    path="/coaches"
                    render={({ match: { url } }) => (
                        <>
                            <Route path={`${url}/`} component={CoachesTable} exact />
                            <Route path={`${url}/view/:id`} component={CoachesView} />
                        </>
                    )}
                />

                <Route
                    path="/workoutschedules"
                    render={({ match: { url } }) => (
                        <>
                            <Route path={`${url}/`} component={WorkoutschedulesIndex} exact />
                            <Route path={`${url}/add`} component={ExerciseWrapper} exact />
                            <Route path={`${url}/add/:customUserId`} component={ExerciseWrapper} />
                            <Route path={`${url}/edit/:id`} component={WorkoutschedulesEdit} />
                            <Route path={`${url}/view/:id`} component={WorkoutschedulesView} />
                        </>
                    )}
                />

                <Route path="/muscelesgroups" component={MuscelesgroupsIndex} exact />

                <Route path="/collection" component={CollectionScreen} />

                <Route path="/profile" component={Profile} />

                <Route path="/logout" exact component={Logout} />

                <Route path="/reset-password/:token" component={ResetPassword} />
                <Route path="/validate-mail/:token" component={ValidateEmail} />
                <Route path="/accept-invite/:token" component={AcceptInvite} />
                <Route path="/confirm-email-change/:token" component={ConfirmEmailChange} />

                <Route path="*">
                    <Redirect to={{ pathname: "/" }} />
                </Route>
            </Switch>

            {background && (
                <>
                    <Route path="/coaches/add" children={<CoachesAdd />} />
                    <Route path="/coaches/edit/:id" children={<CoachesEdit />} />

                    <Route path="/usersearch" children={<UserSearch />} />

                    <Route path="/chat" children={<ChatView />} />

                    <Route
                        path="/workoutschedules/chose/:id"
                        children={<WorkoutschedulesUserAdd />}
                    />
                    <Route path="/exercises/view/:id" children={<ExerciseView />} />

                    <Route path="/muscles/add" children={<MuscleAdd />} />
                    <Route path="/muscles/edit/:id" children={<MuscleEdit />} />

                    <Route path="/equipment/add" children={<EquipmentAdd />} />
                    <Route path="/equipment/edit/:id" children={<EquipmentEdit />} />

                    <Route path="/exercisetags/add" children={<ExercisetagAdd />} />
                    <Route path="/exercisetags/edit/:id" children={<ExercisetagEdit />} />
                </>
            )}
        </ChatProvider>
    );
};

export default AuthenticatedRouter;
