/** @format */

import { workoutschedulesAdapter } from "@api/ApiRequest";
import { WorkoutschedulesIndex } from "@api/ext/WorkoutschedulesAdapter";
import { faPlusCircle, faMinusCircle, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./UserWorkoutschedulesAssignment.scss";

interface UserWorkoutschedulesAssignmentProps {
    user_id: number;
    disabled?: boolean;
}

const UserWorkoutschedulesAssignment: React.FC<UserWorkoutschedulesAssignmentProps> = ({
    user_id,
    disabled = false,
}) => {
    const [t] = useTranslation();

    const history = useHistory();

    const [usersWorkoutschedules, setUsersWorkoutschedules] = useState<WorkoutschedulesIndex[]>([]);
    const [allWorkoutschedules, setAllWorkoutschedules] = useState<WorkoutschedulesIndex[]>([]);

    const getUsersWorkoutschedules = useCallback((id: number) => {
        workoutschedulesAdapter
            .getUsersWorkoutschedules(id)
            .then((value) => {
                setUsersWorkoutschedules(value);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const getAllWorkoutschedules = useCallback(() => {
        workoutschedulesAdapter
            .getWorkoutschedules()
            .then((value) => {
                setAllWorkoutschedules(value);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const assign = useCallback((id: number, workoutschedule_id: number) => {
        workoutschedulesAdapter
            .addUserWorkoutschedule(id, workoutschedule_id)
            .then((value) => {
                if (!value) {
                    return;
                }

                const newAssignment: WorkoutschedulesIndex = {
                    id: value.id,
                    name: value.name,
                };

                setUsersWorkoutschedules((schedules) => {
                    return [...schedules, newAssignment];
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const unassign = useCallback((id: number, workoutschedule_id: number) => {
        workoutschedulesAdapter
            .unassignUserWorkoutschedule(id, workoutschedule_id)
            .then((response) => {
                setUsersWorkoutschedules((schedules) => {
                    return schedules.filter((ele) => ele.id !== workoutschedule_id);
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        if (!user_id) {
            return;
        }

        getUsersWorkoutschedules(user_id);
        getAllWorkoutschedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id]);

    return (
        <>
            <div className="col-md-6 col-12 my-3 user-workoutschedules-assignement">
                <h4>{t("user.addedWorkoutschedules", "Zugewiesene Trainingspläne")}</h4>
                {usersWorkoutschedules.map((ele) => {
                    return (
                        <div className="d-flex assigned d-flex align-items-center" key={ele.id}>
                            <div
                                className={`toggle-button remove ${disabled ? "disabled" : ""}`}
                                onClick={() => unassign(user_id, ele.id)}
                            >
                                <FontAwesomeIcon icon={faMinusCircle} className="fa-lg" />
                            </div>
                            <div
                                className={`toggle-button edit ${disabled ? "disabled" : ""}`}
                                onClick={() => history.push(`/workoutschedules/edit/${ele.id}`)}
                            >
                                <FontAwesomeIcon icon={faPencil} className="fa-lg" />
                            </div>
                            {ele.name}
                        </div>
                    );
                })}
            </div>
            <div className="col-md-6 col-12 my-3 user-workoutschedules-assignement">
                <h4>{t("user.addWorkoutschedules", "Trainingspläne Zuweisen")}</h4>
                <div className="d-flex unassigned mb-3 personal-assign">
                    <div
                        className={`toggle-button ${disabled ? "disabled" : ""}`}
                        onClick={() => history.push(`/workoutschedules/add/${user_id}`)}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="fa-lg" />
                    </div>
                    {t("user.personalWorkoutschedules", "Persöhnlicher Trainingsplan")}
                </div>

                {usersWorkoutschedules &&
                    allWorkoutschedules.map((ele) => {
                        if (usersWorkoutschedules.find((elem) => elem.id === ele.id)) {
                            return null;
                        }

                        return (
                            <div className="d-flex unassigned" key={ele.id}>
                                <div
                                    className={`toggle-button ${disabled ? "disabled" : ""}`}
                                    onClick={() => assign(user_id, ele.id)}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} className="fa-lg" />
                                </div>
                                {ele.name}
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default UserWorkoutschedulesAssignment;
