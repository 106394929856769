/** @format */

import { workoutcoachesAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import ModalView from "@components/Modal/ModalView";
import LoadingContext from "@context//LoadingProvider";
import AuthContext from "@context/AuthProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import { Modal } from "bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";

import "./CoachesEdit.scss";

interface CoachesEditParams {
    id: string;
}

const CoachesEdit: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setSaved, setShouldRefetch } = useContext(LoadingContext);

    const { id } = useParams<CoachesEditParams>();

    const [name, setName] = useState<string>("");
    const [tel, setTel] = useState<string>("");
    const [mail, setMail] = useState<string>("");
    const [text, setText] = useState<string>("");

    const [post, setPost] = useState<boolean>(false);

    const [selectable, setSelectable] = useState<null | OptionType[]>(null);
    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);

    const [selected, setSelected] = useState<SelectedOption>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    useEffect(() => {
        void workoutcoachesAdapter.getEditCoach(id).then((value) => {
            const { companies } = value;
            const { name, user_id, tel, text, email, company_id } = value.workoutcoach;
            const passedUser = value.workoutcoach.user;

            setName(name);
            if (passedUser) {
                setSelected({ value: user_id, label: `${passedUser.name}  ${passedUser.surname}` });
            }
            setTel(tel ? tel : "");
            setMail(email);
            setText(text ? text : "");

            const updateSelect: { value: string; label: string }[] = [];
            Object.keys(value.users).map((key) => {
                updateSelect.push({ value: key, label: value.users[Number(key)] });
            });

            setSelectable(updateSelect);

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            const defaultCompany = updateCompaniesSelect.find(
                (ele) => Number(ele.value) === company_id
            );
            setSelectedCompany(defaultCompany ? defaultCompany : updateCompaniesSelect[0]);
        });
    }, [id, user]);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPost(true);

        void workoutcoachesAdapter
            .edit(Number(id), {
                name,
                tel,
                email: mail,
                text,
                user_id: Number(selected?.value),
                company_id: id
                    ? undefined
                    : user?.is_admin && selectedCompany
                    ? Number(selectedCompany.value)
                    : user?.company_id,
            })
            .then(() => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    if (!selectable || !user) {
        return null;
    }

    return (
        <ModalView
            title={t("coach.edit", "Trainer bearbeiten")}
            pushRef={handlePushClose}
            goBack="coaches"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="coachCompany">
                                {t("coach.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="coachCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                                isDisabled={!!id}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-6">
                        <InputLabel required htmlFor="coachName">
                            {t("coach.coachName", "Trainer Name")}
                        </InputLabel>
                        <input
                            id="coachName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            required
                            value={name}
                        />
                    </div>
                    <div className="col-6">
                        <InputLabel required htmlFor="coachUser">
                            {t("coach.systemUser", "System Benutzer")}
                        </InputLabel>
                        <Select
                            id="coachUser"
                            value={selected}
                            required
                            placeholder={t("global.selectEmptyChose", "-- Wähle --")}
                            onChange={(e) => setSelected(e)}
                            options={selectable}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <InputLabel optional htmlFor="coachTel">
                            {t("coach.phoneNumber", "Telefonnummer")}
                        </InputLabel>
                        <input
                            id="coachTel"
                            className="form-control"
                            onChange={(e) => setTel(e.target.value)}
                            type="text"
                            placeholder="+49 222 222 etc."
                            value={tel}
                        />
                    </div>
                    <div className="col-6">
                        <InputLabel required htmlFor="coachMail">
                            {t("coach.email", "E-Mail")}
                        </InputLabel>
                        <input
                            id="coachMail"
                            className="form-control"
                            onChange={(e) => setMail(e.target.value)}
                            type="email"
                            required
                            placeholder="musterman@interweber.de"
                            value={mail}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <InputLabel optional htmlFor="coachText">
                            {t("coach.presentationText", "Vorstellungstext")}
                        </InputLabel>
                        <textarea
                            id="coachText"
                            className="form-control"
                            onChange={(e) => setText(e.target.value)}
                            rows={3}
                            placeholder="Text"
                            value={text}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("coach.save", "Trainer speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default CoachesEdit;
