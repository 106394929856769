/** @format */

import ExerciseContext from "@context/ExerciseProvider";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

const SelectDescription: React.FC = () => {
    const { description } = useContext(ExerciseContext);

    if (!description) {
        return null;
    }

    return (
        <div className="row mt-2">
            <div className="col">
                <div className="select-text-holder">
                    {description.map((value, index) => (
                        <div key={uuidv4()} className="col mt-2">
                            <div className="select-text">
                                <span className="font-weight-bold">
                                    {index + 1}
                                    .)
                                </span>{" "}
                                {value.text}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SelectDescription;
