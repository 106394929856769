/** @format */

import { extendedUserAdapter, globalUserAdapter, onboardingAdapter } from "@api/ApiRequest";
import { ExtendedUserModel } from "@api/ext/ExtendedUserAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import RowOptionWrapper from "@components/Table/RowOptionWrapper/RowOptionWrapper";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import UsersTable from "@components/Table/UsersTable/UsersTable";
import LoadingContext from "@context/LoadingProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import {
    faArchive,
    faEdit,
    faInfoSquare,
    faMailbox,
    faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

const UnarchivedUserIndex: React.FC = () => {
    const [t] = useTranslation();
    const { setLoading } = useLoading();
    const { setSaved } = useContext(LoadingContext);
    const { updateUserCount } = useSubscriptionContext();

    const [users, setUsers] = useState<ExtendedUserModel[]>([]);
    const [selectedSingleUser, setSelectedSingleUser] = useState<ExtendedUserModel | null>(null);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        extendedUserAdapter
            .getIndex(search)
            .then((data) => {
                setUsers(data.users);
                setLoading(false);
            })
            .catch((e) => console.log(e));
    }, [setLoading, search]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    const onSelectedChange = useCallback((selectedIds) => {
        setSelectedUserIds(selectedIds);
    }, []);

    const doInviteUser = useCallback(() => {
        let idsToSend = null;

        if (selectedSingleUser) {
            idsToSend = selectedSingleUser.id;
        }

        if (selectedUserIds.length && !idsToSend) {
            idsToSend = selectedUserIds;
        }

        if (!idsToSend) {
            return;
        }

        void onboardingAdapter.invite(idsToSend).then((response) => {
            setSelectedSingleUser(null);

            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("userView.inviteSend", "Einladung versendet."),
                });

                fetchData();
            }
        });
    }, [fetchData, selectedSingleUser, selectedUserIds, setSaved, t]);

    const doArchiveUser = useCallback(() => {
        let idsToSend = null;

        if (selectedSingleUser) {
            idsToSend = selectedSingleUser.id;
        }

        if (selectedUserIds.length && !idsToSend) {
            idsToSend = selectedUserIds;
        }

        if (!idsToSend) {
            return;
        }

        void globalUserAdapter.archive(idsToSend).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("userView.archiveSuccess", "Benutzer wurden archiviert."),
                });

                fetchData();
                updateUserCount();
            }
        });
    }, [fetchData, selectedSingleUser, selectedUserIds, updateUserCount, setSaved, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData, search]);

    return (
        <>
            <div className="row align-items-center">
                <div className="col-md-6 col-sm-9 col-12 py-3">
                    <TableSearch
                        handleSearch={handleSearch}
                        placeholder={t("global.search", "Suche")}
                        search={search}
                    />
                </div>
                <div className="col-md-6 col-sm-3 pull-right">
                    <div className="d-flex col justify-content-end">
                        <Link to="/users/add" className="btn btn-save align-self-end">
                            <FontAwesomeIcon icon={faUserPlus} />
                        </Link>
                    </div>
                </div>
            </div>
            <UsersTable
                data={users}
                selectable
                onSelectedChange={onSelectedChange}
                hideColumns={["archived"]}
                multiSelectOptions={
                    <>
                        <button
                            className="btn btn-sm btn-light"
                            onClick={() => setShowInviteModal(true)}
                        >
                            {t("global.sendInvite", "Einladung senden")}
                        </button>
                        <button
                            className="btn btn-sm btn-danger-light"
                            onClick={() => setShowArchiveModal(true)}
                        >
                            {t("global.archive", "Archivieren")}
                        </button>
                    </>
                }
                rowOptions={{
                    id: "options",
                    Header: "",
                    Cell: ({ row }: CellProps<ExtendedUserModel>) => {
                        if (!row.original.id) {
                            return null;
                        }

                        return (
                            <RowOptionWrapper id={`dMenue-${row.original.id}`}>
                                <li>
                                    <Link
                                        className="dropdown-item option-dropdown"
                                        to={`/users/view/${row.original.id}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoSquare}
                                            className="option-icon"
                                        />
                                        {t("global.view", "Ansicht")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="dropdown-item option-dropdown"
                                        to={`/users/edit/${row.original.id}`}
                                    >
                                        <FontAwesomeIcon icon={faEdit} className="option-icon" />
                                        {t("global.edit", "Bearbeiten")}
                                    </Link>
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item option-dropdown"
                                        onClick={() => {
                                            setSelectedSingleUser(row.original);
                                            setShowInviteModal(true);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMailbox} className="option-icon" />
                                        {!row.original.last_invited
                                            ? t("global.sendInvite", "Einladung senden")
                                            : t("global.reinvite", "Erneut einladen")}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item option-dropdown option-danger"
                                        onClick={() => {
                                            setSelectedSingleUser(row.original);
                                            setShowArchiveModal(true);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArchive} className="option-icon" />
                                        {t("global.archive", "Archivieren")}
                                    </button>
                                </li>
                            </RowOptionWrapper>
                        );
                    },
                }}
            />
            {showInviteModal && (
                <ConfirmDialogue
                    title={t("user.reinviteModalTitle", "Benutzer erneut einladen")}
                    show={showInviteModal}
                    onHide={() => setShowInviteModal(false)}
                    onSubmit={doInviteUser}
                >
                    <div className="p-3">
                        {selectedSingleUser ? (
                            <p>
                                {t(
                                    "users.singleInviteText",
                                    "Möchten Sie diesem Benutzer {{name}} jetzt eine Einladung senden?",
                                    {
                                        name:
                                            selectedSingleUser.name +
                                            " " +
                                            selectedSingleUser.surname,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {t(
                                    "users.multiInviteText",
                                    "Sie haben {{num}} Benutzer ausgewählt. Möchten Sie diesen Benutzern jetzt eine Einladungs-Email senden?",
                                    {
                                        num: selectedUserIds.length,
                                    }
                                )}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
            {showArchiveModal && (
                <ConfirmDialogue
                    title={t("user.reinviteModalTitle", "Benutzer erneut einladen")}
                    show={showArchiveModal}
                    onHide={() => setShowArchiveModal(false)}
                    onSubmit={doArchiveUser}
                >
                    <div className="p-3">
                        {selectedSingleUser ? (
                            <p>
                                {t(
                                    "users.singleArchiveText",
                                    "Möchten Sie diesem Benutzer {{name}} jetzt archivieren?",
                                    {
                                        name:
                                            selectedSingleUser.name +
                                            " " +
                                            selectedSingleUser.surname,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {t(
                                    "users.multiArchiveText",
                                    "Sie haben {{num}} Benutzer ausgewählt. Möchten Sie diesen Benutzern jetzt Archivieren?",
                                    {
                                        num: selectedUserIds.length,
                                    }
                                )}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default UnarchivedUserIndex;
