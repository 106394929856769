/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

export interface InvoiceCountry {
    id: number;
    country_code: string;
    name: string;
}

export interface InvoiceRecipient extends InvoiceRecipientAdd {
    id: number;
    country: InvoiceCountry;
    created: DateTime;
}

export interface InvoiceRecipientAdd extends Model {
    country_id: number;
    company: string;
    company2: string;
    sex: string;
    name: string;
    surname: string;
    address: string;
    address2: string;
    zip: string;
    city: string;
    ustid: string;
    email: string;
    phone: string;
    invoice_email: string;
}

export interface InvoiceChangeRespond {
    success: boolean;
    errors?: {
        [model: string]: {
            [field: string]: {
                [error_code: string]: string;
            };
        };
    };
}

export default class InvoiceRecipientAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    InvoiceRecipient,
    Paginator,
    InvoiceRecipientAdd
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("invoice/Recipients", "Recipient", "Recipients", adapter);
    }

    public async getCurrent(): Promise<{
        recipient: InvoiceRecipient | null;
        countries: InvoiceCountry[];
    }> {
        const { data } = await this._get<{
            recipient?: InvoiceRecipient | null;
            countries: InvoiceCountry[];
        }>(`getCurrent`);

        return { recipient: data?.recipient ?? null, countries: data?.countries ?? [] };
    }

    public async change(recipient: InvoiceRecipientAdd): Promise<InvoiceChangeRespond> {
        const { data } = await this._post<InvoiceChangeRespond>(`change`, recipient);

        return data;
    }
}
