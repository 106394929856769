/** @format */

import { ApiAdapter, RequestAdapterInterface } from "@interweberde/prima-core";
import { DateTime } from "luxon";

import { Mandate, PaymentMethod } from "./MandateAdapter";
import { SubscriptionTypeOption } from "./SubscriptiontypeAdapter";

export enum SubscriptionStatus {
    PAID = "paid",
    ACTIVE = "active",
    PENDING = "pending",
    CANCELED = "canceled",
    TRIAL = "trial",
    MOLLIE_PENDING = "mollie_pending",
    SUSPENDED = "suspended",
}

export enum SubscriptionInterval {
    MONTHLY = "1 month",
    QUARTERLY = "3 months",
    YEARLY = "12 month",
}

enum MollieSubscriptionStatus {
    STATUS_ACTIVE = "active",
    STATUS_PENDING = "pending", // Waiting for a valid mandate.
    STATUS_CANCELED = "canceled",
    STATUS_SUSPENDED = "suspended", // Active, but mandate became invalid.
    STATUS_COMPLETED = "completed",
}

type MollieSubscription = {
    id: string;
    interval: string;
    method: PaymentMethod;
    amount: {
        value: string;
        currency: string;
    };
    mandate_id: string;
    mandate: Mandate | null;
    startDate: DateTime;
    canceledAt: DateTime | null;
    nextPaymentDate: DateTime | null;
    status: MollieSubscriptionStatus;
};

export type BillingDetail = {
    subscription_id: number;
    company: string;
    company2: string | null;
    name: string;
    surname: string;
    address: string;
    address2: string | null;
    zip: string;
    city: string;
    ustid: string | null;
    email: string;
    phone: string;
    invoice_email: string | null;
};

type SubscriptionInfoRespond = {
    status: SubscriptionStatus;
    is_active: boolean;
    start_date: string;
    last_payment: string | null;
    last_paid: string | null;
    seat_count: number;
    client_subscription: {
        id: string;
        interval: string;
        method: PaymentMethod;
        amount: {
            value: string;
            currency: string;
        };
        mandate_id: string;
        mandate: Mandate | null;
        startDate: string;
        canceledAt: string | null;
        nextPaymentDate: string | null;
        status: MollieSubscriptionStatus;
    } | null;
    subscriptiontypeoption_id: number;
    trial_end_date: string | null;
    subscription_end_date: string | null;
    billingdetail: BillingDetail | null;
    mollie_db_subscription: MollieDbSubscription;
};

export type MollieDbSubscription = {
    id: number;
    mandantors_mollie_subscription_id: number;
    mollie_subscription_id: number;
    seat_count: number;
    subscriptiontypeoption_id: number;
    subscriptiontypeoption: SubscriptionTypeOption;
};

export type SubscriptionInfo = {
    status: SubscriptionStatus;
    is_active: boolean;
    start_date: DateTime;
    last_payment: DateTime | null;
    last_paid: DateTime | null;
    seat_count: number;
    client_subscription: MollieSubscription | null;
    subscriptiontypeoption_id: number;
    mollie_db_subscription: MollieDbSubscription;
    trial_end_date: DateTime | null;
    subscription_end_date: DateTime | null;
    billingdetail: BillingDetail | null;
};

export type SeatChangeCost = {
    month: number;
    monthGross: number;
    once: number;
    onceGross: number;
    seatDiff: number;
    dayDiff: number;
    totalSeats: number;
};

export default class SubscriptionAdapter<RequestConfigType> extends ApiAdapter<RequestConfigType> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter, "subscriptions/subscriptions");
    }

    public async getCustomerData(): Promise<object> {
        const response = await this._get<object>("get-customer-data");

        if (!response.data) {
            throw new Error("invalid response");
        }

        const data = response.data;

        return data;
    }

    public async info(): Promise<SubscriptionInfo> {
        const response = await this._get<SubscriptionInfoRespond>("info");

        if (!response.data) {
            throw new Error("invalid response");
        }

        const data = response.data;

        return {
            ...data,
            subscriptiontypeoption_id: data.subscriptiontypeoption_id,
            mollie_db_subscription: data.mollie_db_subscription,
            start_date: DateTime.fromISO(data.start_date),
            last_payment: data.last_payment ? DateTime.fromISO(data.last_payment) : null,
            last_paid: data.last_paid ? DateTime.fromISO(data.last_paid) : null,
            trial_end_date: data.trial_end_date ? DateTime.fromISO(data.trial_end_date) : null,
            subscription_end_date: data.subscription_end_date
                ? DateTime.fromISO(data.subscription_end_date)
                : null,
            client_subscription: data.client_subscription
                ? {
                      ...data.client_subscription,
                      startDate: DateTime.fromISO(data.client_subscription.startDate),
                      canceledAt: data.client_subscription.canceledAt
                          ? DateTime.fromISO(data.client_subscription.canceledAt)
                          : null,
                      nextPaymentDate: data.client_subscription.nextPaymentDate
                          ? DateTime.fromISO(data.client_subscription.nextPaymentDate)
                          : null,
                  }
                : null,
        };
    }

    public async changeSubscription(
        seatCount: number,
        subscriptiontypeoptionId: number,
        mandateId?: string
    ): Promise<boolean> {
        const { data } = await this._post<{ status: boolean }>("change-subscription", {
            seatCount,
            subscriptiontypeoptionId,
            mandateId,
        });

        return data.status;
    }

    public async changeSubscriptionMandate(mandateId?: string): Promise<boolean> {
        const { data } = await this._post<{ status: boolean }>("change-subscription-mandate", {
            mandateId,
        });

        return data.status;
    }

    public async subscriptionChangeCost(
        seats: number,
        subscriptiontypeoptionId: number
    ): Promise<SeatChangeCost> {
        const { data } = await this._get<{ cost: SeatChangeCost }>(
            `subscription-change-cost/${seats}/${subscriptiontypeoptionId}`
        );

        if (!data || !data.cost) {
            throw new Error("invalid response");
        }

        return {
            month: data.cost.month,
            monthGross: data.cost.monthGross,
            once: data.cost.once,
            onceGross: data.cost.onceGross,
            seatDiff: data.cost.seatDiff,
            dayDiff: data.cost.dayDiff,
            totalSeats: data.cost.totalSeats,
        };
    }

    public async cancelSubscription(): Promise<void> {
        await this._delete("cancel-subscription");
    }

    public async changePaymentMethod(mandateId: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(
            `change-payment-method/${mandateId}`
        );

        return data.success;
    }
}
