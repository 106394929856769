/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { workoutcoachesAdapter } from "@api/ApiRequest";
import { WorkoutcoachesModel } from "@api/ext/WorkoutcoachesAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import CoachTable from "@components/Table/CoachTable/CoachTable";
import RowOptionWrapper from "@components/Table/RowOptionWrapper/RowOptionWrapper";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faPlus, faEdit, faInfoSquare, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CellProps } from "react-table";

const CoachesIndex: React.FC = () => {
    const [t] = useTranslation();
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const { setSaved, shouldRefetch, setShouldRefetch } = useContext(LoadingContext);
    const { setLoading } = useLoading();

    const [workoutcoachesDefault, setWorkoutcoachesDefault] = useState<WorkoutcoachesModel[]>([]);
    const [workoutcoaches, setWorkoutcoaches] = useState<WorkoutcoachesModel[]>([]);

    const [selectedWorkoutcoach, setSelectedWorkoutcoach] = useState<WorkoutcoachesModel | null>(
        null
    );

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        workoutcoachesAdapter
            .getIndex()
            .then((response) => {
                setWorkoutcoaches(response);
                setWorkoutcoachesDefault(response);

                setLoading(false);
            })
            .catch(() => null);
    }, [setLoading]);

    const doDeleteWorkoutcoach = useCallback(() => {
        if (!selectedWorkoutcoach?.id) {
            return;
        }

        void workoutcoachesAdapter.delete(selectedWorkoutcoach?.id).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("workoutcoaches.deleteSuccess", "Coach {{name}} wurde gelöscht.", {
                        name: selectedWorkoutcoach.name,
                    }),
                });

                fetchData();
            }
        });
    }, [fetchData, selectedWorkoutcoach, setSaved, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (shouldRefetch) {
            fetchData();
            setShouldRefetch(false);
        }
    }, [fetchData, setShouldRefetch, shouldRefetch]);

    useEffect(() => {
        if (search) {
            workoutcoachesAdapter
                .getIndex(search)
                .then((response) => {
                    setWorkoutcoaches(response);
                })
                .catch(() => null);
        } else {
            setWorkoutcoaches(workoutcoachesDefault);
        }
    }, [search, workoutcoachesDefault]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <h1>Trainer</h1>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <div className="d-flex col justify-content-end">
                        <Link
                            className="btn btn-default align-self-end"
                            to={{
                                pathname: `/coaches/add`,
                                state: { background: location },
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} /> {t("global.add", "Hinzufügen")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-6 ps-0 pb-2 pt-2">
                <TableSearch
                    handleSearch={handleSearch}
                    placeholder={t("global.search", "Suche")}
                    search={search}
                />
            </div>
            <CoachTable
                data={workoutcoaches}
                rowOptions={{
                    id: "options",
                    Header: "",
                    Cell: ({ row }: CellProps<WorkoutcoachesModel>) => {
                        if (!row.original.id) {
                            return null;
                        }

                        return (
                            <RowOptionWrapper id={`dMenue-${row.original.id}`}>
                                <li>
                                    <Link
                                        className="dropdown-item option-dropdown"
                                        to={`/coaches/view/${row.original.id}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoSquare}
                                            className="option-icon"
                                        />
                                        {t("global.view", "Ansicht")}
                                    </Link>
                                </li>
                                {((user?.is_admin && row.original.editableMandantor) ||
                                    (user?.is_coach && row.original.editableCompany)) && (
                                    <>
                                        <li>
                                            <Link
                                                className="dropdown-item option-dropdown"
                                                to={{
                                                    pathname: `/coaches/edit/${row.original.id}`,
                                                    state: { background: location },
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="option-icon"
                                                />
                                                {t("global.edit", "Bearbeiten")}
                                            </Link>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item option-dropdown option-danger"
                                                onClick={() => {
                                                    setSelectedWorkoutcoach(row.original);
                                                    setShowDeleteModal(true);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="option-icon"
                                                />
                                                {t("global.delete", "Löschen")}
                                            </button>
                                        </li>
                                    </>
                                )}
                            </RowOptionWrapper>
                        );
                    },
                }}
            />
            {showDeleteModal && (
                <ConfirmDialogue
                    title={t("workoutcoaches.deleteModalTitle", "Coach löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDeleteWorkoutcoach}
                >
                    <div className="p-3">
                        {selectedWorkoutcoach ? (
                            <p>
                                {t(
                                    "workoutcoaches.singleDeleteText",
                                    "Möchten Sie den Coach {{name}} jetzt löschen?",
                                    {
                                        name: selectedWorkoutcoach.name,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {/* {t(
                                    "workoutcoaches.multiDeleteText",
                                    "Sie haben {{num}} Coaches ausgewählt. Möchten Sie die Coaches jetzt löschen?",
                                    {
                                        num: selectedWorkoutcoachIds.length,
                                    }
                                )} */}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default CoachesIndex;
