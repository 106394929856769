/** @format */

import { extendedUserAdapter, workoutschedulesAdapter } from "@api/ApiRequest";
import { UserSearchModel } from "@api/ext/ExtendedUserAdapter";
import LoadingContext from "@context//LoadingProvider";
import { faSpinnerThird, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import ModalView from "../../../components/Modal/ModalView";
import SingleUserElement from "./SingleUserElement/SingleUserElement";

import "./WorkoutschedulesUserAdd.scss";

let timeoutId: ReturnType<typeof setTimeout>;

interface WorkoutschedulesAddParams {
    id: string | undefined;
}

const WorkoutschedulesUserAdd: React.FC = () => {
    const [t] = useTranslation();

    const { id } = useParams<WorkoutschedulesAddParams>();

    const { setSaved } = useContext(LoadingContext);

    const input = useRef<HTMLInputElement>(null);
    const history = useHistory();

    const [search, setSearch] = useState<string>("");

    const [users, setUsers] = useState<UserSearchModel[]>([]);
    const [usersSelected, setUsersSelected] = useState<UserSearchModel[]>([]);

    const [searchLoad, setSearchLoad] = useState<boolean>(false);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);
    const [
        modalRef,
        setModalRef,
    ] = useState<null | React.MutableRefObject<HTMLDivElement | null> | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (input.current) {
                input.current.focus();
            }
        }, 500);
    }, []);

    useEffect(() => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            void extendedUserAdapter.searchUsers(search, true).then((value) => {
                setSearchLoad(false);
                setUsers(value);
            });
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [search]);

    const handlePushClose = (
        modal: Modal,
        ref: React.MutableRefObject<HTMLDivElement | null> | null
    ) => {
        setModaInstance(modal);
        setModalRef(ref);
    };

    const handleClose = (url: string) => {
        if (modalInstance && modalRef && modalRef.current) {
            modalRef.current.addEventListener("hidden.bs.modal", function () {
                history.push(url);
            });

            modalInstance.hide();
        }
    };

    const handleChecked = (e: React.ChangeEvent<HTMLInputElement>, value: UserSearchModel) => {
        let defaultSelect: UserSearchModel[] = [];

        if (e.target.checked) {
            defaultSelect = [value, ...usersSelected];
        } else {
            const defaultData = [...usersSelected];

            const findSelect = defaultData.findIndex((data) => {
                return data.id === value.id;
            });

            defaultData.splice(findSelect, 1);

            defaultSelect = defaultData;
        }

        setUsersSelected(defaultSelect);
    };

    const handleRemoveSelected = (id: number) => {
        let defaultSelect: UserSearchModel[] = [];
        const defaultData = [...usersSelected];

        const findSelect = defaultData.findIndex((data) => {
            return data.id === id;
        });

        defaultData.splice(findSelect, 1);

        defaultSelect = defaultData;

        setUsersSelected(defaultSelect);
    };

    const handleSubmit = () => {
        if (!id) {
            return null;
        }

        const userIds: number[] = [];

        usersSelected.map((value) => {
            userIds.push(value.id);
        });

        const someId = Number(id);
        void workoutschedulesAdapter
            .setUsersWorkoutschedules({ id: someId, user_ids: userIds })
            .then((data) => {
                if (data) {
                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("assign.success", "Trainingspläne zugewiesen"),
                    });

                    if (modalInstance) {
                        modalInstance.hide();
                    }

                    history.push("/workoutschedules");
                } else {
                    setSaved({
                        type: "failed",
                        show: true,
                        text: t("assign.error", "Konnte nicht zugewiesen wwerden!"),
                    });
                }
            });
    };

    if (!id) {
        return null;
    }
    return (
        <ModalView title={t("user.assign", "Benutzer zuweisen")} pushRef={handlePushClose} stopHide>
            <div className="row">
                <div className="col-12 position-relative">
                    <input
                        ref={input}
                        id="searchUser"
                        className="form-control form-control-lg"
                        onChange={(e) => {
                            setSearch(e.target.value);
                            setSearchLoad(true);
                        }}
                        type="text"
                        placeholder={t("user.search", "Benutzer suchen")}
                        required
                        value={search}
                    />
                    <div className="user-serach-icon">
                        {searchLoad ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : null}
                    </div>
                </div>
                <div className="col-12 question-user mt-2">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    <span className="ms-2">
                        {t(
                            "user.question",
                            "Gib einen Benutzer mit seinem Namen oder Nachnamen ein"
                        )}
                    </span>
                </div>
            </div>
            <div className="user-box">
                {users.map((value) => (
                    <React.Fragment key={`rs-${value.id}`}>
                        <SingleUserElement
                            value={value}
                            type="s"
                            handleChecked={handleChecked}
                            handleClose={handleClose}
                        />
                    </React.Fragment>
                ))}
                {users.length === 0 ? (
                    <div className="d-flex justify-content-lg-center align-items-center mt-3">
                        <p className="text-black-50">
                            {t("user.noUsersFound", "Keine Benutzer gefunden")}
                        </p>
                    </div>
                ) : null}
            </div>
            <hr />
            <div className="accordion" id="choseUsers">
                <div className="accordion-item userchose-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button userchose-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#activeUser"
                            aria-expanded="false"
                            aria-controls="activeUser"
                        >
                            <span className="badge bg-secondary me-2">{usersSelected.length}</span>{" "}
                            {t("user.chosed", "Benutzer ausgewählt")}
                        </button>
                    </h2>
                    <div
                        id="activeUser"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#choseUsers"
                    >
                        <div className="accordion-body">
                            {usersSelected.map((value) => (
                                <React.Fragment key={`rc-${value.id}`}>
                                    <SingleUserElement
                                        value={value}
                                        type="c"
                                        handleChecked={handleChecked}
                                        handleClose={handleClose}
                                        handleRemoveSelected={handleRemoveSelected}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex justify-content-end align-items-end">
                    <button
                        className="btn btn-sm btn-save"
                        type="submit"
                        disabled={!usersSelected.length}
                        onClick={() => handleSubmit()}
                    >
                        {t("global.assign", "Zuweisen")}
                    </button>
                </div>
            </div>
        </ModalView>
    );
};

export default WorkoutschedulesUserAdd;
