/** @format */

import { MuscleData } from "@api/ext/ExerciseAdapter";
import { Muscle } from "@context/ExerciseProvider";
import { faCheckSquare, faSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import ModalSmall from "../../Modal/ModalSmall";
import SelectStateMuscle from "./SelectStateMuscle";

interface ModalViewProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    defaultData: Muscle[] | null;
    title: string;
    setData: React.Dispatch<React.SetStateAction<Muscle[] | null>>;
    extra?: MuscleData[];
}

const ModalViewMuscle: React.FC<ModalViewProps> = ({
    show,
    setShow,
    defaultData,
    title,
    setData,
    extra,
}) => {
    const [search, setSearch] = useState<string>("");

    const [serachData, setSearchData] = useState<Muscle[] | null>(null);
    const [currentData, setCurrentData] = useState<Muscle[] | null>(null);
    const [currentDataId, setCurrentDataId] = useState<number[]>([]);

    useEffect(() => {
        if (search) {
            const textSearch = search.toUpperCase();

            if (!defaultData) {
                return;
            }

            const filtered = defaultData.filter((value) => {
                const itemData = `${value.name.toUpperCase()}`;

                return itemData.indexOf(textSearch) > -1;
            });

            setSearchData(filtered);
        } else {
            setSearchData(null);
        }
    }, [search, defaultData]);

    const handleSelect = (id: number) => {
        const newDefault: Muscle[] = [];

        if (!defaultData) {
            return null;
        }

        defaultData.map((value) => {
            newDefault.push(id === value.id ? { ...value, active: !value.active } : value);
        });

        setData(newDefault);
    };

    useEffect(() => {
        setCurrentData(serachData ? serachData : defaultData);
    }, [serachData, defaultData]);

    useEffect(() => {
        if (currentData) {
            const currentId: number[] = [];
            currentData.map((value) => {
                currentId.push(value.id);
            });
            setCurrentDataId(currentId);
        } else {
            setCurrentDataId([]);
        }
    }, [currentData]);

    const handleSelectActiveMuscle = (id: number, type: number) => {
        const newDefault: Muscle[] = [];

        if (!defaultData) {
            return null;
        }

        defaultData.map((value) => {
            newDefault.push(
                id === value.id
                    ? {
                          ...value,
                          type: value.type === type ? 0 : type,
                      }
                    : value
            );
        });

        setData(newDefault);
    };

    if (!extra) {
        return (
            <div>
                <p>Noch keine Daten hinzugefügt</p>
            </div>
        );
    }

    if (!defaultData) {
        return null;
    }

    return (
        <ModalSmall title={title} handleSearch={setSearch} show={show} setShow={setShow}>
            <div className="col modalShowSearch">
                {extra.map((value) => (
                    <div key={uuidv4()}>
                        {value.exercisemuscles.some((e) => currentDataId.includes(e)) && (
                            <h6 className="mt-2 text-header-muscle">{value.name}</h6>
                        )}

                        <div className="list-group">
                            {currentData &&
                                Object.keys(currentData).map((i) => (
                                    <div key={uuidv4()}>
                                        {value.exercisemuscles.includes(
                                            currentData[Number(i)].id
                                        ) ? (
                                            <div
                                                className={`list-group-item muscle-select-item-wrapper justify-content-between  d-flex ${
                                                    currentData[Number(i)].active
                                                        ? " itemGroup-active"
                                                        : ""
                                                }`}
                                            >
                                                <div
                                                    className="d-flex w-100 muscle-select-item left-muscle-item"
                                                    onClick={() =>
                                                        handleSelect(currentData[Number(i)].id)
                                                    }
                                                >
                                                    <div className="d-flex me-2">
                                                        {currentData[Number(i)].active ? (
                                                            <FontAwesomeIcon
                                                                icon={faCheckSquare}
                                                                className="align-self-center"
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faSquare}
                                                                className="align-self-center"
                                                            />
                                                        )}
                                                    </div>
                                                    {currentData[Number(i)].name}
                                                </div>
                                                {currentData[Number(i)].active && (
                                                    <SelectStateMuscle
                                                        id={currentData[Number(i)].id}
                                                        handleClick={handleSelectActiveMuscle}
                                                        stateMuscle={Number(
                                                            currentData[Number(i)]?.type
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </ModalSmall>
    );
};

export default ModalViewMuscle;
