/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { WorkoutcoachesModel } from "@api/ext/WorkoutcoachesAdapter";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import TableWithPagination from "../TableWithPagination";

import "./CoachTable.scss";

type CoachTableColumns = "select" | "image" | "name" | "phone" | "email" | "created" | "options";

interface CoachTableProps {
    data: WorkoutcoachesModel[];
    hideColumns?: CoachTableColumns[];
    selectable?: boolean;
    onSelectedChange?: (selectedIds: number[]) => void;
    multiSelectOptions?: React.ReactNode;
    rowOptions?: React.ReactNode;
}

const CoachTable: React.FC<CoachTableProps> = ({
    data,
    hideColumns = [],
    selectable = false,
    onSelectedChange = () => null,
    multiSelectOptions = null,
    rowOptions = null,
}) => {
    const { t } = useTranslation();

    const columns = useMemo(() => {
        const imageCell = !hideColumns.includes("image")
            ? [
                  {
                      Header: "",
                      id: "image",
                      Cell: ({ row }: CellProps<WorkoutcoachesModel>) => {
                          if (!row.original.user?.uploads || !row.original.id) {
                              return null;
                          }

                          if (row.original.user?.uploads?.length > 0) {
                              return (
                                  <Link to={`/coaches/view/${row.original.id}`}>
                                      <div className="d-flex justify-content-center w-100 text-center">
                                          <img
                                              src={`${row.original.user?.uploads[0].url}`}
                                              alt=""
                                              className="profileImage"
                                          />
                                      </div>
                                  </Link>
                              );
                          }

                          return (
                              <Link to={`/coaches/view/${row.original.id}`}>
                                  <div className="d-flex justify-content-center w-100 text-center">
                                      <div className="userCircle">
                                          {row.original.user.name.charAt(0) +
                                              row.original.user.surname.charAt(0)}
                                      </div>
                                  </div>
                              </Link>
                          );
                      },
                  },
              ]
            : [];

        const nameCell = !hideColumns.includes("name")
            ? [
                  {
                      Header: t("coach.coachName", "Trainer Name"),
                      accessor: "name",
                      Cell: ({ row }: CellProps<WorkoutcoachesModel>) => {
                          if (!row.original.id) {
                              return null;
                          }

                          return (
                              <Link to={`/coaches/view/${row.original.id}`} className="cell-link">
                                  {row.original.name}
                              </Link>
                          );
                      },
                      className: "w-50",
                  },
              ]
            : [];

        const phoneCell = !hideColumns.includes("phone")
            ? [
                  {
                      Header: t("coach.phoneNumber", "Telefonnummer"),
                      accessor: "tel",
                      Cell: ({ row }: CellProps<WorkoutcoachesModel>) => {
                          if (!row.original.tel) {
                              return null;
                          }

                          return (
                              <a className="cell-link" href={`tel:${row.original.tel}`}>
                                  {row.original.tel}
                              </a>
                          );
                      },
                      className: "w-25",
                  },
              ]
            : [];

        const emailCell = !hideColumns.includes("email")
            ? [
                  {
                      Header: t("coach.email", "E-Mail"),
                      accessor: "email",
                      Cell: ({ row }: CellProps<WorkoutcoachesModel>) => {
                          if (!row.original.email) {
                              return null;
                          }

                          return (
                              <a className="cell-link" href={`mailto:${row.original.email}`}>
                                  {row.original.email}
                              </a>
                          );
                      },
                      className: "w-25",
                  },
              ]
            : [];

        const createdCell = !hideColumns.includes("created")
            ? [
                  {
                      Header: t("equipment.created", "Erstellt"),
                      accessor: "lastlogin",
                      Cell: ({ row }: CellProps<WorkoutcoachesModel>) => {
                          const created: string = row.original.created
                              ? row.original.created.toString()
                              : "";

                          return (
                              <div className="flex justify-start w-100">
                                  {created === ""
                                      ? "-"
                                      : DateTime.fromISO(created).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const rowOptionsCell = rowOptions ? [rowOptions] : [];

        return [
            ...(imageCell || []),
            ...(nameCell || []),
            ...(phoneCell || []),
            ...(emailCell || []),
            ...(createdCell || []),
            ...(rowOptionsCell || []),
        ];
    }, [hideColumns, rowOptions, t]);

    return <TableWithPagination columns={columns} data={data} selectable={selectable} />;
};

export default CoachTable;
