/** @format */

import PaymentInterval from "@components/PaymentInterval/PaymentInterval";
import RenderPaymentMethod from "@components/RenderPaymentMethod/RenderPaymentMethod";
import AuthContext from "@context/AuthProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

const SubscriptionDone: React.FC = () => {
    const history = useHistory();

    const { user, refetchSubscription } = useContext(AuthContext);
    const { subscription } = useSubscriptionContext();

    useEffect(() => {
        if (!subscription.client_subscription) {
            history.push("/");
        }
        refetchSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h1 className="mb-4">Vielen Dank, {user?.name}.</h1>
            <p>
                Du hast das{" "}
                <span className="text-uppercase">
                    {
                        subscription.mollie_db_subscription.subscriptiontypeoption?.subscriptiontype
                            ?.title
                    }
                </span>
                -Paket erfolgreich abonniert.
            </p>
            <p>
                Wie vereinbart, werden wir <PaymentInterval subscription={subscription} />{" "}
                {subscription.client_subscription?.amount?.value}{" "}
                {subscription.client_subscription?.amount?.currency} inkl. MwSt. einziehen.
            </p>
            <p>
                Gewähltes Zahlungsmittel:{" "}
                <RenderPaymentMethod mandate={subscription.client_subscription?.mandate} />
            </p>
        </div>
    );
};

export default SubscriptionDone;
