/** @format */

import { TrainingsessionModel } from "@api/ext/TrainingsessionsAdapter";
import {
    faComment,
    faMeh,
    faFrown,
    faSmile,
    faLaugh,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

interface FooterProps {
    trainingsession: TrainingsessionModel;
}

const Footer: React.FC<FooterProps> = ({ trainingsession }) => {
    const [t] = useTranslation();

    return (
        <div className="col-12">
            <div className="trainingsession-footer">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <div className="trainingsesion-icon">
                            <FontAwesomeIcon icon={faComment} />
                        </div>

                        <h4 className="tbody-head">
                            {t("trainingSessions.footer.feedback", "Feedback")}
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex col-6">
                        <div className="feedback-icon">
                            <FontAwesomeIcon
                                icon={faFrown}
                                className={`${
                                    trainingsession.trainingsessionfeedbacks[0].feedback === 1
                                        ? "active"
                                        : ""
                                }`}
                            />
                        </div>
                        <div className="feedback-icon">
                            <FontAwesomeIcon
                                icon={faMeh}
                                className={`${
                                    trainingsession.trainingsessionfeedbacks[0].feedback === 2
                                        ? "active"
                                        : ""
                                }`}
                            />
                        </div>
                        <div className="feedback-icon">
                            <FontAwesomeIcon
                                icon={faSmile}
                                className={`${
                                    trainingsession.trainingsessionfeedbacks[0].feedback === 3
                                        ? "active"
                                        : ""
                                }`}
                            />
                        </div>
                        <div className="feedback-icon">
                            <FontAwesomeIcon
                                icon={faLaugh}
                                className={`${
                                    trainingsession.trainingsessionfeedbacks[0].feedback === 4
                                        ? "active"
                                        : ""
                                }`}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="feedback-text">
                            {t("trainingSessions.footer.comment", "Kommentar")}:
                        </div>
                        {trainingsession.trainingsessionfeedbacks[0] ? (
                            <div className="feedback-comment d-flex">
                                <div className="feedback-comment-icon">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>

                                {trainingsession.trainingsessionfeedbacks[0].text}
                            </div>
                        ) : (
                            <div className="feedback-notext">
                                {t("trainingSessions.footer.noComment", "Kein Kommentar")}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
