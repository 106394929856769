/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useRouteMatch, NavLink } from "react-router-dom";

import Company from "./Company/Company";
import Password from "./Password/Password";
import Invoices from "./Payment/Invoices/Invoices";
import PaymentOverview from "./Payment/PaymentOverview";
import ManageSubscription from "./Payment/Subscription/ManageSubscription";
import Settings from "./Settings/Settings";

import "./Profile.scss";

const Profile: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const { path, url } = useRouteMatch();

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <ul className="nav flex-column profile-nav">
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                aria-current="page"
                                to={`${url}`}
                            >
                                {t("profile.settings", "Einstellungen")}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                activeClassName="active"
                                className="nav-link"
                                to={`${url}/password`}
                            >
                                {t("profile.password", "Kennwort")}
                            </NavLink>
                        </li>
                        {(user?.is_admin || user?.is_coach) && (
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    to={`${url}/company`}
                                >
                                    {t("profile.company", "Unternehmen")}
                                </NavLink>
                            </li>
                        )}
                        {user?.is_admin && (
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        activeClassName="active"
                                        className="nav-link"
                                        to={`${url}/payment`}
                                    >
                                        {t("profile.payment", "Bezahlung")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        activeClassName="active"
                                        className="nav-link"
                                        to={`${url}/invoices`}
                                    >
                                        {t("profile.invoices", "Rechnungen")}
                                    </NavLink>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
                <div className="col-9">
                    <Switch>
                        <Route exact path={path} component={Settings} />
                        <Route path={`${path}/password`} component={Password} />
                        {(user?.is_admin || user?.is_coach) && (
                            <Route path={`${path}/company`} component={Company} />
                        )}
                        {user?.is_admin && (
                            <Switch>
                                <Route path={`${path}/payment`} component={PaymentOverview} />
                                <Route
                                    path={`${path}/subscription`}
                                    component={ManageSubscription}
                                />
                                <Route path={`${path}/invoices`} component={Invoices} />
                            </Switch>
                        )}
                        {/* <Route path={`${url}/progress`} component={Trainingsession} />
                        <Route path={`${url}/notes`} component={Notes} /> */}
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Profile;
