/** @format */

import { PaymentMethod } from "@api/ext/MandateAdapter";
import { SubscriptionStatus } from "@api/ext/SubscriptionAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import RenderPaymentMethod from "@components/RenderPaymentMethod/RenderPaymentMethod";
import AuthContext from "@context/AuthProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import ManageMandate from "../../Mandate/ManageMandate/ManageMandate";
import CreditCard from "./CreditCard/CreditCard";
import Paypal from "./Paypal/Paypal";
import Sepa from "./Sepa/Sepa";

import "../../PaymentOverview.scss";

const SubscriptionPayment: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const {
        subscription,
        setMethod,
        onSubscriptionChange,
        onSubmitMethod,
        changed,
        method,
        selectedMandate,
        setSelectedMandate,
    } = useSubscriptionContext();

    const [agbAccepted, setAgbAccepted] = useState(false);
    const [showManageMandate, setShowManageMandate] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);

    const onAbortChangeMandate = useCallback(() => {
        setShowManageMandate(false);

        if (subscription.client_subscription?.mandate) {
            setSelectedMandate(subscription.client_subscription.mandate);

            return;
        }

        setSelectedMandate(null);
    }, [setSelectedMandate, subscription?.client_subscription?.mandate]);

    useEffect(() => {
        if (subscription.client_subscription?.mandate?.method) {
            setMethod(subscription?.client_subscription?.mandate?.method as PaymentMethod);
        }
    }, [setMethod, subscription]);

    useEffect(() => {
        if (!changed && subscription.is_active) {
            history.push("/profile/subscription");

            return;
        }

        if (!agbAccepted || !method) {
            setCanSubmit(false);

            return;
        }

        setCanSubmit(true);
    }, [changed, history, subscription.is_active, agbAccepted, method]);

    return (
        <>
            <h1>
                {t("subscription.payment.pay.title", "{{name}}, wie möchtest Du bezahlen?", {
                    name: user?.name,
                })}
            </h1>
            <div className="col-12 d-flex flex-column justify-content-center">
                <>
                    {/* {!changeSeatCostLoading ? (
                        <>
                            {subscription.status === SubscriptionStatus.TRIAL ||
                            subscription.status === SubscriptionStatus.CANCELED ? (
                                <div className="d-flex flex-column">
                                    <p>
                                        {currencyFormatter.format(changeSeatCost.monthGross)} (
                                        <PaymentInterval subscription={subscription} />)
                                    </p>
                                </div>
                            ) : (
                                <div className="d-flex flex-column">
                                    <p>Einmalig: {currencyFormatter.format(changeSeatCost.onceGross)}</p>
                                    <p>
                                        Ab nächstem Abrechnungszeitraum:{" "}
                                        {currencyFormatter.format(changeSeatCost.monthGross)} (
                                        <PaymentInterval subscription={subscription} />)
                                    </p>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faSpinnerThird}
                                spin
                                className="loading-icon-spinner ms-5"
                            />
                        </>
                    )} */}
                    {subscription?.client_subscription && selectedMandate && (
                        <>
                            <RenderPaymentMethod mandate={selectedMandate} />

                            <div className="link" onClick={() => setShowManageMandate(true)}>
                                {t(
                                    "subscription.payment.method.changeMethod",
                                    "Zahlungsmethode ändern"
                                )}
                            </div>
                        </>
                    )}
                </>
            </div>

            {subscription.status === SubscriptionStatus.TRIAL && (
                <div className="row d-flex my-4">
                    {method !== PaymentMethod.DIRECTDEBIT && (
                        <>
                            <Paypal active={method === PaymentMethod.PAYPAL} />
                            <CreditCard active={method === PaymentMethod.CREDITCARD} />
                        </>
                    )}
                    <Sepa setCanSubmit={setCanSubmit} />
                </div>
            )}

            <div className="form-check my-5">
                <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="same-email"
                    checked={agbAccepted}
                    onChange={() => setAgbAccepted((prev) => !prev)}
                />
                <label className="form-check-label" htmlFor="same-email">
                    {t(
                        "subscription.payment.method.acceptAgb",
                        "Ich akzeptiere die Nutzungsbedingungen von Intercoach."
                    )}
                </label>
            </div>

            <div className="row">
                <div className="col-12 d-flex justify-content-between px-0">
                    <NavLink
                        activeClassName="active"
                        className="btn btn-default"
                        to="/profile/subscription/data"
                    >
                        {t("global.back", "Zurück")}
                    </NavLink>
                    {/* {subscription.status === SubscriptionStatus.TRIAL && (
                        <button
                            className={`btn btn-save ${!changed ? "disabled" : ""}`}
                            onClick={onSubscriptionChange}
                        >
                            {t("global.saveChange", "Änderung speichern")}
                        </button>
                    )} */}
                    {subscription.client_subscription && subscription.is_active ? (
                        <button
                            className={`btn btn-save ${
                                (!changed && subscription.is_active) || !canSubmit ? "disabled" : ""
                            }`}
                            onClick={onSubscriptionChange}
                        >
                            Kostenpflichtig ändern
                        </button>
                    ) : subscription.status !== SubscriptionStatus.CANCELED ||
                      !subscription.is_active ? (
                        <button
                            className={`btn btn-save ${
                                (!changed && subscription.is_active) || !canSubmit ? "disabled" : ""
                            }`}
                            onClick={() => onSubmitMethod(false)}
                        >
                            Kostenpflichtig Abschließen
                        </button>
                    ) : (
                        <button
                            className={`btn btn-save ${
                                (!changed && subscription.is_active) || !canSubmit ? "disabled" : ""
                            }`}
                            onClick={() => onSubmitMethod(true)}
                        >
                            Abo jetzt reaktivieren
                        </button>
                    )}
                </div>
            </div>
            {showManageMandate && (
                <ConfirmDialogue
                    show={showManageMandate}
                    title="Manage Mandates"
                    onHide={() => setShowManageMandate(false)}
                    onAbort={onAbortChangeMandate}
                    onSubmit={() => setShowManageMandate(false)}
                >
                    <ManageMandate isModal />
                </ConfirmDialogue>
            )}
        </>
    );
};

export default SubscriptionPayment;
