/** @format */

import "./ExercisetagEdit.scss";
import { exercisetagAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import { Modal } from "bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

import ModalView from "../../../components/Modal/ModalView";

interface ExercisetagEditParams {
    id: string;
}

const ExercisetagEdit: React.FC = () => {
    const [t] = useTranslation();
    const { setSaved, setShouldRefetch } = useContext(LoadingContext);
    const { user } = useContext(AuthContext);
    const { id } = useParams<ExercisetagEditParams>();

    const history = useHistory();

    const [exercisetagId, setExercisetagId] = useState<number | null>(null);
    const [name, setName] = useState<string>("");

    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [post, setPost] = useState<boolean>(false);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!exercisetagId) {
            return;
        }

        setPost(true);
        void exercisetagAdapter
            .edit(Number(exercisetagId), {
                name,
                company_id: id
                    ? undefined
                    : user?.is_admin && selectedCompany
                    ? Number(selectedCompany.value)
                    : user?.company_id,
            })
            .then((response) => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    useEffect(() => {
        void exercisetagAdapter.getEditExercisetag(id).then((response) => {
            const { exercisetag, companies } = response;
            if (
                !(user?.is_admin && exercisetag.editableMandantor) &&
                !(user?.is_coach && exercisetag.editableCompany)
            ) {
                return history.push("/collection/exercisetags");
            }

            setExercisetagId(exercisetag.id);
            setName(exercisetag.name);

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            const defaultCompany = updateCompaniesSelect.find(
                (ele) => Number(ele.value) === exercisetag.company_id
            );

            setSelectedCompany(defaultCompany ? defaultCompany : updateCompaniesSelect[0]);
        });
    }, [id, user?.is_admin, user?.is_coach, history]);

    if (!user) {
        return null;
    }

    return (
        <ModalView
            title={t("exercisetag.edit", "Problem-Tag bearbeiten")}
            pushRef={handlePushClose}
            goBack="collection/exercisetags"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="exercisetagCompany">
                                {t("exercisetag.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="exercisetagCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                                isDisabled={!!id}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12">
                        <InputLabel required htmlFor="exercisetagName">
                            {t("exercisetag.exercisetagName", "Problem-Tag")}
                        </InputLabel>
                        <input
                            id="exercisetagName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            required
                            value={name}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button disabled={!name} className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("exercisetag.save", "Problem-Tag speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default ExercisetagEdit;
