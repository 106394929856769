/** @format */

import { extendedMandantorAdapter, invoiceRecipientAdapter } from "@api/ApiRequest";
import { InvoiceCountry, InvoiceRecipientAdd } from "@api/ext/InvoiceRecipientAdapter";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import InvoiceRecipientForm from "./InvoiceRecipientForm";

const InvoiceData: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setLoading } = useLoading();

    const [shouldCreate, setShouldCreate] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [canContinue, setCanContinue] = useState<boolean>(false);
    const [countriesList, setCountriesList] = useState<InvoiceCountry[]>([]);
    const [initialData, setInitialData] = useState<InvoiceRecipientAdd | null>(null);

    const fetchRecipient = useCallback(() => {
        setLoading(true);

        void invoiceRecipientAdapter.getCurrent().then(({ recipient, countries }) => {
            setCountriesList(countries);

            if (!recipient) {
                void extendedMandantorAdapter.getMandantor().then((mandantor) => {
                    setInitialData({
                        sex: user ? user.sex : "",
                        name: user ? user.name : "",
                        surname: user ? user.surname : "",
                        address: mandantor.address ?? "",
                        address2: "",
                        zip: mandantor.zip ?? "",
                        city: mandantor.city ?? "",
                        ustid: "",
                        country_id: 1,
                        company: mandantor.name ?? "",
                        company2: "",
                        email: user ? user.email : mandantor.email ? mandantor.email : "",
                        phone: mandantor.phone ?? "",
                        invoice_email: "",
                    });

                    setShouldCreate(true);
                    setLoading(false);
                });

                return;
            }

            setShouldCreate(false);

            setInitialData({
                sex: recipient.sex ?? "",
                name: recipient.name ?? "",
                surname: recipient.surname ?? "",
                address: recipient.address ?? "",
                address2: recipient.address2 ?? "",
                zip: recipient.zip ?? "",
                city: recipient.city ?? "",
                ustid: recipient.ustid ?? "",
                country_id: recipient.country_id ?? 1,
                company: recipient.company ?? "",
                company2: recipient.company2 ?? "",
                email: recipient.email ?? "",
                phone: recipient.phone ?? "",
                invoice_email: recipient.invoice_email ?? "",
            });

            setLoading(false);
            setCanContinue(true);
        });
    }, [setLoading, user]);

    const afterSave = useCallback(() => {
        fetchRecipient();
        setShowForm(false);
    }, [fetchRecipient]);

    useEffect(() => {
        fetchRecipient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowForm(shouldCreate);
    }, [shouldCreate]);

    return (
        <div>
            <div className="flex flex-col mb-12">
                <h2 className="flex mx-auto mb-4 text-2xl">
                    {t(
                        "subscription.information.title",
                        "{{name}}, bitte fülle die Abrechnungsdaten aus",
                        {
                            name: user?.name,
                        }
                    )}
                </h2>
            </div>
            {!showForm && !shouldCreate && initialData && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-striped table-small">
                                <tbody>
                                    <tr>
                                        <th scope="row">{t("invoice.company", "Firma")}</th>
                                        <td>{initialData.company}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.company2", "Firma2")}</th>
                                        <td>{initialData.company2}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.sex", "Geschlecht")}</th>
                                        <td>
                                            {initialData.sex === "f"
                                                ? t("global.sex.f", "weiblich")
                                                : initialData.sex === "m"
                                                ? t("global.sex.m", "männlich")
                                                : t("global.sex.d", "sonstiges")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.name", "Name")}</th>
                                        <td>{initialData.name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.surname", "Nachname")}</th>
                                        <td>{initialData.surname}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.address", "Adresse")}</th>
                                        <td>{initialData.address}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.address2", "Adresse2")}</th>
                                        <td>{initialData.address2}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.plz", "PLZ")}</th>
                                        <td>{initialData.zip}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.city", "Stadt")}</th>
                                        <td>{initialData.city}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.country", "Land")}</th>
                                        <td>
                                            {countriesList && initialData.country_id
                                                ? countriesList.filter(
                                                      (i) => i.id === initialData.country_id
                                                  )[0].name
                                                : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("invoice.phone", "Telefon")}</th>
                                        <td>{initialData.phone}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("company.email", "E-Mail")}</th>
                                        <td>{initialData.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("company.ustid", "Ust.-ID")}</th>
                                        <td>{initialData.ustid}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            {t("company.invoiceEmail", "E-Mail (Rechnungsversand)")}
                                        </th>
                                        <td>{initialData.invoice_email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                            <button
                                className="btn btn-save"
                                type="button"
                                onClick={() => setShowForm(true)}
                            >
                                {t("global.edit", "Bearbeiten")}
                            </button>
                            <NavLink
                                className={`btn btn-save ${!canContinue ? "disabled" : ""}`}
                                to="payment"
                            >
                                {t("global.continue", "Weiter")}
                            </NavLink>
                        </div>
                    </div>
                </>
            )}
            {showForm && (
                <InvoiceRecipientForm
                    initialData={initialData}
                    countriesList={countriesList}
                    onBack={() => setShowForm(false)}
                    onAfterSave={afterSave}
                />
            )}
        </div>
    );
};

export default InvoiceData;
