/** @format */

import { Mandate, PaymentMethod } from "@api/ext/MandateAdapter";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface RenderPaymentMethodProps {
    mandate?: Mandate | null;
}

const RenderPaymentMethod: React.FC<RenderPaymentMethodProps> = ({ mandate }) => {
    const [t] = useTranslation();

    const paymentMethod = useMemo(() => {
        if (!mandate) {
            return undefined;
        }

        switch (mandate.method) {
            case PaymentMethod.CREDITCARD:
                return t("subscriptions.general.method.creditcard", {
                    defaultValue: "{{type}} (Gültig bis {{expiry}}. Endet auf {{number}})",
                    type: mandate.details?.cardLabel,
                    number: mandate.details?.cardNumber,
                    expiry: mandate.details?.cardExpiryDate,
                });
            case PaymentMethod.PAYPAL:
                return t("subscriptions.general.method.paypal", {
                    defaultValue: "Paypal ({{account}})",
                    account: mandate.details?.consumerAccount,
                });
            case PaymentMethod.DIRECTDEBIT:
                return t("subscriptions.general.method.directdebit", {
                    defaultValue: "Lastschrift (IBAN endet auf {{number}})",
                    number: mandate.details?.consumerAccount,
                });
        }
    }, [mandate, t]);

    return (
        <>
            {paymentMethod}{" "}
            {process.env.NODE_ENV === "development" && (
                <small className="text-danger">(Zeige testweise ID an: {mandate?.id})</small>
            )}
        </>
    );
};

export default RenderPaymentMethod;
