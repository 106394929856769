/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

interface ExerciseDescription {
    id: number;
    text: string;
}

interface ExercisePattern {
    id: number;
    name: string;
}

export interface ExerciseMuscle {
    id: number;
    name: string;
}

interface ExerciseTag {
    id: number;
    name: string;
}

interface ExerciseTrainingsapparatuses {
    id: number;
    name: string;
}

interface ExerciseSection {
    id: number;
    name: string;
}

export interface ExerciseModel extends Model {
    id?: number;
    name: string;
    type: number;
    secondname: string;
    video: string;
    is_template: boolean;
    editableCompany: boolean;
    editableMandantor: boolean;
    exercisedescriptions?: ExerciseDescription[];
    exercisefilters?: ExercisePattern[];
    exercisemuscles?: ExerciseMuscle[];
    exercisetags?: ExerciseTag[];
    trainingsapparatuses?: ExerciseTrainingsapparatuses[];
    exercisesections?: ExerciseSection[];
    uploads?: [{ id: number; url: string }];
}

export interface DefaultExercise {
    name: string;
    id: number;
    exercisesections: {
        id: number;
        name: string;
    }[];
}

export interface GroupDataType {
    [key: number]: string;
}

export interface MuscleData {
    id: number;
    exercisemuscles: number[];
    name: string;
}

interface WorkoutschedulesInfo {
    exercisefilter: GroupDataType;
    exercisemuscle: GroupDataType;
    exercisetag: GroupDataType;
    trainingsapparatus: GroupDataType;
    exercisemuscleList: MuscleData[];
}

export interface ExerciseAddModel extends Partial<ExerciseModel> {
    id?: number;
    end?: DateTime | string;
}

export interface ExerciseModelPaginator extends Paginator {
    trainingsession: ExerciseModel[];
}

export interface ExerciseGetAddModel {
    exercise: {
        id?: number;
        name: string;
        type: number;
        secondname: string;
        video: string;
        is_template: boolean;
        editableCompany: boolean;
        editableMandantor: boolean;
        exercisedescriptions: ExerciseDescription[];
        exercisefilters?: ExercisePattern[];
        exercisemuscles?: ExerciseMuscle &
            {
                id: number;
                _joinData: { type: number };
            }[];
        exercisetags?: ExerciseTag[];
        trainingsapparatuses?: ExerciseTrainingsapparatuses[];
        exercisesections?: ExerciseSection[];
        uploads?: [{ id: number; url: string }];
        exercisesectionsFilter: number[];
        exercisefiltersFilter: number[];
        exercisetagsFilter: number[];
        trainingsapparatusFilter: number[];
        company_id: number | null;
    };
    exercisemuscleList: ExerciseMuscle &
        {
            exercisemuscles: number[];
        }[];
    exercisesection: string[];
    exercisefilter: string[];
    exercisemuscle: string[];
    exercisetag: string[];
    trainingsapparatus: string[];
    exercisedescription: ExerciseDescription[];
    companies: string[];
}

export default class ExerciseAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    ExerciseModel,
    ExerciseModelPaginator,
    ExerciseAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Exercises", "Exercise", "Exercises", adapter);
    }

    public async search(search: string): Promise<number[]> {
        const { data } = await this._get<{ exercises: number[] }>(`search${search}`);

        return data.exercises;
    }

    public async viewExercise(id: number): Promise<ExerciseModel> {
        const { data } = await this._get<{ exercise: ExerciseModel }>(`view/${id}`);

        return data.exercise;
    }

    public async indexSearch(search: string): Promise<ExerciseModel[]> {
        const { data } = await this._get<{ exercises: ExerciseModel[] }>(`indexSearch/${search}`);

        return data.exercises;
    }

    public async getExercise(id: number | null): Promise<ExerciseGetAddModel> {
        const url = id ? `edit/${id}` : "add";
        const { data } = await this._get<ExerciseGetAddModel>(`${url}`);

        return data;
    }

    public async getIndexExercise(): Promise<ExerciseModel[]> {
        const { data } = await this._get<{ exercises: ExerciseModel[] }>(`index`);

        return data.exercises;
    }

    public async getWorkoutschedulesInfo(): Promise<WorkoutschedulesInfo> {
        const { data } = await this._get<WorkoutschedulesInfo>(`listExcerciseData`);

        return data;
    }

    public async getNameView(id: number): Promise<{ name: string; secondname: string }> {
        const { data } = await this._get<{ exercise: { name: string; secondname: string } }>(
            `getNameView/${id}`
        );

        return data.exercise;
    }

    public async addExercise(formData: FormData, url: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(url, formData);

        return data.success;
    }
}
