/** @format */

import LoadingContext from "@context/LoadingProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useContext } from "react";

import "./PopUpLoading.scss";

const PopUpLoading: React.FC = () => {
    const { loading } = useContext(LoadingContext);

    const variants = {
        open: { opacity: 1, y: "-35px" },
        closed: { opacity: 0, y: "100%" },
    };

    return (
        <>
            <motion.div
                className="popup-logout d-flex align-items-center"
                animate={loading ? "open" : "closed"}
                variants={variants}
            >
                <FontAwesomeIcon icon={faSpinnerThird} spin className="info-icon-popup" />
            </motion.div>
        </>
    );
};

export default PopUpLoading;
