/** @format */

import { exercisetagAdapter } from "@api/ApiRequest";
import { ExercisetagModel } from "@api/ext/ExercisetagAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import ExercisetagTable from "@components/Table/ExercisetagTable/ExercisetagTable";
import RowOptionWrapper from "@components/Table/RowOptionWrapper/RowOptionWrapper";
import TableSearch from "@components/Table/TableSearch/TableSearch";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "@hook/useLoading";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CellProps } from "react-table";

const ExercisetagsIndex: React.FC = () => {
    const [t] = useTranslation();
    const location = useLocation();
    const { setLoading } = useLoading();
    const { setSaved, shouldRefetch, setShouldRefetch } = useContext(LoadingContext);
    const { user } = useContext(AuthContext);

    const [exercisetagsDefault, setExercisetagsDefault] = useState<ExercisetagModel[]>([]);

    const [exercisetags, setExercisetags] = useState<ExercisetagModel[]>([]);

    const [selectedExercisetag, setSelectedExercisetag] = useState<ExercisetagModel | null>(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [search, setSearch] = useState("");

    const fetchData = useCallback(() => {
        exercisetagAdapter
            .getIndex()
            .then((data) => {
                setExercisetags(data);
                setExercisetagsDefault(data);
                setLoading(false);
            })
            .catch(() => null);
    }, [setLoading]);

    const onDelete = useCallback((exercisetag?: ExercisetagModel) => {
        if (!exercisetag) {
            return;
        }

        setSelectedExercisetag(exercisetag);
        setShowDeleteModal(true);
    }, []);

    const doDelete = useCallback(() => {
        if (!selectedExercisetag?.id) {
            return;
        }

        void exercisetagAdapter.delete(selectedExercisetag.id).then((response) => {
            setSaved({
                type: "successful",
                show: true,
                text: t("exercisetag.exercisetagDeleted", "Problem-Tag {{name}} wurde gelöscht.", {
                    name: selectedExercisetag.name,
                }),
            });

            fetchData();
        });
    }, [selectedExercisetag, t, fetchData, setSaved]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (shouldRefetch) {
            fetchData();
            setShouldRefetch(false);
        }
    }, [fetchData, setShouldRefetch, shouldRefetch]);

    useEffect(() => {
        if (search) {
            exercisetagAdapter
                .getIndex(search)
                .then((response) => {
                    setExercisetags(response);
                })
                .catch((e) => console.log(e));
        } else {
            setExercisetags(exercisetagsDefault);
        }
    }, [search, exercisetagsDefault]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    return (
        <>
            <div className="row my-4">
                <div className="col-6">
                    <TableSearch
                        handleSearch={handleSearch}
                        placeholder={t("global.search", "Suche")}
                        search={search}
                    />
                </div>
                <div className="col-6 pull-right">
                    <div className="d-flex col justify-content-end">
                        <Link
                            className="btn btn-default align-self-end"
                            to={{
                                pathname: `/exercisetags/add`,
                                state: { background: location },
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} /> {t("global.add", "Hinzufügen")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <ExercisetagTable
                    data={exercisetags}
                    rowOptions={{
                        id: "options",
                        Header: "",
                        Cell: ({ row }: CellProps<ExercisetagModel>) => (
                            <>
                                {((user?.is_admin && row.original.editableMandantor) ||
                                    (user?.is_coach && row.original.editableCompany)) && (
                                    <RowOptionWrapper id={`dMenue-${row.original.id}`}>
                                        <li>
                                            <Link
                                                className="dropdown-item option-dropdown"
                                                to={{
                                                    pathname: `/exercisetags/edit/${row.original.id}`,
                                                    state: { background: location },
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="option-icon"
                                                />
                                                {t("global.edit", "Bearbeiten")}
                                            </Link>
                                        </li>
                                        <li>
                                            <button
                                                onClick={() => onDelete(row.original)}
                                                className="dropdown-item option-dropdown option-danger"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="option-icon"
                                                />
                                                {t("global.delete", "Löschen")}
                                            </button>
                                        </li>
                                    </RowOptionWrapper>
                                )}
                            </>
                        ),
                    }}
                />
            </div>
            {showDeleteModal && (
                <ConfirmDialogue
                    title={t("exercisetag.deleteModalTitle", "Problem-Tag löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDelete}
                >
                    <div className="p-3">
                        {selectedExercisetag ? (
                            <p>
                                {t(
                                    "exercisetag.singleDeleteText",
                                    "Möchten Sie den Problem-Tag {{name}} jetzt löschen?",
                                    {
                                        name: selectedExercisetag.name,
                                    }
                                )}
                            </p>
                        ) : (
                            <p>
                                {/* {t(
                                    "exercisetag.multiDeleteText",
                                    "Sie haben {{num}} Problem-Tags ausgewählt. Möchten Sie diese Problem-Tags jetzt löschen?",
                                    {
                                        num: selectedExercisetagIds.length,
                                    }
                                )} */}
                            </p>
                        )}
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default ExercisetagsIndex;
