/** @format */

import { AuthService, UserAdapter } from "@interweberde/prima-core";

import Storage from "./BrowserStorage";
import RequestAdapter from "./RequestAdapter";
import EquipmentAdapter from "./ext/EquipmentAdapter";
import ExerciseAdapter from "./ext/ExerciseAdapter";
import ExercisetagAdapter from "./ext/ExercisetagAdapter";
import ExtendedMandantorAdapter from "./ext/ExtendedMandantorAdapter";
import ExtendedUserAdapter from "./ext/ExtendedUserAdapter";
import GlobalUserAdapter from "./ext/GlobalUserAdapter";
import InvoiceAdapter from "./ext/InvoiceAdapter";
import InvoiceRecipientAdapter from "./ext/InvoiceRecipientAdapter";
import MandateAdapter from "./ext/MandateAdapter";
import MuscelesgroupAdapter from "./ext/MuscelesgroupAdapter";
import MuscleAdapter from "./ext/MuscleAdapter";
import NotesAdapter from "./ext/NotesAdapter";
import NotificationAdapter from "./ext/NotificationAdapter";
import OnboardingAdapter from "./ext/OnboardingAdapter";
import SubscriptionAdapter from "./ext/SubscriptionAdapter";
import SubscriptiontypeAdapter from "./ext/SubscriptiontypeAdapter";
import TrainingsessionsAdapter from "./ext/TrainingsessionsAdapter";
import WorkoutUserAttributeAdapter from "./ext/WorkoutUserAttributeAdapter";
import WorkoutcoachesAdapter from "./ext/WorkoutcoachesAdapter";
import WorkoutschedulesAdapter from "./ext/WorkoutschedulesAdapter";
import WorkoutschedulesSetsAdapter from "./ext/WorkoutschedulesSetsAdapter";

const requestAdapter = new RequestAdapter();

requestAdapter.setBaseUrl(process.env.REACT_APP_BACKEND_URL as string);

export const storage = new Storage("intercoach");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authAdapter = new AuthService(requestAdapter, storage as any);

export const userAdapter = new UserAdapter(requestAdapter);

export const exerciseAdapter = new ExerciseAdapter(requestAdapter);

export const trainingsessionsAdapter = new TrainingsessionsAdapter(requestAdapter);

export const extendedUserAdapter = new ExtendedUserAdapter(requestAdapter);

export const workoutcoachesAdapter = new WorkoutcoachesAdapter(requestAdapter);

export const workoutschedulesAdapter = new WorkoutschedulesAdapter(requestAdapter);

export const notesAdapter = new NotesAdapter(requestAdapter);

export const globalUserAdapter = new GlobalUserAdapter(requestAdapter);

export const muscleAdapter = new MuscleAdapter(requestAdapter);

export const muscelesgroupAdapter = new MuscelesgroupAdapter(requestAdapter);

export const equipmentAdapter = new EquipmentAdapter(requestAdapter);

export const exercisetagAdapter = new ExercisetagAdapter(requestAdapter);

export const onboardingAdapter = new OnboardingAdapter(requestAdapter);

export const workoutschedulesSetsAdapter = new WorkoutschedulesSetsAdapter(requestAdapter);

export const extendedMandantorAdapter = new ExtendedMandantorAdapter(requestAdapter);

export const workoutUserAttributeAdapter = new WorkoutUserAttributeAdapter(requestAdapter);

export const mandateAdapter = new MandateAdapter(requestAdapter);

export const subscriptionAdapter = new SubscriptionAdapter(requestAdapter);

export const subscriptiontypeAdapter = new SubscriptiontypeAdapter(requestAdapter);

export const notificationAdapter = new NotificationAdapter(requestAdapter);

export const invoiceRecipientAdapter = new InvoiceRecipientAdapter(requestAdapter);

export const invoiceAdapter = new InvoiceAdapter(requestAdapter);
