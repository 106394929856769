/** @format */

import { IterableStorageBase } from "@interweberde/prima-core";
import localforage from "localforage";
import slugify from "slugify";

export default class BrowserStorage<T = unknown> extends IterableStorageBase<T> {
    private store: LocalForage;

    constructor(name: string) {
        super();

        this.store = localforage.createInstance({
            name: `intercoach-store-${slugify(name)}`,
            version: 1,
        });
    }

    async setItem(key: string, value: unknown): Promise<void> {
        await this.store.setItem(key, value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getItem(key: string): Promise<any> {
        return this.store.getItem(key);
    }

    async removeItem(key: string): Promise<void> {
        await this.store.removeItem(key);
    }

    clear(): Promise<void> {
        return this.store.clear();
    }

    async isEmpty(): Promise<boolean> {
        return (await this.store.length()) === 0;
    }

    keys(): Promise<string[]> {
        return this.store.keys();
    }
}
