/**
 * @format
 */

import { extendedMandantorAdapter } from "@api/ApiRequest";
import { CompaniesModel, ExtendedMandantorModel } from "@api/ext/ExtendedMandantorAdapter";
import { ExtendedUserModel } from "@api/ext/ExtendedUserAdapter";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./Company.scss";

import CompanyForm from "./CompanyForm/CompanyForm";

const Company: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);

    const [imageUrl, setImageUrl] = useState<string | undefined>();

    const [mandantor, setMandantor] = useState<ExtendedMandantorModel | null>(null);
    const [owner, setOwner] = useState<ExtendedUserModel | null>(null);

    const [companies, setCompanies] = useState<CompaniesModel[]>([]);

    const { setLoading } = useLoading();

    useEffect(() => {
        void extendedMandantorAdapter.getMandantor().then((mandantor) => {
            if (mandantor.uploads != null) {
                setImageUrl(mandantor.uploads?.url);
            }
            setMandantor(mandantor);
            setCompanies(mandantor.companies);
            setOwner(mandantor.owner ? mandantor.owner : null);

            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!mandantor) {
        return null;
    }

    if (user?.is_admin) {
        return <CompanyForm />;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>{mandantor.name}</h1>
                </div>

                <div className="col-12 justify-content-center d-flex mb-3 position-relative my-4">
                    {!imageUrl ? (
                        <div className="company-placeholder">
                            <span>{mandantor.name.charAt(0) + mandantor.name.charAt(1)}</span>
                        </div>
                    ) : (
                        <img src={imageUrl} alt="Profilbild" className="company-img" />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <table className="table table-striped table-small">
                        <tbody>
                            <tr>
                                <th scope="row">{t("company.name", "Firmenname")}</th>
                                <td>{mandantor.name}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("company.address", "Adresse")}</th>
                                <td>{mandantor.address}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("company.plz", "PLZ")}</th>
                                <td>{mandantor.zip}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("company.city", "Stadt")}</th>
                                <td>{mandantor.city}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("company.phone", "Telefon")}</th>
                                <td>
                                    {mandantor.phone && (
                                        <a href={`tel:${mandantor.phone}`}>{mandantor.phone}</a>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{t("company.email", "E-Mail")}</th>
                                <td>
                                    {mandantor.email && (
                                        <a href={`mailto:${mandantor.email}`}>{mandantor.email}</a>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{t("company.url", "Webadresse (url)")}</th>
                                <td>
                                    {mandantor.www && (
                                        <a href={mandantor.www} target="_blank" rel="noreferrer">
                                            {mandantor.www}
                                        </a>
                                    )}
                                </td>
                            </tr>
                            {owner && (
                                <tr>
                                    <th scope="row">{t("company.owner", "Inhaber*in")}</th>
                                    <td>
                                        {owner.prefix} {owner.fullname}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12">
                    <h5>{t("company.second", "Studios")}</h5>
                </div>
            </div>

            <div className="col-12">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t("company.name", "Name")}</th>
                            <th scope="col">{t("company.city", "Stadt")}</th>
                            <th scope="col">{t("company.address", "Adresse")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((value, index) => (
                            <tr key={`companies-${index}`}>
                                <th scope="row">{index + 1}</th>
                                <td>{value.name}</td>
                                <td>{value.city}</td>
                                <td>{value.address}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Company;
