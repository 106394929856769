/** @format */

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import AddMandate from "../Mandate/ManageMandate/AddMandate";
import ManageMandate from "../Mandate/ManageMandate/ManageMandate";
import InvoiceData from "./InvoiceData/InvoiceData";
import SubscriptionCancel from "./SubscriptionCancel/SubscriptionCancel";
import SubscriptionChange from "./SubscriptionChange/SubscriptionChange";
import SubscriptionDone from "./SubscriptionDone/SubscriptionDone";
import SubscriptionPayment from "./SubscriptionPayment/SubscriptionPayment";

const ManageSubscription: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path} component={SubscriptionChange} />
            <Route path={`${path}/data`} component={InvoiceData} />
            <Route path={`${path}/payment`} component={SubscriptionPayment} />
            <Route path={`${path}/done`} component={SubscriptionDone} />
            <Route path={`${path}/cancel`} component={SubscriptionCancel} />
            <Route path={`${path}/manage-mandates`} component={ManageMandate} />
            <Route path={`${path}/add-mandates`} component={AddMandate} />
        </Switch>
    );
};

export default ManageSubscription;
