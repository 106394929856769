/** @format */

import ExerciseContext, { Description } from "@context/ExerciseProvider";
import { faMinusSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface ModalDescriptionProps {
    show: boolean;
    setShow: (show: boolean) => void;
}

const ModalDescription: React.FC<ModalDescriptionProps> = ({ show, setShow }) => {
    const [t] = useTranslation();
    const { description, setDescription } = useContext(ExerciseContext);
    const modalView = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalView.current) {
            const myModal = modalView.current;
            let bsModal = Modal.getInstance(myModal);

            if (!bsModal) {
                bsModal = new Modal(myModal);
                bsModal.hide();
            } else if (show) {
                bsModal.toggle();
            }
        }
    }, [show, modalView]);

    useEffect(() => {
        if (modalView.current && show) {
            modalView.current.addEventListener("hidden.bs.modal", function () {
                setShow(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const handleAddSection = () => {
        const newData = [];

        if (!description) {
            return null;
        }

        description.map((value) => {
            newData.push(value);
        });

        newData.push({
            id: uuidv4(),
            text: "",
        });
        setDescription(newData);
    };

    const handleRemove = (remove: number) => {
        const newData: Description[] = [];

        if (!description) {
            return null;
        }

        description.map((value, index) => {
            if (index !== remove) {
                newData.push(value);
            }
        });
        setDescription(newData);
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, getIndex: number) => {
        const newData: Description[] = [];

        if (!description) {
            return null;
        }

        description.map((value, index) => {
            if (index === getIndex) {
                newData.push({ ...value, text: event.target.value });
            } else {
                newData.push(value);
            }
        });
        setDescription(newData);
    };

    if (!description) {
        return null;
    }

    return (
        <div ref={modalView} className="modal fade" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div id="contained-modal-title-vcenter" className="modal-title">
                            {t("global.description", "Beschreibung")}
                        </div>
                        <button
                            onClick={() => setShow(false)}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label={t("global.close", "Close")}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <button
                                    className="addBtnSection"
                                    onClick={() => handleAddSection()}
                                >
                                    {t("modalDescription.addSection", "Abschnitt hinzufügen")}
                                </button>
                            </div>
                            <div className="col modalShowSearch">
                                <ul className="list-group">
                                    {Object.keys(description).map((i) => (
                                        <div key={description[Number(i)].id} className="mt-2">
                                            <label className="form-label justify-content-between d-flex">
                                                <span>
                                                    {t("global.section", "Abschnitt")}{" "}
                                                    {Number(i) + 1}
                                                </span>
                                                <span onClick={() => handleRemove(Number(i))}>
                                                    <FontAwesomeIcon
                                                        icon={faMinusSquare}
                                                        className="align-self-center removeSection"
                                                    />
                                                </span>
                                            </label>
                                            <textarea
                                                className="form-control"
                                                rows={3}
                                                value={description[Number(i)].text}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                                ) => handleChange(e, Number(i))}
                                            />
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDescription;
