/** @format */

import { SubscriptionStatus } from "@api/ext/SubscriptionAdapter";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import SubscriptionOverview from "./Subscription/SubscriptionOverview/SubscriptionOverview";

import "./PaymentOverview.scss";

const PaymentOverview: React.FC = () => {
    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const { subscription } = useContext(AuthContext);

    useEffect(() => {
        if (subscription) {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    if (!subscription) {
        return null;
    }

    return (
        <div>
            <h1 className="mb-4">{t("subscription.payment.title", "Zahlung")}</h1>
            <SubscriptionOverview />

            <div className="d-flex">
                {subscription.status !== SubscriptionStatus.TRIAL &&
                    subscription.status !== SubscriptionStatus.CANCELED &&
                    subscription.status !== SubscriptionStatus.SUSPENDED &&
                    subscription.status !== SubscriptionStatus.MOLLIE_PENDING &&
                    subscription.status !== SubscriptionStatus.PENDING && (
                        <NavLink
                            activeClassName="active"
                            className="btn btn-save mt-4"
                            to="/profile/subscription"
                        >
                            {t("subscription.changeSubscription", "Abo ändern")}
                        </NavLink>
                    )}

                {subscription.status === SubscriptionStatus.TRIAL && (
                    <NavLink
                        activeClassName="active"
                        className="btn btn-save mt-4 ms-auto"
                        to="/profile/subscription"
                    >
                        {t("payment.activatePayment", "Abo aktivieren")}
                    </NavLink>
                )}

                {(subscription.status === SubscriptionStatus.CANCELED ||
                    subscription.status === SubscriptionStatus.SUSPENDED) && (
                    <NavLink
                        activeClassName="active"
                        className="btn btn-save mt-4 ms-auto"
                        to="/profile/subscription/payment"
                    >
                        {t("payment.reactivatePayment", "Abo reaktivieren")}
                    </NavLink>
                )}
            </div>

            {subscription.status === SubscriptionStatus.ACTIVE && (
                <div className="d-flex justify-content-end">
                    <NavLink
                        activeClassName="active"
                        className="btn btn-outline-danger mt-4"
                        to="/profile/subscription/cancel"
                    >
                        {t("subscription.canceledSubscription", "Abo kündigen")}
                    </NavLink>
                </div>
            )}
        </div>
    );
};

export default PaymentOverview;
