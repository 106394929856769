/** @format */

import { globalUserAdapter, onboardingAdapter } from "@api/ApiRequest";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import LoadingContext from "@context/LoadingProvider";
import { useSubscriptionContext } from "@context/SubscriptionProvider";
import UserViewContext from "@context/UserViewProvider";
import {
    faUser,
    faAnalytics,
    faTasks,
    faClipboardList,
    faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Switch, Route, useRouteMatch, NavLink, useParams, Link } from "react-router-dom";

import Notes from "./Notes/Notes";
import Statistic from "./Statistic/Statistic";
import Trainingsession from "./Trainingsession/Trainingsession";
import UserInfo from "./UserInfo/UserInfo";

import "./UserView.scss";

interface UserInfoParams {
    id: string;
}

const UserView: React.FC = () => {
    const [t] = useTranslation();
    const history = useHistory();

    const { subscription, userCount, updateUserCount, clientGroupIds } = useSubscriptionContext();
    const { path, url } = useRouteMatch();

    const { id } = useParams<UserInfoParams>();

    const { setSaved } = useContext(LoadingContext);
    const { getUser, user } = useContext(UserViewContext);
    const [canInvite, setCanInvite] = useState<boolean>(true);

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showUnarchiveModal, setShowUnarchiveModal] = useState<boolean>(false);
    const [showCannotUnarchiveModal, setShowCannotUnarchiveModal] = useState<boolean>(false);

    const onUnarchiveUser = useCallback(() => {
        if (
            userCount &&
            user?.group_id &&
            userCount >= subscription.seat_count &&
            clientGroupIds.includes(user.group_id)
        ) {
            setShowCannotUnarchiveModal(true);

            return;
        }

        setShowUnarchiveModal(true);
    }, [userCount, user?.group_id, subscription.seat_count, clientGroupIds]);

    const doInviteUser = useCallback(() => {
        if (!user?.id || !canInvite) {
            return;
        }

        void onboardingAdapter.invite(user.id).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("userView.inviteSend", "Einladung versendet."),
                });

                setCanInvite(false);
            }
        });
    }, [canInvite, user?.id, setSaved, t]);

    const onInviteUser = useCallback(() => {
        if (!user?.last_invited) {
            doInviteUser();

            return;
        }

        setShowConfirmModal(true);
    }, [user?.last_invited, doInviteUser]);

    const doArchive = useCallback(() => {
        if (user?.archived || !user?.id) {
            return;
        }

        void globalUserAdapter.archive(user.id).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("userView.archiveSuccess", "Benutzer wurden archiviert."),
                });

                getUser(Number(user.id));
                updateUserCount();
            }
        });
    }, [getUser, setSaved, t, user?.archived, user?.id, updateUserCount]);

    const doUnarchive = useCallback(() => {
        if (!user?.archived || !user?.id) {
            return;
        }

        void globalUserAdapter.unarchive(user.id).then((response) => {
            if (response) {
                setSaved({
                    type: "successful",
                    show: true,
                    text: t("userView.unarchiveSuccess", "Benutzer wurde dearchiviert."),
                });

                getUser(Number(user.id));
                updateUserCount();
            }
        });
    }, [getUser, setSaved, t, user?.archived, user?.id, updateUserCount]);

    useEffect(() => {
        if (!user?.last_invited) {
            setCanInvite(true);

            return;
        }

        const lastinvited = DateTime.fromISO(String(user?.last_invited));
        const now = DateTime.local();
        const diff = Number(now.diff(lastinvited).toFormat("m"));

        setCanInvite(diff >= 10);
    }, [user?.last_invited]);

    useEffect(() => {
        getUser(Number(id));
    }, [getUser, id]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!user ? (
                        <div className="col-12 text-center">
                            <FontAwesomeIcon icon={faSpinnerThird} spin size="3x" />
                        </div>
                    ) : (
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                {user.uploads?.length ? (
                                    <img
                                        src={`${user.uploads?.[0].url}`}
                                        className="rounded d-block img-fluid img-avatar"
                                        alt="avatar"
                                    />
                                ) : (
                                    <div className="img-placeholder-avatar">
                                        <span> {user.name.charAt(0) + user.surname.charAt(0)}</span>
                                    </div>
                                )}
                                <div className="avatar-text">
                                    <h3>{user.name + " " + user.surname}</h3>
                                    <h4>
                                        <small>{user.workoutcoaches_user?.name}</small>
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex align-items-end btn-group">
                                <Link
                                    href="/"
                                    className="btn btn-sm btn-save"
                                    to={`/users/edit/${id}`}
                                >
                                    {t("global.edit", "Bearbeiten")}
                                </Link>
                                <button
                                    disabled={!canInvite || !!user.archived}
                                    className="btn btn-sm btn-save"
                                    onClick={onInviteUser}
                                >
                                    {!user.last_invited
                                        ? t("userView.sendInvite", "Einladung senden")
                                        : t("userView.sendReinvite", "Erneut einladen")}
                                </button>
                                {user.archived && (
                                    <button
                                        className="btn btn-sm btn-danger-light"
                                        onClick={onUnarchiveUser}
                                    >
                                        {t("userView.unarchiveUser", "Benutzer dearchivieren")}
                                    </button>
                                )}
                                {!user.archived && (
                                    <button
                                        className="btn btn-sm btn-danger-light"
                                        onClick={doArchive}
                                    >
                                        {t("userView.unarchiveUser", "Benutzer archivieren")}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12 mt-3">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to={`${url}`}
                                activeClassName="active"
                                className="nav-link"
                            >
                                <FontAwesomeIcon icon={faUser} color="rgba(0, 0, 0, 0.8)" />
                                <span className="ps-1">
                                    {t("global.informations", "Informationen")}
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/statistics`}
                                className="nav-link"
                            >
                                <FontAwesomeIcon icon={faAnalytics} color="rgba(0, 0, 0, 0.8)" />
                                <span className="ps-1">{t("global.statistic", "Statistik")}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/progress`}
                                className="nav-link"
                            >
                                <FontAwesomeIcon icon={faTasks} color="rgba(0, 0, 0, 0.8)" />
                                <span className="ps-1">{t("global.history", "Verlauf")}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/notes`}
                                className="nav-link"
                            >
                                <FontAwesomeIcon
                                    icon={faClipboardList}
                                    color="rgba(0, 0, 0, 0.8)"
                                />
                                <span className="ps-1">{t("global.notes", "Notizen")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                {!user ? (
                    <div className="col-12 text-center">
                        <FontAwesomeIcon icon={faSpinnerThird} spin size="3x" />
                    </div>
                ) : (
                    <Switch>
                        <Route exact path={path} component={UserInfo} />
                        <Route path={`${path}/statistics`} component={Statistic} />
                        <Route path={`${url}/progress`} component={Trainingsession} />
                        <Route path={`${url}/notes`} component={Notes} />
                    </Switch>
                )}
            </div>
            {showConfirmModal && (
                <ConfirmDialogue
                    title={t("user.reinviteModalTitle", "Benutzer erneut einladen")}
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onSubmit={doInviteUser}
                >
                    <div className="p-3">
                        <p>
                            {t(
                                "user.reinviteModalText",
                                "Dieser Benutzer wurde zuletzt am {{last_date}} eingeladen. Möchten Sie diesem Benutzer jetzt erneut eine Einladung senden?",
                                {
                                    last_date: DateTime.fromISO(
                                        String(user?.last_invited)
                                    ).toFormat("dd.MM.yyyy hh:mm"),
                                }
                            )}
                        </p>
                        <p>{}</p>
                    </div>
                </ConfirmDialogue>
            )}
            {showUnarchiveModal && user && (
                <ConfirmDialogue
                    title={t("user.unarchiveUser", "Benutzer dearchivieren")}
                    show={showUnarchiveModal}
                    onHide={() => setShowUnarchiveModal(false)}
                    onSubmit={doUnarchive}
                >
                    <div className="p-3">
                        <p>
                            {t(
                                "users.singleUnarchiveText",
                                "Möchten Sie diesem Benutzer {{name}} jetzt dearchivieren?",
                                {
                                    name: user.name + " " + user.surname,
                                }
                            )}
                        </p>
                    </div>
                </ConfirmDialogue>
            )}
            {showCannotUnarchiveModal && (
                <ConfirmDialogue
                    title={t("user.cannotUnarchiveModalTitle", "Benutzer erneut einladen")}
                    show={showCannotUnarchiveModal}
                    onHide={() => setShowCannotUnarchiveModal(false)}
                    onSubmit={() => {
                        setShowCannotUnarchiveModal(false);

                        history.push("/profile/subscription");
                    }}
                    confirmText={t("user.cannotUnarchiveModalSubmit", "Zusätzliche Sitze buchen")}
                >
                    <div className="p-3">
                        <p>
                            {t(
                                "users.cannotUnarchiveModalText",
                                "Aktuell sind {{userCount}} von {{seatCount}} in Benutzung. Buchen Sie weitere Sitze um die ausgewählten Benutzer zu Dearchivieren.",
                                {
                                    userCount: userCount ?? 0,
                                    seatCount: subscription.seat_count,
                                }
                            )}
                        </p>
                    </div>
                </ConfirmDialogue>
            )}
        </>
    );
};

export default UserView;
