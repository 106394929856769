/** @format */

import "./ExercisetagAdd.scss";
import { exercisetagAdapter } from "@api/ApiRequest";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import ModalView from "@components/Modal/ModalView";
import LoadingContext from "@context//LoadingProvider";
import AuthContext from "@context/AuthProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import { Modal } from "bootstrap";
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const ExercisetagAdd: React.FC = () => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const { setSaved, setShouldRefetch } = useContext(LoadingContext);

    const [name, setName] = useState<string>("");

    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [post, setPost] = useState<boolean>(false);

    const [modalInstance, setModaInstance] = useState<null | Modal>(null);

    const handlePushClose = (modal: Modal) => {
        setModaInstance(modal);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setPost(true);
        void exercisetagAdapter
            .add({
                name,
                company_id:
                    user?.is_admin && selectedCompany
                        ? Number(selectedCompany.value)
                        : user?.company_id,
            })
            .then((response) => {
                setPost(false);

                if (modalInstance) {
                    modalInstance.hide();
                }

                setSaved({ type: "successful", show: true });
                setShouldRefetch(true);
            });
    };

    useEffect(() => {
        void exercisetagAdapter.getAddExercisetag().then((response) => {
            const { companies } = response;

            const updateCompaniesSelect: { value: string; label: string }[] = [];
            Object.keys(companies).map((key) => {
                updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
            });
            setSelectableCompanies(updateCompaniesSelect);

            if (user?.is_admin && updateCompaniesSelect[0]) {
                setSelectedCompany(updateCompaniesSelect[0]);
            }
        });
    }, [user?.is_admin, user?.is_coach]);

    if (!user) {
        return null;
    }

    return (
        <ModalView
            title={t("exercisetag.add", "Problem-Tag hinzufügen")}
            pushRef={handlePushClose}
            goBack="collection/exercisetags"
        >
            <form onSubmit={(e) => (!post ? handleSubmit(e) : null)}>
                {selectableCompanies && user.is_admin && (
                    <div className="row mb-3">
                        <div className="col-6">
                            <InputLabel required htmlFor="exercisetagCompany">
                                {t("exercisetag.company", "Studio")}
                            </InputLabel>
                            <Select
                                id="exercisetagCompany"
                                value={selectedCompany}
                                required
                                onChange={(e) => setSelectedCompany(e)}
                                options={selectableCompanies}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12">
                        <InputLabel required htmlFor="exercisetagName">
                            {t("exercisetag.exercisetagName", "Problem-Tag")}
                        </InputLabel>
                        <input
                            id="exercisetagName"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            required
                            value={name}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <button disabled={!name} className="btn btn-save" type="submit">
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {t("exercisetag.save", "Problem-Tag speichern")}
                        </button>
                    </div>
                </div>
            </form>
        </ModalView>
    );
};

export default ExercisetagAdd;
