/** @format */

import MainNav from "@components/Navigation/MainNav";
import SubscriptionTrailProgress from "@components/SubscriptionTrialProgress/SubscriptionTrialProgress";
import AuthContext from "@context/AuthProvider";
import { SubscriptionContextProvider } from "@context/SubscriptionProvider";
import React, { useContext } from "react";

import AuthenticatedRouter from "./AuthenticatedRouter";
import ClientRouter from "./ClientRouter";
import NoSubscriptionRouter from "./NoSubscriptionRouter";
import UnconfirmedRouter from "./UnconfirmedRouter";

import "./MainRouter.scss";

export const MainScreen: React.FC = () => {
    const { user, subscription } = useContext(AuthContext);

    if (!user) {
        return null;
    }

    if (!user?.is_admin && !user?.is_coach) {
        return (
            <div className="min-vh-100">
                <MainNav />
                <main className="main-content-container container p-3 md:p-5">
                    {user?.emailconfirmed ? <ClientRouter /> : <UnconfirmedRouter />}
                </main>
            </div>
        );
    }

    return (
        <div className="min-vh-100">
            <MainNav />
            <SubscriptionTrailProgress />
            <main className="main-content-container container p-3 md:p-5">
                {!subscription || !user ? null : user?.emailconfirmed ? (
                    <SubscriptionContextProvider>
                        {subscription.is_active ? (
                            <AuthenticatedRouter />
                        ) : (
                            <NoSubscriptionRouter />
                        )}
                    </SubscriptionContextProvider>
                ) : (
                    <UnconfirmedRouter />
                )}
            </main>
        </div>
    );
};
