/** @format */

import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface KgProps {
    sendData: Dispatch<SetStateAction<string>>;
}

const Kg: React.FC<KgProps> = ({ sendData }) => {
    const [t] = useTranslation();

    return (
        <div className="col-6">
            <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                    {t("global.kg", "Kilogramm")}
                </span>

                <input
                    name="kg"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    className="form-control text-right form-type-chose"
                    onChange={(e) => sendData(e.target.value)}
                />
            </div>
        </div>
    );
};

export default Kg;
