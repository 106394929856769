/** @format */

import { notesAdapter } from "@api/ApiRequest";
import { UserNotes } from "@api/ext/ExtendedUserAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import LoadingContext from "@context//LoadingProvider";
import UserViewContext from "@context/UserViewProvider";
import { faInfoSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import "./Notes.scss";

const Notes: React.FC = () => {
    const [t] = useTranslation();

    const { user, getUser } = useContext(UserViewContext);
    const { setSaved } = useContext(LoadingContext);

    const [noteText, setNoteText] = useState<string>("");

    const [notes, setNotes] = useState<UserNotes[]>([]);

    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            setNotes(user.workoutnotes);
        }
    }, [user]);

    const handleAddNote = useCallback(() => {
        if (!user || !user.id) {
            return;
        }

        if (noteText.length <= 3) {
            setError(true);
            return;
        }

        notesAdapter
            .setNote({
                user_id: user?.id,
                note: noteText,
            })
            .then((value) => {
                if (value) {
                    if (!user || !user.id) {
                        return;
                    }

                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("notes.saved", "Diese Notiz wurde gespeichert"),
                    });

                    setNoteText("");
                    getUser(user?.id);
                }
            })
            .catch(() => {
                setSaved({ type: "failed", show: true });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noteText, user]);

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="col-12">
                <div className="info-note-coach">
                    <small>
                        <FontAwesomeIcon icon={faInfoSquare} />{" "}
                        {t("notes.info", "Diese Notizen sieht nur der Trainer")}
                    </small>
                </div>
            </div>
            <div className="col-12 d-flex justify-content-end">
                <button className="btn btn-save btn-normal" onClick={() => handleAddNote()}>
                    {t("notes.add", "Hinzufügen")}
                </button>
            </div>
            <div className="col-12">
                <div className="mb-3">
                    <InputLabel required htmlFor="notesTextarea">
                        {t("notes.create", "  Notitz erstellen")}
                    </InputLabel>
                    <textarea
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        id="notesTextarea"
                        rows={4}
                        placeholder="..."
                        required
                        value={noteText}
                        onChange={(e) => {
                            setError(false);
                            setNoteText(e.target.value);
                        }}
                    />
                    <div className="invalid-feedback">
                        {t("notes.error", "Fügen Sie eine längere Notiz hinzu.")}
                    </div>
                </div>
                <div className="mt-3">
                    <h5 className="pb-3">{t("notes.added", "Hinzugefügte Notizen")}</h5>
                    {notes.map((value, index) => (
                        <div
                            key={`${index}-note`}
                            className="row border-bottom border-secondary d-flex mb-4 m-1"
                        >
                            <p className="col-12 p-0">{value.note}</p>
                            <div className="d-flex justify-content-end">
                                <small className="info-date">
                                    ({value.author} |{" "}
                                    {DateTime.fromISO(String(value.created), {
                                        setZone: true,
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy HH:mm")}
                                    )
                                </small>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Notes;
