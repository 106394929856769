/** @format */

import { Modal } from "bootstrap";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmDialogueProps {
    show: boolean;
    title: string;
    onHide: () => void;
    onSubmit: () => void;
    onAbort?: () => void;
    hideAbort?: boolean;
    hideSubmit?: boolean;
    confirmText?: string;
    abortText?: string;
}

const ConfirmDialogue: React.FC<ConfirmDialogueProps> = ({
    show,
    title,
    onHide,
    onSubmit,
    onAbort,
    hideAbort = false,
    hideSubmit = false,
    confirmText = null,
    abortText = null,
    children,
}) => {
    const [t] = useTranslation();
    const modalView = useRef<HTMLDivElement>(null);

    const handleSubmit = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    useEffect(() => {
        let bsModal: Modal | null = null;

        if (modalView.current) {
            const myModal = modalView.current;
            bsModal = Modal.getInstance(myModal);

            if (!bsModal && !show) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.hide();
            } else if (!bsModal && show) {
                bsModal = new Modal(myModal, { backdrop: false });
                bsModal.show();
            } else if (show) {
                bsModal.show();
            }

            modalView.current.addEventListener("hidden.bs.modal", function () {
                onHide();
            });
        }
    }, [show, onHide]);

    return (
        <div className="modal-backdrop-custom">
            <div
                ref={modalView}
                className="modal fade"
                tabIndex={-1}
                id="confirmModal"
                aria-hidden="true"
                aria-labelledby="modal"
                style={{
                    display: "block",
                }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="confirm-dialogue modal-content">
                        <div className="modal-header">
                            <h4>{title}</h4>
                        </div>
                        {children}
                        <div className="modal-footer d-flex justify-content-between">
                            {!hideAbort && (
                                <button
                                    className="btn btn-danger"
                                    data-bs-dismiss="modal"
                                    data-bs-target="#confirmModal"
                                    aria-label="Close"
                                    onClick={() => onAbort ?? null}
                                >
                                    {abortText ?? t("global.cancel", "Abbrechen")}
                                </button>
                            )}
                            {!hideSubmit && (
                                <button
                                    className="btn btn-save"
                                    onClick={handleSubmit}
                                    data-bs-dismiss="modal"
                                    data-bs-target="#confirmModal"
                                >
                                    {confirmText ?? t("global.confirm", "Bestätigen")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialogue;
