/** @format */

import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
} from "@interweberde/prima-core";

import { CompanyListItem } from "./ExtendedMandantorAdapter";
import { ExtendedUserModel } from "./ExtendedUserAdapter";

export interface WorkoutcoachesModel extends Model {
    name: string;
    tel?: string;
    email?: string;
    text?: string;
    editableCompany: boolean;
    editableMandantor: boolean;
    user?: {
        id: number;
        fullname: string;
        name: string;
        surname: string;
        uploads: {
            url: string;
        }[];
    };
    workout_users?: ExtendedUserModel[];
}

export interface WorkoutcoachesAddModel extends Model {
    id?: number;
    name: string;
    tel?: string;
    email: string;
    text?: string;
    user_id: number;
    company_id?: number;
    user?: {
        name: string;
        surname: string;
    };
}

interface WorkoutcoachUsers {
    [key: number]: string;
}

export default class WorkoutcoachesAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    WorkoutcoachesModel,
    Paginator,
    WorkoutcoachesAddModel
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Workoutcoaches", "Workoutcoache", "Workoutcoaches", adapter);
    }

    public async view(id: string): Promise<WorkoutcoachesModel> {
        const { data } = await this._get<{ workoutcoach: WorkoutcoachesModel }>(`view/${id}`);

        return data.workoutcoach;
    }

    public async getIndex(search = ""): Promise<WorkoutcoachesModel[]> {
        const { data } = await this._get<{ workoutcoaches: WorkoutcoachesModel[] }>(
            `index?search=${search}`
        );

        return data.workoutcoaches;
    }

    public async getAddCoach(): Promise<{
        users: WorkoutcoachUsers;
        companies: CompanyListItem;
    }> {
        const { data } = await this._get<{
            users: WorkoutcoachUsers;
            companies: CompanyListItem;
        }>(`add`);

        return data;
    }

    public async getEditCoach(
        id: string
    ): Promise<{
        users: WorkoutcoachUsers;
        workoutcoach: WorkoutcoachesAddModel;
        companies: CompanyListItem;
    }> {
        const { data } = await this._get<{
            users: WorkoutcoachUsers;
            workoutcoach: WorkoutcoachesAddModel;
            companies: CompanyListItem;
        }>(`edit/${id}`);

        return data;
    }
}
