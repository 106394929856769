/** @format */

import { DefaultExercise, ExerciseModel } from "@api/ext/ExerciseAdapter";
import {
    ControllerAdapter,
    Model,
    Paginator,
    RequestAdapterInterface,
    UserModel,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

import { CompanyListItem } from "./ExtendedMandantorAdapter";

export interface WorkoutschedulesUsers {
    [key: number]: string;
}

export interface ExercisesWorkoutschedulesSets extends Model {
    id: number;
    exercises_workoutschedule_id: number;
    value: string | number;
    wdh: string | number;
}

export interface ExercisesWorkoutschedules extends Model {
    id: number;
    exercise_id: number;
    workoutschedule_id: number;
    sort: number;
    primariepropertie: number;
    secondariepropertie: number;
    option: number;
    type: number;
    exercises_workoutschedules_sets?: ExercisesWorkoutschedulesSets[];
    exercise?: {
        id: number;
        name: string;
        uploads: [
            {
                url: string;
            }
        ];
    };
}

export interface WorkoutschedulesModel {
    id: number;
    name: string;
    template?: boolean;
    created?: DateTime;
    modified?: DateTime;
    exercises?: ExerciseModel[];
    self_workoutschedules?: WorkoutschedulesModel[];
    users?: UserModel[];
    exercises_workoutschedules?: {
        [key: number]: ExercisesWorkoutschedules[];
    };
}

export interface WorkoutschedulesModelEdit extends Model {
    id: number;
    company_id: number;
    name: string;
    template?: boolean;
    created?: DateTime;
    modified?: DateTime;
    exercises?: ExerciseModel[];
    users: UserModel[];
    exercises_workoutschedules: ExercisesWorkoutschedules[];
}

export interface WorkoutschedulesIndex {
    id: number;
    name: string;
    editableCompany?: boolean;
    editableMandantor?: boolean;
    company_id?: number;
    created?: DateTime;
    exercises?: number;
    self_workoutschedules?: number;
}

interface AddWorkoutschedules {
    exercises: DefaultExercise[];
    users: WorkoutschedulesUsers;
    companies: CompanyListItem;
}

export default class WorkoutschedulesAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("workout/Workoutschedules", "Workoutschedule", "Workoutschedules", adapter);
    }

    public async view(id: number): Promise<WorkoutschedulesModel> {
        const { data } = await this._get<{ workoutschedule: WorkoutschedulesModel }>(`view/${id}`);

        return data.workoutschedule;
    }

    public async getAddWorkoutschedules(): Promise<AddWorkoutschedules> {
        const { data } = await this._get<AddWorkoutschedules>(`add`);

        return data;
    }

    public async getWorkoutschedulesEdit(id: number): Promise<WorkoutschedulesModelEdit> {
        const {
            data: { workoutschedule },
        } = await this._get<{ workoutschedule: WorkoutschedulesModelEdit }>(`edit/${id}`);

        return workoutschedule;
    }

    public async getWorkoutschedules(search = ""): Promise<WorkoutschedulesIndex[]> {
        const { data } = await this._get<{ workoutschedules: WorkoutschedulesIndex[] }>(
            `index?search=${search}`
        );

        return data.workoutschedules;
    }

    public async setUsersWorkoutschedules(setData: {
        id: number;
        user_ids: number[];
    }): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(
            `setUsersWorkoutschedules`,
            setData
        );

        return data.success;
    }

    public async getUsersWorkoutschedules(id: number): Promise<WorkoutschedulesIndex[]> {
        const { data } = await this._get<{ workoutschedules: WorkoutschedulesIndex[] }>(
            `get-users-workoutschedules/${id}`
        );

        return data.workoutschedules;
    }

    public async addUserWorkoutschedule(
        user_id: number,
        workoutschedule_id: number
    ): Promise<WorkoutschedulesIndex> {
        const { data } = await this._get<{ workoutschedule: WorkoutschedulesIndex }>(
            `add-user-workoutschedules/${workoutschedule_id}/${user_id}`
        );

        return data.workoutschedule;
    }

    public async unassignUserWorkoutschedule(
        user_id: number,
        workoutschedule_id: number
    ): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(
            `unassign-user-workoutschedule/${workoutschedule_id}/${user_id}`
        );

        return data.success;
    }
}
