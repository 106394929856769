/** @format */

import UserViewContext from "@context/UserViewProvider";
import React, { useContext } from "react";

import TrainingsessionWrapper from "../../../../components/TrainingsessionWrapper/TrainingsessionWrapper";

const Trainingsession: React.FC = () => {
    const { user } = useContext(UserViewContext);

    if (!user) {
        return null;
    }

    return (
        <div className="h-100">
            <TrainingsessionWrapper id={user.id} />
        </div>
    );
};

export default Trainingsession;
