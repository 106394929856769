/** @format */

import { UserSearchModel } from "@api/ext/ExtendedUserAdapter";
import { faUser, faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SingleUserElementProps {
    value: UserSearchModel;
    handleClose: (id: string) => void;
    handleChecked: (e: React.ChangeEvent<HTMLInputElement>, value: UserSearchModel) => void;
    type: "s" | "c";
    handleRemoveSelected?: (id: number) => void;
}

const SingleUserElement: React.FC<SingleUserElementProps> = ({
    type,
    value,
    handleChecked,
    handleClose,
    handleRemoveSelected = () => null,
}) => {
    return (
        <div
            key={String(type) + "-" + String(value.id)}
            className={`w-100 ${type === "s" ? "user-search-item-base" : "usi-base"} ${
                value.archived ? "bg-muted" : ""
            }`}
        >
            <div className={type === "c" ? "user-search-item usi-chose" : "user-search-item"}>
                <div className="d-flex align-items-center">
                    {type === "c" ? (
                        <div className="me-2">
                            <FontAwesomeIcon
                                className="remove-usi"
                                icon={faMinusCircle}
                                color="rgba(178,47,59,0.8)"
                                onClick={() => handleRemoveSelected(value.id)}
                            />
                        </div>
                    ) : (
                        <div className="form-check me-1">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`check-${value.id}`}
                                disabled={value.archived}
                                onChange={(e) => handleChecked(e, value)}
                            />
                        </div>
                    )}

                    {value.uploads[0] ? (
                        <div className="d-flex justify-content-center text-center me-3">
                            <img
                                src={`${value.uploads[0].url}`}
                                alt="avatar"
                                className="profileImage"
                            />
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center text-center me-3">
                            <div className="userCircle">
                                {value.name.charAt(0) + value.surname.charAt(0)}
                            </div>
                        </div>
                    )}

                    {value.full}
                </div>
                <div className="d-flex">
                    <div
                        onClick={() => handleClose(`/users/view/${value.id}`)}
                        className="user-search-link"
                    >
                        <FontAwesomeIcon icon={faUser} color="rgba(0, 0, 0, 0.8)" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleUserElement;
