/**
 * /* eslint react/destructuring-assignment: 0
 *
 * @format
 */

/* eslint array-callback-return: 0 */
import { WorkoutschedulesUsers } from "@api/ext/WorkoutschedulesAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const options: OptionType[] = [];

interface NameProps {
    userId?: number;
    users: WorkoutschedulesUsers;
    nameError: boolean;
    name: string;
    setUser: (user: number) => void;
    setName: (name: string) => void;
    edit?: boolean;
}

const Name: React.FC<NameProps> = ({
    userId,
    users,
    nameError,
    name,
    setUser,
    setName,
    edit = false,
}) => {
    const [t] = useTranslation();
    const [selected, setSelected] = useState<SelectedOption>(null);

    const [usersValue, setUsersValue] = useState<OptionType[]>([]);

    const [selectUser, setSelectUser] = useState<boolean>(() => {
        return !!userId;
    });

    useEffect(() => {
        setUsersValue((users as unknown) as OptionType[]);
    }, [users]);

    useEffect(() => {
        Object.keys(usersValue).map((value) => {
            options.push({
                value,
                label: String(usersValue[Number(value)]),
            });
        });
    }, [usersValue]);

    useEffect(() => {
        if (name) {
            setName(name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        if (selected) {
            setUser(Number(selected.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        if (userId && users) {
            setSelected({ value: userId, label: users[userId] });
        }
    }, [userId, users]);

    useEffect(() => {
        if (!selectUser) {
            setSelected(null);
        }
    }, [selectUser]);

    return (
        <div className="row">
            <div className="col-12">
                <InputLabel required>
                    {t("workoutschedules.workoutscheduleName", "Trainingsplan Name")}:
                </InputLabel>
                <input
                    type="text"
                    placeholder={t("workoutschedules.workoutscheduleName", "Trainingsplan Name")}
                    className={`form-control ${nameError ? "is-invalid" : ""}`}
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div className="invalid-feedback">{nameError}</div>
            </div>
            <div className="col-12">
                <div className="toogleTemplate">
                    <button
                        className="btn btn-default"
                        onClick={() => setSelectUser(!userId ? !selectUser : true)}
                    >
                        {t("workoutschedules.addUser", "Anwender Hinzufügen")}
                    </button>
                    {!userId && (
                        <div className="templateToogle">
                            <span>{t("workoutschedules.template", "Trainingsplan Vorlage")}</span>
                            {!selectUser ? (
                                <FontAwesomeIcon
                                    className="template-icon-success"
                                    icon={faCheckCircle}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="template-icon-fail"
                                    icon={faTimesCircle}
                                />
                            )}
                        </div>
                    )}
                </div>
                {selectUser && (
                    <div className="mt-3">
                        <InputLabel>
                            {t("workoutschedules.choseUser", "Wähle einen Anwender")}
                        </InputLabel>
                        <Select
                            value={selected}
                            placeholder={t("global.selectEmptyChose", "-- Wähle --")}
                            onChange={(e) => setSelected(e)}
                            options={options}
                            isDisabled={!!edit}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Name;
