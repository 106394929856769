/** @format */

import {
    UserModel,
    ControllerAdapter,
    RequestAdapterInterface,
    Paginator,
} from "@interweberde/prima-core";
import { DateTime } from "luxon";

export interface UploadImage {
    id: number | string;
    ext?: string;
    file: string;
    type: string;
    url?: string;
}

export interface ExtendedUserModel extends UserModel {
    uploads?: UploadImage[];
    users_online: boolean;
    last_invited: DateTime | null;
    archived: DateTime | null;
    pending_email_change: string | null;
    workoutcoaches_user: {
        name: string;
    };
    prefix: string;
    fullname: string;
    salutation: string;
}

export interface ExtendedUserModelPaginator extends Paginator {
    users: ExtendedUserModel[];
}

export interface UserNotes {
    author: string;
    created: string;
    note: string;
}

export interface UserView extends UserModel {
    group: {
        name: string;
    };
    uploads?: { url: string }[];
    workoutcoaches_user?: {
        coach_id: number;
        name: string;
        email: string;
    };
    workoutnotes: UserNotes[];
    last_invited: DateTime | null;
    archived: DateTime | null;
}

export interface UserSearchModel {
    id: number;
    full: string;
    name: string;
    surname: string;
    uploads: {
        id: number;
        url: string;
    }[];
    is_admin: boolean;
    is_coach: boolean;
    is_client: boolean;
    archived?: boolean;
    coachName: string | null;
}

export interface UserSelectedModel extends UserSearchModel {
    active: boolean;
}

export interface UserAdd {
    id?: number;
    name: string;
    surname: string;
    email: string;
    username: string;
    group_id: number;
    sex: string;
    lang?: string;
    enabled?: boolean;
    reminds?: boolean;
    workoutcoaches_user?: {
        coach_id: number;
    };
    company_id?: number;
    role_id?: number;
    coach_id?: number;
    lastlogin?: boolean;
    workoutcoach?: {
        email: string;
        tel?: string;
        text?: string;
    };
}

export interface ExtendedUserChose {
    user: UserAdd;
    groups: {
        [id: number]: string;
    };
    workoutcoaches: {
        [id: number]: string;
    };
    companies: {
        [id: number]: string;
    };
}

export interface ExtendedUserSend {
    user: UserSearchModel;
    success: boolean;
    errors?: {
        [model: string]: {
            [field: string]: {
                [error_code: string]: string;
            };
        };
    };
}

export interface ExtendedUserAdd extends UserAdd {
    id?: number;
    isCoach: boolean;
}

export interface ExtendedOwnerSignup {
    user: {
        name: string;
        surname: string;
        email: string;
        username: string;
        sex: string;
        password: string;
        newPasswordAgain: string;
        privdisaccept: boolean;
    };
    mandantor: {
        name: string;
    };
    subscriptiontypeoptionId?: number;
    coach?: {
        tel: string;
        text: string;
        email: string;
    };
    success?: boolean;
    errors?: {
        [model: string]: {
            [field: string]: {
                [error_code: string]: string;
            };
        };
    };
}

export default class ExtendedUserAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    ExtendedUserModel,
    ExtendedUserModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("extended-users", "extended-user", "extended-users", adapter);
    }

    public async getIndex(search = "", archived = false): Promise<ExtendedUserModelPaginator> {
        const { data } = await this._get<ExtendedUserModelPaginator>(
            `index?archived=${String(archived)}&search=${search}`
        );

        return data;
    }

    public async getAddUser(id: string | undefined): Promise<ExtendedUserChose> {
        const url = id ? `edit/${id}` : "add";
        const { data } = await this._get<ExtendedUserChose>(`${url}`);

        return data;
    }

    public async setAddUser(
        user: ExtendedUserAdd,
        sendInvite: boolean | undefined,
        id: string | undefined
    ): Promise<ExtendedUserSend> {
        const url = id ? `edit/${id}` : "add";
        const { data } = await this._post<ExtendedUserSend>(`${url}`, {
            ...user,
            sendInvite,
        });

        return data;
    }

    public async signupOwner(saveData: ExtendedOwnerSignup): Promise<ExtendedOwnerSignup> {
        const { user, mandantor, coach, subscriptiontypeoptionId } = saveData;

        const { data } = await this._post<ExtendedOwnerSignup>("signup-owner", {
            user,
            mandantor,
            coach,
            subscriptiontypeoptionId,
        });

        return data;
    }

    public async getUser(id: number): Promise<UserView | null> {
        const { data } = await this._get<{ user: UserView | null }>(`view/${id}`);
        if (!data) {
            return null;
        }
        return data.user;
    }

    public async profileImage(): Promise<UploadImage> {
        const { data } = await this._get<{ upload: UploadImage }>(`profileImage`);

        return data.upload;
    }

    public async searchUsers(search = "", onlyClients = false): Promise<UserSearchModel[]> {
        const { data } = await this._get<{ users: UserSearchModel[] }>(
            `searchUsers?search=${search}&onlyClients=${String(onlyClients)}`
        );

        return data.users;
    }

    public async getUserCount(): Promise<number> {
        const { data } = await this._get<{ count: number }>(`get-user-count`);

        return data.count;
    }

    public async getClientCount(): Promise<number> {
        const { data } = await this._get<{ count: number }>(`get-client-count`);

        return data.count;
    }

    public async getChatUsers(search = ""): Promise<UserSearchModel[]> {
        const { data } = await this._get<{ users: UserSearchModel[] }>(
            `getChatUsers?search=${search}`
        );

        return data.users;
    }
}
