/**
 * /* eslint no-nested-ternary: 0
 *
 * @format
 */

import { Invoice } from "@api/ext/InvoiceAdapter";
import TableWithPagination from "@components/Table/TableWithPagination";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";

type InvoicesTableColumns = "select" | "name" | "created" | "options";

interface InvoicesTableProps {
    data: Invoice[];
    hideColumns?: InvoicesTableColumns[];
    selectable?: boolean;
    onSelectedChange?: (selectedIds: number[]) => void;
    multiSelectOptions?: React.ReactNode;
    rowOptions?: React.ReactNode;
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({
    data,
    hideColumns = [],
    selectable = false,
    onSelectedChange = () => null,
    multiSelectOptions = null,
    rowOptions = null,
}) => {
    const { t } = useTranslation();

    const columns = useMemo(() => {
        const nameCell = !hideColumns.includes("name")
            ? [
                  {
                      Header: t("invoice.invoiceNumber", "Rechnungsnummer"),
                      accessor: "invoice_number",
                      Cell: ({ row }: CellProps<Invoice>) => row.original.invoice_number,
                      className: "w-50",
                  },
              ]
            : [];

        const createdCell = !hideColumns.includes("created")
            ? [
                  {
                      Header: t("global.created", "Erstellt"),
                      accessor: "created",
                      Cell: ({ row }: CellProps<Invoice>) => {
                          const created: string = row.original.created
                              ? row.original.created.toString()
                              : "";

                          return (
                              <div className="flex justify-start w-100">
                                  {created === ""
                                      ? "-"
                                      : DateTime.fromISO(created).toFormat("dd.MM.yyyy")}
                              </div>
                          );
                      },
                  },
              ]
            : [];

        const urlCell = [
            {
                Header: t("invoice.link", "Link"),
                accessor: "url",
                Cell: ({ row }: CellProps<Invoice>) => {
                    if (!row.original.url) {
                        return "-";
                    }

                    return (
                        <a href={`${row.original.url}`} className="cell-link" target="_blank">
                            {t("global.goto", "zur Rechnung")}
                        </a>
                    );
                },
            },
        ];

        const rowOptionsCell = rowOptions ? [rowOptions] : [];

        return [...(nameCell || []), ...(createdCell || []), ...urlCell, ...(rowOptionsCell || [])];
    }, [hideColumns, rowOptions, t]);

    return <TableWithPagination columns={columns} data={data} selectable={selectable} />;
};

export default InvoicesTable;
