/**
 * @format
 */

/* eslint camelcase: 0 */
/* eslint react/no-array-index-key: 0 */
/* eslint react/no-access-state-in-setstate: 0 */
/* eslint-disable @typescript-eslint/unbound-method */
import { exerciseAdapter, workoutschedulesAdapter } from "@api/ApiRequest";
import { DefaultExercise } from "@api/ext/ExerciseAdapter";
import { WorkoutschedulesUsers } from "@api/ext/WorkoutschedulesAdapter";
import InputLabel from "@components/Form/InputLabel/InputLabel";
import AuthContext from "@context/AuthProvider";
import LoadingContext from "@context/LoadingProvider";
import { faSpinnerThird, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionType, SelectedOption } from "@screen/Muscles/MuscleAdd/MuscleAdd";
import debugLib from "debug";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import ExerciseCard from "./ExerciseCard";
import ExerciseSelectedCard from "./ExerciseSelectedCard";
import Name from "./Input/Name";
import Search from "./Search/Search";
import ExerciseModal from "./WorkoutschedulesModal/ExerciseModal/ExerciseModal";
import ExerciseViewModal from "./WorkoutschedulesModal/ExerciseSingleView/ExerciseSingleView";

import "./ExerciseWrapper.scss";

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "rgba(221, 181, 110, 0.2)" : "",
});

const reorder = (list: ExerciseListDefaultSet[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const debug = debugLib("machbar:workout:chose:workoutschedule");

const validateSchema = yup.object().shape({
    name: yup
        .string()
        .typeError("Es dürfen nur Buchstaben eingegeben werden.")
        .matches(
            new RegExp("^[a-zA-ZäüöÄÜÖß_\\-:0-9_ ]*$"),
            "Es dürfen nur Buchstaben eingegeben werden."
        )
        .min(5, "Es müssen mindestens 5 Zeichen eingegeben werden.")
        .max(35, "Es dürfen nicht mehr als 20 Zeichen eingegeben werden.")
        .required("Eine Eingabe ist notwendig."),
});

export interface ExercisesWorkoutscheduleDefault {
    exercises_workoutschedule_id?: number;
    id?: number;
    order?: null | number;
    value: string | number;
    wdh: string | number;
}

interface ExercisesWorkoutschedulesAdd {
    sort: number;
    primariepropertie: number;
    secondariepropertie: number;
    type: number;
    option: string;
    exercise_id: number;
    id: null | number;
    exercises_workoutschedules_sets: ExercisesWorkoutscheduleDefault[];
}

export interface ExerciseListDefaultSet {
    content: string | null;
    defaultId?: number;
    new?: boolean;
    id: number;
    option?: string;
    sort?: number;
    type: number;
    uuid: string;
    primariepropertie?: number;
    secondariepropertie?: number;
    data: ExercisesWorkoutscheduleDefault[];
}

interface ExerciseWrapperProps {
    selectedDefault?: ExerciseListDefaultSet[];
    nameDefault?: string;
    companyDefaultId?: number;
    userId?: number;
    workoutscheduleId?: number;
    edit?: boolean;
}

interface ExerciseDraggable {
    [key: string]: string;
}

interface WorkoutschedulesAddParams {
    customUserId: string | undefined;
}

const ExerciseWrapper: React.FC<ExerciseWrapperProps> = ({
    selectedDefault,
    nameDefault,
    companyDefaultId,
    userId,
    workoutscheduleId,
    edit,
}) => {
    const [t] = useTranslation();
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const { customUserId } = useParams<WorkoutschedulesAddParams>();

    const { setSaved } = useContext(LoadingContext);

    const [currUser, setCurrUser] = useState<number | null>(null);

    const [defaultUserId] = useState<undefined | number>(() => {
        if (customUserId && !edit) {
            return Number(customUserId);
        }

        if (userId) {
            return userId;
        }
    });

    const [name, setName] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const [post, setPost] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    const [exercises, setExercises] = useState<ExerciseDraggable | null>(null);
    const [exercisesDefault, setExercisesDefault] = useState<DefaultExercise[]>([]);

    const [selected, setSelected] = useState<ExerciseListDefaultSet[]>(() => {
        const selected: ExerciseListDefaultSet[] = [];

        if (selectedDefault) {
            selectedDefault.map((value: ExerciseListDefaultSet) => {
                if (value.type === 1) {
                    selected.push(value);
                }
            });
        }

        return selected;
    });
    const [selectedMain, setSelectedMain] = useState<ExerciseListDefaultSet[]>(() => {
        const selected: ExerciseListDefaultSet[] = [];

        if (selectedDefault) {
            selectedDefault.map((value: ExerciseListDefaultSet) => {
                if (value.type === 2) {
                    selected.push(value);
                }
            });
        }

        return selected;
    });
    const [selectedEnd, setSelectedEnd] = useState<ExerciseListDefaultSet[]>(() => {
        const selected: ExerciseListDefaultSet[] = [];

        if (selectedDefault) {
            selectedDefault.map((value: ExerciseListDefaultSet) => {
                if (value.type === 4) {
                    selected.push(value);
                }
            });
        }

        return selected;
    });

    const [search, setSearch] = useState<string>("");

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMainShow, setModalMainShow] = useState<boolean>(false);
    const [modalEndShow, setModalEndShow] = useState<boolean>(false);

    const [exerciseModalView, setExerciseModalView] = useState<boolean>(false);
    const [exerciseViewId, setExerciseViewId] = useState<string | null>(null);

    const [selectableCompanies, setSelectableCompanies] = useState<null | OptionType[]>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedOption>(null);

    const [exercisesData, setExercisesData] = useState<number | null>(null);

    const [users, setUsers] = useState<WorkoutschedulesUsers>({});
    const [save, setSave] = useState(false);

    const [nameError, setNameError] = useState<boolean>(false);
    const [selectError, setSelectError] = useState<boolean>(false);
    const [activeSection, setActiveSection] = useState<number | null>(null);

    const [searchString, setSearchString] = useState<string>("");

    useEffect(() => {
        if (!exerciseModalView) {
            setExerciseViewId(null);
        }
    }, [exerciseModalView]);

    useEffect(() => {
        if (nameDefault) {
            setName(nameDefault);
        }
    }, [nameDefault]);

    useEffect(() => {
        if (companyDefaultId && selectableCompanies) {
            const defCompany = selectableCompanies.find(
                (ele) => Number(ele.value) === companyDefaultId
            );

            if (defCompany) {
                setSelectedCompany(defCompany);
            }
        }
    }, [companyDefaultId, selectableCompanies]);

    useEffect(() => {
        workoutschedulesAdapter
            .getAddWorkoutschedules()
            .then((data) => {
                const { users, exercises, companies } = data;

                setLoaded(true);
                setUsers(users);

                const mapDefault = (data: { name: string; id: number }[]) => {
                    let defaultData = {};
                    data.map((value) => {
                        defaultData = { ...defaultData, [value.id]: value.name };
                    });

                    return defaultData;
                };

                setExercisesDefault(exercises);

                setExercises(mapDefault(exercises));

                const updateCompaniesSelect: { value: string; label: string }[] = [];
                Object.keys(companies).map((key) => {
                    updateCompaniesSelect.push({ value: key, label: companies[Number(key)] });
                });
                setSelectableCompanies(updateCompaniesSelect);

                if (user?.is_admin && updateCompaniesSelect[0]) {
                    setSelectedCompany(updateCompaniesSelect[0]);
                }

                debug("componendDidMound: fetch workoutschedules", data);
            })
            .catch(() => {
                setError(true);

                debug("componendDidMound: fetch failed workoutschedules", error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchByString = useCallback(
        (exerciseData: ExerciseDraggable) => {
            if (search !== null) {
                const searchValue = search.trim().toLowerCase();
                const pattern = new RegExp(searchValue);

                let exercisesList = exerciseData ? exerciseData : {};
                let exercisesKeys;

                if (search.length > 0) {
                    exercisesKeys = Object.keys(exercisesList).filter(function (exercise) {
                        return (
                            exercisesList[exercise] &&
                            pattern.exec(exercisesList[exercise].toLowerCase())
                        );
                    });

                    exercisesList = {};
                    exercisesKeys.forEach((element) => {
                        exercisesList = {
                            ...exercisesList,
                            [element]: exerciseData[element],
                        };
                    });

                    return Object.keys(exercisesList).length > 0 ? exercisesList : null;
                }
            } else {
                return null;
            }
        },
        [search]
    );

    const filterById = useCallback(
        (toSetExercise: ExerciseDraggable): Promise<ExerciseDraggable | null> =>
            new Promise((resolve) => {
                void exerciseAdapter.search(searchString).then((data) => {
                    if (data.length > 0) {
                        const mapExerciseString = (): ExerciseDraggable => {
                            let defaultData: ExerciseDraggable = {};

                            for (const [key, value] of Object.entries(toSetExercise)) {
                                if (data.includes(Number(key))) {
                                    defaultData = { ...defaultData, [key]: value };
                                }
                            }

                            return defaultData;
                        };

                        resolve(mapExerciseString());
                    } else {
                        resolve(null);
                    }
                });
            }),
        [searchString]
    );

    useEffect(() => {
        if (exercisesDefault.length !== 0) {
            const mapDefault = () => {
                let defaultData = {};
                exercisesDefault.map((value) => {
                    const checkIncludes: number[] = [];
                    value.exercisesections.map((v) => {
                        checkIncludes.push(v.id);
                    });
                    if (activeSection && checkIncludes.includes(activeSection)) {
                        defaultData = {
                            ...defaultData,
                            [value.id]: value.name,
                        };
                    } else if (activeSection === null) {
                        defaultData = {
                            ...defaultData,
                            [value.id]: value.name,
                        };
                    }
                });

                return defaultData;
            };

            let toSetExercise = mapDefault();

            if (search) {
                const getSearch = searchByString(toSetExercise);
                toSetExercise = getSearch ? getSearch : {};
            }

            if (searchString) {
                void filterById(toSetExercise).then((data) => {
                    setExercises(data);
                });
            } else {
                setExercises(toSetExercise);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection, exercisesDefault, searchString, search]);

    const handleSearchDelete = () => {
        setSearch("");
    };

    const onDragEnd = useCallback(
        (result: DropResult) => {
            const { destination, draggableId, source } = result;

            if (
                source.droppableId === "warm-up" ||
                source.droppableId === "main" ||
                source.droppableId === "end"
            ) {
                if (
                    destination === null ||
                    (destination && destination.droppableId === "droppable")
                ) {
                    if (source.droppableId === "warm-up") {
                        const removed = [...selected];

                        removed.splice(source.index, 1);

                        return setSelected(removed);
                    } else if (source.droppableId === "main") {
                        const removed = [...selectedMain];

                        removed.splice(source.index, 1);

                        return setSelectedMain(removed);
                    } else {
                        const removed = [...selectedEnd];

                        removed.splice(source.index, 1);

                        return setSelectedEnd(removed);
                    }
                }
            }

            if (destination === null) {
                return false;
            }

            if (
                destination &&
                (destination.droppableId === "warm-up" ||
                    destination.droppableId === "main" ||
                    destination.droppableId === "end")
            ) {
                if (
                    source.droppableId !== "warm-up" &&
                    source.droppableId !== "main" &&
                    source.droppableId !== "end"
                ) {
                    const select: ExerciseListDefaultSet = {
                        id: parseInt(draggableId, 10),
                        uuid: uuidv4(),
                        new: true,
                        type:
                            destination.droppableId === "warm-up"
                                ? 1
                                : destination.droppableId === "main"
                                ? 2
                                : 4,
                        content: exercises ? exercises[draggableId] : "",
                        data: [],
                    };

                    if (destination.droppableId === "warm-up") {
                        const end = selected.slice(destination.index);
                        const start = selected.slice(0, destination.index);

                        return setSelected([...start, select, ...end]);
                    } else if (destination.droppableId === "main") {
                        const end = selectedMain.slice(destination.index);
                        const start = selectedMain.slice(0, destination.index);

                        return setSelectedMain([...start, select, ...end]);
                    } else {
                        const end = selectedEnd.slice(destination.index);
                        const start = selectedEnd.slice(0, destination.index);

                        return setSelectedEnd([...start, select, ...end]);
                    }
                }

                if (source.droppableId === "warm-up") {
                    if (result.destination) {
                        const selectedValue = reorder(
                            selected,
                            result.source.index,
                            result.destination.index
                        );

                        return setSelected(selectedValue);
                    }
                } else if (source.droppableId === "main") {
                    if (result.destination) {
                        const selectedValue = reorder(
                            selectedMain,
                            result.source.index,
                            result.destination.index
                        );

                        return setSelectedMain(selectedValue);
                    }
                } else {
                    if (result.destination) {
                        const selectedValue = reorder(
                            selectedEnd,
                            result.source.index,
                            result.destination.index
                        );

                        return setSelectedEnd(selectedValue);
                    }
                }
            }

            return false;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeSection, selected, exercises, selectedEnd, selectedMain]
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setSearch("");
        } else {
            setSearch(e.target.value);
        }
    };

    const saveData = () => {
        const exercises_workoutschedules: ExercisesWorkoutschedulesAdd[] = [];

        setPost(true);

        const selectedData = [...selected, ...selectedMain, ...selectedEnd];

        selectedData.map((value, index) => {
            return exercises_workoutschedules.push({
                sort: index + 1,
                primariepropertie: value.primariepropertie ? value.primariepropertie : 0,
                secondariepropertie: value.secondariepropertie ? value.secondariepropertie : 0,
                type: value.type,
                option: value.option ? value.option : "",
                exercise_id: value.id,
                id: value.defaultId || null,
                exercises_workoutschedules_sets: value.data,
            });
        });

        const saveData = {
            exercises_workoutschedules,
            id: workoutscheduleId || undefined,
            name,
            company_id: workoutscheduleId
                ? undefined
                : user?.is_admin && selectedCompany
                ? Number(selectedCompany.value)
                : user?.company_id,
            users: {
                _ids: [currUser],
            },
        };

        if (workoutscheduleId) {
            workoutschedulesAdapter
                .edit(workoutscheduleId, saveData)
                .then((response) => {
                    debug("saveData: succes save", response);

                    setPost(false);
                    setSave(true);

                    setSaved({
                        type: "successful",
                        show: true,
                        text: t("workoutschedules.addSuccess", "Trainingsplan wurde gespeichert."),
                    });
                })
                .catch((error) => {
                    debug("saveData: save failed", error);
                });

            return;
        }

        workoutschedulesAdapter
            .add(saveData)
            .then((response) => {
                debug("saveData: succes save", response);

                setPost(false);
                setSave(true);
            })
            .catch((error) => {
                debug("saveData: save failed", error);
            });
    };

    const checkSelected = (selected: ExerciseListDefaultSet[]) => {
        let setValdidate = false;

        selected.map((select) => {
            if (select.data.length === 0) {
                setSelectError(true);

                setValdidate = true;
                return setValdidate;
            }

            return null;
        });

        return setValdidate;
    };

    const handleSubmit = (): boolean => {
        setNameError(false);

        if (selected.length > 0 || selectedMain.length > 0 || selectedEnd.length > 0) {
            setSelectError(false);

            const errSelect = checkSelected(selected);
            const errSelectMain = checkSelected(selectedMain);
            const errSelectEnd = checkSelected(selectedEnd);

            if (errSelect || errSelectMain || errSelectEnd) {
                return false;
            }

            validateSchema
                .validate(
                    { name },
                    {
                        strict: true,
                    }
                )
                .then(() => {
                    saveData();
                })
                .catch((err: { message: boolean }) => {
                    setNameError(err.message);
                });
        }
        return true;
    };

    const handleViewClick = (id: string) => {
        setExerciseModalView(true);
        setExerciseViewId(id);
    };

    const handleSectionActive = (id: number) => {
        if (activeSection === id) {
            setActiveSection(null);
        } else {
            setActiveSection(id);
        }
    };

    useEffect(() => {
        setSaveDisabled(() => {
            if (!selected.length && !selectedMain.length && !selectedEnd.length) {
                return true;
            }

            if (
                checkSelected(selected) ||
                checkSelected(selectedMain) ||
                checkSelected(selectedEnd)
            ) {
                return true;
            }

            if (!name) {
                return true;
            }

            return false;
        });
    }, [selected, selectedMain, selectedEnd, name]);

    useEffect(() => {
        if (save) {
            if (defaultUserId) {
                history.push(`/users/view/${defaultUserId}`);
            } else {
                history.push("/workoutschedules");
            }
        }
    }, [history, save, defaultUserId]);

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                Ein Problem ist Aufgetreten
            </div>
        );
    }

    if (!loaded) {
        return (
            <div className="col-12 text-center">
                <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
            </div>
        );
    }

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1>
                            {t("global.workoutschedule", "Trainingsplan")}{" "}
                            {edit ? t("global.edit", "Bearbeiten") : t("global.add", "Hinzufügen")}
                        </h1>
                        {edit ? (
                            <p>Bearbeite den Aktuellen Trainingsplan:</p>
                        ) : (
                            <p>Füge einen neuen Trainingsplan hinzu:</p>
                        )}
                    </div>
                    <div>
                        <button
                            className="btn btn-save"
                            disabled={!loaded || saveDisabled}
                            onClick={() => (!post ? handleSubmit() : null)}
                        >
                            {post && <FontAwesomeIcon icon={faSpinnerThird} spin />}{" "}
                            {t("workoutschedule.save", "Trainingsplan Speichern")}
                        </button>
                    </div>
                </div>
            </div>
            {selectableCompanies && user.is_admin && (
                <div className="row mb-3">
                    <div className="col-6">
                        <InputLabel required htmlFor="workoutscheduleCompany">
                            {t("workoutschedule.company", "Studio")}
                        </InputLabel>
                        <Select
                            id="workoutscheduleCompany"
                            value={selectedCompany}
                            required
                            onChange={(e) => setSelectedCompany(e)}
                            options={selectableCompanies}
                            isDisabled={!!workoutscheduleId}
                        />
                    </div>
                </div>
            )}
            <Name
                userId={defaultUserId}
                users={users}
                nameError={nameError}
                name={name}
                setUser={setCurrUser}
                setName={setName}
                edit={edit}
            />
            {modalShow && exercisesData !== null ? (
                <ExerciseModal
                    key="warm-up-modal"
                    selected={selected}
                    setSelected={setSelected}
                    show={modalShow}
                    exercisesData={selected[exercisesData]}
                    index={exercisesData}
                    handleModalClose={() => {
                        setModalShow(false);
                        setExercisesData(null);
                    }}
                />
            ) : null}
            {modalMainShow && exercisesData !== null ? (
                <ExerciseModal
                    key="main-modal"
                    selected={selectedMain}
                    setSelected={setSelectedMain}
                    show={modalMainShow}
                    exercisesData={selectedMain[exercisesData]}
                    index={exercisesData}
                    handleModalClose={() => {
                        setModalMainShow(false);
                        setExercisesData(null);
                    }}
                />
            ) : null}
            {modalEndShow && exercisesData !== null ? (
                <ExerciseModal
                    key="end-modal"
                    selected={selectedEnd}
                    setSelected={setSelectedEnd}
                    show={modalEndShow}
                    exercisesData={selectedEnd[exercisesData]}
                    index={exercisesData}
                    handleModalClose={() => {
                        setModalEndShow(false);
                        setExercisesData(null);
                    }}
                />
            ) : null}
            <div className="row mt-5">
                <div className="col">
                    <h4 className="font-weight-light">Übungen Hinzufügen</h4>
                </div>
            </div>
            <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                <div className="row mb-2 align-items-center">
                    <div className="col-6">
                        <input
                            onChange={(e) => handleSearch(e)}
                            type="text"
                            placeholder={t("global.search", "Suche")}
                            value={search}
                            className="form-control deleteIconSpace"
                        />
                        {search && (
                            <div className="searchIconDelete" onClick={() => handleSearchDelete()}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </div>
                        )}
                    </div>
                    <div className="col-6">
                        <div className="btn-group btn-group-sm w-100" role="group">
                            <button
                                onClick={() => handleSectionActive(1)}
                                className={`btn brn-primary col-4 select-btn-active ${
                                    activeSection === 1 ? "section-active" : ""
                                }`}
                            >
                                {t("global.warmUp", "Warm Up")}
                            </button>
                            <button
                                onClick={() => handleSectionActive(2)}
                                className={`btn brn-primary col-4 select-btn-active ${
                                    activeSection === 2 ? "section-active" : ""
                                }`}
                            >
                                {t("global.main", "Hauptteil")}
                            </button>
                            <button
                                onClick={() => handleSectionActive(4)}
                                className={`btn brn-primary col-4 select-btn-active ${
                                    activeSection === 4 ? "section-active" : ""
                                }`}
                            >
                                {t("global.cooldown", "Cooldown")}
                            </button>
                        </div>
                    </div>
                    <div className="col-12">
                        <Search setSearchString={setSearchString} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 draggable-exercises-list">
                        {!exercises ? <div className="noExercise">Keine Übung gefunden</div> : null}
                        <Droppable key="droppable" droppableId="droppable" type="normal">
                            {(provided) => (
                                <div
                                    className="draggable-target"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {exercises &&
                                        Object.keys(exercises).map((exercis, index) => (
                                            <ExerciseCard
                                                key={index}
                                                handleViewClick={handleViewClick}
                                                exercisId={exercis}
                                                exercisName={exercises[exercis]}
                                                index={index}
                                            />
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="col-6 target-droppable-field">
                        <h6 className="exercise-target-name">{t("global.warmUp", "Warm Up")}</h6>
                        <Droppable key="warm-up" droppableId="warm-up" type="normal">
                            {(provided, snapshot) => (
                                <div
                                    className="draggable-exercises-type"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {selected &&
                                        selected.map((exercis, index) => (
                                            <React.Fragment key={`warm-up-key${index}`}>
                                                {exercis.type === 1 ? (
                                                    <ExerciseSelectedCard
                                                        dragging={snapshot.isDraggingOver}
                                                        dragElement={
                                                            snapshot.draggingFromThisWith
                                                                ? snapshot.draggingFromThisWith
                                                                : ""
                                                        }
                                                        key={`warm-up${index}`}
                                                        exercisId={exercis.uuid}
                                                        exercisType={exercis.type}
                                                        exercisName={exercis.content ?? ""}
                                                        exercisData={exercis.data}
                                                        index={index}
                                                        handleModalShow={(id) => {
                                                            setModalShow(true);
                                                            setExercisesData(id);
                                                        }}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <h6 className="exercise-target-name mt-1">
                            {t("global.main", "Hauptteil")}
                        </h6>
                        <Droppable key="main" droppableId="main" type="normal">
                            {(provided, snapshot) => (
                                <div
                                    className="draggable-exercises-type"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {selectedMain &&
                                        selectedMain.map((exercis, index) => (
                                            <React.Fragment key={`main-up-ke${index}`}>
                                                {exercis.type === 2 ? (
                                                    <ExerciseSelectedCard
                                                        dragging={snapshot.isDraggingOver}
                                                        dragElement={
                                                            snapshot.draggingFromThisWith
                                                                ? snapshot.draggingFromThisWith
                                                                : ""
                                                        }
                                                        key={`main${index}`}
                                                        exercisId={exercis.uuid}
                                                        exercisType={exercis.type}
                                                        exercisName={exercis.content ?? ""}
                                                        exercisData={exercis.data}
                                                        index={index}
                                                        handleModalShow={(id) => {
                                                            setModalMainShow(true);
                                                            setExercisesData(id);
                                                        }}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <h6 className="exercise-target-name mt-1">
                            {t("global.cooldown", "Cooldown")}
                        </h6>
                        <Droppable droppableId="end" type="normal">
                            {(provided, snapshot) => (
                                <div
                                    className="draggable-exercises-type"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {selectedEnd &&
                                        selectedEnd.map((exercis, index) => (
                                            <React.Fragment key={`end-up-key${index}`}>
                                                {exercis.type === 4 ? (
                                                    <ExerciseSelectedCard
                                                        dragging={snapshot.isDraggingOver}
                                                        dragElement={
                                                            snapshot.draggingFromThisWith
                                                                ? snapshot.draggingFromThisWith
                                                                : ""
                                                        }
                                                        key={`end${index}`}
                                                        exercisId={exercis.uuid}
                                                        exercisType={exercis.type}
                                                        exercisName={exercis.content ?? ""}
                                                        exercisData={exercis.data}
                                                        index={index}
                                                        handleModalShow={(id) => {
                                                            setModalEndShow(true);
                                                            setExercisesData(id);
                                                        }}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 offset-6 select-error">
                        {selectError ? "Bitte fügen Sie überall einen Satz hinzu." : ""}
                    </div>
                </div>
            </DragDropContext>
            {exerciseViewId && exerciseModalView ? (
                <ExerciseViewModal
                    setShow={setExerciseModalView}
                    show={exerciseModalView}
                    id={exerciseViewId}
                />
            ) : null}
        </>
    );
};

export default ExerciseWrapper;
