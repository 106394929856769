/** @format */

import { faCheck, faInfoCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PasswordStrengthProps {
    password: string;
    title?: boolean;
    callback?: (value: boolean) => void;
}

const PasswordStrength: React.FC<PasswordStrengthProps> = ({
    password,
    title = true,
    callback = null,
}) => {
    const [t] = useTranslation();

    const [hasLowercase, setHasLowercase] = useState<boolean>(false);
    const [hasUppercase, setHasUppercase] = useState<boolean>(false);
    const [hasNumber, setHasNumber] = useState<boolean>(false);
    const [hasLength, setHasLength] = useState<boolean>(false);

    useEffect(() => {
        setHasLowercase(password.match(/[a-z]/g) !== null);
        setHasUppercase(password.match(/[A-Z]/g) !== null);
        setHasNumber(password.match(/[0-9]/g) !== null);
        setHasLength(password.length >= 8);
    }, [password]);

    useEffect(() => {
        callback && callback(hasLowercase && hasUppercase && hasNumber && hasLength);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasLength, hasLowercase, hasNumber, hasUppercase]);

    return (
        <>
            {title && <h5>{t("password.infoHeadline", "Sicheres Kennwort")}</h5>}
            <p className="text">
                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                {t(
                    "password.info",
                    "Stelle bitte sicher das Du ein Sicheres Kennwort für Deinen Account vergibst."
                )}
            </p>

            <ul className="checklist">
                <li className={`${hasNumber ? "fullfilled" : ""}`}>
                    <div className="checker">
                        {hasNumber ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} />
                        )}
                    </div>
                    {t("password.infoUL.1", "min. 1 Zahl sollte enthalten sein")}
                </li>
                <li className={`${hasUppercase ? "fullfilled" : ""}`}>
                    <div className="checker">
                        {hasUppercase ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} />
                        )}
                    </div>
                    {t("password.infoUL.2", "min. 1 Großbuchstabe sollte enthalten sein")}
                </li>
                {/* <li>
                    {t("password.infoUL.3", "min. 1. Sonderzeichen sollte enthalten sein")}
                </li> */}
                <li className={`${hasLowercase ? "fullfilled" : ""}`}>
                    <div className="checker">
                        {hasLowercase ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} />
                        )}
                    </div>
                    {t("password.infoUL.5", "min. 1 Kleinbuchstaben sollte enthalten sein")}
                </li>
                <li className={`${hasLength ? "fullfilled" : ""}`}>
                    <div className="checker">
                        {hasLength ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} />
                        )}
                    </div>
                    {t("password.infoUL.4", "das Kennwort sollte mindestens 8 Zeichen lang sein")}
                </li>
            </ul>
        </>
    );
};

export default PasswordStrength;
