/** @format */

import { workoutschedulesAdapter } from "@api/ApiRequest";
import { WorkoutschedulesModel } from "@api/ext/WorkoutschedulesAdapter";
import ConfirmDialogue from "@components/ConfirmDialogue/ConfirmDialogue";
import LoadingContext from "@context/LoadingProvider";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Link,
    NavLink,
    Route,
    Switch,
    useHistory,
    useParams,
    useRouteMatch,
} from "react-router-dom";

import AssignedExercises from "./AssignedExercises/AssignedExercises";
import AssignedUsers from "./AssignedUsers/AssignedUsers";

import "./WorkoutschedulesView.scss";

interface WorkoutschedulesViewParams {
    id: string | undefined;
}

const WorkoutschedulesView: React.FC = () => {
    const [t] = useTranslation();
    const { id } = useParams<WorkoutschedulesViewParams>();
    const history = useHistory();
    const { setSaved } = useContext(LoadingContext);
    const { path, url } = useRouteMatch();

    const [workoutschedule, setWorkoutschedule] = useState<WorkoutschedulesModel | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDelete = useCallback(() => {
        if (!workoutschedule) {
            return;
        }

        setShowDeleteModal(true);
    }, [workoutschedule]);

    const doDelete = useCallback(() => {
        if (!workoutschedule?.id) {
            return;
        }

        void workoutschedulesAdapter.delete(workoutschedule.id).then((response) => {
            setSaved({
                type: "successful",
                show: true,
                text: t(
                    "workoutschedule.workoutscheduleDeleted",
                    "Trainingsplan {{name}} wurde gelöscht.",
                    {
                        name: workoutschedule.name,
                    }
                ),
            });

            history.push("/workoutschedules");
        });
    }, [workoutschedule, t, history, setSaved]);

    useEffect(() => {
        if (!id) {
            return;
        }

        workoutschedulesAdapter
            .view(Number(id))
            .then((workoutschedule) => {
                setWorkoutschedule(workoutschedule);
                setLoaded(true);
            })
            .catch((e) => {
                console.log("failed", e);
            });
    }, [id]);

    if (!loaded) {
        return (
            <div className="col-12 text-center">
                <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
            </div>
        );
    }

    if (!workoutschedule) {
        return null;
    }

    return (
        <div className="workoutschedules-view">
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center header-text">
                        <h4>
                            <small>{t("global.workoutschedule", "Trainingsplan")}</small>
                        </h4>
                        <h3>{workoutschedule.name}</h3>
                        <h4>
                            <small>{workoutschedule.template ? "Vorlage" : ""}</small>
                        </h4>
                    </div>
                    <div className="d-flex btn-group align-items-center">
                        <Link
                            className="btn btn-sm btn-light"
                            to={`/workoutschedules/edit/${workoutschedule.id}`}
                        >
                            {t("global.edit", "Bearbeiten")}
                        </Link>
                        <button className="btn btn-sm btn-danger-light" onClick={onDelete}>
                            {t("global.delete", "Löschen")}
                        </button>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <table className="table table-striped table-small">
                        <tbody>
                            <tr>
                                <th scope="row">{t("global.created", "Erstellt")}</th>
                                <td>
                                    {DateTime.fromISO(String(workoutschedule.created), {
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy")}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{t("global.modified", "Geändert")}</th>
                                <td>
                                    {DateTime.fromISO(String(workoutschedule.modified), {
                                        locale: "de",
                                    }).toFormat("dd LLL. yyyy")}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-12 mt-3">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to={`${url}`}
                                activeClassName="active"
                                className="nav-link"
                            >
                                <span>
                                    {t("workoutschedules.assignedExercieses", "Zugehörige Übungen")}
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${url}/users`}
                                className="nav-link"
                            >
                                <span>
                                    {t("workoutschedules.assignedUsers", "Zugewiesene Benutzer")}
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-3">
                {!workoutschedule ? (
                    <div className="col-12 text-center">
                        <FontAwesomeIcon icon={faSpinnerThird} spin size="3x" />
                    </div>
                ) : (
                    <Switch>
                        <Route exact path={path}>
                            <AssignedExercises
                                exercisesWorkoutschedules={
                                    workoutschedule.exercises_workoutschedules
                                        ? workoutschedule.exercises_workoutschedules
                                        : []
                                }
                            />
                        </Route>
                        <Route path={`${path}/users`}>
                            <AssignedUsers workoutworkflows={workoutschedule} />
                        </Route>
                    </Switch>
                )}
            </div>
            {showDeleteModal && workoutschedule && (
                <ConfirmDialogue
                    title={t("workoutschedules.deleteModalTitle", "Trainingsplan löschen")}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onSubmit={doDelete}
                >
                    <div className="p-3">
                        <p>
                            {t(
                                "workoutschedules.singleDeleteText",
                                "Möchten Sie den Trainingsplan {{name}} jetzt löschen?",
                                {
                                    name: workoutschedule.name,
                                }
                            )}
                        </p>
                    </div>
                </ConfirmDialogue>
            )}
        </div>
    );
};

export default WorkoutschedulesView;
